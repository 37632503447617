import React, {Component, useEffect, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {translation} from 'helpers/utilsHelper.js';
import {getTimeFilterRange, formatDate, substractDays, compareAsc} from 'helpers/datesHelper.js';
import {groupTransactionsByData} from 'helpers/transactionsHelper.js';
import transactionTypesEnum from 'transactionTypes.enum.js';
import Datepicker from 'Datepicker.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _some from 'lodash/some';
import _omit from 'lodash/omit';
import _find from 'lodash/find';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import classNames from 'classnames';
import S from 'StyledAccountBetHistory.js';
import {
    changeDateByType,
    changeActiveTab,
    changeActiveFilter,
    setCurrentPage,
    fetchTransactionsByFilter,
    fetchCashoutsMaximumAmount,
    resetBetHistoryList,
    refreshBetHistoryList,
    processCashoutAmount,
    processRebet,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId
} from 'accountBetHistoryActions.js';

import BetHistoryTransactionItem from 'BetHistoryTransactionItem.js';
import TvBetHistoryTransactionItem from 'TvBetHistoryTransactionItem.js';
import { toggleAccountModal } from 'accountActions.js';

class AccountBetHistory extends Component {

    state = {
        isCountDownRequired: false,
    }

    componentDidMount = async() => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, activeFilter, currentPage} = this.props;
        
        let transactionPageLimitations;
        currentPage == 0? (transactionPageLimitations = currentPage) : (transactionPageLimitations = currentPage * limit)
        
        const params = {
            endDate,
            startDate,
            limit,
            activeTab,
            activeFilter,
            currentPage: transactionPageLimitations
        };
        try {
            const data = await fetchTransactionsByFilter(params);
        } catch (error) {
            console.log('fetchTransactionsByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('startDate', startDate);
        fetchTransactionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, activeTab, fetchTransactionsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit,
            activeTab
        };

        changeDateByType('endDate', endDate);
        fetchTransactionsByFilter(params);
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchTransactionsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit
        };

        changeActiveTab(activeTab);
        fetchTransactionsByFilter(params);
    };

    onChangeRange = (activeFilter) => {
        const {changeActiveFilter, changeDateByType, fetchTransactionsByFilter, limit, activeTab} = this.props;

        changeActiveFilter(activeFilter);
        const dataRangeObj = getTimeFilterRange(activeFilter);

        changeDateByType('startDate', dataRangeObj['startDate']);
        changeDateByType('endDate', dataRangeObj['endDate']);

        const params = {
            activeTab,
            startDate: dataRangeObj['startDate'],
            endDate: dataRangeObj['endDate'],
            limit
        };

        fetchTransactionsByFilter(params);
    };

    setStartDate = (days) => {
        const {changeDateByType, fetchTransactionsByFilter, activeTab, currentPage, limit} = this.props;
        const today = new Date();
        const startDate = substractDays(today, days);
        const endDate = days == 1 ? startDate : today;
        const params = {
            startDate,
            activeTab,
            endDate,
            currentPage,
            limit
        };

        fetchTransactionsByFilter(params);
        changeDateByType('startDate', startDate);
        changeDateByType('endDate', endDate);
    };

    compareDates = (dateLeft, dateRight) => {
        dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
        dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
        const result = compareAsc(dateLeft, dateRight);
        if (!result) {
            return true;
        }
        return false;
    };

    prepareCashout = async(slipId) => {
        const {fetchCashoutsMaximumAmount} = this.props;
        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            if (maxAmount.countDownRequired != undefined) {
                this.setState({isCountDownRequired: maxAmount.countDownRequired});
            }
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };

    processCashout = async(config, abortControllerSignal) => {
        const curtainDelay = async(delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({message: translation('account_cashoutForm_aborted')});
                });
            });
            return p;
        };

        const {processCashoutAmount, processLiveCurtainForTxId, transactionsList} = this.props;
        try {
            let slipId = _get(config, ['slipId']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
            if (hasCashoutLiveCurtain && this.state.isCountDownRequired) {
                const curtainData = _omit({...config}, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            }else{
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    processRebet = async(eventId) => {
        const {processRebet} = this.props;
        try {
            const result = await processRebet(eventId);
            return result;
        } catch (error) {
            throw error;
        }
    };

    renderTransactions = () => {

        const {                            
            currencyCode,
            transactionsList,
            cashouts,
            activeFreebets,
            refreshBetHistoryList,
            toggleLiveCurtainForTxId
        } = this.props;

        let transactionsGroupedByData = groupTransactionsByData(transactionsList, "regDate");

        let temporaryJsxContainer = [];

        for (let property in transactionsGroupedByData) {


            temporaryJsxContainer.push(
                <Fragment key={property}>
                    <S.transactionDataHeader key={property}>
                        <S.transactionDataHeaderInfo>
                            <S.HeaderInfoRegData>{property}</S.HeaderInfoRegData>
                            <S.HeaderInfoDataLabel>{translation('account_betHistory_stake')}</S.HeaderInfoDataLabel>
                            <S.HeaderInfoDataLabel>{translation('account_betHistory_win')}</S.HeaderInfoDataLabel>
                        </S.transactionDataHeaderInfo>
                            {_map(transactionsGroupedByData[property], (transaction, index) => {
                                const transactionItemProps = {
                                    index,
                                    currencyCode,
                                    transaction,
                                    transactionsList,
                                    cashouts,
                                    activeFreebets,
                                    refreshBetHistoryList,
                                    toggleLiveCurtainForTxId
                                };
                                if(transactionTypesEnum.TV_BET_TRANSACTIONS.indexOf(transaction.transactionType) > -1){
                                    return (     
                                        <TvBetHistoryTransactionItem key={transaction.transactionId}
                                                                className="transaction-list-item" 
                                                                {...transactionItemProps}
                                                                toggleAccountModal={toggleAccountModal}
                                        >
                                        </TvBetHistoryTransactionItem>
                                        )
                                } else {
                                    return (
                                        <BetHistoryTransactionItem key={transaction.transactionId}
                                                                className="transaction-list-item" 
                                                                {...transactionItemProps}
                                                                prepareCashout={this.prepareCashout}
                                                                processCashout={this.processCashout}
                                                                processRebet={this.processRebet}
                                                                toggleAccountModal={toggleAccountModal}
                                        >
                                        </BetHistoryTransactionItem>
                                        )
                                    }
                                })
                            }
                    </S.transactionDataHeader>
                </Fragment>
            )
        }

        return temporaryJsxContainer;
    }

    goPage = async(currentPage) => {
        const {fetchTransactionsByFilter, startDate, endDate, limit, activeTab, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            activeTab,
            endDate,
            startDate,
            limit,
            currentPage: offset
        };
        try {
            await fetchTransactionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    render() {

        let { isPending, error, activeTab, startDate, endDate, limit, transactionsList, currentPage} = this.props;
        
        let transactionsJsx = [];

        //loop over sorted object with data, and then map single transactions
        if(transactionsList.length > 0) { 
            transactionsJsx = this.renderTransactions();
        }
        const today = new Date();

        return (
            <S.AccountBetHistory className="account-bet-history">
                <S.BetHistoryFilters className="bet-history-filters">
                    <S.BetHistoryFiltersRow>
                        <S.BetHistoryFiltersDates className="bet-history-filters-dates">

                            <S.DateWrapper className="date-wrapper">
                                <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                                <Datepicker
                                    selected={startDate}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    cb={this.handleChangeStart}
                                />
                                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                            </S.DateWrapper>

                            <S.DateWrapper className="date-wrapper">
                                <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                                <Datepicker
                                    selected={endDate}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    dateFormat="yyyy-MM-dd"
                                    cb={this.handleChangeEnd}
                                />
                                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                            </S.DateWrapper>

                        </S.BetHistoryFiltersDates>

                        <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">

                            <S.FilterBtn isActive={(this.compareDates(startDate, today) && this.compareDates(endDate, today))}
                                         onClick={this.setStartDate.bind(this, 0)}>
                                {translation('account_betHistory_range_today')}
                            </S.FilterBtn>

                            <S.FilterBtn isActive={(this.compareDates(startDate, substractDays(today, 1)) && this.compareDates(endDate, substractDays(today, 1)))}
                                         onClick={this.setStartDate.bind(this, 1)}>
                                {translation('account_betHistory_range_yesterday')}
                            </S.FilterBtn>

                            <S.FilterBtn isActive={(this.compareDates(startDate, substractDays(today, 7)) && this.compareDates(endDate, today))}
                                         onClick={this.setStartDate.bind(this, 7)}>
                                {translation('account_betHistory_range_week')}
                            </S.FilterBtn>

                            <S.FilterBtn isActive={(this.compareDates(startDate, substractDays(today, 30)) && this.compareDates(endDate, today))}
                                         onClick={this.setStartDate.bind(this, 30)}>
                                {translation('account_betHistory_range_month')}
                            </S.FilterBtn>

                        </S.BetHistoryFiltersBtns>
                    </S.BetHistoryFiltersRow>
                    <S.BetHistoryFiltersRow>
                        <S.BetHistoryFiltersBtns className="bet-history-btns-wrapper">
                            <S.FilterBtn
                                onClick={this.onChangeFilter.bind(this, 'all')}
                                className={classNames({active: (activeTab == 'all')})}>{translation('account_betHistory_types_all')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeFilter.bind(this, 'lost')}
                                className={classNames({active: (activeTab == 'lost')})}>{translation('account_betHistory_types_lost')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeFilter.bind(this, 'won')}
                                className={classNames({active: (activeTab == 'won')})}>{translation('account_betHistory_types_won')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeFilter.bind(this, 'undecided')}
                                className={classNames({active: (activeTab == 'undecided')})}>{translation('account_betHistory_types_undecided')}</S.FilterBtn>
                            <S.FilterBtn
                                onClick={this.onChangeFilter.bind(this, 'tvBet')}
                                className={classNames("tvBet",{active: (activeTab == 'tvBet')})}>{translation('account_betHistory_types_tvbet')}</S.FilterBtn>
                        </S.BetHistoryFiltersBtns>
                    </S.BetHistoryFiltersRow>
                </S.BetHistoryFilters>

                <S.BetHistoryTransactions className="bet-history-transaction-list">
                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                            <S.BetHistoryTransactionsList className="transaction-list">
                                {_size(transactionsList) > 0 && (transactionsJsx)}

                                {!_size(transactionsList) && (
                                    <S.NoResult className="no-result">{translation('account_betHistory_transfersNotFound')}</S.NoResult>)}

                            </S.BetHistoryTransactionsList>

                            {_size(transactionsList) > 0 && (
                                <Pagination currentPage={currentPage} limit={limit} data={transactionsList}
                                            cb={this.goPage}/>)}

                            </>
                        )
                    }
                </S.BetHistoryTransactions>

            </S.AccountBetHistory>
        );
    }
}

const mapStateToProps = ({AccountBetHistory:{isPending, error, startDate, endDate, limit, transactionsList, currentPage, activeTab, activeFilter, cashouts}, AccountFreebet:{freebets:{active:{data:activeFreebets}}}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        limit,
        currencyCode,
        activeTab,
        activeFilter,
        transactionsList,
        cashouts,
        activeFreebets,
        currentPage,
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeActiveFilter: bindActionCreators(changeActiveFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
        fetchCashoutsMaximumAmount: bindActionCreators(fetchCashoutsMaximumAmount, dispatch),
        resetBetHistoryList: bindActionCreators(resetBetHistoryList, dispatch),
        refreshBetHistoryList: bindActionCreators(refreshBetHistoryList, dispatch),
        processCashoutAmount: bindActionCreators(processCashoutAmount, dispatch),
        processRebet: bindActionCreators(processRebet, dispatch),
        toggleAccountModal: bindActionCreators(toggleAccountModal, dispatch),
        processLiveCurtainForTxId: bindActionCreators(processLiveCurtainForTxId, dispatch),
        toggleLiveCurtainForTxId: bindActionCreators(toggleLiveCurtainForTxId, dispatch),
        dispatch
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountBetHistory));
