import React, { useState, useEffect } from 'react';
import {bindActionCreators} from 'redux';
import {Field, reduxForm, SubmissionError, destroy} from 'redux-form';
import {translation, redirect} from 'helpers/utilsHelper.js';
import classNames from 'classnames';
import {logInUser} from 'authActions.js';
import {getFavouriteLeagues} from 'favouritesActions.js';
import {setGoogleDataLayerCookie} from 'cookiesHelper.js';
import {connect, useDispatch} from 'react-redux';
import S from 'StyledLoginForm.js';
import CmsPage from 'CmsPage.js';
// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.LOGIN);
    return errors;
};

const renderField = ({
    input,
    type,
    placeholder,
    errorSubmission,
    passwordInputText,
    setPasswordInputText,
    isPassword,
    meta: {touched, error, warning, submitFailed}
}) => {

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && (error || errorSubmission))
    });

    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" placeholder={placeholder} className={inputClass}/>
                {isPassword && <S.VisiblePassword onClick={()=>setPasswordInputText(!passwordInputText)}></S.VisiblePassword>}
            </S.InputWrapper>
        </S.Row>
    )
};



const LoginForm = ({logInUser, openForgotPasswordModal, handleSubmit, pristine, submitting, error}) => {

    const [passwordInputText, setPasswordInputText] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        destroy('login'); //remove store on init
    },[])

    // similar method in RegisterWizardForm
    const onFormSubmit = async(values) => {
        try {
            const user = await logInUser(values);
            const {userId} = user;
            if (userId) {
                setGoogleDataLayerCookie(userId);
            }
            dispatch(getFavouriteLeagues());
            app.partial.EventListCountryList.showFavouritesOnSubmit();
            return user
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <>
            <S.LogoWrapper>
                <S.Link href={`/`}>
                    <S.Logo src='/images/svg/logo-desktop.svg' alt='fuksiarz.pl'/>
                </S.Link>
            </S.LogoWrapper>
            <S.RegisterButton onClick={()=>redirect('/rejestracja')} data-translation='loginForm_registerButton'>{translation('loginForm_registerButton')}</S.RegisterButton>
            <CmsPage name="login-popup-banner" isPlaces={true}/>
            <S.LoginForm method="post" onSubmit={handleSubmit(onFormSubmit)}>
                <S.Header>
                    <S.Title>
                        {translation('loginModal_header')}
                    </S.Title>
                </S.Header>

                <S.LoginFormTop>

                    <Field
                        name="login"
                        type="text"
                        component={renderField}
                        passwordInputText={passwordInputText}
                        setPasswordInputText={setPasswordInputText}
                        errorSubmission={error}
                        placeholder={translation('loginModal_phoneNumberPlaceholder')}
                    />

                    <Field
                        name="password"
                        type={passwordInputText ? "text" : "password"}
                        component={renderField}
                        passwordInputText={passwordInputText}
                        setPasswordInputText={setPasswordInputText}
                        errorSubmission={error}
                        placeholder={translation('loginModal_passwordPlaceholder')}
                        isPassword={true}
                    />

                    {error && <S.SubmissionError className="submission-error" dangerouslySetInnerHTML={{__html: error}}/>}
                    
                    <S.RememberMe>
                        <S.RememberMeChbox as={Field} component="input" type="checkbox" name="autologin"/>
                        <S.RememberMeFakeChbox></S.RememberMeFakeChbox>
                        <S.RememberMeText>{translation('loginModal_rememberMe')}</S.RememberMeText>
                    </S.RememberMe>

                    <S.SubmitBtn type="submit" data-test="loginForm_login" disabled={submitting}>{translation('loginModal_logIn')}</S.SubmitBtn>

                </S.LoginFormTop>

                <S.LoginFormBottom>
                
                    <S.RegisterUserRow>
                        <S.RegisterUserQuestion>
                            {translation('loginModal_registerUserQuestion')}
                        </S.RegisterUserQuestion>
                        <S.RegisterUserLink onClick={()=>redirect('/rejestracja')}>
                            {translation('loginModal_registerUser')}
                        </S.RegisterUserLink>
                    </S.RegisterUserRow>

                    <S.ForgetPasswordLink onClick={openForgotPasswordModal}>
                        {translation('loginModal_forgotPassword')}
                    </S.ForgetPasswordLink>
                </S.LoginFormBottom>
            </S.LoginForm>
        </>
    );
}

const mapDispatchToProps = dispatch => {
    const bindedActions = {
        logInUser,
    };
    return {
        ...bindActionCreators(bindedActions, dispatch),
        openForgotPasswordModal: () =>  dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: {key: 'TOGGLE_OPEN_FORGOT_PASSWORD_MODAL', isOpen: true}
        })
    }
}

export default  connect(null, mapDispatchToProps)(reduxForm({
    form: 'login',
    validate,
})(LoginForm));


