import React, {useEffect, useState} from 'react';
import {useDispatch, connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledUnloggedPanel.js';
import {translation, redirect} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import ForgotPasswordForm from 'ForgotPasswordForm.js';
import ResetPasswordForm from 'ResetPasswordForm.js';
import AccountActivate from 'AccountActivate.js';

const UnloggedPanel = ({forgotPasswordOnSubmit, resetPasswordOnSubmit, isResetPasswordModalOpen, isForgotPasswordModalOpen, isActivateAccountModalOpen}) => {

    const dispatch = useDispatch();
    const [showForgetPasswordModal, toggleForgetPasswordModal] = useToggle(false);
    const [showResetPasswordModal, toggleResetPasswordModal] = useToggle(isResetPasswordModalOpen);
    const [showActivateAccountModal, toggleActivateAccountModal] = useToggle(isActivateAccountModalOpen);

    useEffect(() => {
        if (showResetPasswordModal && !isResetPasswordModalOpen) {
            toggleResetPasswordModal();
        } else if (!showResetPasswordModal && isResetPasswordModalOpen) {
            toggleResetPasswordModal();
        }
    }, [isResetPasswordModalOpen]);

    useEffect(() => {
        if (showForgetPasswordModal && !isForgotPasswordModalOpen) {
            toggleForgetPasswordModal();
        } else if (!showForgetPasswordModal && isForgotPasswordModalOpen) {
            toggleForgetPasswordModal();
        }
    }, [isForgotPasswordModalOpen]);

    useEffect(() => {
        if (showActivateAccountModal && !isActivateAccountModalOpen) {
            toggleActivateAccountModal();
        } else if (!showActivateAccountModal && isActivateAccountModalOpen) {
            toggleActivateAccountModal();
        }
    }, [isActivateAccountModalOpen]);

    const onActivationModalClose = () => {
        // disable toggle - if line below is active we cannot close modal ERROR_400 - it's still showing - DK 13.08.2021 
        // toggleActivateAccountModal();
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: {key: 'TOGGLE_OPEN_ACTIVATE_ACCOUNT_MODAL', isOpen: false}
        });
    };

    const onForgotPasswordModalClose = () => {
        toggleForgetPasswordModal();
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: { key: 'TOGGLE_OPEN_FORGOT_PASSWORD_MODAL', isOpen: false}
        });
    };

    return (
        <S.UnloggedPanel className="unlogged-panel-box">

            <S.Button className="register-btn"
                    data-test="account_unloggedPanel_header_register"
                      onClick={()=>redirect('/rejestracja')}>
                {translation('account_unloggedPanel_header_register')}
            </S.Button>

            <S.Button className="register-btn"
                data-test="account_unloggedPanel_header_login"
                onClick={()=>redirect('/login')}>
                    {translation('loginModal_logIn')}
            </S.Button>

            {/* ACCOUNT ACTIVATION MODAL */}
            <ModalOpacity isOpen={showActivateAccountModal}
                          showHeader={false}
                          toggleOpen={onActivationModalClose}>

                <AccountActivate onActivationModalClose={onActivationModalClose}/>

            </ModalOpacity>

            {/* PASSWORD RECOVERY MODAL */}
            <ModalOpacity isOpen={showForgetPasswordModal}
                          toggleOpen={onForgotPasswordModalClose}
                          title={translation('forgotPasswordForm_title')}
                          showHeaderIcon={false}
                          showCloseIcon={true}
                          padding={0}>

                <ForgotPasswordForm forgotPasswordOnSubmit={forgotPasswordOnSubmit}/>

            </ModalOpacity>

            {/* RESET PASSWORD MODAL */}
            <ModalOpacity isOpen={showResetPasswordModal}
                          toggleOpen={toggleResetPasswordModal}
                          showHeaderIcon={false}
                          showCloseIcon={true}
                          padding={0}
                          title={translation('account_unloggedPanel_header_newPassword')}>

                <ResetPasswordForm resetPasswordOnSubmit={resetPasswordOnSubmit}/>

            </ModalOpacity>


        </S.UnloggedPanel>
    );
};


export default UnloggedPanel;
