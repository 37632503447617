import styled,{css} from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js';
const S = {};

const CashoutLabelColor = ({ theme: { $accountProfileBetHistoryCashoutLabel } }) => `${$accountProfileBetHistoryCashoutLabel}`;
const CashoutHeaderColor = ({ theme: { $accountProfileBetHistoryCashoutHeader } }) => `${$accountProfileBetHistoryCashoutHeader}`;
const CashoutLightTextColor = ({ theme: { $accountProfileBetHistoryCashoutLightText } }) => `${$accountProfileBetHistoryCashoutLightText}`;
const CashoutDarkTextColor = ({ theme: { $accountProfileBetHistoryCashoutDarkText } }) => `${$accountProfileBetHistoryCashoutDarkText}`;
const CashoutThemeColor = ({ theme: { $accountProfileBetHistoryCashoutThemeColor } }) => `${$accountProfileBetHistoryCashoutThemeColor}`;
const CashoutDisabledText = ({ theme: { $accountProfileBetHistoryCashoutSubmitDisabledText } }) => `${$accountProfileBetHistoryCashoutSubmitDisabledText}`;
const CashoutDisabledBackground = ({ theme: { $accountProfileBetHistoryCashoutSubmitDisabledBackground } }) => `${$accountProfileBetHistoryCashoutSubmitDisabledBackground}`;

S.CashoutForm = styled(Form)`
    position: relative;
    width: 41.67rem;
    padding: 0.83rem;
`;

S.Row = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
`;

S.CashoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.67rem 0.83rem;
    background: ${CashoutThemeColor};
`;

S.Title = styled.h2`
    margin: 0;
    padding: 0;
    float: none!important;
    text-align: center;
`;

S.CloseIcon = styled.div`
    position: absolute;
    top: -1.33rem;
    right: 0;
    padding: 0;
    transform: none;
    font-size: 2rem;
    color: #000;
    cursor: pointer;
        &:before {
        content: "\\00d7";
        font-size: 2.92rem;
    }
`;

S.InfoContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
    color: ${CashoutDarkTextColor};
    border-bottom: 0.33rem solid ${CashoutThemeColor};
`;

S.Label = styled.label`
    color: ${CashoutLabelColor};
    font-size: 1.33rem;
    font-weight: 600;
    margin: 0 auto 0 0;
`;

S.CashoutLabel = styled(S.Label)`
    margin: auto;
    color: ${CashoutLightTextColor}
`;

S.InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;

S.Input = styled(Input)`
    box-shadow: none;
    border-radius: .42rem;
    border: .08rem solid ${CashoutLabelColor};
    color: ${CashoutDarkTextColor};
    font-size: 1.33rem;
    padding: 2.1rem 1.75rem 2.1rem 1rem;
    display: block;
    width: 100%;
    height: 34px;
    &:disabled {
        border: none;
        /* background-color: #fff; */
    }
`;


S.SubmitBtn = styled(Button)`
    border-radius: .42rem;
    height: 3rem;
    padding: 0.42rem;
    margin-top: 0.83rem;
    width: 80%;
    font-size: 1.33rem;
    font-weight: 600;
    color: ${CashoutDarkTextColor};
    background-color: ${CashoutLightTextColor};
    cursor: pointer;

    ${({disabled})=> {
        return (disabled == true) && `
                opacity: 0.5;
        `;
    }}
`;

S.Error = styled.div`
    color: ${CashoutThemeColor};
    width: 100%;
    text-align: center;
    font-size: 1.33rem
`;

S.FakeChbox = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid ${CashoutLabelColor};
    border-radius: 0;
    margin-right:  1rem;
    cursor: pointer;
    margin-top: -2px;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #fff;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    color: ${CashoutLightTextColor};
`;

S.InputWrapper = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0.83rem;
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        ${S.Label}{
            cursor: pointer;
            position: relative;
        }
        ${S.Input}{
            position: absolute!important;
            top: 0;
            left: 0.25rem;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            height: 1rem;
            width: auto;
            cursor:pointer;
            &:checked + ${S.FakeChbox}{
                background-color: ${CashoutThemeColor};
                border-color: ${CashoutThemeColor};
            }
        }
    }
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    /* background-color: #fba59f; */
`;

S.Stake = styled.div`
    font-size: 1.33rem;
    text-align: right;
    font-weight: 600;
    color: ${CashoutDarkTextColor};
`;

S.PossibleReturn = styled(S.Stake)``;
S.CashoutAmount = styled(S.Stake)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 3rem;
    padding: 0 0.83rem;
    margin-top: 0.83rem;
    background: ${CashoutLightTextColor};
`;


S.Button = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: 3rem;
    padding: 0.42rem;
    border: 0;
    border-radius: .33rem;
    font-size: 1.33rem;
    cursor: pointer;
    text-transform: uppercase;
    color: ${CashoutLightTextColor};
    background: ${CashoutThemeColor};
`;

S.ConclusionMsg = styled.div`
    position: relative;
    width: 100%;
    padding: 0.83rem;
    border: none;
    border-radius: 0.33rem;
    margin: 0.83rem 0;
    text-align: center;
    font-weight: 600;
    font-size: 1.33rem;
    color: ${CashoutDarkTextColor};
`;

S.Overlay = styled.div`
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
`;

S.TaxInfoMessage = styled.div`
    color: #787d83;
    width: 100%;
    text-align: center;
`; 

export default S;