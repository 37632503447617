import styled from 'styled-components';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.AccountEditProfile = styled.div`
    flex: 1;
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    display: flex;
    justify-content: center;
   // position: relative;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
`;

export default S;