import React, { Component } from 'react';
import { translation } from 'utilsHelper.js';

import SportFilter from 'SportFilter.js';
import GameFilter from 'GameFilter.js';
import TimeFilter from 'TimeFilter.js';

import S from 'StyledFiltersBox.js';


class FiltersBox extends Component {

    render() {
        return (
            <S.FiltersBox>
                <S.FiltersBoxHeader>{translation('filters_headerName')}</S.FiltersBoxHeader>
                <S.FiltersWrapper>
                    <SportFilter className="sportFilter" />
                    <GameFilter className="gameFilter" />
                    <TimeFilter className="timeFilter" />
                </S.FiltersWrapper>
            </S.FiltersBox>
        );
    }
}

export default FiltersBox;
