const isCurrentViewEsport = () => {
    const conditions = ['esport=true'];
    const eSportControllers = ['eSport', 'eSportEvent', 'eSportEventList', 'eSportLivescore', 'eSportStatistics'];
    const currentLocation = window.location.href;
    const currentController = app.getCurrentController();
    const isEsport = (conditions.some(el => currentLocation.toLowerCase().includes(el)) || eSportControllers.some(el => currentController.includes(el)));
    return isEsport;
}

export {isCurrentViewEsport};
