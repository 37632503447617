import React, {useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import S from 'StyledAccountResponsibleGameSelfExclusion.js';
import {translation} from 'utilsHelper.js';
import {excludeAccount} from 'responsibleGameActions.js';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _map from 'lodash/map';
import accountExclusionPeriod from 'accountExclusionPeriod.enum.js';
import limits from 'limitTypes.enum.js';
import {formatDate} from 'datesHelper';
import Box from "react-styled-box";
import BellIcon from 'bell-icon.svg';
import dialogPolyfill from 'dialog-polyfill';

const Portal = ({children})=>{
    return ReactDOM.createPortal(children, document.body);
}

const AccountResponsibleGameSelfExclusion = ({excludeAccount, exclusion, suspension, customerLimits}) => {

    const suspensionError = _get(suspension, ['error']);
    const exclusionError = _get(exclusion, ['error']);
    const suspensionPending = _get(suspension, ['isPending']);
    const exclusionPending = _get(exclusion, ['isPending']);

    const dialogContainer = useRef(null);
    const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
    const [exclusionPeriod, setExclusionPeriod] = useState(0);
    const [isExcludeButtonActive, setIsExcludeButtonActive] = useState(false);

    const closeConfirmationModal = (action) => {
        const dialogElement = dialogContainer.current;
        if (dialogElement && typeof dialogElement.close === 'function') dialogElement.close(action);
        if (action == 'cancel'){
            setExclusionPeriod(0);
        }
    };

    const openConfirmationModal = () => {

        const promise = new Promise((resolve, reject) => {

            const dialogElement = dialogContainer.current;
            dialogPolyfill.registerDialog(dialogElement);

            if (dialogElement && typeof dialogElement.showModal === 'function') {
                const onModalClose = () => {
                    dialogElement.returnValue == 'approve'? resolve(): reject();
                    dialogElement.removeEventListener('close', onModalClose);
                };
                dialogElement.addEventListener('close', onModalClose);
                dialogElement.showModal();
            } else {
                reject();
            }
        });
        return promise;
    };

    const handleExclusionSubmit = async (event) => {
        event.preventDefault();
        setExclusionModalOpen(true);
        try {
            await openConfirmationModal();
            if (exclusionPeriod == 90){
                const disablePeriod = _get(exclusion, ['period']); 
                excludeAccount('exclusion', disablePeriod);
            } else {
                excludeAccount('suspension', exclusionPeriod); 
            }
        } catch (error) {}
    };

    const userExcludedValidToDate = useMemo(() => {
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount, limitValidTo} = bettingExclusionLimitExist;
            const todayUnix = +(new Date());
            if (limitValidTo && todayUnix < limitValidTo) {
                return limitAmount > 1 ? formatDate(limitValidTo, 'yyyy-MM-dd HH:mm') : false;
            }
        }
    }, [customerLimits]);

    const userSuspendedValidToDate = useMemo(() => {
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount, limitValidTo} = bettingExclusionLimitExist;
            const todayUnix = +(new Date());
            if (limitValidTo && todayUnix < limitValidTo) {
                return limitAmount == 1 ? formatDate(limitValidTo, 'yyyy-MM-dd HH:mm') : false;
            }
        }
    }, [customerLimits]);

    const userExcludedPeriodActive = useMemo(()=> {
        const limitType = limits.BETTING_EXCLUSION_TIME_LIMIT;
        const bettingExclusionLimitExist = _find(customerLimits, {limitType});
        if (bettingExclusionLimitExist) {
            const {limitAmount} = bettingExclusionLimitExist;
            setExclusionPeriod(limitAmount);
            return limitAmount;
        }
        setExclusionPeriod(0);
        return 0;
    }, [customerLimits]);

    const selfExclusionTimeOptions = useMemo(() => {

        const options = _map(accountExclusionPeriod, ({ nameTranslation, days }) => {
            const option = {};

            option['label'] = translation(`${nameTranslation}`);
            option['value'] = days;
            return option;
        });

        return options;

    }, [userExcludedPeriodActive]);

    const createExclusionPeriodLabel = ({label, value}) => {
        const exclusionPeriodLabel = value == '90' ? `${translation('account_responsibleGaming_label')} ${label} ${translation('account_responsibleGaming_selfExclusion_header')}` : `${translation('account_responsibleGaming_label')} ${label}`;
        return exclusionPeriodLabel;
    };

    const onAccountExclusionPeriodChange = (value) => {
        setIsExcludeButtonActive(true)
        setExclusionPeriod(value)
    };

    return (
        <S.SelfExclusion>
            <Portal>
                <S.Dialog ref={dialogContainer}>
                    <Box flexDirection="column">

                        <Box justifyContent="center">
                            <S.BellIcon dangerouslySetInnerHTML={{__html: BellIcon}}></S.BellIcon>
                        </Box>

                        <Box margin="20px 0 0" alignSelf="center">
                            <S.DialogText dangerouslySetInnerHTML={{__html: `${translation('account_responsibleGaming_exclusionConfirmMessage')} ${translation(accountExclusionPeriod[exclusionPeriod]?.nameTranslation)}`}}/>
                        </Box>

                        <Box justifyContent="space-between" margin="20px 0 0">
                            <S.DialogBtnOk onClick={closeConfirmationModal.bind(null, 'approve')}>{translation("common_ok")}</S.DialogBtnOk>
                            <S.DialogBtnCancel onClick={closeConfirmationModal.bind(null, 'cancel')}>{translation("common_cancel")}</S.DialogBtnCancel>
                        </Box>

                    </Box>

                </S.Dialog>
            </Portal>


            <S.SelfExclusionBoxArea>

                <S.SelfExclusionBoxWrapper>
                    <S.SelfExclusionBox>
                        <S.Title>{translation('account_responsibleGaming_suspensionHeader')}</S.Title>
                        <S.Description>{translation('account_responsibleGame_selfExclusion_suspensionDescription')}</S.Description>
                        <S.SelectWrapper>
                            {selfExclusionTimeOptions.map((selfExclusionTimeOption, index) =>
                                <S.RowWrapper key={index}>
                                    <S.RowLabel>{createExclusionPeriodLabel({label: selfExclusionTimeOption.label, value: selfExclusionTimeOption.value})}</S.RowLabel>
                                    <S.RadioButton>
                                        <S.RadioButtonInput type="radio" name="self-exclusion-time-option" value={selfExclusionTimeOption.value} onChange={() => onAccountExclusionPeriodChange(selfExclusionTimeOption.value)}/>
                                    </S.RadioButton>
                                </S.RowWrapper>
                            )}
                        </S.SelectWrapper>
                    </S.SelfExclusionBox>
                </S.SelfExclusionBoxWrapper>

                <S.SelfExclusionBoxWrapper> 
                    <S.SelfExclusionBoxForm onSubmit={handleExclusionSubmit}>
                            <S.Button type="submit"
                                      disabled={exclusionPending||userExcludedValidToDate||!isExcludeButtonActive}>
                                {translation("account_responsibleGaming_excludeConfirm")}
                            </S.Button>
                        {userSuspendedValidToDate && <S.SelfExclusionValidTo>{translation('account_responsibleGaming_dateInfo', [userSuspendedValidToDate])}</S.SelfExclusionValidTo>}
                        {suspensionError && <S.Error>{suspensionError}</S.Error>}
                        {userExcludedValidToDate && <S.SelfExclusionValidTo>{translation('account_responsibleGaming_dateInfo', [userExcludedValidToDate])}</S.SelfExclusionValidTo>}
                        {exclusionError && <S.Error>{exclusionError}</S.Error>}
                    </S.SelfExclusionBoxForm>
                </S.SelfExclusionBoxWrapper>

            </S.SelfExclusionBoxArea>
        </S.SelfExclusion>
    );
}

const mapStateToProps = ({AccountResponsibleGameSelfExclusion: {suspension, exclusion}, Customer: {customerLimits}}) => {
    return {
        customerLimits,
        suspension,
        exclusion
    }
};

const mapDispatchToProps = {excludeAccount};

export default connect(mapStateToProps, mapDispatchToProps)(AccountResponsibleGameSelfExclusion);
