import { setSuccessedDepositsCounterCookie, getSuccessedDepositsCounterCookie } from 'helpers/cookiesHelper.js';
import { pushCustomEventToGoogleAnalytics } from 'helpers/utilsHelper';
import _isObject from 'lodash/isObject';

const processCustomerDepositData = ({depositCounter, depositInfo, userId}) =>{
  if (!depositInfo){
    return;
  }
  const date = new Date();
  const cookieCounter = getSuccessedDepositsCounterCookie();
  const depositData = _isObject(JSON.parse(depositInfo)) ? JSON.parse(depositInfo) : JSON.parse(JSON.parse(depositInfo));
  if(process.env.DEPOSIT_COUNTER_DATA_LAYER_ENABLED && JSON.parse(process.env.DEPOSIT_COUNTER_DATA_LAYER_ENABLED) && depositInfo){
    processDepositDataLayer({depositCounter, depositInfo, userId});
  }
  //#110808 display custom first deposit modal (only once and only if it hasn't been displayed before)
  if(CLIENT == 'fuksiarz' && checkIfFirstDeposit(date, depositData, depositCounter, cookieCounter) && !app.modal.FirstDeposit.__rendered){
    setTimeout(()=>{
      // Timeout bcs we had no other idea to render this modal in task 101808 on env modal was rendered yet not visible
      app.system.render(app.modal.FirstDeposit)
    },1000)
  }
  setSuccessedDepositsCounterCookie(`${depositCounter || 0}`);
}


const processDepositDataLayer = ({depositCounter, depositInfo, userId}) => {
  if (!depositInfo){
    return;
  }
  const date = new Date();
  const cookieCounter = getSuccessedDepositsCounterCookie();
  const depositData = _isObject(JSON.parse(depositInfo)) ? JSON.parse(depositInfo) : JSON.parse(JSON.parse(depositInfo));
  if(checkIfFirstDeposit(date, depositData, depositCounter, cookieCounter)){
    pushCustomEventToGoogleAnalytics({
      'event': 'firstDeposit',
      'category': 'conversion',
      'userId': userId,
      'value': depositData.lastDepositAmount
    })
  } else if(checkIfReturnDeposit(date, depositData, depositCounter, cookieCounter)){
    pushCustomEventToGoogleAnalytics({
      'event': 'returnDeposit',
      'category': 'conversion',
      'userId': userId,
      'value': depositData.lastDepositAmount
    })
  }
}

const checkIfFirstDeposit = (date, {firstDepositTime, lastDepositTime}, depositCounter, cookieCounter = 0) => {

  const firstDeposit = new Date(firstDepositTime).getTime();
  const lastDeposit = new Date(lastDepositTime).getTime();
  
  if((firstDeposit >= (date.getTime() - 300000)) && (firstDeposit < date.getTime() + 10000)){
    if((firstDeposit === lastDeposit && depositCounter == 1  && cookieCounter == 0)){
      return true
    }
  }
  return false
}

const checkIfReturnDeposit = (date, {firstDepositTime, lastDepositTime}, depositCounter, cookieCounter) => {

  const firstDeposit = new Date(firstDepositTime).getTime();
  const lastDeposit = new Date(lastDepositTime).getTime();
  
  if((firstDeposit != lastDeposit) && (lastDeposit >= (date.getTime() - 30000)) && (lastDeposit < date.getTime())){
    if(cookieCounter > 1 && depositCounter > 1 && cookieCounter != depositCounter){
      return true;
    }
  }

}

export {
  processCustomerDepositData
}