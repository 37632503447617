import React, {useState, useEffect} from 'react';
import { formValueSelector, Field, touch, change, getFormSyncErrors } from 'redux-form';
import {connect} from 'react-redux';
import {translation} from 'helpers/utilsHelper.js';
const { updateSyncErrors } = require('redux-form/lib/actions').default;
import _get from 'lodash/get';
import _find from 'lodash/find';
import S from 'StyledUploadDocument.js';
import documentTypesOptions from 'documentTypes.enum.js';
import customerVerificationStatus from 'customerVerificationStatus.enum.js';

const renderField = (field) => {
    return (
        <div className="input-row" style={{flexBasis:'100%', textAlign:'center', marginLeft: '150px'}}>
            <input key={new Date()} {...field.input} type="hidden"/>
            {(field.meta.touched || field.meta.submitFailed) && field.meta.error &&
            <span className="error">{field.meta.error}</span>}
        </div>
    )
}

const errorHelperFileValidation = (value, allValues, { dispatch }, name) => {
    if(value == "initValid") {
        return undefined;
    }
    if(value == "validAfterUpload") {
        dispatch(touch('accountEditProfile', 'address', 'city', 'postalCode', 'bankAccountNumber', 'passport', 'id'));
        return undefined
    } else if(value == "invalid") {
        return 'account_editProfileForm_scanRequired';
    }
}


const UploadDocument = (props) => {

    const {
        selectedDocumentTypes, 
        documentScans,
        uploadedDocumentData,
        accountData,
        dispatch,
        syncErrors
    } = props;

    const [isUploadBtnActive, setUploadBtnActive] = useState({
        page1: false,
        page2: false
    });

    const [pageName1, setPageName1] = useState(null);
    const [pageName2, setPageName2] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);

    useEffect(() => {
        setUploadBtnActive({page1: false, page2: false});
        setPageNumber(null);
        setPageName1(null);
        setPageName2(null);
        if(documentScans && documentScans.length > 0){
            const documentExist = documentScans.some(({ type }) => type == selectedDocumentTypes);
            if(documentExist && accountData?.verificationStatus != customerVerificationStatus.REJECTED) {
                setUploadBtnActive({page1: true, page2: true});
            }
        }
    }, [documentScans, selectedDocumentTypes, accountData]);

    useEffect(() => {
        if(selectedDocumentTypes == 'id') {
            setPageName1(`documentType${selectedDocumentTypes}_1`);
            setPageName2(`documentType${selectedDocumentTypes}_2`);
            setPageNumber(2);
        } else {
            setPageName1(`documentType${selectedDocumentTypes}_1`);
            setPageNumber(1);
        }

        let inputValue;
        if(pageNumber == 1 || pageNumber == 2){
            inputValue = isUploadBtnActive["page1"] ? 'initValid' : 'invalid';
            dispatch(change('accountEditProfile', `${pageName1}_error`, inputValue));
        }
        if(pageNumber == 2) {
            inputValue = isUploadBtnActive["page2"] ? 'initValid' : 'invalid';
            dispatch(change('accountEditProfile', `${pageName2}_error`, inputValue));
        }

    }), [isUploadBtnActive];


    const onFileUpload = (documentType, page, event)=>{
        const inputFile = event.target;
        const fileData = inputFile.files[0];
        var imgMimeType = fileData.type;
        var ext = imgMimeType.substr(imgMimeType.indexOf('/')+1);
        const fieldNameError =  `documentType${documentType}_${page}_error`;

        if(['jpg','jpeg','pdf','png'].indexOf(ext) == -1){
            const errorMsg = translation('account_editProfileForm_wrongFormat');
            checkAndSyncErrors(fieldNameError, syncErrors, errorMsg);
            return;
        } else {
            checkAndSyncErrors(fieldNameError, syncErrors);
        }

        var fileSize = fileData.size;
        if(fileSize>5000000){
            const errorMsg = translation('account_editProfileForm_wrongFileSize');
            checkAndSyncErrors(fieldNameError, syncErrors, errorMsg)
            return; 
        } else {
            checkAndSyncErrors(fieldNameError, syncErrors);
        }

        dispatch(change('accountEditProfile', 'documentTypeNeedUpload',' '));
        uploadedDocumentData(page, fileData);
        setUploadBtnActive({...isUploadBtnActive, [`page${page}`] : true})
        dispatch(change('accountEditProfile', fieldNameError, 'validAfterUpload'));
    };

    const checkAndSyncErrors = (fieldName, err, errorMsg = undefined)=>{

        dispatch(updateSyncErrors('accountEditProfile', {
            ...err,
            [fieldName]:errorMsg
          }));
        dispatch(touch('accountEditProfile', fieldName));
    }

    const renderScanFields = (() => {
        
        let document = _find(documentTypesOptions, {name: selectedDocumentTypes});
        let {pages = 0, documentType = 0, name = "id"} = document || {};

        const renderUploadDocument = (pages, documentType, name) => {

            name = `${name[0].toUpperCase() + name.slice(1)}`;

            let fields = [];
                
            if(pages != 0) {
                for (let pageNumber = 1; pageNumber <= pages; pageNumber++) {
                    const fileInputKey = `fieldName_${new Date()}`;
                    const fieldName = `documentType${selectedDocumentTypes}_${pageNumber}`;

                    const field = (
                        <S.ButtonWithLabel key={fieldName} style={{flexWrap:'wrap'}}>
                            {(
                                <>
                                <span style={{flex:1, maxWidth:'150px', textAlign: 'center'}}>
                                    {translation(`account_editProfileForm_${fieldName}`)}
                                </span>
                                <label htmlFor={`file-upload-${pageNumber}_${fieldName}`} className={`custom-file-upload ${isUploadBtnActive[`page${pageNumber}`]? "uploaded" : ""}`}>
                                    {isUploadBtnActive[`page${pageNumber}`]
                                        ? translation(
                                            `account_editProfileForm_uploadedPage`
                                        )
                                        : translation(
                                            `account_editProfileForm_uploadDocumentPage`
                                        )
                                    }
                                </label>
                                <input  
                                    key={fileInputKey}
                                    id={`file-upload-${pageNumber}_${fieldName}`}
                                    name={fieldName}
                                    className={`page_${pageNumber}`} 
                                    type="file" 
                                    onChange={(event) => onFileUpload(selectedDocumentTypes, pageNumber, event)} 
                                    accept=".jpg, .jpeg, .pdf, .png"
                                    />
                                    <Field name={`${fieldName}_error`} component={renderField} validate={errorHelperFileValidation}/>
                                </>
                            )}
                    
                        </S.ButtonWithLabel>
                    );
                    fields.push(field);
                }
            }
    
            return (
                <>
                    <S.ButtonsWrapper>{[fields]}</S.ButtonsWrapper>
                </>
            )
        }

        return renderUploadDocument(pages, documentType, name);
 
    })();

    return (
        <S.UploadDocument>
            { renderScanFields }
        </S.UploadDocument>
    )
}

const selector = formValueSelector('accountEditProfile');
export default connect((state)=>{
    const {Customer:{documentTypes, documentScans}} = state;
    const {Account:{accountData}} = state;
    const { form: {accountEditProfile : { values }}} = state;
    return {
        selectedDocumentTypes: selector(state, 'documentTypes'),
        syncErrors: getFormSyncErrors("accountEditProfile")(state),
        documentTypes,
        documentScans,
        accountData
    }
})(UploadDocument);
 