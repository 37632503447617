import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import S from 'StyledBetSlip.js';
import {connect} from 'react-redux';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _size from 'lodash/size';
import _filter from 'lodash/filter';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isEmpty from 'lodash/isEmpty';
import _replace from 'lodash/replace';
import _pick from 'lodash/pick';
import _each from 'lodash/each';
import classNames from 'classnames';
import {translation, formatMoney, decimal} from 'utilsHelper.js';
import {bindActionCreators} from 'redux';
import withErrorBoundary from  'withErrorBoundary.js';
import Loader from 'Loader.js';
import BetSlipTypesTabs from 'BetSlipTypesTabs.js';
import FreebetList from 'FreebetList.js';
import TotalBonus from 'TotalBonus.js';
import BetSlipAccumulator from 'BetSlipAccumulator.js';
import BetSlipSystem from 'BetSlipSystem.js';
import PropositionOffer from 'PropositionOffer.js';
// import BetSlipTabs from 'BetSlipTabs.js';
import Box from "react-styled-box";
import {CSSTransition} from 'react-transition-group';
import PlaceBetSuccessIcon from 'placebet_success.svg';
import IncrementStakeIcon from 'increment_stake.svg';
import DecrementStakeIcon from 'decrement_stake.svg';
import BetSlipIcon from 'betslip_icon.svg';
import TrashIcon from 'trash.svg';
import {
    changeActiveTab,
    changeSlipType,
    fetchOddsByOutcomesIds,
    removeOutcome,
    clearBetslip,
    changeSlipStake,
    changeSlipTotalStake,
    placeBet,
    placeBetSlipAgain,
    startIntervalFetchOddsByOutcomeId,
    stopIntervalFetchOddsByOutcomeId,
    toggleOutcomeToBlock,
    createBlock,
    toggleBankerOutcome,
    changeCombinationType,
    toggleAccumulator,
    toggleBalance,
    toggleAcceptHigherOdds,
    toggleAcceptAnyOdds,
    removeOutcomeFromBlock,
    updateBlock
} from 'betSlipActions.js';
import {loadApprovalDataOnInit, acceptTraderChanges, rejectTraderChanges, hideApprovalMessage} from 'approvalActions.js';
import {fetchFreebetByStatus, toggleFreebet} from 'freebetActions.js';

const EmptyBetSlip = () => {
    return (
        <S.EmptySlip>
            {translation('betSlip_empty')}
        </S.EmptySlip>
    )
};

const PlaceBetSuccess = (props) => {
    
    const {betInShop, placeBetSlipAgain, clearBetslip, winning, totalStake, currencyCode, propositionOffersVisible, propositionOffers, placedBetDetails} = props;

    const {possibleReturn, stake, transactionDetails, slipType} = placedBetDetails;
    
    const calculateTotalOdds = (transactionDetails) => {

        let totalOdds = 1.0;
        if (transactionDetails) {
            for (let index=0; index<transactionDetails.length; index++){
                let detail = transactionDetails[index];
                if (detail.outcomeId != -1){
                    totalOdds = totalOdds * detail.odds;
                }
            }

        }
        return decimal(totalOdds);
    };
   
    return (
        <S.PlaceBetSuccess>
            <S.PlaceBetSuccessTop>
                <S.PlaceBetIcon dangerouslySetInnerHTML={{__html: PlaceBetSuccessIcon}}></S.PlaceBetIcon>
                <S.PlaceBetMessage>{betInShop ? _replace(translation('betSlip_success_betInShop'), '{0}', betInShop) : translation('betSlip_placedBetSlip')}</S.PlaceBetMessage>
                
                {!transactionDetails || transactionDetails.length == 0 && !possibleReturn && 
                    (<S.PlaceBetInfo>
                        <span>{translation("betSlip_moreInfoInBetSlipHistory")}</span>
                    </S.PlaceBetInfo>)
                }

                {possibleReturn &&
                    (<S.PlaceBetPotentialWinning>
                        <span>{translation("betSlip_potentialWinning")}</span>
                        <span className="value">{decimal(possibleReturn)} {currencyCode}</span>
                    </S.PlaceBetPotentialWinning>)
                }

                <S.PlaceBetStake>
                    <span>{translation("betSlip_totalStake")}</span>
                    <span className="value">{stake ? decimal(stake) : decimal(totalStake)} {currencyCode}</span>
                </S.PlaceBetStake>
                
                { slipType != undefined && (slipType == 0 || slipType == 100) && transactionDetails && transactionDetails.length != 0 && (
                    <S.PlaceBetOdds>
                        <span>{translation("betSlip_placeBetOdds")}</span>
                        <span className="value">{ calculateTotalOdds(transactionDetails)} </span>
                    </S.PlaceBetOdds>
                )}

                { propositionOffersVisible && propositionOffers > 0 && (
                    <S.CashbackOffer>
                        <span>{translation("betSlip_cashbackOfferAmount")}</span>
                        <span className="value">{formatMoney(propositionOffers)}</span>
                    </S.CashbackOffer> ) 
                } 

            </S.PlaceBetSuccessTop>
            <S.PlaceBetSuccessBottom>
                <S.PlaceAgainBtn onClick={placeBetSlipAgain}>{translation('betSlip_placeBetAgain')}</S.PlaceAgainBtn>
                <S.ClearBetSlipBtn onClick={clearBetslip}>{translation('betSlip_close')}</S.ClearBetSlipBtn>
            </S.PlaceBetSuccessBottom>
        </S.PlaceBetSuccess>
    )
};

const UnexpectedBetSlipError = () => {
    return (
        <S.UnexpectedError>
            <S.UnexpectedErrorIcon className="fa fa-exclamation-triangle"/>
            <S.UnexpectedErrorMessage>{translation('betSlip_unexpectedError')}</S.UnexpectedErrorMessage>
        </S.UnexpectedError>
    )
};

const ErrorContainer = (props) => {
    const {error} = props;
    return (
        <S.ErrorContainer>
            {_map(error, (err, idx) => {
                return <S.Error key={idx}>{err}</S.Error>;
            })}
        </S.ErrorContainer>
    )
};

class BetSlip extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        const hasApprovalMessageChanged = Boolean(nextProps.approvalMessage);
        if (hasApprovalMessageChanged != prevState.hasApprovalMessageVisibled) {
            return {
                hasApprovalMessageVisibled: hasApprovalMessageChanged,
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            hasApprovalMessageVisibled: false,
            hidden: false,
            sticked: false,
            submitDisabled: false
        };

        this.betSlipWrapperRef = React.createRef();
        this.stakeRef = React.createRef();
        this.totalStakeRef = React.createRef();
        this.acceptHigherOddsRef = React.createRef();
        this.acceptAnyOddsRef = React.createRef();

        this.debouncedOnStakeChange = _debounce((newStake)=>{
            const { freebet } = this.props;
            if (_size(freebet)) {
                return;
            }
            const {stake} = this.props;
            if (decimal(stake) == decimal(newStake)) {
                this.stakeRef.current.value = formatMoney(newStake);
            }
            props.changeSlipStake(newStake);
            this.setState({submitDisabled:false});
        }, 1500);

        this.debouncedOnTotalStakeChange = _debounce((newTotalStake)=>{
            const {totalStake} = this.props;
            if (decimal(totalStake) == decimal(newTotalStake)) {
                this.totalStakeRef.current.value = formatMoney(newTotalStake);
            }
            props.changeSlipTotalStake(newTotalStake);
            this.setState({submitDisabled:false});
        }, 1500);
    }

    betSlipMarkup = () => {
        const { sticked, submitDisabled} = this.state;
        const {
            outcomes,
            error,
            odds,
            winning,
            removeOutcome,
            clearBetslip,
            placeBetSlipAgain,
            betInShop,
            placeBet,
            placeBetInShop,
            isLogged,
            locked,
            isWinningTaxEnabled,
            placed,
            slipType,
            changeSlipType,
            blocks,
            toggleOutcomeToBlock,
            createBlock,
            toggleBankerOutcome,
            stake,
            totalStake,
            types,
            type,
            changeCombinationType,
            changeSlipStake,
            toggleAccumulator,
            addAccumulator,
            fetchFreebetByStatus,
            toggleFreebet,
            validFreebets,
            freebet,
            currencyCode,
            bonus,
            acceptHigherOdds,
            acceptAnyOdds,
            toggleAcceptHigherOdds,
            toggleAcceptAnyOdds,
            hasApprovalButtonsVisibled,
            tax,
            approvalMessage,
            acceptTraderChanges,
            rejectTraderChanges,
            removeOutcomeFromBlock,
            updateBlock,
            betSlips,
            changeActiveTab,
            activeTab,
            hideApprovalMessage,
            isTotalBonusEnabled,
            totalBonus,
            propositionOffersVisible,
            propositionOffers,
            placedBetDetails,
            winningWithoutTax
        } = this.props;
        const isSingle = _size(outcomes) == 1 ? true : false;

        return (
            <S.BetSlipContainer className="betslip-container">

                {_isEmpty(outcomes) ? (
                    <EmptyBetSlip />
                ) : placed ? (
                    <PlaceBetSuccess
                        betInShop={betInShop}
                        placeBetSlipAgain={placeBetSlipAgain}
                        clearBetslip={clearBetslip}
                        winning={winning}
                        totalStake={totalStake}
                        currencyCode={currencyCode}
                        propositionOffersVisible={propositionOffersVisible}
                        propositionOffers={propositionOffers}
                        placedBetDetails={placedBetDetails}
                    />
                ) : (
                    <>
                        <div
                            style={{ position: 'relative' }}
                            className="betslip-inner"
                        >
                            {locked && (
                                <S.BetSlipOverlay className="betslip-overlay">
                                    <Loader size="5" />
                                </S.BetSlipOverlay>
                            )}

                            <BetSlipTypesTabs
                                slipType={slipType}
                                sticked={sticked}
                                changeSlipType={changeSlipType}
                                clearBetslip={clearBetslip}
                                toggleSticked={this.toggleSticked}
                                isSingle={isSingle}
                                hasApprovalButtonsVisibled={
                                    hasApprovalButtonsVisibled
                                }
                            />

                            {slipType == 'ACCUMULATOR' ? (
                                <BetSlipAccumulator
                                    removeOutcome={removeOutcome}
                                    outcomes={outcomes}
                                    hasApprovalButtonsVisibled={
                                        hasApprovalButtonsVisibled
                                    }
                                    onUpdate={this.onScrollbarUpdate}
                                />
                            ) : (
                                <BetSlipSystem
                                    removeOutcome={removeOutcome}
                                    outcomes={outcomes}
                                    blocks={blocks}
                                    toggleOutcomeToBlock={toggleOutcomeToBlock}
                                    createBlock={createBlock}
                                    updateBlock={updateBlock}
                                    toggleBankerOutcome={toggleBankerOutcome}
                                    removeOutcomeFromBlock={
                                        removeOutcomeFromBlock
                                    }
                                    types={types}
                                    type={type}
                                    changeCombinationType={
                                        changeCombinationType
                                    }
                                    toggleAccumulator={toggleAccumulator}
                                    addAccumulator={addAccumulator}
                                    hasApprovalButtonsVisibled={
                                        hasApprovalButtonsVisibled
                                    }
                                    onUpdate={this.onScrollbarUpdate}
                                />
                            )}

                            {isLogged && propositionOffersVisible && ( 
                                <PropositionOffer
                                    amount={propositionOffers}
                                />
                            )}

                            {isLogged && (
                                <S.BalanceSelect as={Select} options={this.getBalanceList()} value={this.selectedOption()} onChange={this.onBalanceChange}
                                    isSearchable={false} classNamePrefix="react-select" className="react-select-container" isDisabled={_size(freebet)}/>
                            )}
                            
                            <Box flexDirection="column" margin="10px 0 10px 0">
                                <S.FormGroup className="small-text">
                                    <S.FormGroupText>
                                        {translation('betSlip_rate')}
                                    </S.FormGroupText>
                                    <S.FormGroupText>{decimal(tax)}</S.FormGroupText>
                                </S.FormGroup>
                                
                                { odds 
                                    ? (
                                        <S.FormGroup>
                                            <S.FormGroupText>
                                                {translation('betSlip_odds_fullname')}
                                            </S.FormGroupText>
                                            <S.FormGroupText>
                                                {odds}
                                            </S.FormGroupText>
                                        </S.FormGroup>
                                        ) 
                                    : null 
                                }

                                {/* {bonus && (
                                    <S.FormGroup>
                                        <S.FormGroupText>
                                            {translation('betSlip_bonus')}
                                        </S.FormGroupText>
                                        <S.FormGroupText>
                                            {bonus}
                                        </S.FormGroupText>
                                    </S.FormGroup>
                                )} */}

                                <S.FormGroupWrapper>
                                    <S.Label htmlFor="betslip-stake">
                                        {translation('betSlip_stake')}
                                    </S.Label>
                                    <S.StakeControls>
                                        <S.StakeControl
                                            onClick={() =>
                                                this.onStakeChangeByControl(
                                                    'dec'
                                                )
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: DecrementStakeIcon,
                                            }}
                                        ></S.StakeControl>
                                        <S.StakeControl
                                            onClick={() =>
                                                this.onStakeChangeByControl(
                                                    'inc'
                                                )
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: IncrementStakeIcon,
                                            }}
                                        ></S.StakeControl>
                                    </S.StakeControls>
                                    <S.InputWrapper>
                                        <S.Input
                                            id="betslip-stake"
                                            defaultValue={formatMoney(stake)}
                                            ref={this.stakeRef}
                                            disabled={
                                                locked ||
                                                hasApprovalButtonsVisibled ||
                                                _size(freebet)
                                            }
                                            onKeyDown={(e)=>this.preventSpecialCharsOnKeyDown(e, this.onStakeChange)}
                                            onChange={this.onStakeChange}
                                        ></S.Input>
                                    </S.InputWrapper>
                                </S.FormGroupWrapper>

                                {slipType == 'SYSTEM' && (
                                    <S.FormGroupWrapper>
                                        <S.Label htmlFor="betslip-totalStake">
                                            {translation('betSlip_totalStake')}
                                        </S.Label>
                                        <S.InputWrapper>
                                            <S.Input
                                                id="betslip-totalStake"
                                                defaultValue={formatMoney(
                                                    totalStake
                                                )}
                                                ref={this.totalStakeRef}
                                                disabled={
                                                    locked ||
                                                    hasApprovalButtonsVisibled
                                                }
                                                onKeyDown={(e)=>this.preventSpecialCharsOnKeyDown(e, this.onTotalStakeChange)}
                                                onChange={
                                                    this.onTotalStakeChange
                                                }
                                            ></S.Input>
                                        </S.InputWrapper>
                                    </S.FormGroupWrapper>
                                )}

                                <S.FormGroup className="medium-text">
                                    <S.FormGroupText>
                                        {translation('betSlip_totalWin')}
                                    </S.FormGroupText>
                                    <S.FormGroupText>
                                        {winning} &nbsp;{translation('common_pln')}
                                    </S.FormGroupText>
                                </S.FormGroup>
                            </Box>

                            <S.CheckboxWrapper>
                                <S.CheckboxGroup>
                                    <S.LabelCheckbox>
                                        <S.InputCheckbox
                                            type="checkbox"
                                            defaultChecked={
                                                acceptHigherOdds
                                            }
                                            onClick={toggleAcceptHigherOdds}
                                            ref={this.acceptHigherOddsRef}
                                            disabled={
                                                hasApprovalButtonsVisibled
                                            }
                                        />
                                        <S.FakeCheckbox />
                                        <S.CheckboxText>
                                            {translation(
                                                'betslip_oddsAccept_higher'
                                            )}
                                        </S.CheckboxText>
                                    </S.LabelCheckbox>
                                </S.CheckboxGroup>

                                <S.CheckboxGroup>
                                    <S.LabelCheckbox>
                                        <S.InputCheckbox
                                            type="checkbox"
                                            defaultChecked={acceptAnyOdds}
                                            onClick={toggleAcceptAnyOdds}
                                            ref={this.acceptAnyOddsRef}
                                            disabled={
                                                hasApprovalButtonsVisibled
                                            }
                                        />
                                        <S.FakeCheckbox />
                                        <S.CheckboxText>
                                            {translation(
                                                'betslip_oddsAccept_any'
                                            )}
                                        </S.CheckboxText>
                                    </S.LabelCheckbox>
                                </S.CheckboxGroup>
                            </S.CheckboxWrapper>

                            {isLogged && (
                                <FreebetList
                                    fetchFreebetByStatus={fetchFreebetByStatus}
                                    toggleFreebet={toggleFreebet}
                                    validFreebets={validFreebets}
                                    freebet={freebet}
                                    slipType={slipType}
                                    currencyCode={currencyCode}
                                    isBalanceBonusSelected={this.isBalanceBonusSelected()}
                                />
                            )}

                            {isWinningTaxEnabled && (
                                <S.WinningTaxBox>
                                    <span>{translation('betSlip_winningTaxAmount')}</span>
                                </S.WinningTaxBox>
                            )}

                            {isTotalBonusEnabled && (
                                <TotalBonus
                                    winning={winning}
                                    totalBonus={totalBonus}
                                    bonus={bonus}
                                />
                            )}

                            {!hasApprovalButtonsVisibled && (
                                <S.ButtonsWrapper>
                                    {isLogged ? (
                                        <S.PlaceBet
                                            onClick={placeBet}
                                            disabled={locked||submitDisabled}
                                            data-test='betSlip_placeBet'
                                        >
                                            {translation('betSlip_placeBet')}
                                        </S.PlaceBet>
                                    ) : (
                                        <S.LoginInfo>{translation('betSlip_needToBeLoggedIn')}</S.LoginInfo>
                                    )}
                                </S.ButtonsWrapper>
                            )}
                        </div>

                        {hasApprovalButtonsVisibled && (
                            <S.ButtonsWrapper>
                                <S.AcceptTraderChanges
                                    onClick={acceptTraderChanges}
                                >
                                    {translation('betSlip_accept')}
                                </S.AcceptTraderChanges>
                                <S.RejectTraderChanges
                                    onClick={rejectTraderChanges}
                                >
                                    {translation('betSlip_cancel')}
                                </S.RejectTraderChanges>
                            </S.ButtonsWrapper>
                        )}

                        {error && <ErrorContainer error={error} />}
                    </>
                )}

                <CSSTransition
                    in={this.state.hasApprovalMessageVisibled}
                    appear={true}
                    classNames={{
                        enter: 'animated',
                        enterActive: 'rubberBand',
                        exit: 'animated',
                        exitActive: 'fadeOutRight',
                    }}
                    unmountOnExit
                    timeout={200}
                >
                    <S.Info status={_get(approvalMessage, ['status'])}>
                        <S.InfoMessage>{_get(approvalMessage, ['msg'])}</S.InfoMessage>
                        <S.InfoCloseIcon onClick={hideApprovalMessage}>&times;</S.InfoCloseIcon>
                    </S.Info>
                </CSSTransition>
            </S.BetSlipContainer>
        );
    };

    onTotalStakeChange = (e) => {
        const totalStakeValue =  String(e.target.value).replace(/\,/, '.').replace(/\s+/, '');
        const isNumber = !isNaN(totalStakeValue);
        let totalStake =
            _size(totalStakeValue) && isNumber
                ? decimal(totalStakeValue)
                : '0.0';
        totalStake = Math.abs(totalStake);

        this.setState({submitDisabled:true});
        this.debouncedOnTotalStakeChange(totalStake);
    };

    onStakeChangeByControl = (type) => {
        const { stake, changeSlipStake, freebet, hasApprovalButtonsVisibled } = this.props;
        if ((!stake && type == 'dec') || _size(freebet) || hasApprovalButtonsVisibled) {
            return;
        }
        const changedStake =
            type == 'inc'
                ? parseFloat(stake) + parseFloat(process.env.STAKE_CONTROL_VALUE)
                : parseFloat(stake) - parseFloat(process.env.STAKE_CONTROL_VALUE);
        if (changedStake < 0) {
            changeSlipStake(0.0);
        } else {
            changeSlipStake(changedStake);
        }
    };

    onStakeChange = (e) => {
        const targetValue = String(e.target.value);
        const stakeValue = targetValue.replace(/\,/, '.').replace(/\s+/, '');
        const isNumber = !isNaN(stakeValue);
        let stake = _size(stakeValue) && isNumber ? decimal(stakeValue) : '0.0';
        stake = Math.abs(stake);

        this.setState({submitDisabled:true});
        this.debouncedOnStakeChange(stake);
    };

    preventSpecialCharsOnKeyDown = (e, callbackFn) => {
        const key = e.key;
        //const charTyped = String.fromCharCode(e.which);
        const numberRegex = /\d+/;
        const allowedOnlySpecialCharsRegex = /(\,|\.)/;
        if (
            key.match(numberRegex) ||
            key.match(allowedOnlySpecialCharsRegex) ||
            (key == 'Backspace' || key == 'Delete' || key == 'Enter' || key == 'ArrowLeft' || key == 'ArrowRight')
        ) {
           return true;
        }else{
           callbackFn(e);
           e.preventDefault();
        }
    }

    liveOutcomesExistCheck = () => {
        const { outcomes } = this.props;
        const hasLiveOutcomes = _filter(outcomes, (o) => {
            return (o.outcomeLive == 'true' || o?.virtualSport == 'VTI')
        });
        let outcomesIds = [];
        if (_size(hasLiveOutcomes)) {
            outcomesIds = _map(hasLiveOutcomes,
                ({ outcomeId }) => {
                    return { outcomeId };
                }
            );
        }
        return outcomesIds;
    };

    restartLiveIntervals = () => {
        const {
            startIntervalFetchOddsByOutcomeId,
            locked,
            hasApprovalButtonsVisibled,
        } = this.props;

        const liveOutcomes = this.liveOutcomesExistCheck();
        if (_size(liveOutcomes) && !locked && !hasApprovalButtonsVisibled) {
            this.rerunActiveLiveIntervals();
            startIntervalFetchOddsByOutcomeId(liveOutcomes);
        }
    };

    rerunActiveLiveIntervals = () => {
        const Timer = app.service.Timers;
        const activeIntervals = Timer.liveEventsListHandlerActions;

        const outcomeButtonWithOddsChangeIndicator = [
            ...document.querySelectorAll('.outcomeButtonPartial.up'),
            ...document.querySelectorAll('.outcomeButtonPartial.down'),
        ];
        _map(outcomeButtonWithOddsChangeIndicator, (dom) =>
            dom.classList.remove('up', 'down')
        );

        Timer.clearLiveEventsListIntervals();

        for (let actionName in activeIntervals) {
            if (Timer.liveEventsListHandler) {
                clearInterval(Timer.liveEventsListHandler);
                Timer.liveEventsListHandler = null;
            }
            activeIntervals[actionName]();
            Timer.setLiveEventsInterval(
                actionName,
                activeIntervals[actionName]
            );
        }
    };

    subscribeToSpikeOnRouteChange = () => {
        const { stopIntervalFetchOddsByOutcomeId } = this.props;

        app.router.onRouteChange('REACT_LISTEN_SPIKE_ROUTE_CHANGE', () => {
            stopIntervalFetchOddsByOutcomeId();
            this.restartLiveIntervals();
        });
    };

    componentDidMount = () => {
        const { loadApprovalDataOnInit, locked } = this.props;

        this.subscribeToSpikeOnRouteChange();
        this.restartLiveIntervals();

        if (locked) {
            setTimeout(() => {
                loadApprovalDataOnInit();
            }, process.env.FETCH_APPROVED_SLIPS_TIMEOUT);
        }
    };

    addScrollHandler = () => {
        document.addEventListener('scroll', this.bindScrollEvent);
    };

    removeScrollHandler = () => {
        document.removeEventListener('scroll', this.bindScrollEvent);

        const betSlipWrapperSelector = this.betSlipWrapperRef.current;
        betSlipWrapperSelector.classList.remove('is-sticked');
        betSlipWrapperSelector.parentNode.style.paddingTop = `0px`;
    };

    bindScrollEvent = () => {
        const windowScrollTop = window.scrollY;
        const betSlipWrapperSelector = this.betSlipWrapperRef.current;

        betSlipWrapperSelector.classList.toggle(
            'is-sticked',
            windowScrollTop > 138
        );

        this.onScrollbarUpdate();
    };

    onScrollbarUpdate = () => {
        const betSlipWrapperSelector = this.betSlipWrapperRef.current;
        const isSticked = betSlipWrapperSelector.classList.contains(
            'is-sticked'
        );
        if (isSticked) {
            const bestSlipOffsetHeight = betSlipWrapperSelector.offsetHeight;
            betSlipWrapperSelector.parentNode.style.paddingTop = `${bestSlipOffsetHeight}px`;
            betSlipWrapperSelector.parentNode.style.width = `inherit`;
        } else {
            betSlipWrapperSelector.parentNode.style.paddingTop = `0px`;
        }
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.stakeRef.current && prevProps.stake !== this.props.stake) {
            this.stakeRef.current.value = formatMoney(this.props.stake);
        }

        if (
            this.totalStakeRef.current &&
            prevProps.totalStake !== this.props.totalStake
        ) {
            this.totalStakeRef.current.value = formatMoney(
                this.props.totalStake
            );
        }

        if (
            this.acceptHigherOddsRef.current &&
            prevProps.acceptHigherOdds != this.props.acceptHigherOdds
        ) {
            this.acceptHigherOddsRef.current.checked = this.props.acceptHigherOdds;
        }

        if (
            this.acceptAnyOddsRef.current &&
            prevProps.acceptAnyOdds != this.props.acceptAnyOdds
        ) {
            this.acceptAnyOddsRef.current.checked = this.props.acceptAnyOdds;
        }

        if (prevProps.locked != this.props.locked && this.props.locked) {
            const { stopIntervalFetchOddsByOutcomeId } = this.props;
            stopIntervalFetchOddsByOutcomeId();
        }

        if (
            _size(prevProps.outcomes) != _size(this.props.outcomes) ||
            (_size(prevProps.outcomes) == _size(this.props.outcomes) &&
                prevProps.placed != this.props.placed &&
                !this.props.placed)
        ) {

            const { stopIntervalFetchOddsByOutcomeId } = this.props;
            stopIntervalFetchOddsByOutcomeId();

            this.restartLiveIntervals();
        }

        if (prevState.sticked != this.state.sticked) {
            if (this.state.sticked) {
                this.addScrollHandler();
            } else {
                this.removeScrollHandler();
            }
        }
    };

    toggleVisibility = () => {
        this.setState((prevState) => {
            return { ...prevState, hidden: !prevState.hidden };
        });
    };

    toggleSticked = () => {
        this.setState((prevState) => {
            return { ...prevState, sticked: !prevState.sticked };
        });
    };

     getBalanceList = () => {
        const {currencyCode, balance, bonusBalanceList} = this.props.user;
        const options = [];
        const mainBalance = {
            value: 0,
             label: `${translation('balance')}: ${formatMoney(balance)} ${translation('common_pln')}`,
        };
    
        options.push(mainBalance);
    
        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${formatMoney(amount)} ${currency}`
            };
            options.push(bonusBalance);
        });
    
        return options;
    };

    onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        this.props.toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || this.props.balanceId;
        const selectedValue = _find(this.getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };

    isBalanceBonusSelected = () => {
        return (localStorage.getItem('_bonus_balance_id') || this.props.balanceId) != '0';
    };

    componentWillUnmount = () => {
        const { stopIntervalFetchOddsByOutcomeId, locked } = this.props;
        if (!locked) {
            stopIntervalFetchOddsByOutcomeId();
        }
    };

    render() {
        const { hidden } = this.state;
        const arrowIconClass = classNames({
            ion: true,
            'ion-ios-arrow-down': hidden,
            'ion-ios-arrow-up': !hidden,
        });

        return (
            <S.BetSlipWrapper ref={this.betSlipWrapperRef}>
                <S.BetSlipHeader>
                    <S.BetSlipIcon
                        dangerouslySetInnerHTML={{ __html: BetSlipIcon }}
                    ></S.BetSlipIcon>
                    <div>{translation('betSlip_header')}</div>
                    {!_isEmpty(this.props.outcomes) && !this.props.locked && !this.props.hasApprovalButtonsVisibled ? (
                        <S.ClearBetSlipIcon
                            onClick={this.props.clearBetslip}
                            dangerouslySetInnerHTML={{ __html: TrashIcon }}
                        ></S.ClearBetSlipIcon>
                    ) : null}
                </S.BetSlipHeader>
                <S.BetSlipBody isHidden={hidden}>
                    {this.betSlipMarkup()}
                </S.BetSlipBody>
            </S.BetSlipWrapper>
        );
    }
}

const mapStateToProps = ({BetSlip:{activeTab, balanceId, betSlips, locked, validFreebets, acceptHigherOdds, acceptAnyOdds, showApprovalButtons: hasApprovalButtonsVisibled}, Auth:{isLogged, user}}, props) => {

    const currencyCode = process.env.MAIN_CURRENCY_CODE;
    const stakeControlValue = process.env.STAKE_CONTROL_VALUE;
    const activeSlip = _get(betSlips, [activeTab]);

    const oddsPrecision = _get(activeSlip, ['oddsPrecision']);
    const amountPrecision = _get(activeSlip, ['amountPrecision']);

    const keysToPick = ['tax', 'approvalMessage', 'outcomes', 'totalStake', 'stake', 'totalOdds', 'totalOddsWithoutSalesTaxFactor', 'winning', 'error', 'placed', 'betInShop', 'blocks', 'slipType', 'types', 'type', 'addAccumulator', 'freebet', 'bonus', 'validFreebets', 'winningWithoutTax', 'minPossibleWinning', 'winningForDefaultTaxFactor', 'totalStake', 'maxStake', 'totalBonus', 'placedBetDetails'];
    const keysFromActiveSlip = _pick(activeSlip, keysToPick);

    const keysWithOddsPrecision = _pick(keysFromActiveSlip, ['totalOdds', 'totalOddsWithoutSalesTaxFactor']);
    _each(keysWithOddsPrecision, (value, key) => {
        keysFromActiveSlip[key] = parseFloat(value).toFixed(oddsPrecision);
        keysFromActiveSlip[key] = (keysFromActiveSlip[key] && keysFromActiveSlip[key]!='0.00')?keysFromActiveSlip[key]:null;
    });

    const keysWithAmountPrecision = _pick(keysFromActiveSlip, ['bonus', 'winning', 'winningWithoutTax', 'minPossibleWinning', 'winningForDefaultTaxFactor', 'totalStake', 'maxStake']);
    _each(keysWithAmountPrecision, (value, key) => {
        keysFromActiveSlip[key] = parseFloat(value).toFixed(amountPrecision);
        keysFromActiveSlip[key] = (keysFromActiveSlip[key] && keysFromActiveSlip[key]!='0.00')?keysFromActiveSlip[key]:'0.00';
    });

    const odds = process.env.ODDS_WITH_SALES_TAX_FACTOR ? keysFromActiveSlip.totalOdds : keysFromActiveSlip.totalOddsWithoutSalesTaxFactor;
    const isWinningTaxEnabled = parseFloat(keysFromActiveSlip.winning) >= parseFloat(process.env.WINNING_TAX_AMOUNT) ? true : false;

    const winning = parseFloat(keysFromActiveSlip['winning']);
    const finalWinningAmount = winning;
    keysFromActiveSlip['winning'] = (finalWinningAmount).toFixed(amountPrecision);

    // Total bonus
    const isTotalBonusEnabled = process.env.TOTAL_BONUS && activeSlip.totalBonus?.isTotalbonusAvailable;

    const propositionOffersVisible = process.env.PROPOSITION_OFFERS;
    const propositionOffers = activeSlip.propositionOffers;

    return {
        hasApprovalButtonsVisibled,
        acceptHigherOdds,
        acceptAnyOdds,
        currencyCode,
        validFreebets,
        stakeControlValue,
        activeTab,
        isLogged,
        betSlips,
        odds,
        locked,
        isWinningTaxEnabled,
        balanceId,
        user,
        ...keysFromActiveSlip,
        isTotalBonusEnabled,
        propositionOffersVisible,
        propositionOffers
    }
};

const mapDispatchToProps = (dispatch, props) => {

    const boundActionCreators = bindActionCreators(
        {
            hideApprovalMessage,
            changeActiveTab,
            changeSlipStake,
            changeSlipTotalStake,
            fetchOddsByOutcomesIds,
            removeOutcome,
            startIntervalFetchOddsByOutcomeId,
            stopIntervalFetchOddsByOutcomeId,
            toggleOutcomeToBlock,
            loadApprovalDataOnInit,
            changeCombinationType,
            fetchFreebetByStatus,
            toggleFreebet,
            removeOutcomeFromBlock,
            toggleBalance,
            updateBlock
        },
        dispatch
    );

    return {
        ...boundActionCreators,
        placeBetInShop: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(placeBet(true));
        },
        placeBet: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(placeBet());
        },
        clearBetslip: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(clearBetslip());
        },
        placeBetSlipAgain: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(placeBetSlipAgain());
        },
        changeSlipType: (slipType, e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(changeSlipType(slipType));
        },
        toggleAccumulator: (e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(toggleAccumulator());
        },
        toggleAcceptHigherOdds: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(toggleAcceptHigherOdds());
        },
        toggleAcceptAnyOdds: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(toggleAcceptAnyOdds());
        },
        acceptTraderChanges: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(acceptTraderChanges());
        },
        rejectTraderChanges: (e) => {
            e.nativeEvent.stopImmediatePropagation();
            dispatch(rejectTraderChanges());
        },
        toggleBankerOutcome: (outcomeId, e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(toggleBankerOutcome(outcomeId));
        },
        createBlock: (e) => {
            if (e && ('nativeEvent' in e)) {
                e.nativeEvent.stopImmediatePropagation();
            }
            dispatch(createBlock());
        },
        dispatch
    }
};

export default withErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(BetSlip), UnexpectedBetSlipError);

