import React, {useEffect, useState} from 'react';
import Loader from 'Loader.js';
import {useDispatch, batch} from 'react-redux';
import {translation} from 'utilsHelper.js';
import usePrevious from 'UsePrevious.js';
import _size from 'lodash/size';
import {customerActivateAccount, startLoadCustomerDataInterval, getCustomerBettingStatus} from 'customerActions.js';
import {startSessionTimeInterval} from 'accountActions.js';
import S from 'StyledAccountActivate.js';

const AccountActivate = ({onActivationModalClose}) => {

    const dispatch = useDispatch();
    const [activationObj, setActivationObj] = useState({isPending: true, error: null, user: null});
    const {isPending, error, user} = activationObj;
    const prevStep = usePrevious(isPending);

    const componentWillUnmount = () => {
        onActivationModalClose();
    };

    useEffect(() => {
        if (!isPending && ((isPending != prevStep) && prevStep && user)) {

            const processRegisterUser = async()=>{
                await dispatch(getCustomerBettingStatus());
                batch(() => {
                    dispatch({type: 'USER_SIGNIN_SUCCESS', payload:{user}});
                    dispatch(startLoadCustomerDataInterval());
                    dispatch(startSessionTimeInterval());
                })
            };
            try{
                processRegisterUser();
            }catch(error){
                console.log('AccountActivate error: ', error);
            }

        }
    }, [isPending]);

    useEffect(() => {
        requestActivateAccount();
        return componentWillUnmount;
    }, []);

    const requestActivateAccount = async() => {
        try {
            const {userId, code:activationCode} = app.router.getPathParams();
            const params = {};
            params['customerId'] = userId;
            params['activationCode'] = activationCode;

            const user = await dispatch(customerActivateAccount(params));
            if(user && _size(user)){
                setActivationObj({isPending: false, user});
            }
        } catch (error) {
            setActivationObj({isPending: false, error})
        }
    };

    if (isPending) {
        return <Loader color="#F05A22"/>;
    }
    if (error) {
        return <S.Error>{error}</S.Error>
    }

    return (
        <S.AccountActivate>
            {translation('accountActivate_success')}
        </S.AccountActivate>
    );
};

export default AccountActivate;
