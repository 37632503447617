import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$loginFormSubmitBtnBgColor}}) => `${$loginFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$loginFormSubmitBtnColor}}) => `${$loginFormSubmitBtnColor}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const S = {};
const baseFont = `12px`;

S.ChangePasswordForm = styled(Form)`
width: 100%;
`;

S.Row = styled.div``;

S.Label = styled.label`
    pointer-events: none;
    color: ${({theme: {$accountProfileInput}}) => $accountProfileInput}!important;
    font-size: 1.33rem;
    font-weight: 400;
`; 

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: 2.25rem;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: ${({theme: {$accountProfileInput}}) => $accountProfileInput}!important;
    display: inline-block;
    font-size: ${rem('14px', baseFont)};
    font-weight: 500;
    border: none;
    border: 1px solid ${({theme: {$accountProfileInputBorder}}) => $accountProfileInputBorder};
    background-color: ${({theme: {$accountProfileInputBackground}}) => $accountProfileInputBackground};
    height: ${rem('42px', baseFont)};
    padding-left: ${rem('10px', baseFont)};
    border-radius: 3px;
    opacity: 1;
    margin: auto;
    width: 100%;
    &.valid {
        cursor: not-allowed;
        opacity: 0.7;
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: .42rem;
    }
`;

S.SubmitBtn = styled(Button)`
    font-size: ${rem('14px', baseFont)};
       background-color: ${SubmitBtnBgColor};
      color: ${SubmitBtnColor};
     font-weight: ${FwSemibold};
    cursor: pointer;
    width: 100%;
    height: ${rem('42px', baseFont)};
    line-height: ${rem('42px', baseFont)};
    text-align: center;
     border-radius: 3px;
    text-transform: uppercase;
             ${({disabled})=> {
    return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
}}
`;

S.Error = styled.div`
    color: ${({theme:{$accountProfilErrorRedColor}})=> $accountProfilErrorRedColor};
    margin: 5px 0;
    text-align: center;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.SubmissionSuccess = styled(S.SubmissionError)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;

S.SuccessModal = styled.div`
    margin-top: -44px;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.1538461538rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

export default S;