import styled from "styled-components";

const S = {};

const BetSlipCashbackAmountBorderColor = ({theme:{$betSlipCashbackAmountBorderColor}}) => `${$betSlipCashbackAmountBorderColor}`;

S.PropositionOfferWrapper = styled.div`
    position: relative;
    color: black;
`;

S.PropositionOfferAmountWrapper = styled.div`
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
`;

S.PropositionOfferRow = styled.div`
    width: 100%;
    text-align: center;
`;

S.CashbackIcon = styled.div`
    width: 3rem;
    height: 3rem;
    background-image: url(/images/svg/cashback-red-icon.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin: auto;
    margin-bottom: 0.5rem; 
`;

S.CashbackMessage = styled.div`
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
`;

S.CashbackAmount = styled.div`
    font-size: 2.3rem;
    font-weight: 600;
    text-align: center;
    border-bottom: 2px solid ${BetSlipCashbackAmountBorderColor};
    width: 100px;
    margin: auto;
`;

S.CashbackMixedEventsMessage = styled.div`
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem
`;




export default S;