import styled from 'styled-components';
import {rem} from 'polished';
import {Button} from 'global/styles/styles.js';
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.RegisterWizardForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: ${FontFamily};
  background-color: #fff;
`;

S.LogoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 16.66rem;
  margin: ${rem('15px', baseFont)} 0;
  ${({ theme: { mqForMac } }) => mqForMac}{
    width: 7%;
  }
`;

S.Link = styled.a`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

S.Logo = styled.img`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

S.LoginButton = styled.button`
  position: absolute;
  top: ${rem('15px', baseFont)};
  right: ${rem('15px', baseFont)};
  height: 3.5rem;
  font-weight: 600;
  padding: 0 ${rem('15px', baseFont)};
  border: 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 1.17rem;
`

S.Stepper = styled.div`
  display: flex;
  width: ${rem('250px', baseFont)};
  margin: 0 auto;
  
     & > :nth-of-type(1){
        &:after{
         flex:1 ;
                        content: '';
                        margin-top: ${rem('13px', baseFont)};
                        height: 1px;
                        margin-left: ${rem('-23px', baseFont)};
                        background-color: ${({step}) => ((step == 2) ? ' #D32F2F;' : 'rgba(0, 0, 0, 0.24);')};
        }
    }
    & > :nth-of-type(2){
       &:before{
         flex:1 ;
                        content: '';
                        margin-top: ${rem('13px', baseFont)};
                        height: 1px;
                        margin-right: ${rem('-23px', baseFont)};
                        background-color:  ${({step}) => ((step == 2) ? ' #D32F2F;' : 'rgba(0, 0, 0, 0.24);')};
        }
    
    }
`;

S.StepWrapper = styled.div`
    flex: 1;
    display: flex;
`;

S.Step = styled.div`
  width: ${rem('72px', baseFont)};
  flex-shrink: 0;
  display: flex;
  flex-direction: column;  
  align-items: center;
  ${({isActive}) => {
    if (isActive == true) {
        return `
                      ${S.StepCircle}{
                           font-weight: ${FwSemibold}; 
                           border-color: #D32F2F;
                           background-color: #D32F2F;
                           color: #fff;
                       }
                       
                        ${S.StepLabel}{
                           color: #D32F2F;
                       }
                       
                    `;
    }}};
    ${({hasPointer}) => {
      if (hasPointer) {
        return `
            cursor: pointer;
        `
      }
    }}

`;

S.StepCircle = styled.div`
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   width: ${rem('26px', baseFont)};
   height: ${rem('26px', baseFont)};
   border: 1px solid  rgba(0, 0, 0, 0.24);
   margin-bottom: ${rem('5px', baseFont)};
   color: rgba(0, 0, 0, 0.24);
`;

S.StepLabel = styled.div`
  letter-spacing: -0.18px;
  font-size: ${rem('12px', baseFont)};
  color: rgba(0, 0, 0, 0.24);
}
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('24px', baseFont)} 0;
  position: relative;
`;

S.Title = styled.h2`
   margin: 0;
   color: #000000DE;
    font-size: ${rem('26px', baseFont)};
    padding: 0;
    font-weight: ${FwRegular};
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.CloseIcon = styled.div`
    position: absolute;
    right: ${rem('16px', baseFont)};
    top: ${rem('10px', baseFont)};
    padding: 0;
    transform: none;
    line-height: 1;
    cursor: pointer;
    color: #000;
    &:before{
      content: "\\00d7";
      font-size: ${rem('35px', baseFont)};
    }
`;

S.HideIframe = styled.iframe`
    display: none;
`;


S.Dialog = styled.div`
   width:  ${rem('335px', baseFont)};
   padding: ${rem('16px', baseFont)};
   box-shadow: 0 19px 38px #00000042;

`;

S.DialogBtnOk = styled(Button)`
 width:  ${rem('142px', baseFont)};
 min-height:  ${rem('42px', baseFont)};
 text-transform: uppercase;
 color: #fff;
 background-color: #C62828;
 border-radius: 3px;
 font-weight: ${FwSemibold};
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
 color: #C62828;
 background-color: #fff;
 border: 1px solid #C62828;
`;

S.DialogText =styled.div`
  font-size: ${rem('13px', baseFont)};
  color: #000;
      text-align: center;
`;

S.BellIcon = styled.div`
  width:  ${rem('32px', baseFont)};
`;

S.ConfirmationModal = styled.div`
   display: flex;
   flex-direction: column;
   padding: ${rem('40px', baseFont)};
`;

S.ConfirmationMsg = styled.div`
   font-size: ${rem('20px', baseFont)};
   color: #000;
`;

S.ConfirmationCloseBtn = styled(Button)`
    background-color: #CE0006;
    height: ${rem('44px', baseFont)};
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    width: ${rem('125px', baseFont)};
    margin-bottom: 0;
    margin-top: 1.5rem;
    color: #fff;
    cursor: pointer;
    border: 2px solid #CE0006;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

S.BannerWrapper = styled.div`
  position:relative;
`

S.PrevStepBtn = styled(Button)`
    display:flex;
    background-color: transparent;
    align-items: center;
    position: absolute;
    padding: 0;
    bottom: ${rem('-20px', baseFont)};
    left: ${rem('5px', baseFont)};
`;

S.PrevIcon = styled.div`
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: ${rem('16px', baseFont)};
    height: ${rem('14px', baseFont)};
    display: flex;
    line-height: 1;
     & svg{
     width: 100%;
        & path{
            fill: #000 !important;
        }
    }
`;

export default S;
