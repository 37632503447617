import styled from "styled-components";
import {Button, Input,Form} from 'global/styles/styles.js';
import Loader from "Loader.js";
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = `12px`;
const S = {};

S.QuickSearchWrapper = styled.div`
    position: relative;
    height: ${rem('37px', baseFont)};
    display: flex;
    width: 100%;
    margin: ${rem('8px', baseFont)} 0;
`;

S.QuickSearch = styled(Form)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    align-self: center; 
`;

S.QuickSearchInput = styled(Input)`
    font-family: ${FontFamily};
    font-weight: 400;
    color: #3F3F3F;
    border: 2px solid #D32F2F; 
    border-radius: 3px;
    padding: 0 ${rem('30px', baseFont)} 0 ${rem('65px', baseFont)};
    height: 100%;
    flex-grow: 1;
    font-size:  ${rem('12px', baseFont)};
    width: 100%;
    
     &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                }
     }
`;

S.Label = styled.div`
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    left: ${rem('15px', baseFont)};
    color: #000 !important;
    font-size:  ${rem('10px', baseFont)};
    font-weight: 400;
    
    &:after{
      content: '';
      width: 1px;
      height: 16px;
      background-color: #000;
      margin-left: 4px;
    }
`;

S.SubmitButton = styled(Button)`
    background-color: transparent;
    width: 17px;
    height: 17px;
    padding: 0;
    position: absolute;
    right: ${rem('14px', baseFont)};
    top: 0;
    bottom: 0;
    margin: auto 0;
    & svg{
        & path{
            fill: #C62828  !important;
        }
    }
`;

S.ClearResults = styled(Button)`
    background-image: url(/images/svg/close_yellow.svg);
    background-repeat: no-repeat;
    height: 1.5rem;
    width: 1.5rem;
    position: absolute;
    right: 10px;
    background-color: transparent;
`;

S.ResultsBox = styled.ul`
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    top: 36px;
    background: #fff;
    list-style: none;
    padding: 0rem;
    color: #000;
    z-index: 1
    ${props => props.center &&`
       text-align: center;
    `}
`;

S.ResultItem = styled.li`
    padding: 0.5rem 0 0.5rem 1rem;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
        background: #d08989;
    }

    .highlight {
        background-color: transparent;
        font-weight: bold;
    }
`;

S.Loader = styled(Loader)`
    position: absolute;
    right: 5px;
`;

export default S;
