import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const FwBold = ({theme: {$fwBold}}) => `${$fwBold}`;

const baseFont = `12px`;
const S = {};

S.RegisterWizardFormStep1 = styled(Form)` 
    width: ${rem('500px', baseFont)};
    padding: 0;
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
`;

S.PasswordToggler = styled.div`
    position: absolute;
    right: ${rem('10px', baseFont)};
    top: ${rem('7px', baseFont)};
    font-size: ${rem('20px', baseFont)};
`;

S.PasswordTogglerIcon = styled.i`
  &:before{
       color: ${({isActive})=>(isActive==true?'#D32F2F':'grey')};
    }
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: ${rem('11px', baseFont)};
    left: ${rem('16px', baseFont)};
    color: rgba(0, 0, 0, 0.5);
    font-size:  ${rem('14px', baseFont)};
    font-weight: 400;
`;

S.FakeChbox = styled.div`
    width: ${rem('18px', baseFont)};
    height: ${rem('18px', baseFont)};
    border: 1px solid #EFF1F2;
    border-radius: 0;
    margin-right:  ${rem('10px', baseFont)};
    cursor: pointer;
    margin-top: -2px;
    flex-shrink: 0;
    pointer-events: none;
    z-index: 3;
    background-color: #fff;
    position: relative;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    color: #fff;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: rgba(0, 0, 0, 0.7);
    font-size:  ${rem('14px', baseFont)};
    padding: 0 ${rem('16px', baseFont)};
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: block;
    width: 100%;
    height: ${rem('42px', baseFont)};
    &:valid{
       & + ${S.Label}{
         margin-top: ${rem('-17px', baseFont)};
         margin-left: ${rem('-3px', baseFont)};
         font-size:  ${rem('10px', baseFont)};
         padding: 0 ${rem('5px', baseFont)};
         background-color: #fff;
       }
    }
    &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                }
     }
     
    &.has-error{
       border: 1px solid #D32F2F;
       & + ${S.Label}{
            color: #D32F2F;
       }
    }
    
    &.is-valid{
        border: 1px solid #63dd7d;
        & + ${S.Label}{
            color: #63dd7d;
       }
    }
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('42px', baseFont)};
    font-size: ${rem('14px', baseFont)};
    font-weight: ${FwSemibold};
    padding: 0;
    width: 100%;
    margin-top: ${rem('20px', baseFont)};
    color: ${SubmitBtnColor};
    cursor: pointer;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    padding: 0 0 5px 0;
    margin-left: 0;
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.33rem;
    color: #bebebe;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    width: 100%;
    margin: 0 0 2rem;
    cursor: pointer;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: ${rem('10px', baseFont)};
    
    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: ${rem('10px', baseFont)};
        
        ${S.Error}{
           width: 100%;
        }  
        ${S.Label}{
            flex: 1;
            font-size: ${rem('10px', baseFont)};
            font-weight: 400;
            padding-top: 0;
            color: rgba(0, 0, 0, 0.87);
            display: block;
            position: relative;
            left: 0;
            cursor: pointer;
            top: 0;
            align-items: flex-end;
            pointer-events: auto;
           
        }
         ${S.Input}{
            position: absolute!important;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 3px;
            top: 0;
            height: 12px;
            width: auto;
            margin: 0;
            cursor:pointer;
            &:checked + ${S.FakeChbox}{
              background-color: #D32F2F;
              border-color: #D32F2F;
            }
         }
    }
`;

S.Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${rem('35px', baseFont)};
  height:  ${rem('80px', baseFont)};
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 ${rem('9px', baseFont)} ${rem('87px', baseFont)};
`;

S.Title = styled.h2`
   margin: 0;
   color: #262626;
    font-size: ${rem('24px', baseFont)};
    padding: 0 0 0 10px;
    font-weight: 800;
    float: none!important;
    text-align: center;
    text-transform: uppercase;
`;

S.Body = styled.div`
  padding:  ${rem('30px', baseFont)};
`;

S.HelpBtn = styled.div`
    color: #777D85;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: ${rem('20px', baseFont)};
`;

S.HelpIcon = styled.div`
    cursor: pointer;
    width: ${rem('19px', baseFont)};
    height: ${rem('19px', baseFont)};
    background-color: #CBD0D6;
    border-radius: 50%;
    display: flex;
    color: #fff;
    margin-right: ${rem('10px', baseFont)};
    justify-content: center;
    align-items: center;
    
    &:before{
     background-color: transparent;
    }
`;

S.Info = styled.div`
   font-size: ${rem('10px', baseFont)};
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
`;

S.Footer = styled.div``;

S.LabelPart = styled.span`
 margin-right: ${rem('3px', baseFont)};
`;

S.Spoiler = styled.span`
  position: relative;
`;

S.Summary = styled.summary`
  display: list-item;
  list-style-type: none;
  color: #C62828;
  line-height: 1.2;
  font-weight: ${FwBold};
  &:before{
    content: "(rozwiń)";
  }
  &::-webkit-details-marker{
      background: none;
      color: transparent;
  }
`;

S.Details = styled.details`
  display: inline !important;
  &[open]{
    padding-right: ${rem('40px', baseFont)};
    & ${S.Summary}{
      position: absolute;
      right: 0;
      bottom: 0;
      &:before{
         content: "(zwiń)";
      }
    }
  }
`;

export default S;
