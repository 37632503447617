import styled from 'styled-components';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const baseFont = '12px';
const S = {};

S.AccountWithdraw = styled.div`
    width: 100%;
    font-family: ${FontFamily};
     background-color: #fff;
`;

S.AccountWithdrawInner = styled.div`
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 0 auto;
`;

S.NoActiveProviders = styled.div`
    text-align: center;
    font-size: 1.5rem;
    margin: 2rem;
`;


export default S;