import styled from 'styled-components';
import { rem } from 'polished';

const S = {};

const baseFont = `12px`;

S.FiltersBox = styled.div`
    float: left;
    width: 100%;
    background-color: ${({ theme: { $sportFilterContainerBackground } }) => $sportFilterContainerBackground};
    color: ${({ theme: { $sportFilterText } }) => $sportFilterText};
`;

S.FiltersBoxHeader = styled.div`
    font-size: 14px;
    height: ${rem('40px', baseFont)};
    line-height: ${rem('40px', baseFont)};
    display: flex;
    align-items: center;
    justify-items: flex-end;
    border-bottom: 1px solid ${({ theme: { $sportFilterHeaderBorder } }) => $sportFilterHeaderBorder};
    padding-left: 10px;
`;

S.FiltersWrapper = styled.div`
    padding: 5px 0;
`;

export default S;