import React, {memo, useMemo, useEffect, useState} from 'react';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _size from 'lodash/size';
import _reduce from 'lodash/reduce';
import _difference from 'lodash/difference';
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import _set from 'lodash/set';
import _find from 'lodash/find';
import _without from 'lodash/without';
import S from 'StyledFavouriteLeagues.js';
import Loader from 'Loader.js';
import ShowSelectedIcon from 'star_selected.svg';
import FavouriteIcon from 'star_unselected.svg';
import memoize from 'fast-memoize';
import {translation, findCategoryItemByIdLevel, getUrlPreparedString} from 'utilsHelper.js';

const FavouriteLeagues = (props) => {

    const {
        favouriteLeagues,
        toggleFavouriteForLeague,
        time,
        orgCategories,
        sportsOrder,
        selectedLeaguesBySport,
        toggleSelectedLeague,
        toggleSelectedLeaguesForSports,
        outrights,
        isFilteredCategoriesRequestPending,
        getFavouriteByType,
        toggleFavouriteByType,
        favouritesByType,
        removeFavouriteByType
    } = props;

    const [participants, setParticipants] = useState(null);

    useEffect(() => {
        const fetchFavouriteParticipants = async () => {
            const participants = await fetchParticipants();
            setParticipants(participants);
        }
        fetchFavouriteParticipants();
    }, [favouritesByType]);

    useEffect(() => {
        return () => {
            removeFavouriteByType('participant');
        }
    } , []);

    const fetchParticipants = async () => {
        let participants = _get(favouritesByType, ['participant']);
        participants = participants??await getFavouriteByType('participant');
        return participants;
    };

    const toggleSelectedForLeague = (leagueId, sportId) => {
        toggleSelectedLeague(leagueId, sportId);
        redirectToEventList(leagueId, sportId);
    };

    const isOutright = (categoryId, categories) => {
        const league = _find(categories, {categoryId});
        return _get(league, 'treatAsSport') == -500;
    };

    const isOutrightMemoized = memoize(isOutright);

    const redirectToEventList = (leagueId, sportId) => {

        let selectedLeaguesBySportCopy = {...selectedLeaguesBySport};
        let sportsOrderCopy = [];
        if (!isNaN(sportsOrder[0])) {
            sportsOrderCopy = [...sportsOrder];
        }
        let outrightsCopy = [...outrights];
        let hasNewLeagues = false;
        let leaguesIdsMappedToSport = null;
        let isArray = false;

        if (leagueId && sportId) {
            leaguesIdsMappedToSport = [{sportId, leagues: [].concat(leagueId)}];
        } else {
            leaguesIdsMappedToSport = leagueId;
            isArray = true;
        }

        _each(leaguesIdsMappedToSport, ({sportId, leagues}, idx) => {

            sportId = Number(sportId);

            _each(leagues, (leagueId) => {
                const isOutright = isOutrightMemoized(leagueId, orgCategories);
                if (isOutright) {
                    let idOfSport = -1;
                    const indexOfLeague = outrights.indexOf(leagueId);

                    if (indexOfLeague != -1) {
                        if (outrightsCopy.length == 1) {
                            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
                            sportsOrderCopy.splice(indexOfSportId, 1);
                        }
                        outrightsCopy = _without(outrightsCopy, ...([].concat(leagueId)));
                    } else {
                        const leagues = [].concat(leagueId);
                        const newLeaguesIds = _difference(leagues, outrightsCopy);
                        if (newLeaguesIds.length) {

                            if (isArray) {
                                outrightsCopy.push(...newLeaguesIds);
                            } else {
                                outrightsCopy.unshift(...newLeaguesIds);
                            }

                            const indexOfSportId = sportsOrderCopy.indexOf(idOfSport);
                            if (isArray) {
                                if (indexOfSportId == -1) {
                                    sportsOrderCopy.push(idOfSport);
                                }
                            } else {
                                if (indexOfSportId != -1) {
                                    sportsOrderCopy.splice(indexOfSportId, 1);
                                }
                                sportsOrderCopy.unshift(idOfSport);
                            }
                        }
                    }
                } else {
                    const leaguesForSport = _get(selectedLeaguesBySportCopy, [sportId], []);
                    const indexOfLeague = leaguesForSport.indexOf(leagueId);
                    if (indexOfLeague != -1) {
                        const leaguesForSportSliced = _without(leaguesForSport, ...([].concat(leagueId)));
                        _set(selectedLeaguesBySportCopy, [sportId], leaguesForSportSliced);

                        if (leaguesForSport.length == 1) {
                            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
                            sportsOrderCopy.splice(indexOfSportId, 1);
                        }
                    } else {

                        const leagues = [].concat(leagueId);
                        const newLeaguesIds = _difference(leagues, leaguesForSport);
                        if (newLeaguesIds.length) {
                            hasNewLeagues = true;
                            if (isArray) {
                                leaguesForSport.push(...newLeaguesIds);
                            } else {
                                leaguesForSport.unshift(...newLeaguesIds);
                            }

                            _set(selectedLeaguesBySportCopy, [sportId], leaguesForSport);

                            const indexOfSportId = sportsOrderCopy.indexOf(sportId);
                            if (isArray) {
                                if (indexOfSportId == -1) {
                                    sportsOrderCopy.push(sportId);
                                }
                            } else {
                                if (indexOfSportId != -1) {
                                    sportsOrderCopy.splice(indexOfSportId, 1);
                                }
                                sportsOrderCopy.unshift(sportId);
                            }
                        }
                    }
                }
            });

        });

        let categoriesNames = _reduce(sportsOrderCopy, (initialArray, sportId) => {
            let sportName = null;
            let category2Name = null;
            let category3Name = null;
            if (sportId == -1) {
                sportName = 'Outrights';
            } else {
                const sportCategory = _find(orgCategories, {sportId});
                const categoryLvl2 = findCategoryItemByIdLevel(leagueId, 2, orgCategories);
                const categoryLvl3 = findCategoryItemByIdLevel(leagueId, 3, orgCategories);
                sportName = _get(sportCategory, 'sportName');
                category2Name = _get(categoryLvl2, 'categoryName');
                category3Name = _get(categoryLvl3, 'categoryName');
            }
            sportName = sportName.replace(/\s+/g, '-').toLowerCase();
            category2Name = category2Name.replace(/\s+/g, '-').toLowerCase();
            category3Name = category3Name.replace(/\s+/g, '-').toLowerCase();
            return initialArray.concat(sportName, category2Name, category3Name);
        }, []);

        const params = {};
        params['selectedCategories'] = categoriesNames.join('/');
        params['leagueId'] = leagueId;

        const urlParams = {};
        if (time) {
            urlParams['filterEventsByTime'] = time;
        }
        if (_size(params['selectedCategories']) && leagueId) {
            app.router.redirect('/zaklady-bukmacherskie/:selectedCategories/:leagueId', params, urlParams);
        } else {
            app.router.redirect('/');
        }
    };

    const allFavouriteLeaguesSorted = useMemo(() => {
        const favouritesWithOrders = _map(favouriteLeagues, (o) => {
            const {categoryId, sortOrder} = o;
            const calegoryLvl1 = findCategoryItemByIdLevel(categoryId, 1, orgCategories);
            const calegoryLvl2 = findCategoryItemByIdLevel(categoryId, 2, orgCategories);
            const calegoryLvl1Order = _get(calegoryLvl1, 'sortOrder');
            const calegoryLvl2Order = _get(calegoryLvl2, 'sortOrder');
            const order = `${calegoryLvl1Order}_${calegoryLvl2Order}_${sortOrder}`;
            return {...o, order}
        });

        return _sortBy(favouritesWithOrders, ['order']);
    }, [favouriteLeagues]);

    const isSelected = (leagueId, sportId) => {
        const isOutright = isOutrightMemoized(leagueId, orgCategories);
        if (isOutright) {
            return (outrights.indexOf(leagueId) != -1);
        } else {
            const leaguesForSport = _get(selectedLeaguesBySport, [sportId], []);
            return (leaguesForSport.indexOf(leagueId) != -1);
        }
    };

    const goToParticipantEventList = (id, name) => {
        const encodedPhrase = getUrlPreparedString(name);
        app.router.redirect(`/quick-search-results/${encodedPhrase}/${id}`);
    };

    const removeFavouriteParticipant = async(id, e) => {
        e.stopPropagation();
        try{
            await toggleFavouriteByType(id, '', false, 'participant');
            app.util.Utils.syncFavouriteByType(id, 'participant');
        }catch(error){
            console.log('removeFavouriteParticipant:', error);
        }
    };

    const eventsCount = useMemo(()=>{
        return _reduce(favouriteLeagues, (initial, {eventsCount})=>{
            return initial+eventsCount;
        }, 0);
    }, [favouriteLeagues]);

    if(!participants){
        return <Loader size="5" color="#F05A22"/>;
    }

    return (

        <S.FavouriteLeagues className="favourite-leagues">

            <S.Header className="favourite-leagues-header">
                <S.FavouriteIcon dangerouslySetInnerHTML={{__html: FavouriteIcon}}/>
                <S.HeaderText>{translation('my_favourite_leagues')}</S.HeaderText>
                <S.HeaderEventsCount>{eventsCount}</S.HeaderEventsCount>
            </S.Header>


            {_size(allFavouriteLeaguesSorted) > 0 && (
                
                <S.Body className="favourite-leagues-body">

                    <S.List className="favourite-leagues-body">
                        {_map(allFavouriteLeaguesSorted, ({categoryName, categoryId, eventsCount, sportId}) => {
                            const selected = isSelected(categoryId, sportId);
                            return (
                                <S.ListItem
                                    key={categoryId}
                                    isSelected={selected}
                                    onClick={toggleSelectedForLeague.bind(null, categoryId, sportId)}>

                                    <S.FavouriteIcon
                                        dangerouslySetInnerHTML={{__html: ShowSelectedIcon}}
                                        onClick={toggleFavouriteForLeague.bind(null, categoryId, false)}>
                                    </S.FavouriteIcon>
                                    
                                    <S.LeagueName>{categoryName}</S.LeagueName>
                                    <S.EventsCount>{eventsCount}</S.EventsCount>
                                </S.ListItem>
                            )
                        })}
                    </S.List>
                </S.Body>
            )}

            {_size(participants)>0 && (
                <S.Body>
                    <S.List>
                        {_map(participants, ({id,  name}) => {
                            return (
                                <S.ListItem
                                    key={id}
                                    onClick={goToParticipantEventList.bind(null, id, name)}>

                                    <S.FavouriteIcon
                                        dangerouslySetInnerHTML={{__html: ShowSelectedIcon}}
                                        onClick={removeFavouriteParticipant.bind(null, id)}>
                                    </S.FavouriteIcon>

                                    <S.ParticipaneName>{name}</S.ParticipaneName>

                                </S.ListItem>
                            )
                        })}
                    </S.List>
                </S.Body>
            )}


        </S.FavouriteLeagues>
    );
};

export default memo(FavouriteLeagues);

