import styled from "styled-components";
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const PopularCategoriesHeaderBgColor = ({theme: {$popularCategoriesHeaderBgColor}}) => `${$popularCategoriesHeaderBgColor}`;
const PopularCategoriesItemBgColor = ({theme: {$popularCategoriesItemBgColor}}) => `${$popularCategoriesItemBgColor}`;
const PopularCategoriesItemBgColorHover = ({theme: {$popularCategoriesItemBgColorHover}}) => `${$popularCategoriesItemBgColorHover}`;
const PopularCategoriesSportIconColor = ({theme: {$popularCategoriesSportIconColor}}) => `${$popularCategoriesSportIconColor}`;
const PopularCategoriesEventsCountColor = ({theme: {$popularCategoriesEventsCountColor}}) => `${$popularCategoriesEventsCountColor}`;

const baseFont = `12px`;
const S = {};

S.PopularCategories = styled.div`
  font-family: ${FontFamily};
   margin-bottom: ${rem('8px', baseFont)};
`;

S.PopularCategoriesList = styled(List)`
  position: relative;
`;

S.PopularCategoriesListItemLink = styled.a`
    display: none;
    width: 0%;
    height: 0%;
    text-decoration: none;
`;

S.PopularCategoriesListItem = styled.li`
    height: ${rem('37px', baseFont)};
    display: flex;
    align-items: center;
    padding: 0 ${rem('16px', baseFont)};
    background-color: ${PopularCategoriesItemBgColor};
    cursor: pointer;
    &:hover {
      background-color:${PopularCategoriesItemBgColorHover};
    }
`;

S.PopularCategoriesHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem('16px', baseFont)};
  height: ${rem('40px', baseFont)};
  background-color: ${PopularCategoriesHeaderBgColor};
`;

S.PopularCategoriesHeaderIcon = styled.div`
  flex-shrink: 0;
  width: ${rem('16px', baseFont)};
  height: ${rem('16px', baseFont)};
  margin-right: ${rem('10px', baseFont)};
  
  & > svg{
    height: 100%;
    & rect,
    & path{
      fill: #000 !important;
    }
  }
`;

S.PopularCategoriesHeaderText = styled.div`
  color: #000;
  font-size: ${rem('18px', baseFont)};
  font-weight: ${FwSemibold};
  text-transform: lowercase;
  &:first-letter{
  text-transform: uppercase;
  }
`;




S.SportIcon = styled.i`
  display: flex;
  position: relative;
  flex-shrink: 0;
  width: ${rem('12px', baseFont)};
  height: ${rem('12px', baseFont)};
  margin-right: ${rem('8px', baseFont)};
  line-height: 1;
  &:before{
     font-size: ${rem('12px', baseFont)};
      color: ${PopularCategoriesSportIconColor};
  }
`;

S.PopularCategoriesItemName = styled.div`
  font-size: ${rem('14px', baseFont)};
  color: #000000;
  opacity: 0.87;
`;

S.EventsCount = styled(S.PopularCategoriesItemName)`
  margin-left:auto;
  color: ${PopularCategoriesEventsCountColor};
`;

S.PopularCategoriesHeaderEventsCount = styled.div`
  width: 16.6666666667%;
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  color: ${PopularCategoriesEventsCountColor};
  text-align: right;
    font-size: 14px;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
    flex: 1;
    font-weight: 600;
`;

export default S;