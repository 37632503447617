/* global app */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { SBPayments } from 'sb-payments'
import S from 'StyledAccountDeposit.js';
import CmsPage from 'CmsPage.js';
import {bindActionCreators} from 'redux';
import {getCustomerDaysFromRegistration} from 'customerActions.js';


const { Payments, useSession } = SBPayments

const AccountDeposit = ({ isLogged,getCustomerDaysFromRegistration, isAccountAboveDaysLimitAndTemporary }) => {
  const session = useSession()

  const [lang, setLang] = useState(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase())

  useEffect(() => {
    setLang(`lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase())
  }, [app.config])
  useEffect(() => {
    getCustomerDaysFromRegistration()
  },[]);

  return (
    isLogged && (
      <S.AccountDeposit>
        <S.AccountDepositInner>
          <CmsPage name="deposit-main-banner" isPlaces={true} />
          <Payments lang={lang} session={session} shouldDepositButtonBeDisabled={isAccountAboveDaysLimitAndTemporary}/>
        </S.AccountDepositInner>
      </S.AccountDeposit>
    )
  )
}

const mapStateToProps = ({ Auth: { isLogged },Customer:{isAccountAboveDaysLimitAndTemporary} }) => {
  return {
    isLogged,
    isAccountAboveDaysLimitAndTemporary
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerDaysFromRegistration: bindActionCreators(getCustomerDaysFromRegistration, dispatch),
  }
};


export default connect(mapStateToProps,mapDispatchToProps)(AccountDeposit)
