import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

const duration = 300;

S.BetHistoryItemDetails = styled.div`
  &.slide-transition-enter {
      max-height: 0px;
      margin-bottom: 3.75rem;
      overflow: hidden;
      transition: max-height ${duration}ms;
  }
  
  &.slide-transition-enter-active {
       max-height: 10000px;
  }
  
  &.slide-transition-exit-active {
       max-height: 0;
  }
  
`;

S.BetHistoryDetailsWrapper = styled.div`
    width: 50rem;
    padding-top: 2.5rem;
`;

S.BetHistoryDetailsHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 3.33rem;
    border-collapse: collapse;
    color: #000;

    &.tvbet {
        margin-bottom: 0;
    }
`;

S.BetHistoryDetailsHeaderActions = styled.div`
    display: flex;
    align-items: center;
    height: 52px;
    padding: 0 1.33rem;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBgActions}})=> $accountProfileBetHistoryTransactionDetailsHeaderBgActions};
`;

S.BetHistoryDetailsHeaderGoBack = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 1.33rem;
    background: url(/images/svg/ic_expand_close.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
`;

S.BetHistoryDetailsHeaderTitle = styled.div`
    display: flex;
    padding-bottom: 2px;
    font-size: 1.83rem;
    font-weight: 600;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderTitle}})=> $accountProfileBetHistoryTransactionDetailsHeaderTitle};
`;

S.BetHistoryDetailsHeaderShareActions = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 150px;
    margin-left: auto;

    & .shareIcon {
        display: flex;
        width: 32px;
        height: 32px;
        margin-right: 0.67rem;
        background-size: contain;
    }
`;

S.BetHistoryDetailsHeaderRebet = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10.5rem;
    height: 2.5rem;
    margin-left: auto;
    line-height: 1;
    font-size: 1.17rem;
    font-weight: 600;
    border-radius: 3px;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsRebetBackground}})=> $accountProfileBetHistoryTransactionDetailsRebetBackground};
    cursor: pointer;
`;

S.BetHistoryDetailsHeaderShareFb = styled.div`
    background: url(/images/svg/fb.svg) no-repeat;
`;

S.BetHistoryDetailsHeaderShareTwitter = styled.div`
    background: url(/images/svg/twitterShare.svg) no-repeat;
`;

S.BetHistoryDetailsHeaderShareLink = styled.div`
    background: url(/images/svg/share.svg) no-repeat;
    &:last-child {
        margin-right: 0;
    }
`;

S.InfoContainer = styled.div`
    display: ${({inView})=>(inView == true ? 'flex': 'none')};
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    padding-left: 1.67rem;
    background-color: ${({theme:{$accountProfileBetHistoryShareLinkInfoSuccessBackground}})=> $accountProfileBetHistoryShareLinkInfoSuccessBackground};
    color: ${({theme:{$accountProfileBetHistoryShareLinkInfoSuccessColor}})=> $accountProfileBetHistoryShareLinkInfoSuccessColor};

    & .close {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1.67rem;
        background: url(/images/svg/ic_expand_close.svg) no-repeat;
        background-size: contain;
    }
`;

S.BetHistoryDetailsHeaderInfo = styled.div`
    display: flex;
    align-items: center;
    height: 46px;
    padding: 0 1.75rem;
    font-size: 1.17rem;
    font-weight: 400;
    line-height: 1; 
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBgInfo}})=> $accountProfileBetHistoryTransactionDetailsHeaderBgInfo};

    &:last-child{
        border-top: 1px solid ${({theme:{$accountProfileBetHistoryTvBetInfoSecionSeparator}})=> $accountProfileBetHistoryTvBetInfoSecionSeparator};
    }
`;

S.betHistoryBlockHeader = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0.83rem 0;
    padding: 0 0.83rem;
    height: 24px;
    font-weight: 600; 
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBlockBg}})=> $accountProfileBetHistoryTransactionDetailsHeaderBlockBg};
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsHeaderBlockTxt}})=> $accountProfileBetHistoryTransactionDetailsHeaderBlockTxt};
`;

S.BetHistoryDetailsHeaderInfoSliptype = styled.div`
    font-weight: 600;
`;

S.BetHistoryDetailsHeaderInfoDate = styled.div`
    margin-left: 2.67rem;
`;
S.BetHistoryDetailsHeaderInfoIcon = styled.div`
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    margin-left: 1rem;

    & .tvBet {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26%;
        height: 24px;
        font-size: .9rem;
        border-radius: 4px;
        border: 2px solid ${({theme:{$accountProfileBetHistoryTvBetBorderColor}})=> $accountProfileBetHistoryTvBetBorderColor}; 
    }
`;

S.BetHistoryDetailsHeaderInfoId = styled.div`
    margin-left: auto;
`;

S.BetHistoryDetailsBody = styled.div`
    width: 100%;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    display: flex;
    flex-direction: column;
`;

S.BetHistoryDetailsBodyInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    margin-bottom: 1.17rem;
    border-bottom: 2px solid ${({theme:{$accountProfileBetHistoryTransactionHeaderBorder}})=> $accountProfileBetHistoryTransactionHeaderBorder};

    & span {
        font-size: 1rem;
        font-weight: 600;
        color: ${({theme:{$accountProfileBetHistoryTransactionDetailsMainColor}})=> $accountProfileBetHistoryTransactionDetailsMainColor};
    }

    & .details-category {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1;

        & .separator {
            display: block;
            margin: 0 0.42rem;
            width: 0.5rem;
            height: 0.5rem;
            background-image: url(/images/svg/arrow-right-separator.svg);
            background-repeat: no-repeat;
        }
    }
`;

S.BetHistoryDetailsBodyTransactionData = styled.div`
    display: flex;
    height: 9.17rem;
    background-color: ${({theme:{$accountProfileBetHistoryTransactionDetailsBg}})=> $accountProfileBetHistoryTransactionDetailsBg};
    border-radius: 0.25rem;
`;

S.BetHistoryDetailsBodyTransactionDataIcon = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 1.33rem;
    width: 10%;

    & > div {
        font-size: 1.83rem;
    }
`;

S.BetHistoryDetailsBodyTransactionDataTypes = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 73%;

    & .details-bet {
        display: flex;
        flex-direction: column;
        font-weight: 400;

        &.status-4 > span {
            text-decoration: line-through;
            text-decoration-color: #C62828;
        }

        & .event-name  {
            padding: 1.25rem 0 1.17rem 0;
            font-size: 1.33rem;
            font-weight: 600;
        }
        & .game-name  {
            padding-bottom: 0.5rem;
            font-size: 0.92rem;
            line-height: 1;
        }
        & .outcome-name {
            font-size: 1.08rem;
        }
    }
`;

S.BetHistoryDetailsBodyTransactionDataResults = styled.div`
    display: flex;
    flex-direction: column;
    width: 17%;

    & > div {
        display: flex;
        justify-content: flex-end;
        padding-right: 1.33rem;

        &.details-result {
            padding: 1.33rem 1.5rem 1.5rem 0;
            font-size: 16px;
            font-weight: 600;
            & .details-score {
                min-height: 43px;
                text-align: right;
            }
        }
    }
    & .details-odds {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 50%;
            margin-right: auto;
            &.status-4 {
                text-decoration: line-through;
                text-decoration-color: #C62828;
            }
        }
    & .cashback.item-icon {
        margin: 0 0 0 .7rem;
    }
`;

S.StatusIcon = styled.div`

    &.details_lost,
    &.details_won,
    &.details_placed,
    &.details_cancel,
    &.details_cashouted {
        height: 2rem;
        width: 2rem;
        border-radius: 20px;
        background: none;
    }
    &.details_lost {
        background-image: url(/images/svg/lost-24px.svg);
    }
    &.details_won {
        background-image: url(/images/svg/win-24px.svg);
    }
    &.details_placed {
        background-image: url(/images/svg/placed-24px.svg);
        width: 1.7rem;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.details_cashouted {
        background-image: url(/images/svg/cashout-24px.svg);
    }
    &.details_cancel {
        background-image: url(/images/svg/cancel-24px.svg);
        width: 1.7rem;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.cashback {
        width: 24px;
        height: 24px;
        background-image: url(/images/svg/cashback_icon.svg);
        background-size: contain;
        margin-right: .7rem;
    }
    &.earlyPayout {
        width: 24px;
        height: 24px;
        background-image: url(/images/svg/earlyPayout.svg);
        background-size: contain;
    }
`;

S.BetHistoryItemsFooter = styled.div`
    display: flex;
    padding-top: 2.5rem;
    font-size: 1.33rem;
    color: ${({theme:{$accountProfileBetHistoryTransactionDetailsFooterTxt}})=> $accountProfileBetHistoryTransactionDetailsFooterTxt};
`;

S.BetHistoryItemsFooterLabels = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    font-weight: 400;

    & > div {
        padding-bottom: 0.83rem;
    }
    & .ako {

    }
    & .stake {
        font-weight: 600;
    }
    & .cashback {
        display: flex;
        font-size: 1.17rem;
        font-weight: 700;
        & .cashBackLabel {
            margin-left: 0;
            margin-right: .7rem;
        }
    }
    & .possibleWin,
      .confirmedWin {
        font-size: 1.17rem;
        font-weight: 700;
    }
    & .taxOnWinning,
    & .taxFactor {
        font-size: 1rem;
    }
`;

S.BetHistoryItemsFooterInfo = styled(S.BetHistoryItemsFooterLabels)`
    align-items: flex-end;
    & .confirmedWin {
        &.won {
            color: ${({theme:{$accountProfileBetHistoryTransactionWonLabelColor}})=> $accountProfileBetHistoryTransactionWonLabelColor};
        }
    }
`;

S.BetHistoryItemsAdditionalOptions = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

S.CashoutButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: 100%;
    height: 3rem;
    font-size: 1.33rem;
    font-weight: 600;
    color: ${({ theme: { $accountProfileBetHistoryCashoutBttnColor } }) => $accountProfileBetHistoryCashoutBttnColor};
    background-color: ${({ theme: { $accountProfileBetHistoryCashoutBttnBackground } }) => $accountProfileBetHistoryCashoutBttnBackground};
    &:disabled {
        color: ${({ theme: { $accountProfileBetHistoryCashoutBttnDisabledColor } }) => $accountProfileBetHistoryCashoutBttnDisabledColor};
        background-color: ${({ theme: { $accountProfileBetHistoryCashoutBttnDisabledBackground } }) => $accountProfileBetHistoryCashoutBttnDisabledBackground};
        cursor: not-allowed;
    }
`;

S.CashoutButtonTxt = styled.span`
    text-align: left;
    margin-right: 1.33rem;
`;

S.CashoutButtonAmmount = styled.span`
`;

S.ErrorModal = styled.div`
    width: 100%;
`;

S.ErrorModalTitle = styled.h4`
    color: red;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.ErrorModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 1.2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

export default S;