const documentTypes = 
    {
        "id": {
            active: true,
            documentType: 1,
            name: "id",
            pages: 2,
            nameTranslation: "account_editProfileForm_NameIdNumber",
            subFieldName: "account_editProfileForm_IdNumber" 
        },
        "passport": {
            active: true,
            documentType: 3,
            name: "passport",
            pages: 1,
            nameTranslation: "account_editProfileForm_NamePassportNumber",
            subFieldName: "account_editProfileForm_passportNumber"
        }
    };

export default documentTypes;