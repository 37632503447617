import React, { Component, useMemo} from "react";
import {translation,formatMoney} from 'utilsHelper.js';
import _get from 'lodash/get';
import S from "StyledPropositionOffer.js";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const PropositionOffer = (props) => {

    const {amount, balanceId, betslip: {slipType}} = props;

    const mixedEvents = () => {

        const { betslip : { outcomes } } = props;
        let hasCashbackEvent = false;
        let hasStandardOfferEvent = false;
        if (outcomes?.length > 0){
            for (let index in outcomes) {
              if (outcomes[index].sportId == process.env.CASHBACK_PLUS_SPORT_ID){
                hasCashbackEvent = true;
              } else {
                hasStandardOfferEvent = true;
              }
            }
        } 
           
        
        if (hasCashbackEvent && hasStandardOfferEvent) {
            return true;
        }
        return false;
    };

    const isPropositionOfferVisible = useMemo(() => {
        return amount > 0 && slipType != 'SYSTEM' && balanceId == 0;
    }, [amount, balanceId, slipType]);

    return  (
        <S.PropositionOfferWrapper>
            {isPropositionOfferVisible &&
                <S.PropositionOfferAmountWrapper>
                    <S.PropositionOfferRow>
                        <S.CashbackIcon></S.CashbackIcon>
                    </S.PropositionOfferRow>
                    <S.CashbackMessage>
                        <span>{translation("betSlip_cashbackOfferAmount")}</span>
                    </S.CashbackMessage>
                    <S.CashbackAmount>{`${formatMoney(amount)} zł`}</S.CashbackAmount>
                </S.PropositionOfferAmountWrapper>
            }
            {amount == 0 && mixedEvents() &&
                <S.CashbackMixedEventsMessage>
                    <span>{translation("betSlip_cashbackMixedEvents")}</span>
                </S.CashbackMixedEventsMessage>
            }
        </S.PropositionOfferWrapper>
    );

}

const mapStateToProps = (state, props) => {
    const {BetSlip:{activeTab, betSlips, balanceId}} = state;
    const betslip = _get(betSlips, [activeTab]);
    return {
        betslip,
        balanceId
    }
};


export default connect(mapStateToProps, null)(PropositionOffer);