import styled,{css, keyframes}  from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipOutcomeOddsColor = ({theme:{$betSlipOutcomeOddsColor}}) => `${$betSlipOutcomeOddsColor}`;
const BetSlipOutcomeEventNameColor = ({theme:{$betSlipOutcomeEventNameColor}}) => `${$betSlipOutcomeEventNameColor}`;
const BetSlipOutcomeGameNameColor = ({theme:{$betSlipOutcomeGameNameColor}}) => `${$betSlipOutcomeGameNameColor}`;
const BetSlipOutcomeNameColor = ({theme:{$betSlipOutcomeNameColor}}) => `${$betSlipOutcomeNameColor}`;
const BetSlipOutcomeBorderColor = ({theme:{$betSlipOutcomeBorderColor}}) => `${$betSlipOutcomeBorderColor}`;
const BetSlipCombinationTypesSelectBackground = ({theme:{$betSlipCombinationTypesSelectBackground}}) => `${$betSlipCombinationTypesSelectBackground}`;
const BetSlipCombinationTypesSelectArrowColor = ({theme:{$betSlipCombinationTypesSelectArrowColor}}) => `${$betSlipCombinationTypesSelectArrowColor}`;
const BetSlipCombinationTypesSelectTextColor = ({theme:{$betSlipCombinationTypesSelectTextColor}}) => `${$betSlipCombinationTypesSelectTextColor}`;
const BetSlipSelectBackground = ({theme:{$betSlipSelectBackground}}) => `${$betSlipSelectBackground}`;
const BetSlipSelectArrowColor = ({theme:{$betSlipSelectArrowColor}}) => `${$betSlipSelectArrowColor}`;
const BetSlipSelectTextColor = ({theme:{$betSlipSelectTextColor}}) => `${$betSlipSelectTextColor}`;
const BetSlipBlockSelectBackground = ({theme:{$betSlipBlockSelectBackground}}) => `${$betSlipBlockSelectBackground}`;
const BetSlipBlockSelectArrowColor = ({theme:{$betSlipBlockSelectArrowColor}}) => `${$betSlipBlockSelectArrowColor}`;
const BetSlipBlockSelectTextColor = ({theme:{$betSlipBlockSelectTextColor}}) => `${$betSlipBlockSelectTextColor}`;
const BetSlipBlockSelectBorderColor = ({theme:{$betSlipBlockSelectBorderColor}}) => `${$betSlipBlockSelectBorderColor}`;
const BetSlipBlockHeaderTextColor = ({theme:{$betSlipBlockHeaderTextColor}}) => `${$betSlipBlockHeaderTextColor}`;
const BetSlipBlockHeaderBackground = ({theme:{$betSlipBlockHeaderBackground}}) => `${$betSlipBlockHeaderBackground}`;
const BetSlipBlockBodyBorderColor = ({theme:{$betSlipBlockBodyBorderColor}}) => `${$betSlipBlockBodyBorderColor}`;

const baseFont = `12px`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;

const S = {};

S.BetSlipSystem = styled.div`
`;

S.CombinationTypesSelect = styled.select`

        & .react-select__control{
            
            background-color: ${BetSlipCombinationTypesSelectBackground};
            border-radius: 0;
            min-height: ${rem('25px', baseFont)};
            
            & .react-select__value-container{
            
                & .react-select__single-value{
                    font-size: ${rem('14px', baseFont)};
                    color: ${BetSlipCombinationTypesSelectTextColor};
                }
            }
            
            & .react-select__indicators{
                margin-right: ${rem('10px', baseFont)};
                & .react-select__indicator-separator {
                    display: none;
                }
                
                & .react-select__indicator{
                    padding: 0;
                    & svg{
                        & path{
                            fill: ${BetSlipCombinationTypesSelectArrowColor} !important;
                        }
                    }
                }
                
            }
        }

        & .react-select__menu {
            & .react-select__menu-list {
                & .react-select__option {
                    color: #000;
                    background-color: #EFF1F2;
                    &--is-focused {
                        background-color: #CBD0D6;
                    }
                    &--is-selected {
                        background-color: #C62828;
                    }
                }
            }
        }
`;

S.Select = styled.select`
           & .react-select__control{
            background-color: ${BetSlipSelectBackground};
            border-radius: 0;
            min-height: ${rem('25px', baseFont)};
            border: none;
            
            & .react-select__value-container{
            
                & .react-select__single-value{
                    font-size: ${rem('14px', baseFont)};
                    color: ${BetSlipSelectTextColor};
                    font-weight: bold;
                }
            }
            
            & .react-select__indicators{
                margin-right: ${rem('10px', baseFont)};
                & .react-select__indicator-separator {
                    display: none;
                }
                
                & .react-select__indicator{
                     padding: 0;
                    & svg{
                        & path{
                            fill: ${BetSlipSelectArrowColor} !important;
                        }
                    }
                }
                
            }
        }

        & .react-select__menu {
            & .react-select__menu-list {
                & .react-select__option {
                    color: #000;
                    background-color: #EFF1F2;
                    &--is-focused {
                        background-color: #CBD0D6;
                    }
                    &--is-selected {
                        background-color: #C62828;
                    }
                }
            }
        }
`;

S.Outcome = styled.div`
   padding: 0;
   border-bottom: 1px solid ${BetSlipOutcomeBorderColor};
   display: flex;
   flex-direction: column;
   
  &.no-active{
    background-color: grey;
    opacity: 0.5;
  }
  
  &.unavailable{
     background-color: red;
   }
   
  &.oddsChanged{
      &.up{
       border: 2px dashed green;
       }
      &.down{
        border: 2px dashed red;
      }
   }
   & input{
    margin-right: 5px !important;
   }
`;

S.OutcomeHeader = styled.div`
`;

S.OutcomeBody = styled.div`
    display: flex;
    flex: 1;
    padding: ${rem('8px', baseFont)};
    &:not(.outcome-inside-block){
        background: #FFF;
    }
`;

S.SportIcon = styled.svg`
    width: 18px;
    height: 18px;
    margin-right: 1rem;
`;

S.OutcomeLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

S.OutcomeRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${rem('40px', baseFont)};
    align-items: flex-end;
`;

S.EventName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeEventNameColor};
    font-size: ${rem('12px', baseFont)};
    
    & > span {
        ${({theme: { lineClamp }}) => lineClamp(2)};
        font-weight: 600;
        cursor: pointer;
    }
`;

S.LiveIndicator = styled.div`
    align-self: center;
    background-color: #CE0006;
    color: #fff;
    height: ${rem('19px', baseFont)};
    text-transform: uppercase;
    flex-shrink: 0;
    margin-left:  ${rem('5px', baseFont)};
    padding: 0 ${rem('5px', baseFont)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-weight: bold;
 `;

S.GameName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeGameNameColor};
    font-size: ${rem('10px', baseFont)};
`;

S.OutcomeName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeNameColor};
    font-size: ${rem('11px', baseFont)};
    margin-top: ${rem('5px', baseFont)};
`;

S.OutcomeOdds = styled.div`
    color: ${BetSlipOutcomeOddsColor};
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
`;


S.CreateBlockBtn = styled(Button)`
 justify-content: center;
    background-color: #000;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
        margin-top: 10px;
        width: 120px;
`;


S.AccumulatorBtn = styled(Button)`
   justify-content: center;
   width: 120px;
    margin-top: ${rem('10px', baseFont)};
    margin-bottom: ${rem('10px', baseFont)};
    background-color: #000;
    height: 30px;
    border-radius: 3px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: ${rem('8px', baseFont)};
    ${({isActive})=>{
        if(isActive==true){
            return `&:after{
                content: "✓";
                color: #fff;
                margin-left: 10px;
            }`;
        }
    }}
`;

S.PrevOdds = styled.span`
    position: relative;
    margin-left: auto;
    &.up,
    &.down{
         padding-left: 25px;
         &:before{
              left: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 7px 7px 7px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 7px 7px 0px 7px;
        }
     }
`;

S.Block = styled.div`
    border: 1px solid ${BetSlipBlockBodyBorderColor};
    box-sizing: border-box;
`;

S.BlockHeader = styled.div`
   background-color: ${BetSlipBlockHeaderBackground};
   color: ${BetSlipBlockHeaderTextColor};
   padding: 2px;
   display: flex;
   justify-content: space-between;
   height: ${rem('25px', baseFont)};
   font-weight: bold;
   padding: 0 ${rem('10px', baseFont)};
   font-size: ${rem('14px', baseFont)};
   align-items: center;
`;

S.BlockBody = styled.div`

    & ${S.OutcomeHeader}{
       margin-top: ${rem('5px', baseFont)};
    }
    & ${S.Outcome}{
        border: none;
    }
    & ${S.Select}{
            & .react-select__control{
            border: 1px solid ${BetSlipBlockSelectBorderColor};
            margin: 0  ${rem('10px', baseFont)};
            background-color: ${BetSlipBlockSelectBackground};
            border-radius: 0;
            min-height: ${rem('25px', baseFont)};
            
            & .react-select__value-container{
            
                & .react-select__single-value{
                    font-size: ${rem('14px', baseFont)};
                    color: ${BetSlipBlockSelectTextColor};
                }
            }
            
            & .react-select__indicators{
            
                & .react-select__indicator-separator {
                    display: none;
                }
                
                & .react-select__indicator{
                    & svg{
                        & path{
                            fill: ${BetSlipBlockSelectArrowColor} !important;
                        }
                    }
                }
                
            }
        }
    }
`;

S.RemoveOutcome = styled(Button)`
    padding: 0;
    background-color: transparent;
    flex-shrink: 0;
    width:  ${rem('12px', baseFont)};
`;

S.Banker = styled(Button)`
    padding: 0;
    background-color: transparent;
    width: 14px;
    flex-shrink: 0;
    ${({isActive})=>{
        if(isActive==true){
            return `
                svg {
                    circle{
                        fill: #88d726 !important;
                    }
                }
            `;
        }
    }}
`;

S.BlockName = styled.div``;

S.BlockOdds = styled.div``;

export default S;