import React, {memo, useEffect, useState, useMemo} from 'react';
import Switch from 'react-switch';
import S from 'StyledFreebetList.js';
import {translation} from 'utilsHelper.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _size from 'lodash/size';
import { Scrollbars } from 'react-custom-scrollbars';

const FreebetList = (props) => {

    const {fetchFreebetByStatus, toggleFreebet, validFreebets, freebet, currencyCode, slipType, hasApprovalButtonsVisibled,  isBalanceBonusSelected} = props;

    const didMount = async() => {
        try {
            const config = {
                status: 'ACTIVE'
            };
            await fetchFreebetByStatus(config);
        } catch (error) {
            console.log('freebet:', error)
        }
    };

    useEffect(() => {
        didMount();
    }, []);

    const toggleActiveFreebet = (offerFreebetId) => {
        const item = _find(validFreebets, {offerFreebetId});
        toggleFreebet(item);
    };

    const isVisible = useMemo(() => {
        return (slipType == 'ACCUMULATOR' && _size(validFreebets) > 0);
    }, [slipType, validFreebets]);

    return (
        <S.FreebetListWrapper className="freebet-list-wrapper" isVisible={isVisible}>
            <Scrollbars style={{ width: '100%' }} autoHeight autoHeightMax={300}>
            <S.FreebetList className="freebet-list-wrapper">
                {_map(validFreebets, ({freebetStake, offerFreebetId, offerName}) => {
                    const selectedFreebetId = _get(freebet, ['offerFreebetId']);
                    return (
                        <S.FreebetListItem key={offerFreebetId} isActive={offerFreebetId == selectedFreebetId}>
                            <span>{offerName}: {freebetStake} {currencyCode}</span>&nbsp;&nbsp;
                            <Switch height={20}
                                    width={40}
                                    disabled={hasApprovalButtonsVisibled || isBalanceBonusSelected }
                                    onChange={toggleActiveFreebet.bind(null, offerFreebetId)}
                                    checked={offerFreebetId == selectedFreebetId}/>
                        </S.FreebetListItem>
                    )
                })}
            </S.FreebetList>
            </Scrollbars>
        </S.FreebetListWrapper>
    );
};

export default memo(FreebetList);
