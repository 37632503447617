export default {
    backgammon:	1,
    wheelbet: 2,
    poker: 3,
    war: 5,
    bet5: 6,
    bet7: 7,
    jokerbet: 8,
    keno: 9,
    bet1: 10,
    teen_patti: 11,
    lucky6: 12,
    fruitRace: 14,
    mega6: 15,
    andar_bahar: 17,
    blackjack: 19,
};

