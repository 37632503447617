import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "react-styled-box";
import S from "StyledGetLucky.js";
import _debounce from "lodash/debounce";
import { translation, formatMoney, decimal } from "helpers/utilsHelper.js";
import {
    changeGetLuckyStake,
    changeGetLuckyToWin,
    changeSelectedDays,
    changeRange,
    getLucky
} from "getLuckyActions.js";
import Loader from 'Loader.js';
import InputRange from "react-input-range";
import GetLuckyIcon from 'getlucky_icon.svg';
import "react-input-range/lib/css/index.css";

class GetLucky extends Component {
    state = {
        rangeValue: {
            min: this.props.range.value.min,
            max: this.props.range.value.max
        },
        hidden: false
    };

    toWinInputRef = React.createRef();
    stakeInputRef = React.createRef();

    toggleVisibility = () => {
        this.setState(prevState => {
            return {
                hidden: !prevState.hidden
            }
        })
    }

    debouncedOnStakeChange = _debounce(stake => {
        this.stakeInputRef.current.value = formatMoney(stake);
    }, 1000);

    debouncedOnTotalWinChange = _debounce(toWin => {
        this.toWinInputRef.current.value = formatMoney(toWin);
    }, 1000);

    onStakeChange = event => {
        let stake = parseFloat(
            parseFloat(event.target.value.replace(/\s/g, "")).toFixed(2)
        );
        if (isNaN(stake)) {
            stake = 0.0;
        }
        this.props.changeGetLuckyStake(stake);
        this.debouncedOnStakeChange(stake);
    };

    onToWinChange = event => {
        let toWin = parseFloat(
            parseFloat(event.target.value.replace(/\s/g, "")).toFixed(2)
        );
        if (isNaN(toWin)) {
            toWin = 0.0;
        }
        this.props.changeGetLuckyToWin(toWin);
        this.debouncedOnTotalWinChange(toWin);
    };

    onCheckboxChange = event => {
        this.props.changeSelectedDays(event.target.name, event.target.checked);
    };

    onRangeChange = valueObject => {
        this.setState({ rangeValue: valueObject });
    };

    onRangeChangeComplete = valueObject => {
        valueObject.min = parseFloat(valueObject.min.toFixed(2));
        valueObject.max = parseFloat(valueObject.max.toFixed(2));
        this.props.changeRange(valueObject);
    };

    handleSubmit = event => {
        event.preventDefault();
        this.props.getLucky();
    };

    render() {
        const {
            toWin,
            stake,
            selectedDays: { today, tomorrow, dayAfterTomorrow },
            range: { min: rangeMin, max: rangeMax, step },
            error,
            isPending
        } = this.props;

        const { rangeValue, rangeValue: {min: rangeMinValue, max: rangeMaxValue} } = this.state;

        return (
            <S.GetLucky className="get-lucky-form" onSubmit={this.handleSubmit}>
                { isPending ? 
                    <Loader /> 
                    : (
                        <>
                        <S.GetLuckyHeader
                            as={Box}
                            justifyContent="flex-start"
                            alignItems="center"
                            className="get-lucky__header"
                            onClick={() => {
                                if(!process.env.DISABLE_GET_LUCKY_TOGGLE_VISIBILITY) {
                                    this.toggleVisibility();
                                }
                            }}
                        >
                            <S.GetLuckyHeaderTitle className="get-lucky__header-title">
                                { process.env.GET_LUCKY_HEADER_ICON ? (
                                    <>
                                        <S.GetLuckyIcon
                                            dangerouslySetInnerHTML={{ __html: GetLuckyIcon }}
                                        ></S.GetLuckyIcon>
                                        <div>{translation("get_lucky_title")}</div>
                                    </>
                                ) : translation("get_lucky_title") }
                            </S.GetLuckyHeaderTitle>
                            { !process.env.DISABLE_GET_LUCKY_TOGGLE_VISIBILITY && 
                                (this.state.hidden ? <S.ArrowIcon className="ion ion-ios-arrow-down"></S.ArrowIcon> : <S.ArrowIcon className="ion ion-ios-arrow-up"></S.ArrowIcon>) 
                            }
                        </S.GetLuckyHeader>
                        <S.GetLuckyBody className="get-lucky-body" isHidden={this.state.hidden}>
                            <S.GetLuckyBodyContent className="get-lucky-body-content">
                                <S.FormGroupWrapper
                                    as={Box}
                                    alignItems="center"
                                    className="form-group-wrapper"
                                >
                                    <S.Label>{translation("get_luck_to_winn")}</S.Label>
                                    <S.AmountWrapper as={Box} alignItems="center" justifyContent="flex-end">
                                        <S.Input
                                            type="text"
                                            ref={this.toWinInputRef}
                                            defaultValue={formatMoney(toWin)}
                                            onChange={this.onToWinChange}
                                        />
                                        { !process.env.DISABLE_GET_LUCKY_CURRENCY && (
                                            <S.Currency>
                                                {app.config.mainCurrencyCode}
                                            </S.Currency>
                                        ) }
                                    </S.AmountWrapper>
                                </S.FormGroupWrapper>
                                <S.FormGroupWrapper
                                    as={Box}
                                    className="form-group-wrapper"
                                >
                                    <S.Label>{translation("get_lucky_stake")}</S.Label>
                                    <S.AmountWrapper as={Box} alignItems="center" justifyContent="flex-end">
                                        <S.Input
                                            type="text"
                                            ref={this.stakeInputRef}
                                            defaultValue={formatMoney(stake)}
                                            onChange={this.onStakeChange}
                                        />
                                        { !process.env.DISABLE_GET_LUCKY_CURRENCY && (
                                            <S.Currency>
                                                {app.config.mainCurrencyCode}
                                            </S.Currency>
                                        ) }
                                    </S.AmountWrapper>
                                </S.FormGroupWrapper>
                                <S.CheckboxWrapper
                                    as={Box}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className="checkbox-wrapper"
                                >
                                    <S.CheckboxGroup className="checkbox-group">
                                        <S.LabelCheckbox className="label-checkbox">
                                            <S.InputCheckbox
                                                type="checkbox"
                                                defaultChecked={today}
                                                autoComplete="off"
                                                onClick={this.onCheckboxChange}
                                                name="today"
                                            />
                                            <S.FakeCheckbox></S.FakeCheckbox>
                                            <S.CheckboxText>
                                                {translation("get_lucky_today")}
                                            </S.CheckboxText>
                                        </S.LabelCheckbox>
                                    </S.CheckboxGroup>
                                    <S.CheckboxGroup className="checkbox-group">
                                        <S.LabelCheckbox className="label-checkbox">
                                            <S.InputCheckbox
                                                type="checkbox"
                                                defaultChecked={tomorrow}
                                                autoComplete="off"
                                                onClick={this.onCheckboxChange}
                                                name="tomorrow"
                                            />
                                            <S.FakeCheckbox></S.FakeCheckbox>
                                            <S.CheckboxText>
                                                {translation("get_lucky_tomorrow")}
                                            </S.CheckboxText>
                                        </S.LabelCheckbox>
                                    </S.CheckboxGroup>
                                    <S.CheckboxGroup className="checkbox-group">
                                        <S.LabelCheckbox className="label-checkbox">
                                            <S.InputCheckbox
                                                type="checkbox"
                                                defaultChecked={dayAfterTomorrow}
                                                autoComplete="off"
                                                onClick={this.onCheckboxChange}
                                                name="dayAfterTomorrow"
                                            />
                                            <S.FakeCheckbox></S.FakeCheckbox>
                                            <S.CheckboxText>
                                                {translation(
                                                    "get_lucky_after_tomorrow"
                                                )}
                                            </S.CheckboxText>
                                        </S.LabelCheckbox>
                                    </S.CheckboxGroup>
                                </S.CheckboxWrapper>
                                <S.SliderWrapper className="slider-wrapper">
                                    <S.SliderLabel>
                                        {translation("odds_range")}
                                    </S.SliderLabel>
                                    <InputRange
                                        maxValue={rangeMax}
                                        minValue={rangeMin}
                                        value={rangeValue}
                                        onChange={this.onRangeChange}
                                        onChangeComplete={this.onRangeChangeComplete}
                                        step={step}
                                        formatLabel={value => value.toFixed(2)}
                                    />
                                    <S.InputRangeValuesWrapper as={Box} justifyContent="space-between">
                                        <S.InputRangeValue>{rangeMinValue.toFixed(2)}</S.InputRangeValue>
                                        <S.InputRangeValue>{rangeMaxValue.toFixed(2)}</S.InputRangeValue>
                                    </S.InputRangeValuesWrapper>
                                </S.SliderWrapper>
                            </S.GetLuckyBodyContent>
                            <S.GetLuckyFooter
                                as={Box}
                                justifyContent="center"
                                alignItems="center"
                                className="get-lucky-footer"
                            >
                                <S.Button type="submit">{translation("get_lucky_bet")}</S.Button>
                                {this.props.error ? <S.Error>{ error }</S.Error> : null}
                            </S.GetLuckyFooter>
                        </S.GetLuckyBody>
                        </>
                    )}
            </S.GetLucky>
        );
    }
}

const mapStateToProps = ({
    GetLucky: { toWin, stake, selectedDays, range, error, isPending }
}) => {
    return {
        toWin,
        stake,
        selectedDays,
        range,
        error,
        isPending
    };
};

const mapDispatchToProps = {
    changeGetLuckyStake,
    changeGetLuckyToWin,
    changeSelectedDays,
    changeRange,
    getLucky
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(GetLucky));
