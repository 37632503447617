import React from 'react';
import S from 'StyledForgotPasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'


// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.FORGOT_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    disabled,
    meta: {touched, error, warning, submitFailed}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required" disabled={disabled}/>
                <S.Label>{label}</S.Label>
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ForgotPasswordForm = ({forgotPasswordOnSubmit,  handleSubmit, submitSucceeded, submitting, error}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);

    const onFormSubmit = async(values) => {
        try {
            await forgotPasswordOnSubmit(values);
            toggleSuccessModal();
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ForgotPasswordForm method="post" onSubmit={handleSubmit(onFormSubmit)} autocomplete="off" noValidate>

            {submitSucceeded && <S.SubmissionSuccess>{translation('forgotPasswordForm_resetPasswordSuccess')}</S.SubmissionSuccess>}
            {error && <S.SubmissionError>{error}</S.SubmissionError>}

            <S.Info>{translation('forgotPasswordForm_text1')}</S.Info>

            <Field
                name="email"
                type="text"
                component={renderField}
                label="E-mail"
                disabled={submitSucceeded}
            />

            <S.SubmitBtn type="submit" disabled={submitting||submitSucceeded}>{translation('forgotPasswordForm_recoverPassword')}</S.SubmitBtn>

        </S.ForgotPasswordForm>
    );
};

export default reduxForm({
    form: 'forgotPassword',
    validate
})(ForgotPasswordForm)