import validation from 'sb-validate-js/validate.js';
import {translation} from 'utilsHelper.js';
import {substractYears} from 'datesHelper.js'
import {isPeselAdult, isPeselValid, getDateOfBirthFromPesel} from 'validateHelper.js';
import _range from 'lodash/range';
import _repeat from 'lodash/repeat';
import _reduce from 'lodash/reduce';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';

validation.validators.checkPolishPostalCodePattern = function (value, options, key, global) {
    const {nationality} = global;
    const {message} = options;
    if(value && String(value).replace(/\s/g, '').length){
        if(nationality == 'PL'){
            return !/^[0-9]{2}-[0-9]{3}$/.test(value) ? message() : null;
        }else{
            return !/^[a-zA-Z0-9\-\s\/]{2,8}$/.test(value) ? message() : null;
        }
    }
    return null;
};

validation.validators.peselMatchWithDate = function (value, options, key, global) {
    const size = value && _size(value);
    if (size == 11) {
        const {message} = options;
        const {dayOfBirth, monthOfBirth, yearOfBirth} = global;
        const dateOfBirthFromPesel = getDateOfBirthFromPesel(value);
        const peselMatchWithDate = ((dateOfBirthFromPesel.getFullYear() == yearOfBirth) && (dateOfBirthFromPesel.getMonth() == monthOfBirth) && (dateOfBirthFromPesel.getDate() == dayOfBirth));
        return !peselMatchWithDate ? message() : null;
    }
    return null;
};

validation.validators.isValidDate = function (value, {message}, key, global) {
    let day, month, year;
    if (typeof value == 'string') {
        const dateParts = value.split('-').map(Number);
        day = dateParts[2];
        month = (dateParts[1] > 0 ? dateParts[1] - 1 : 0);
        year = dateParts[0];
        const date = new Date(year, month, day);
        value = date;
    } else {
        let {dayOfBirth, monthOfBirth, yearOfBirth} = global;
        day = (+dayOfBirth);
        month = (+monthOfBirth);
        year = (+yearOfBirth);
    }

    const isValid = (value instanceof Date) && ((value.getFullYear() == year) && (value.getMonth() == month) && (value.getDate() == day));
    return !isValid ? message() : null;
};

validation.validators.isAdult = function (value, {message, latest}) {
    if (typeof value == 'string') {
        const dateParts = value.split('-').map(Number);
        const date = new Date(dateParts[0], (dateParts[1] > 0 ? dateParts[1] - 1 : 0), dateParts[2]);
        value = date;
    } 
    const adult = value <= latest;
    return !adult ? message() : null;
};

validation.validators.isPeselAdult = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const adult = isPeselAdult(value);
        return !adult ? message() : null;
    }
    return null;
};

validation.validators.isPeselValid = function (value, {message}) {
    const size = value && _size(value);
    if (size == 11) {
        const valid = isPeselValid(value);
        return !valid ? message() : null;
    }
    return null;
};

let prohibitedPhoneNumbers = _reduce(_range(1, 10), (initialArray, nextElem) => {
    const number = _repeat(nextElem, 9);
    return [...initialArray, number];
}, []);
prohibitedPhoneNumbers = [...prohibitedPhoneNumbers, ...['123456789', '987654321', '123123123']];

validation.validators.isSowValid = function (value, {message}) {
    return _isEmpty(value) ? message() : null;
};

export default {
    REGISTER_STEP1: {
        password: {
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_registerForm_passwordTooShort'),
                tooLong: () => translation('account_registerForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=[^\s]*?[A-Z])(?=[^\s]*?[a-z])(?=[^\s]*?[0-9])[^\s]*$/,
                message: () => translation('account_registerForm_wrongPasswordPattern'),
            },
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_registerForm_passwordEmpty'),
            },
            equality: {
                attribute: 'password',
                message: () => translation('account_registerForm_passwordsNotEqual'),
            }
        },
        email: {
            presence: {
                message: () => translation('account_registerForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_registerForm_wrongEmailPattern'),
            },
            length: {
                minimum: 5,
                maximum: 120,
                tooShort: () => translation('account_registerForm_emailTooShort'),
                tooLong: () => translation('account_registerForm_emailTooLong'),
            }
        },
        mobile: {
            presence: {
                message: () => translation('account_registerForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_registerForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_registerForm_phoneTooShort'),
                tooLong: () => translation('account_registerForm_phoneTooLong'),
            },
            exclusion: {
                within: prohibitedPhoneNumbers,
                message: () => translation('account_registerForm_prohibitedPhoneNumber'),
            },
            numericality: {
                onlyInteger: true,
                notInteger: () => translation('account_registerForm_onlyInteger'),
            }
        },
        rodoClause: {
            inclusion: {
                within: [true],
                message: () => translation('account_registerForm_adult'),
            },
            presence: {
                message: () => translation('account_registerForm_adult'),
            },
        }
    },
    REGISTER_STEP2: {
        firstName: {
            presence: {
                message: () => translation('account_registerForm_firstNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_firstNameTooShort'),
            },
            format: {
                pattern: /^.*[^ ].*[a-zA-ZąĄęĘóÓćĆłŁńŃśŚżŻźŹ-\s]*$/,
                message: () => translation('account_registerForm_firstNameEmpty'),
            }
        },
        lastName: {
            presence: {
                message: () => translation('account_registerForm_lastNameEmpty'),
            },
            length: {
                minimum: 3,
                tooShort: () => translation('account_registerForm_lastNameTooShort'),
            },
            format: {
                pattern: /^.*[^ ].*[a-zA-ZąĄęĘóÓćĆłŁńŃśŚżŻźŹ-\s]*$/,
                message: () => translation('account_registerForm_lastNameEmpty'),
            }
        },
        address:{
            presence: {
                message: () => translation('account_registerForm_addressEmpty'),
            },
            format: {
                pattern: /^.*[^ ].*[\D\d\s-./][^!"#$;*+<=>@~{|}`]*/,
                message: () => translation('account_editProfileForm_addressWrongPattern'),
            },
            length: {
                minimum: 7,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
        },
        postalCode:{
            presence: {
                message: () => translation('account_registerForm_postalCodeEmpty'),
            },
            checkPolishPostalCodePattern: {
                message: () => translation('account_registerForm_postalCodeWrongPattern'),
            },
            format: {
                pattern: /^.*[^ ].*[0-9]*$/,
                message: () => translation('account_registerForm_postalCodeEmpty'),
            },
        },
        dateOfBirth: {
            isValidDate:{
                message: () => translation('account_registerForm_dateOfBirthNotValid')
            },
            isAdult: {
                latest: substractYears(new Date, 18),
                message: () => translation('account_registerForm_notAdult')
            }
        },
        personalIdentifier: {
            isPeselValid:{
                message: () => translation('account_registerForm_peselNotValid')
            },
            isPeselAdult: {
                message: () => translation('account_registerForm_notAdult')
            },
            presence: {
                message: () => translation('account_registerForm_peselEmpty'),
            },
            length: {
                minimum: 11,
                maximum: 11,
                tooShort: () => translation('account_registerForm_peselTooShort'),
                tooLong: () => translation('account_registerForm_peselTooLong'),
            }
        },
        verificationCode: {
            presence: {
                message: () => translation('account_registerForm_smsCodeEmpty'),
            },
            numericality: {
                onlyInteger: true,
                message: () => translation('account_registerForm_verificationCodeIntegerOnly')
            },
            length: {
                minimum: 4,
                maximum: 4,
                tooShort: () => translation('account_registerForm_smsCodeTooShort'),
                tooLong: () => translation('account_registerForm_smsCodeTooLong'),
            }
        }
    },
    LOGIN: {
        login: {
            presence: {
                message: () => translation('loginModal_loginEmpty'),
            }
        },
        password: {
            presence: {
                message: () => translation('loginModal_passwordEmpty'),
            }
        }
    },
    FORGOT_PASSWORD: {
        email: {
            presence: {
                message: () => translation('account_forgotPasswordForm_emailEmpty'),
            },
            email: {
                message: () => translation('account_forgotPasswordForm_wrongEmailPattern'),
            },
        }
    },
    CASHOUT: {},
    CHANGE_PASSWORD: {
        oldPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_oldPasswordEmpty'),
            },
        },
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    RESET_PASSWORD: {
        newPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_newPasswordEmpty'),
            },
            equality: {
                attribute: 'confirmPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
            length: {
                minimum: 8,
                maximum: 25,
                tooShort: () => translation('account_changePasswordForm_passwordTooShort'),
                tooLong: () => translation('account_changePasswordForm_passwordTooLong'),
            },
            format: {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/,
                message: () => translation('account_changePasswordForm_wrongPasswordPattern'),
            },
        },
        confirmPassword: {
            presence: {
                message: () => translation('account_changePasswordForm_confirmPasswordEmpty'),
            },
            equality: {
                attribute: 'newPassword',
                message: () => translation('account_changePasswordForm_passwordsNotEqual'),
            },
        },
    },
    EDIT_ACCOUNT: {
        id:{
            presence: {
                message: () => translation('account_editProfileForm_noIdNumber'),
                allowEmpty: false
            },
            format: {
                pattern: /^[a-zA-Z]{3}[0-9]{6}$/,
                message: () => translation('account_editProfileForm_idNumber_incorrect'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        passport:{
            presence: {
                message: () => translation('account_editProfileForm_noPassportNumber'),
                allowEmpty: false
            },
            format: {
                pattern: /^[a-zA-Z]{2}[0-9]{7}$/,
                message: () => translation('account_editProfileForm_passportNumber_incorrect'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        mobile: {
            presence: {
                message: () => translation('account_editProfileForm_phoneEmpty'),
            },
            format: {
                pattern: /^[1-9][0-9]{8}$/,
                message: () => translation('account_editProfileForm_wrongPhoneNumberPattern'),
            },
            length: {
                minimum: 9,
                maximum: 9,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
        },
        city: {
            presence: {
                message: () => translation('account_editProfileForm_cityEmpty'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
            format: {
                pattern: /^[A-Za-z0-9ąĄęĘóÓćĆłŁńŃśŚżŻźŹ.,-\s]*$/,
                message: () => translation('account_editProfileForm_wrongCityPattern'),
            },
        },
        sow: {
            isSowValid: {
                message: () => translation('account_editProfileForm_sowEmpty'),
            },
        },
        sowOther: {
            presence: {
                message: () => translation('account_editProfileForm_sowOtherEmpty'),
            },
            length: {
                minimum: 2,
                maximum: 200,
                tooShort: () => translation('account_editProfileForm_toShort'),
                tooLong: () => translation('account_editProfileForm_toLong'),
            },
            format: {
                pattern: /^[A-Za-z0-9ąĄęĘóÓćĆłŁńŃśŚżŻźŹ.,\s]*$/,
                message: () => translation('account_editProfileForm_wrongOtherSowPattern'),
            },
        },
        address: {
            presence: {
                message: () => translation('account_editProfileForm_adressEmpty'),
            },
            length: {
                minimum: 2,
                tooShort: () => translation('account_editProfileForm_toShort'),
            },
            format: {
                pattern: /^[A-Za-z0-9ąĄęĘóÓćĆłŁńŃśŚżŻźŹ.,-\s]*$/,
                message: () => translation('account_editProfileForm_wrongAddressPattern'),
            },
        },
        postalCode: {
            presence: {
                message: () => translation('account_editProfileForm_postalCodeEmpty'),
            },
            checkPolishPostalCodePattern: {
                message: () => translation('account_registerForm_postalCodeWrongPattern'),
            }
        },
        personalIdentifier: {
            isPeselValid:{
                message: () => translation('account_registerForm_peselNotValid')
            },
            isPeselAdult: {
                message: () => translation('account_registerForm_notAdult')
            },
            presence: {
                message: () => translation('account_registerForm_peselEmpty'),
            },
            length: {
                minimum: 11,
                maximum: 11,
                tooShort: () => translation('account_registerForm_peselTooShort'),
                tooLong: () => translation('account_registerForm_peselTooLong'),
            }
        },
        bankAccountNumber: {
            presence: {
                message: () => translation('account_editProfileForm_bankAccountEmpty'),
            },
            format: {
                pattern: /^[0-9]{2} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$|^[0-9\s]{32}$|^[0-9]{26}$/,
                message: () => translation('account_editProfileForm_wrongbankAccountPattern'),
            }
        }

    },
};

