import styled, {css} from 'styled-components';
import {List, Link} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.SubList = styled(List)`
`;

S.SubListItem = styled.li`
`;

S.SubListLink = styled.div`
`;

S.SubmenuList = styled(List)`
    height: 100%;
    display: flex;
    flex: 0 1 auto;
    margin-right: 0;
    padding-left: 0;
`;

S.SubmenuLink = styled(Link)`
    height: 100%;
    line-height: 1;
    width: 100%;
    font-size: ${rem('14px', baseFont)};
    font-weight: 400;
    font-style: normal;
    padding: 0;
    text-decoration: none;
    margin-right:0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #FFF;
    
    &:link,
    &:visited,
    &:hover,
    &:active{
      text-decoration: none;
      color: #FFF;
    }
`;

S.SubmenuListItem = styled.li`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${rem('32px', baseFont)};
    text-transform: uppercase;
    &:last-of-type{
     margin-right: 0
    }
    
    ${({isActive}) => {
    if (isActive == true) {
        return css`
                & ${S.SubmenuLink}{
                    color: #FFF;
                }
            `;
    }

}}
`;

export default S;