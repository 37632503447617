import React, {useRef, useMemo, useEffect} from 'react';
import S from 'StyledRegisterWizardFormStep2.js';
import {Field, reduxForm, formValues, change} from 'redux-form';
import {translation} from 'utilsHelper.js';
import { getPromoCookie } from 'cookiesHelper.js';
import {isValueUnique, smsCodeVerifyWithAttemps} from 'authActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import Select from 'react-select';
import _find from 'lodash/find';
import _map from 'lodash/map';
import Loader from 'Loader.js';
import usePrevious from 'UsePrevious.js';
import BellIcon from 'bell-icon.svg';
import dialogPolyfill from 'dialog-polyfill'

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP2);
    return errors;
};

const shouldAsyncValidate = ({pristine, trigger, syncValidationPasses}) =>{
    if (!syncValidationPasses) {
        return false
    }
    switch (trigger) {
        case 'blur':
        case 'change':
        case 'submit':
            return true;
        default:
            return false
    }
};

const asyncValidate = async (values, dispatch, props, field) => {
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];

        let requestAction = null;
        switch (fieldName) {
            case 'verificationCode':
                const mobileNumber = values['mobile'];
                requestAction = smsCodeVerifyWithAttemps.bind(null, fieldName, fieldValue, mobileNumber);
                break;
            default:
                requestAction = isValueUnique.bind(null, fieldName, fieldValue);
                break;
        }
        try {
            const result = await dispatch(requestAction());
            return result;
        } catch (err) {
            const isObject = ({}).toString.call(err) == '[object Object]';
            if(isObject && fieldName == 'verificationCode'){
                const {message, code} = err;
                if(code==440) {
                    dispatch(change('registerWizardForm','emailVerification', true));
                    throw {_error: translation(message), [fieldName]: [translation('account_registerForm_verificationCode_wrongSMSCode')]};
                }
            }else{
                throw {[fieldName]: [translation('account_registerForm_' + err)]};
            }
        }
    }
};

const renderField = ({
                         input,
                         label,
                         type,
                         readonly,
                         meta: {touched, error, warning, valid, asyncValidating, submitFailed}
                     }) => {

    const isHidden = (type == 'hidden');
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });
    return (
        <S.Row>
            <S.InputWrapper isHidden={isHidden}>

                <S.Input {...input}
                         type={type}
                         required="required"
                         className={inputClass}
                         readOnly={readonly}
                />

                {!isHidden && (<S.Label dangerouslySetInnerHTML={{__html: label}}></S.Label>)}

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
                                       input,
                                       labelPart1,
                                       labelPart2,
                                       required,
                                       meta: {touched, error, warning, submitFailed}
                                   }) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">

                <S.Input {...input}
                         value={input.value}
                         checked={input.value}
                         onChange={event => input.onChange(event.target.checked)}
                         type="checkbox" required={required}
                />

                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>

                <S.Label>

                    <S.LabelPart dangerouslySetInnerHTML={{__html: labelPart1}}></S.LabelPart>

                    {labelPart2 && (
                        <S.Spoiler>
                            <S.Details>
                                <S.Summary></S.Summary>
                                <S.LabelPart dangerouslySetInnerHTML={{__html: labelPart2}}></S.LabelPart>
                            </S.Details>
                        </S.Spoiler>
                    )}

                </S.Label>

                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    );
};


const renderSelect = ({
                          input,
                          label,
                          options,
                          className,
                          meta: {touched, error, warning, valid, submitFailed}
                      }) => {

    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });

    const inputWrapperClass = classNames('with-select', {[`${className}`]: Boolean(className)});

    return (
        <S.Row>
            <S.InputWrapper className={inputWrapperClass}>

                <S.Select as={Select}
                          options={options}
                          value={_find(options, {value: input.value})}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          className={inputClass}
                          placeholder={label}
                          onChange={(props) => {
                              input.onChange(props.value);
                          }}
                />

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    )
};

let PeselToggler = ({nationality}) => {

    return (nationality == 'PL' &&
        (
            <Field
                name="personalIdentifier"
                type="text"
                component={renderField}
                label={translation('account_registerForm_personalIdentifier')}
            />
        )
    )
};
PeselToggler = formValues('nationality')(PeselToggler);

let VerificationCodeToggler = ({emailVerification, change}) => {

    const chboxPrevValue = usePrevious(emailVerification);
    const dialogContainer = useRef(null);

    useEffect(() => {
        if (emailVerification == true && emailVerification != chboxPrevValue) {
            const dialogElement = dialogContainer.current;
            dialogPolyfill.registerDialog(dialogElement);

            if (dialogElement && typeof dialogElement.showModal === 'function') {
                const uncheckEmailVerificationChbox = () => change('emailVerification', false);
                const onModalClose = () => {
                    if (dialogElement.returnValue == 'cancel') {
                        uncheckEmailVerificationChbox();
                    }
                    dialogElement.removeEventListener('cancel', uncheckEmailVerificationChbox);
                    dialogElement.removeEventListener('close', onModalClose);
                };

                dialogElement.addEventListener('cancel', uncheckEmailVerificationChbox);
                dialogElement.addEventListener('close', onModalClose);
                dialogElement.showModal();
            }
        }
    }, [emailVerification]);

    const closeModalWithArgument = (action) => {
        const dialogElement = dialogContainer.current;
        if (dialogElement && typeof dialogElement.close === 'function') dialogElement.close(action);
    };

    return (
        <>
            <S.Dialog ref={dialogContainer}>

                <Box flexDirection="column">

                    <Box justifyContent="center">
                        <S.BellIcon dangerouslySetInnerHTML={{__html: BellIcon}}></S.BellIcon>
                    </Box>

                    <Box margin="20px 0 0" alignSelf="center">
                        <S.DialogText>Na pewno chcesz aktywować konto<br/> poprzez email?</S.DialogText>
                    </Box>

                    <Box justifyContent="space-between" margin="20px 0 0">
                        <S.DialogBtnOk onClick={closeModalWithArgument.bind(null, 'approve')}>ok</S.DialogBtnOk>
                        <S.DialogBtnCancel onClick={closeModalWithArgument.bind(null, 'cancel')}>Cancel</S.DialogBtnCancel>
                    </Box>

                </Box>

            </S.Dialog>

            {!emailVerification && (
                <Field
                    name="verificationCode"
                    type="text"
                    component={renderField}
                    label={translation('register_smsCode')}
                />
            )}
        </>
    )
};
VerificationCodeToggler = formValues('emailVerification')(VerificationCodeToggler);

let AddressToggler = ({nationality}) => {
    return (nationality != 'PL' &&
        (
            <>
                <Box>
                    <Field
                        name="address"
                        type="text"
                        component={renderField}
                        label={translation('register_address')}
                    />
                </Box>

                <Box>
                    <Field
                        name="postalCode"
                        type="text"
                        component={renderField}
                        label={translation('register_postalCode')}
                    />
                </Box>
            </>
        )
    );
};
AddressToggler = formValues('nationality')(AddressToggler);

let DateOfBirthHidden =  ({dayOfBirth, monthOfBirth, yearOfBirth, change, touch}) => {

    useEffect(()=>{
        change('dateOfBirth', `${yearOfBirth}-${('0'+(monthOfBirth+1)).substr(-2)}-${('0'+(dayOfBirth)).substr(-2)}`)
    }, [dayOfBirth, monthOfBirth, yearOfBirth]);

    useEffect(()=>{
        touch('dateOfBirth');
    },[]);

    return (
            <Field
                name="dateOfBirth"
                component={renderField}
                type="hidden"
            />
        )
};
DateOfBirthHidden = formValues({'dayOfBirth':'dayOfBirth', 'monthOfBirth':'monthOfBirth', 'yearOfBirth':'yearOfBirth'})(DateOfBirthHidden);

let DateOfBirthToggler = ({nationality, daysOptions, monthsOptions, yearsOptions, change, touch}) => {
    return (nationality != 'PL' && (
        <>
            <Box>
                <S.DateOfBirth>{translation('account_registerForm_dateOfBirth')}</S.DateOfBirth>
            </Box>

            <Box>
                <Box width="33%" padding="0 10px 0 0" flexGrow={1}>
                    <Field
                        name="dayOfBirth"
                        component={renderSelect}
                        options={daysOptions}
                        label={translation('account_registerForm_dayOfBirth')}
                        className="no-margin"
                    />
                </Box>

                <Box width="33%" flexGrow={1}>
                    <Field
                        name="monthOfBirth"
                        component={renderSelect}
                        options={monthsOptions}
                        label={translation('account_registerForm_monthOfBirth')}
                        className="no-margin"
                    />
                </Box>

                <Box width="33%" padding="0 0 0 10px" flexGrow={1}>
                    <Field
                        name="yearOfBirth"
                        component={renderSelect}
                        options={yearsOptions}
                        label={translation('account_registerForm_yearOfBirth')}
                        className="no-margin"
                    />
                </Box>

            </Box>

            <Box>
                <DateOfBirthHidden change={change} touch={touch}/>
            </Box>
        </>
    ));
};
DateOfBirthToggler = formValues('nationality')(DateOfBirthToggler);

let RegisterWizardFormStep2 = ({handleSubmit, submitting, error, change, touch, asyncValidating, isPromoCodeVisible }) => {

    const promo = getPromoCookie();

    const countries = useMemo(() => {
        const countries = app.service.Language.cachedDict.countries;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['label'] = countryName;
            option['value'] = countryCode;
            return option;
        });

        options.sort((a, b) => a['label'].localeCompare(b['label']));
        return options;
    }, []);

    const daysOptions = useMemo(() => {
        const days = app.service.Dict.getDays();
        const options = _map(days, ({value, name}) => {
            const option = {};
            option['label'] = name;
            option['value'] = Number(value);
            return option;
        });

        return options;
    }, []);

    const monthsOptions = useMemo(() => {
        const months = app.service.Dict.getMonths();
        const options = _map(months, ({value, name}) => {
            const option = {};
            option['label'] = name;
            option['value'] = Number(value);
            return option;
        });

        return options;
    }, []);

    const yearsOptions = useMemo(() => {
        const years = app.service.Dict.getYears();
        const options = _map(years, ({value, name}) => {
            const option = {};
            option['label'] = name;
            option['value'] = Number(value);
            return option;
        });

        return options;
    }, []);
    return (
        <S.RegisterWizardFormStep2 onSubmit={handleSubmit} noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.Body>

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="column">
                    <Box>
                        <Box width="50%" padding="0 7px 0 0">
                            <Field
                                name="firstName"
                                type="text"
                                component={renderField}
                                label={translation('account_registerForm_firstName')}
                            />
                        </Box>

                        <Box width="50%" padding="0 0 0 7px">
                            <Field
                                name="lastName"
                                type="text"
                                component={renderField}
                                label={translation('account_registerForm_lastName')}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Field
                            name="nationality"
                            component={renderSelect}
                            options={countries}
                            label={translation('account_registerForm_nationality')}
                        />
                    </Box>

                    <Box flexDirection="column">
                        <AddressToggler/>
                    </Box>

                    <Box flexDirection="column">
                        <DateOfBirthToggler daysOptions={daysOptions} monthsOptions={monthsOptions} yearsOptions={yearsOptions} change={change} touch={touch}/>
                    </Box>

                    <Box>
                        <PeselToggler/>
                    </Box>

                    <Box>
                        <VerificationCodeToggler change={change}/>
                    </Box>

                    {isPromoCodeVisible && (
                    <Box>
                         <Field
                            name="promoCode"
                            type="text"
                            component={renderField}
                            label={translation('account_registerForm_promoCode')}
                            readonly={promo}
                         />    
                    </Box>
                    )}

                    <Box margin="10px 0 0 0">
                        <Field
                            name="emailVerification"
                            component={CheckboxWithLabelAndError}
                            labelPart1={translation('account_registerForm_emailVerification')}
                            required={false}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="isBonus"
                            component={CheckboxWithLabelAndError}
                            labelPart1={translation('account_registerForm_bonus')}
                            required={false}
                        />
                    </Box>

                    <S.SubmitBtn type="submit" disabled={submitting||asyncValidating}
                        data-test='account_registerForm_signup'>
                        {translation('account_registerForm_signup')}
                    </S.SubmitBtn>

                </Box>

            </S.Body>

        </S.RegisterWizardFormStep2>
    );
};

export default reduxForm({
    form: 'registerWizardForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['personalIdentifier', 'verificationCode'],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    shouldAsyncValidate
})(RegisterWizardFormStep2);
