import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.TvBetHistoryItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 4.33rem;
`;

S.TvBetHistoryItem = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    padding-bottom: 2px;
    & > div{
            display: flex;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            padding: .67rem;
            min-height: 3.33rem;
            color: #000;
            align-items: center;
            background-color: ${({theme:{$accountProfileBetHistoryTransactionLabelBg}})=> $accountProfileBetHistoryTransactionLabelBg};

            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        
        &.transaction-date{
            flex: .2 1 0;
            font-size: 12px;
        }
        
        &.transaction-stake{
            flex: 1 1 0;
        }
        &.transaction-win{
            flex: 1 1 0;
        }
        &.transaction-confirmed-win,
        &.transaction-amount {
            flex: .3 1 0;
            font-size: 1.33rem;
            justify-content: flex-start;
            &.lost {
                color: ${({theme:{$accountProfileBetHistoryTransactionLostLabelColor}})=> $accountProfileBetHistoryTransactionLostLabelColor};
            }
            &.won {
                color: ${({theme:{$accountProfileBetHistoryTransactionWonLabelColor}})=> $accountProfileBetHistoryTransactionWonLabelColor};
            }
            &.placed {
                color: ${({theme:{$accountProfileBetHistoryTvBetPlacedTypeColor}})=> $accountProfileBetHistoryTvBetPlacedTypeColor};
            }
        }
        &.transaction-type{
            flex: .55 1 0;
            font-size: 1.33rem;
            justify-content: flex-start;
        }
        &.transaction-icon {
            flex: 2.7 1 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            & .tvBet {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 8%;
                height: 24px;
                font-size: .9rem;
                border-radius: 4px;
                border: 2px solid ${({theme:{$accountProfileBetHistoryTvBetBorderColor}})=> $accountProfileBetHistoryTvBetBorderColor}; 
            }
        }

        &.transaction-status{
            width: 4rem;
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
              
              & button{
                    border: none;
                    font-weight: 700;
                    vertical-align: middle;
                    display: block;
                    width: 25%;
                    background: 0 0;
                    color: #fff;
                    padding: 0;
                    height: auto;
                    margin-top: 0;
                    font-size: 1.25rem;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    
                    & > span{
                        font-size: 12px;
                        font-weight: 400;
                    }
                    
              }
        
        }
        
    }
`;

S.StatusIcon = styled.div`

    &.won,
    &.placed,
    &.cancel,
    &.tvbet {
        height: 2rem;
        width: 2rem;
        border-radius: 20px;
        background: none;
    }
    &.cancel {
        background-image: url(/images/svg/cancel-24px.svg);
    }
    &.won {
        background-image: url(/images/svg/win-24px.svg);
    }
    &.placed {
        background-image: url(/images/svg/placed-24px.svg);
    }
    &.tvBet {
        background-image: url(/images/svg/placed-24px.svg);
    }
    &.jackpot {
        background-image: url(/images/svg/win-24px.svg); 
    }
`;

export default S;