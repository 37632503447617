import React, {useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import S from 'StyledModal.js';
import classNames from 'classnames';
import {modalUserIconSVG} from 'global/svgIcons/svgIcons.js';

const Modal = ({showHeader, showHeaderIcon, showCloseIcon, showHeaderTitle, onClose, title , children, className, padding, width, headerPadding, minHeightBody, minWidthBody, justifyContentBody, preventCloseOnOverlay, height, maxHeight, backgroundWrapper, backgroundCurtain, positionByMargin, closeIconColor}) => {

    useEffect(() => {
        window.addEventListener('scroll', preventScrollEventHandler);

        const mainWrapperElem = document.querySelector('.main-wrapper');
        if (mainWrapperElem) {
            mainWrapperElem.classList.add('has-blur');
        }
        return () => {
            if (mainWrapperElem) {
                mainWrapperElem.classList.remove('has-blur');
            }
            window.removeEventListener('scroll', preventScrollEventHandler);
        }
    }, []);

    const elementMouseDownRef = useRef(null);

    const preventScrollEventHandler = (e) => {
        window.scrollTo(0, 0);
    };

    const onMouseUpHandler = (e) => {
        if (elementMouseDownRef.current == e.target && e.target.matches('.modal-background')) {
            if (!preventCloseOnOverlay && onClose && typeof onClose == 'function') {
                onClose();
                reactStopPropagation(e);
            }
        } else {
            reactStopPropagation(e);
        }
    };

    const onMouseDownHandler = (e)=>{  
        reactStopPropagation(e); 
        elementMouseDownRef.current = e.target; 
    };

    const closeHandler = (e) => {
        reactStopPropagation(e);
        onClose();
    }

    const reactStopPropagation = (e) => {
        e.persist(); 
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
    }

    const closeIcon = (showCloseIcon && <S.CloseIconContainer closeIconColor={closeIconColor} onClick={(e) => closeHandler(e)}></S.CloseIconContainer>);

    const headerIcon = (showHeaderIcon && <S.UserIconContainer dangerouslySetInnerHTML={modalUserIconSVG()}></S.UserIconContainer>);

    const headerTitle = (showHeaderTitle && <S.ModalTitle>{title}</S.ModalTitle>);

    const modalMarkup = (
        <S.ModalBackground className={classNames(className, 'modal-background')}
                            onMouseDown={onMouseDownHandler}
                            onMouseUp={onMouseUpHandler}
                            onClick={reactStopPropagation}
                            backgroundCurtain={backgroundCurtain}>

            <S.ModalWrapper className="modal-wrapper"
                            padding={padding}
                            width={width}
                            height={height}
                            maxHeight={maxHeight}
                            backgroundWrapper={backgroundWrapper}
                            positionByMargin={positionByMargin}>

                {showHeader && (
                    <S.ModalHeader headerPadding={headerPadding}>
                        {closeIcon}
                        {headerIcon}
                        {headerTitle}
                    </S.ModalHeader>
                )}
                <S.ModalBody minHeightBody={minHeightBody} minWidthBody={minWidthBody} justifyContentBody={justifyContentBody}>
                    {children}
                </S.ModalBody>

            </S.ModalWrapper>

        </S.ModalBackground>
    );

    return ReactDOM.createPortal(modalMarkup, document.body);
};

export default Modal;
