import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import _get from 'lodash/get';
import _find from 'lodash/find';
import {bindActionCreators} from 'redux';
import S from 'StyledTopMenu.js';
import CmsPage from 'CmsPage.js';
import SubmenuList from 'SubmenuList.js';
import UnloggedPanel from 'UnloggedPanel.js';
import LoggedPanel from 'LoggedPanel.js';
import {changeActiveItem, changeSubmenuActiveItem, changeSubmenuDropdownItem} from 'topMenuActions.js';
import {filterLeftMenuEsportCategories} from 'categoriesActions.js';
import {loadCustomerOffersBalances} from 'offersActions.js';
import {
    logOutUser,
    forgotPassword,
    resetPassword,
} from 'authActions.js';
import {getFavouriteLeagues} from 'favouritesActions.js';
import {toggleBalance} from 'betSlipActions.js';
import {translation} from 'utilsHelper.js';
import InstagramIcon from 'ig_icon.svg';
import TwitterIcon from 'twitter_icon.svg';
import FacebookIcon from 'fb_icon.svg';
import {fetchCFLocationData} from 'otherActions.js';

const LOGO_SRC = `${window.location.origin.includes("localhost") ? 'https://test.fuksiarz.pl' : ''}/static/images/cms/fuksiarz/logo/Logo_fuksiarzpl.svg`

class TopMenu extends Component {

    constructor(props) {
        super(props);
        this.topMenuRef = createRef();
    }

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {hasError: false};

    componentDidMount = async () => {
        const {loadCustomerOffersBalances} = this.props;
        try {
            this.watchRoute();
            this.selectTopMenuItemsFromPath();
            this.checkUserLocation();
            await loadCustomerOffersBalances();
        } catch (error) {
            console.log('topMenuSelection error:', error);
        }
    };

    watchRoute = () => {
        app.router.onRouteChange('topMenuSelection', () => {
            filterEsportCategories();
            handleTopMenuChange();
            this.renderBeforeFooterCmsPlace();
        });
        const filterEsportCategories = () => {
            const eSportControllers = ['eSport', 'eSportEvent', 'eSportEventList', 'eSportLivescore', 'eSportStatistics'];
            const {filterLeftMenuEsportCategories} = this.props;
            if (eSportControllers.indexOf(app.getCurrentController()) != -1) {
                filterLeftMenuEsportCategories(true);
            } else {
                filterLeftMenuEsportCategories(false);
            }
        };
        const handleTopMenuChange = () => {
            this.selectTopMenuItemsFromPath();
        }
        filterEsportCategories();

        this.renderBeforeFooterCmsPlace();
    };

    renderBeforeFooterCmsPlace = () => {

        let cmsPlaceName = ``;
        let currentRoutePath = app.router.getCurrentRoute();
        const currentController = app.getCurrentController();
        const previousController = app.previousController;

        if (currentController == previousController) {
            return;
        }

        switch (currentController) {
            case 'LiveEventList':
            case 'LiveMulti':
            case 'LiveEvent':
            case 'LiveCalendar':
                if (['LiveEventList', 'LiveMulti', 'LiveEvent', 'LiveCalendar'].indexOf(previousController) != -1) {
                    return;
                }
                cmsPlaceName = `before-footer-live-place`;
                break;
            case 'Virtual':
                cmsPlaceName = `before-footer-virtual-place`;
                break;
            case 'eSport':
                cmsPlaceName = `before-footer-esport-place`;
                break;
            case 'TvBet':
                cmsPlaceName = `before-footer-betgames-place`;
                break;
            case 'Sports':
                cmsPlaceName = `before-footer-sports-place`;
                break;
            case 'CmsCustomPage':
                currentRoutePath = currentRoutePath.substr(currentRoutePath.lastIndexOf('/') + 1).toLowerCase();
                if (['statystyki', 'betgames'].indexOf(currentRoutePath) != -1) {
                    cmsPlaceName = `before-footer-${currentRoutePath}-place`;
                }
                break;
        }

        const beforeFooterCmsPlaceSelector = document.getElementById('beforeFooterCmsPlace');
        if (beforeFooterCmsPlaceSelector) {
            if (cmsPlaceName.length) {
                beforeFooterCmsPlaceSelector.setAttribute('name', cmsPlaceName);
                app.partial.CustomCmsElement.initCmsElements([].concat(beforeFooterCmsPlaceSelector), true);
            } else {
                beforeFooterCmsPlaceSelector.innerHTML = ``;
            }
        }
    };

    checkUserLocation = async () => {
        const {dispatch, fetchCFLocationData} = this.props;
        const locationData = await fetchCFLocationData();
        if (locationData['loc'] != 'PL') {
            dispatch({
                type: 'TOGGLE_BY_KEY',
                payload: {key: 'TOGGLE_FORBIDDEN_ACCESS_MODAL', isOpen: true}
            });
        }
    };

    closeForbiddenModal = () => {
        const {dispatch} = this.props;
        dispatch({
            type: 'TOGGLE_BY_KEY',
            payload: {key: 'TOGGLE_FORBIDDEN_ACCESS_MODAL', isOpen: false}
        });
    }

    selectTopMenuItemsFromPath = () => {
        const {changeActiveItem, changeSubmenuActiveItem} = this.props;
        const currentRoutePath = app.router.getCurrentRoute();
        const currentController = app.getCurrentController();

        if (/^\/(zaklady-bukmacherskie.*?)/.test(currentRoutePath) || currentRoutePath.length === 1) {
            changeActiveItem('HOME');

        } else if (/^\/zaklady-live.*?/.test(currentRoutePath)) {
            changeActiveItem('LIVES');
            switch (currentController) {
                case 'LiveEventList':
                    changeSubmenuActiveItem('OVERVIEW');
                    break;
                case 'LiveMulti':
                    changeSubmenuActiveItem('MULTI');
                    break;
                case 'LiveEvent':
                    changeSubmenuActiveItem('LIVE');
                    break;
                case 'LiveCalendar':
                    changeSubmenuActiveItem('CALENDAR');
                    break;
            }
        } else if (/^\/sporty-wirtualne.*?/.test(currentRoutePath)) {
            changeActiveItem('VIRUTALS');

        } else if (/^\/esport.*?/.test(currentRoutePath)) {
            changeActiveItem('ESPORTS');

        } else if (/^\/bonuses.*?/.test(currentRoutePath)) {
            changeActiveItem('BONUSES');

        } else if (/^\/blog.*?/.test(currentRoutePath)) {
            changeActiveItem('BLOG');
        }else {
            changeActiveItem(currentRoutePath);
        }
    };

    handleCmsSubMenuRoute = (route) => {
        if (/^\/(zaklady-bukmacherskie.*?)/.test(route) || route.length === 1) {
            return <CmsPage key="menu-main-sport" name="menu-main-sport" isPlaces={true}/>

        } else if (/^\/zaklady-live.*?/.test(route)) {
            return <CmsPage key="menu-main-live" name="menu-main-live" isPlaces={true}/>

        } else if (/^\/cashback.*?/.test(route)) {
            return <CmsPage key="menu-main-cashback" name="menu-main-cashback" isPlaces={true}/>

        } else if (/^\/sporty-wirtualne.*?/.test(route)) {
            return <CmsPage key="menu-main-sporty-wirtualne" name="menu-main-sporty-wirtualne" isPlaces={true}/>

        } else if (/^\/zaklady-gry-karciane.*?/.test(route)) {
            return <CmsPage key="menu-main-betgames" name="menu-main-betgames" isPlaces={true}/>

        } else if (/^\/esport.*?/.test(route)) {
            return <CmsPage key="menu-main-esport" name="menu-main-esport" isPlaces={true}/>

        } else if (/^\/promocje.*?/.test(route)) {
            return <CmsPage key="menu-main-promocje" name="menu-main-promocje" isPlaces={true}/>
        }
    }

    returnCmsSubMenu = () => {
        const currentRoutePath = app.router.getCurrentRoute();
        const topMenuSelector = this.topMenuRef?.current;
        let cmsSubMenu = null;
        cmsSubMenu = this.handleCmsSubMenuRoute(currentRoutePath);
        if (!cmsSubMenu && topMenuSelector) {
            const cmsTopActiveElement = topMenuSelector.querySelector('.sb-active'); // get active/selected element from top menu
            const hrefAttribute = cmsTopActiveElement?.dataset?.href;
            if (cmsTopActiveElement && hrefAttribute) {
                cmsSubMenu = this.handleCmsSubMenuRoute(hrefAttribute);
            }
        }
        if (cmsSubMenu) {
            return cmsSubMenu
        }
    }

    forgotPasswordOnSubmit = async (postData) => {
        const {forgotPassword} = this.props;
        try {
            const user = await forgotPassword(postData);
            return user;
        } catch (error) {
            throw error;
        }
    };

    resetPasswordOnSubmit = async (values) => {
        const {resetPassword} = this.props;
        try {
            const result = await resetPassword(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    render() {

        const {
            isLogged,
            logOutUser,
            user,
            balanceId,
            toggleBalance,
            toggleAccountModal,
            isOpen,
            isResetPasswordModalOpen,
            isForgotPasswordModalOpen,
            isRegisterModalOpen,
            isActivateAccountModalOpen,
            customerRegulations,
            freebets,
            offersBalances,
            activeItem,
            isForbiddenAccessModalOpen,
            ...rest
        } = this.props;

        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }

        return (
            <S.TopMenu className="top-menu">

                {isForbiddenAccessModalOpen ?
                    (
                        <S.ForbiddenAccess>
                            {translation('access_forbidden')}
                            <S.CloseIcon onClick={this.closeForbiddenModal}/>
                        </S.ForbiddenAccess>
                    )
                    :
                    ''}

                <S.MenuRow ref={this.topMenuRef} className="menu-list">

                    {/* <S.Logo as="img" dangerouslySetInnerHTML={{__html: LogoIcon}}/> */}
                    <S.LogoWrapper>
                        <S.Link href={`/`}>
                            <S.Logo src={LOGO_SRC} alt="fuksiarz.pl"/>
                        </S.Link>
                    </S.LogoWrapper>

                    <CmsPage  name="menu-main-component" isPlaces={true}/>



                    {isLogged ?
                        (<LoggedPanel user={user}
                                      customerRegulations={customerRegulations}
                                      logOut={logOutUser}
                                      toggleBalance={toggleBalance}
                                      balanceId={balanceId}
                                      isAccountOpen={isOpen}
                                      toggleUserAccountModal={toggleAccountModal}
                                      freebets={freebets}
                                      offersBalances={offersBalances}
                            />
                        )
                        :
                        (<UnloggedPanel forgotPasswordOnSubmit={this.forgotPasswordOnSubmit}
                                        resetPasswordOnSubmit={this.resetPasswordOnSubmit}
                                        isResetPasswordModalOpen={isResetPasswordModalOpen}
                                        isForgotPasswordModalOpen={isForgotPasswordModalOpen}
                                        isActivateAccountModalOpen={isActivateAccountModalOpen}
                            />
                        )
                    }

                </S.MenuRow>

                <S.SubmenuRow className="submenu-row">
                    {this.returnCmsSubMenu()}

                    <S.SocialIcons>
                        <S.SocialIconLink href={translation('socialItems_facebook_link')} target="_blank" rel="nofollow"
                                          dangerouslySetInnerHTML={{__html: FacebookIcon}}/>
                        <S.SocialIconLink href={translation('socialItems_twitter_link')} target="_blank" rel="nofollow"
                                          dangerouslySetInnerHTML={{__html: TwitterIcon}}/>
                        <S.SocialIconLink href={translation('socialItems_instagram_link')} target="_blank" rel="nofollow"
                                          dangerouslySetInnerHTML={{__html: InstagramIcon}}/>
                    </S.SocialIcons>
                </S.SubmenuRow>

            </S.TopMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        TopMenu: {activeItem, submenuItem, submenuDropdownItem},
        Auth: {isLogged, user}, BetSlip: {balanceId, freebets},
        Account: {isOpen},
        Toggle: {toggleMap},
        Customer: {customerRegulations},
        Offers: {offersBalances},
    } = state;
    const toggleResetModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_RESET_PASSWORD_MODAL'});
    const toggleForgotModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_FORGOT_PASSWORD_MODAL'});
    const toggleRegisterModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_REGISTER_MODAL'});
    const toggleActivateAccountModalObject = _find(toggleMap, {key: 'TOGGLE_OPEN_ACTIVATE_ACCOUNT_MODAL'});
    const toggleForbiddenAccessModalObject = _find(toggleMap, {key: 'TOGGLE_FORBIDDEN_ACCESS_MODAL'});

    const isResetPasswordModalOpen = _get(toggleResetModalObject, ['isOpen']);
    const isForgotPasswordModalOpen = _get(toggleForgotModalObject, ['isOpen']);
    const isRegisterModalOpen = _get(toggleRegisterModalObject, ['isOpen']);
    const isActivateAccountModalOpen = _get(toggleActivateAccountModalObject, ['isOpen']);
    const isForbiddenAccessModalOpen = _get(toggleForbiddenAccessModalObject, ['isOpen']);

    return {
        activeItem,
        isLogged,
        user,
        balanceId,
        submenuItem,
        submenuDropdownItem,
        isResetPasswordModalOpen,
        isForgotPasswordModalOpen,
        isRegisterModalOpen,
        customerRegulations,
        isActivateAccountModalOpen,
        freebets,
        offersBalances,
        isForbiddenAccessModalOpen
    }
};

const mapDispatchToProps = (dispatch) => {

    const bindedActions = {
        changeActiveItem,
        logOutUser,
        forgotPassword,
        toggleBalance,
        changeSubmenuActiveItem,
        changeSubmenuDropdownItem,
        resetPassword,
        filterLeftMenuEsportCategories,
        loadCustomerOffersBalances,
        getFavouriteLeagues,
        fetchCFLocationData
    };

    return {
        ...bindActionCreators(bindedActions, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);

