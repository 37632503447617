import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountFreebet.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import {translation} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {
    changeDateByType,
    fetchFreebetsByFilter,
    setCurrentPage,
    fetchAllFreebets,
    changeActiveTab,
    resetToInitialState
} from 'accountFreebetActions.js';
import {pl} from 'date-fns/locale';

class AccountFreebet extends Component {

    componentDidMount = async () => {
        try {
            this.fetchAllFreebets();
        } catch (error) {
            console.log('fetchFreebetByFilter:' + error);
        }
    };

    componentWillUnmount = () => {
        const {resetToInitialState} = this.props;
        resetToInitialState();
    }

    handleChangeStart = (date) => {
        const {changeDateByType, endDate, limit, fetchFreebetsByFilter} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = 'HISTORY';

        changeDateByType('startDate', startDate);
        fetchFreebetsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, startDate, limit, fetchFreebetsByFilter} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = 'HISTORY';

        changeDateByType('endDate', endDate);
        fetchFreebetsByFilter(params);
    };

    fetchAllFreebets = async (params = {}) => {
        const {fetchAllFreebets} = this.props;
        try {
            const result = await fetchAllFreebets(params);
            return result;
        } catch (error) {
            throw error;
        }
    };

    goPage = async (status, currentPage) => {
        const {fetchFreebetsByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const params = {
            endDate,
            startDate,
            limit,
            status,
            currentPage
        };

        try {
            await fetchFreebetsByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    onChangeFilter = (activeTab) => {
        const {changeActiveTab, fetchFreebetsByFilter, startDate, endDate, limit} = this.props;
        const params = {
            endDate,
            startDate,
            limit
        };
        params['status'] = activeTab.toUpperCase();

        changeActiveTab(activeTab);
        fetchFreebetsByFilter(params);
    };

    render() {

        const {isPending, activeTab, startDate, endDate, freebets, limit, currencyCode} = this.props;
        const activeFreebets = _get(freebets, 'active');
        const historyFreebets = _get(freebets, 'history');
        const activeFreebetsData = _get(activeFreebets, 'data');
        const historyFreebetsData = _get(historyFreebets, 'data');

        return (
            <S.AccountFreebet>
                <S.FreebetFilters className="freebet-filters">

                    <S.FreebetFiltersBtns>
                        <S.FilterBtn
                            isActive={(activeTab == 'active')}
                            onClick={this.onChangeFilter.bind(this, 'active')}
                            data-test='account_freebets_activeFreebets'>
                            {translation('account_freebets_activeFreebets')}
                        </S.FilterBtn>

                        <S.FilterBtn
                            isActive={(activeTab == 'history')}
                            onClick={this.onChangeFilter.bind(this, 'history')}
                            data-test='account_freebets_historyFreebets'>
                            {translation('account_freebets_historyFreebets')}
                        </S.FilterBtn>
                    </S.FreebetFiltersBtns>

                    <S.FreebetFiltersDates className="freebet-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <Datepicker
                                disabled={(activeTab == 'active')}
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                locale={pl}
                                cb={this.handleChangeStart}
                            />
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <Datepicker
                                disabled={(activeTab == 'active')}
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                locale={pl}
                                cb={this.handleChangeEnd}
                            />
                        </S.DateWrapper>

                    </S.FreebetFiltersDates>

                </S.FreebetFilters>


                <S.FreebetList>

                    {isPending ?
                        <Loader/>
                        : (
                            <>
                                {activeTab == 'active' && (
                                    <S.FreebetActiveList className="freebet-active-list">
                                        <S.FreebetFakeTable>
                                            <S.Thead>
                                                <S.Tr>
                                                    <S.Th className="th freebet_id">
                                                        <span>{translation('account_freebets_freebetId')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_ammount">
                                                        <span>{translation('account_freebets_freebetAmount')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_name">
                                                        <span>{translation('account_freebets_freebetName')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_expirationDate">
                                                        <span>{translation('account_freebets_freebetExpirationDate')}</span>
                                                    </S.Th>
                                                </S.Tr>
                                            </S.Thead>
                                            <S.Tbody>
                                                {activeFreebets ?
                                                    (<>
                                                        {_map(activeFreebetsData, ({validTo, nameKey, offerFreebetId, freebetStake}) => {
                                                            return (
                                                                <S.Tr className="row-flex" key={offerFreebetId}>
                                                                    <S.Td className="td freebet-id">
                                                                        <span>{offerFreebetId}</span>
                                                                    </S.Td>
                                                                    <S.Td className="td freebet-ammount">
                                                                        <span
                                                                            className="ammount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                                        <span className="currency">{translation('common_pln')}</span></S.Td>
                                                                    <S.Td className="td freebet-name">
                                                                        <span>{translation(nameKey)}</span>
                                                                    </S.Td>
                                                                    <S.Td className="td freebet-expirationDate">
                                                                        <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                                    </S.Td>
                                                                </S.Tr>
                                                            )
                                                        })}

                                                    </>)
                                                    :
                                                    <S.NoResult>{translation('account_freebets_noActiveFreebets')}</S.NoResult>
                                                }
                                            </S.Tbody>
                                        </S.FreebetFakeTable>

                                    </S.FreebetActiveList>
                                )}
                                {activeTab == 'history' && (
                                    <S.FreebetHistoryList className="freebet-history-list">
                                        <S.FreebetFakeTable>
                                            <S.Thead>
                                                <S.Tr>
                                                    <S.Th className="th freebet_id">
                                                        <span>{translation('account_freebets_freebetId')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_ammount">
                                                        <span>{translation('account_freebets_freebetAmount')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_name">
                                                        <span>{translation('account_freebets_freebetName')}</span>
                                                    </S.Th>
                                                    <S.Th className="th freebet_expirationDate">
                                                        <span>{translation('account_freebets_freebetExpirationDate')}</span>
                                                    </S.Th>
                                                </S.Tr>
                                            </S.Thead>

                                            <S.Tbody>
                                                {activeFreebets ?
                                                    (<>
                                                        {_map(historyFreebetsData, ({validTo, nameKey, offerFreebetId, freebetStake}) => {
                                                            return (
                                                                <S.Tr className="row-flex" key={offerFreebetId}>
                                                                    <S.Td className="td freebet-id">
                                                                        <span>{offerFreebetId}</span>
                                                                    </S.Td>
                                                                    <S.Td className="td freebet-ammount">
                                                                    <span
                                                                        className="ammount">{freebetStake.toFixed(2)}</span>&nbsp;
                                                                        <span
                                                                            className="currency">{translation('common_pln')}</span></S.Td>
                                                                    <S.Td className="td freebet-name">
                                                                        <span>{translation(nameKey)}</span>
                                                                    </S.Td>
                                                                    <S.Td className="td freebet-expirationDate">
                                                                        <span>{formatDate(validTo, 'yyyy-MM-dd HH:mm')}</span>
                                                                    </S.Td>
                                                                </S.Tr>
                                                            )
                                                        })}

                                                        {_size(historyFreebetsData) > 0 && (
                                                            <Pagination
                                                                currentPage={_get(historyFreebets, 'currentPage')}
                                                                limit={limit} data={historyFreebetsData}
                                                                cb={this.goPage.bind(this, 'history')}/>)}
                                                    </>)

                                                    :
                                                    <S.NoResult>{translation('account_freebets_noHistoryFreebets')}</S.NoResult>
                                                }

                                            </S.Tbody>

                                        </S.FreebetFakeTable>
                                    </S.FreebetHistoryList>
                                )}
                            </>
                        )
                    }

                </S.FreebetList>

            </S.AccountFreebet>
        );
    }
}

const mapStateToProps = ({AccountFreebet: {isPending, error, startDate, endDate, freebets, limit, activeTab}, Auth: {user: {currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        freebets,
        limit,
        currencyCode,
        activeTab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchFreebetsByFilter: bindActionCreators(fetchFreebetsByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchAllFreebets: bindActionCreators(fetchAllFreebets, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        resetToInitialState: bindActionCreators(resetToInitialState, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountFreebet);
