import React, {Component} from 'react';
import {connect} from 'react-redux';
import Datepicker from 'Datepicker.js';
import {pl} from 'date-fns/locale';
import Pagination from 'Pagination.js';
import S from 'StyledAccountSessionHistory.js';
import Loader from 'Loader.js'
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import _orderBy from "lodash/orderBy";
import _size from "lodash/size";
import _each from "lodash/each";
import {translation} from 'utilsHelper.js';
import {changeDateByType, fetchSessionsByFilter, setCurrentPage, resetToInitialState} from 'accountSessionsHistoryActions.js';
import SessionOpenIcon from 'session_open_icon.svg';
import SessionCloseIcon from 'session_close_icon.svg';
import SessionTimeIcon from 'session_time_icon.svg';
import CalendarIcon from 'calendar_icon.svg';
import {formatDate} from "datesHelper.js";
import AccountSessionTimer from 'AccountSessionTimer.js';
import classNames from "classnames";

class AccountSessionHistory extends Component {

    componentDidMount = async () => {
        const {fetchSessionsByFilter, resetToInitialState} = this.props;
        try {
            resetToInitialState();
            fetchSessionsByFilter();
        } catch (error) {
            console.log('fetchSessionsByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, fetchSessionsByFilter, endDate, currentPage, limit} = this.props;
        const startDate = new Date(date);
        const params = {
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('startDate', startDate);
        fetchSessionsByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, fetchSessionsByFilter, startDate, currentPage, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('endDate', endDate);
        fetchSessionsByFilter(params);
    };

    goPage = async (currentPage) => {
        const {fetchSessionsByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        const params = {
            endDate,
            startDate,
            currentPage,
            limit
        };
        try {
            await fetchSessionsByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    countSessionDiffTime = (loginTime, logoutTime) => {
        if(!loginTime || !logoutTime){
            return;
        }
        const sessionTimeDiff = Math.floor( logoutTime / 1000 ) - Math.floor(loginTime / 1000 );
        const hours = Math.floor(sessionTimeDiff / 3600);
        const minutes = Math.floor((sessionTimeDiff - (hours * 3600)) / 60);
        const seconds = sessionTimeDiff - (hours * 3600) - (minutes * 60);
        let timeParts = [hours, minutes, seconds];
        timeParts = timeParts.map(function (part) {
            return ('0' + part).substr(-2);
        });
        return timeParts.join(':');
    };

    groupSessionsByStartDate = (data) => {
        if(!_size(data)){
            return data;
        }
        const sessionDataGrouped = _groupBy(data, ({loginDate})=> formatDate(new Date(loginDate), 'dd-MM-yyyy'));
        const sessionDataGroupedKeysSorted = Object.keys(sessionDataGrouped).sort((dateFirst, dateSecond)=>{
            const aComps = dateFirst.split("-");
            const bComps = dateSecond.split("-");
            const aDate = new Date(aComps[2], aComps[1], aComps[0]);
            const bDate = new Date(bComps[2], bComps[1], bComps[0]);
            const aTimestamp = aDate.getTime() ;
            const bTimestamp = bDate.getTime();
            if (aTimestamp < bTimestamp) return 1;
            if (aTimestamp > bTimestamp) return -1;
            return 0;
        });

        const orderedSessionsGroups = {};
        _each(sessionDataGroupedKeysSorted, (startDate)=>{ orderedSessionsGroups[startDate] = sessionDataGrouped[startDate]});
        return orderedSessionsGroups;
    };

    render() {
        const {isPending, error, startDate, endDate, data, currentPage, limit} = this.props;
        const sessionsGroupedByStartDate = this.groupSessionsByStartDate(data);
        let rowCounter = 1;

        return (
            <S.AccountSessionHistory>

                <S.CurrentSessionTimer>
                    <S.CurrentSessionIcon dangerouslySetInnerHTML={{__html: SessionTimeIcon}}/>
                    <S.CurrentSessionLabel>
                        {translation('account_sessions_history_currentSessionTime')}
                    </S.CurrentSessionLabel>

                    <AccountSessionTimer/>

                </S.CurrentSessionTimer>

                <S.AccountSessionHistoryFiltersDates>

                    <S.DateWrapper>
                        <Datepicker
                            selected={startDate}
                            locale={pl}
                            dateFormat="yyyy-MM-dd"
                            cb={this.handleChangeStart}
                        />
                        <S.DateIcon dangerouslySetInnerHTML={{__html: CalendarIcon}}/>
                    </S.DateWrapper>

                    <S.DateWrapper>
                        <Datepicker
                            selected={endDate}
                            locale={pl}
                            dateFormat="yyyy-MM-dd"
                            cb={this.handleChangeEnd}
                        />
                        <S.DateIcon dangerouslySetInnerHTML={{__html: CalendarIcon}}/>
                    </S.DateWrapper>

                </S.AccountSessionHistoryFiltersDates>

                <S.AccountSessionHistoryListWrapper>

                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                                <S.AccountSessionHistoryList>

                                    {_map(sessionsGroupedByStartDate, (sessions, date) => {
                                        const sessionsSorted = _orderBy(sessions, ['loginDate'], ['desc']);
                                        return (
                                            <S.AccountSessionHistoryListItemsWrapper key={date}>

                                                <S.AccountSessionHistoryListItemsHeader>
                                                    {date}
                                                </S.AccountSessionHistoryListItemsHeader>

                                                {_map(sessionsSorted, ({loginDate, logoutDate, sesionUUID}) => {
                                                    const even = rowCounter % 2;
                                                    const rowClass = classNames({even: even == 0});
                                                    rowCounter++;
                                                    return (
                                                        <S.AccountSessionHistoryListItem key={sesionUUID} className={rowClass}>

                                                            <S.SessionOpen>
                                                                <S.SessionOpenIcon dangerouslySetInnerHTML={{__html: SessionOpenIcon}}/>
                                                                <S.SessionDetails>
                                                                    <S.SessionDetailsText>
                                                                        {translation('account_sessions_history_sessionOpen')}
                                                                    </S.SessionDetailsText>
                                                                    <S.SessionDetailsTime>
                                                                        {loginDate ? formatDate(new Date(loginDate), 'HH:mm:ss') : '-'}
                                                                    </S.SessionDetailsTime>
                                                                </S.SessionDetails>
                                                            </S.SessionOpen>

                                                            <S.SessionClose>
                                                                <S.SessionCloseIcon dangerouslySetInnerHTML={{__html: SessionCloseIcon}}/>
                                                                <S.SessionDetails>
                                                                    <S.SessionDetailsText>
                                                                        {translation('account_sessions_history_sessionClose')}
                                                                    </S.SessionDetailsText>
                                                                    <S.SessionDetailsTime>
                                                                        {logoutDate ? formatDate(new Date(logoutDate), 'HH:mm:ss') : '-'}
                                                                    </S.SessionDetailsTime>
                                                                </S.SessionDetails>
                                                            </S.SessionClose>

                                                            <S.SessionTime>
                                                                <S.SessionTimeIcon dangerouslySetInnerHTML={{__html: SessionTimeIcon}}/>
                                                                <S.SessionDetails>
                                                                    <S.SessionDetailsText>
                                                                        {translation('account_sessions_history_sessionTime')}
                                                                    </S.SessionDetailsText>
                                                                    <S.SessionDetailsTime>
                                                                        {this.countSessionDiffTime(loginDate, logoutDate)??'-'}
                                                                    </S.SessionDetailsTime>
                                                                </S.SessionDetails>
                                                            </S.SessionTime>
                                                            

                                                        </S.AccountSessionHistoryListItem>
                                                    )
                                                })}

                                            </S.AccountSessionHistoryListItemsWrapper>
                                        )
                                    })}

                                    {!_size(data) && (<S.NoResult>{translation('account_sessions_history_notFound')}</S.NoResult>)}

                                </S.AccountSessionHistoryList>

                                {_size(data) > 0 && (<Pagination currentPage={currentPage} limit={limit} data={data} cb={this.goPage}/>)}

                            </>
                        )
                    }
                </S.AccountSessionHistoryListWrapper>

            </S.AccountSessionHistory>
        );
    }
}

const mapStateToProps = ({AccountSessionHistory: {isPending, error, startDate, endDate, data, currentPage, limit}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        data,
        currentPage,
        limit
    }
};

const mapDispatchToProps = {
    changeDateByType,
    fetchSessionsByFilter,
    setCurrentPage,
    resetToInitialState
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSessionHistory);