import styled from 'styled-components';
import {rem} from 'polished';

const S = {};
const baseFont = `12px`;

S.UploadDocument = styled.div`
    margin-left: 1rem;
    display: flex;
    flex-direction: column;

    & .custom-file-upload {
        position: relative; 
        text-align: center;
        background: ${({theme:{$accountProfileButtonUploadBackground}})=> $accountProfileButtonUploadBackground};
        color: ${({theme:{$accountProfileButtonUploadColor}})=> $accountProfileButtonUploadColor};
        border-radius: 3px;
        width: ${rem('228px', baseFont)};
        height: ${rem('42px', baseFont)};
        line-height: ${rem('42px', baseFont)};
        cursor: pointer;
        text-transform: uppercase;
        margin-bottom: 0px!important;
    }

    & .custom-file-upload.uploaded {
        background:  ${({theme:{$accountProfileValidUpload}})=> $accountProfileValidUpload};
    }

    & .valid-document {
        background: #54C341;
    }

    & span {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & input[type=file] {
        display: none;
    }
`;

S.ButtonsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

S.ButtonWithLabel = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin-right: 20px;

    & .input-row {
        width: 30px;
        text-overflow: ellipsis;
        overflow: hidden;

        & .error {
            color: #CE0006;
            font-size: 12px;
        }
    }

    & input[type=text] {
        border: none;
        background: transparent;
        color: #CE0006;
    }
`;




export default S;