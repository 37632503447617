import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _each from 'lodash/each';
import { translation, redirect } from 'helpers/utilsHelper.js';
import S from 'StyledSportFilter.js';

class SportFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSport: {
                value: 0,
            },
        };
    };

    getSportsList = () => {
        const {categories} = this.props;
        const filteredCategories = _filter(categories, (o) => {
            return o.level === 1;
        });

        const options = [];

        _each(filteredCategories, ({ categoryName, categoryId }) => {
            const sportCategory = {
                value: categoryId,
                label: categoryName,
            };
            options.push(sportCategory);
        })
        return options;
    };

    selectedOption = () => {
        const currentSelectedSport = this.state.selectedSport.value;
        const selectedValue = _find(this.getSportsList(), { value: Number(currentSelectedSport) });
        return selectedValue;
    };

    onSportChange = ({ value }) => {
        const sportId = value;
        this.setState({
            selectedSport: _find(this.getSportsList(), { value: Number(sportId) })
        })
        this.redirectToEventList(sportId);
    };

    redirectToEventList = (sportId) => {
        const params = {
            sportId: sportId,
        };
        redirect('/search/sport/:sportId', params);
    };

    render() {
        return (

                <S.SportSelect
                    as={Select}
                    options={this.getSportsList()}
                    value={this.selectedOption()}
                    onChange={this.onSportChange}
                    placeholder={translation('filters_sportFilterPlaceholder')}
                    classNamePrefix={'react-select'}
                />

        )
    }
}

const mapStateToProps = (state) => {
    const { Categories: { categories } } = state;
    return {
        categories,
    }
}



export default connect(mapStateToProps)(SportFilter)