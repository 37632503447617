import React from 'react';
import S from 'StyledProgressBarLine.js';

const ProgressBarLine = ({percentage}) => {
    return (
        <>
            <S.ProgressBar>
                <S.ProgressBarPercentage className={(percentage > 62)? "light" : "dark"}>{`${percentage} %`}</S.ProgressBarPercentage>
                <S.ProgressFiller style={{ width: `${percentage}%`}}></S.ProgressFiller>
            </S.ProgressBar>
        </>
    );
};

export default ProgressBarLine;