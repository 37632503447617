import React, {useMemo, memo} from 'react';
import S from 'StyledBetSlipAccumulator.js';
import _map from 'lodash/map';
import classNames from 'classnames';
import {translation, formatMoney, decimal} from 'utilsHelper.js';
import RemoveIcon from 'remove_outcome_icon.svg';
import { Scrollbars } from 'react-custom-scrollbars';

const BetSlipAccumulator = (props) => {

    const {outcomes, removeOutcome, hasApprovalButtonsVisibled, onUpdate} = props;

    return (
        <S.BetSlipAccumulator className="betslip-accumulator">

            <Scrollbars style={{ width: '100%' }} autoHeight autoHeightMax={500} onUpdate={onUpdate}>

                {_map(outcomes, ({eventName, gameName, outcomeName, outcomeLive, outcomeId, outcomeOdds, sportId, eventId, prevOdds, active = true, unavailable = false, oddsChanged = false}, index) => {
                    const indexNumber = ++index;
                    const isLive = outcomeLive && JSON.parse(outcomeLive);
                    const outcomeChangedUp = (prevOdds && (outcomeOdds > prevOdds));
                    const outcomeChangedDown = (prevOdds && (outcomeOdds < prevOdds));
                    const outcomeOddsChangedClass = classNames({
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'outcome': true,
                        'no-active': !active
                    });
                    const outcomeClass = classNames({
                        'unavailable': unavailable,
                        'oddsChanged': oddsChanged,
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'no-active': !active
                    });

                    const onRedirectHandler = () => {
                        const redirectLink = isLive ? `/zaklady-live/event/${eventId}` : `/zaklady-bukmacherskie/event/${eventId}`;
                        app.router.redirect(redirectLink);
                    }

                    return (
                        <S.Outcome key={outcomeId} className={outcomeClass}>

                            <S.OutcomeBody>
                                <S.SportIcon>
                                    <use href={`#sport-${sportId}`}></use>
                                </S.SportIcon>
                                <S.OutcomeLeftSide>

                                    <S.EventName title={eventName}>
                                        <span onClick={onRedirectHandler}>{indexNumber}. {eventName}</span>
                                        {isLive && <S.LiveIndicator>{translation('betSlip_live')}</S.LiveIndicator>}
                                    </S.EventName>

                                    <S.GameName>{gameName}</S.GameName>

                                    <S.OutcomeName>{translation('betSlip_outcomeType')} {outcomeName}</S.OutcomeName>

                                </S.OutcomeLeftSide>

                                <S.OutcomeRightSide>

                                    <S.RemoveOutcome onClick={removeOutcome.bind(null, outcomeId)}
                                                     disabled={hasApprovalButtonsVisibled}
                                                     dangerouslySetInnerHTML={{__html: RemoveIcon}}>
                                    </S.RemoveOutcome>

                                    {prevOdds && (prevOdds != outcomeOdds) && (
                                        <S.PrevOdds className={outcomeOddsChangedClass}>{decimal(prevOdds)}</S.PrevOdds>
                                    )}

                                    <S.OutcomeOdds>{decimal(outcomeOdds)}</S.OutcomeOdds>

                                </S.OutcomeRightSide>

                            </S.OutcomeBody>

                        </S.Outcome>
                    )
                })}

            </Scrollbars>

        </S.BetSlipAccumulator>
    );
};

export default memo(BetSlipAccumulator);
