import React from 'react';
import S from 'StyledRegisterConfirmation.js';
import {translation, redirect} from 'utilsHelper.js';
import Box from 'react-styled-box';
import CmsPage from 'CmsPage.js';
import ReactTooltip from 'react-tooltip';

const RegisterConfirmation = ({toggleOpen}) => {

    const onStartGameClick = ()=>{
        toggleOpen();
        redirect('/konto/wplata');
    };

    return (
        <S.RegisterConfirmation className="register-step3">
            <CmsPage name={'register-modal-confirmation'} isPlaces={true} />
            <S.Header>
                <S.Title>{translation('registerForm_step3')}</S.Title>
            </S.Header>

            <S.Body>
                <Box flexDirection="column">

                    <Box>{translation('account_registerForm_finalDescription')}</Box>

                    <S.StartGameBtn onClick={onStartGameClick}>{translation('account_registerForm_startGame')}</S.StartGameBtn>

                </Box>
            </S.Body>

        </S.RegisterConfirmation>
    );
};

export default React.memo(RegisterConfirmation);

