import React, {useState, useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import S from 'StyledCashoutForm.js';
import {Field, reduxForm, SubmissionError}  from 'redux-form';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import Box from 'react-styled-box';
import useToggle from 'UseToggle.js';
import ModalOpacity from 'ModalOpacity.js';
import Loader from 'Loader.js';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    if (values.maximumAmount && values.maximumAmount.amount) {
        values.maximumAmount = values.maximumAmount.amount;
    }
    const errors = validation(values, validators.CASHOUT);
    return errors;
};

const renderField = ({
    input,
    extraData,
    label,
    type,
    disabled,
    customError,
    meta: {touched, error, warning}
}) => {
    if (input.name == 'maximumAmount') {
        input.value = extraData.amount ? extraData.amount : extraData;
    }
    return (
        <S.Row>

            <S.InputWrapper>
                <S.Input {...input} type={type} disabled={disabled} required="required"/>
                {touched ?
                    ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))
                    :
                    (customError && <S.Error>{customError}</S.Error>)
                }
            </S.InputWrapper>

        </S.Row>
    )
};

const CheckboxWithLabel = ({
    input,
    label,
    id,
    name,
}) => {
    return (
        <S.InputWrapper className="with-checkbox">

            <S.Input {...input}
                value={input.value}
                checked={input.value}
                onChange={event => input.onChange(event.target.checked)}
                type="checkbox"
                name={name}
                id={id}
            />

            <S.FakeChbox>
                {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
            </S.FakeChbox>

            <S.Label for={id} dangerouslySetInnerHTML={{ __html: label }}></S.Label>

        </S.InputWrapper>
    );
};

let CashoutForm = ({
    toggleLiveCurtainForTxId,
    curtainActive,
    refreshBetHistoryList,
    dispatch,
    maxAmount,
    prepareCashout,
    cachoutOnSubmit,
    toggleCashoutModal,
    stake,
    possibleReturn,
    currencyCode,
    transactionId,
    handleSubmit,
    pristine,
    submitting,
    valid,
    error
}) => {
    const [showInfoModal, toggleInfoModal] = useToggle(false);
    const [showAmountChangeChbox, toggleAmountChangeChbox] =  useState(false);
    const [infoModalSuccessMsg, setInfoModalSuccessMsg] = useState('');
    const [infoModalAmountChange, toggleInfoModalAmountChange] = useState(false);
    const [infoCashoutBelowStake, toggleInfoCashoutBelowStake] = useState(false);
    const abortController = useRef(new AbortController());
    const cashoutTaxStartingValue = 2280.0;

    useEffect(() => {
        // poprawic, tylko dla curtainActive = true
        return () => {
                abortController.current.abort();
                toggleLiveCurtainForTxId(transactionId, false);
            }
    }, []);


    const onFormSubmit = async (type, values) => {

        const config = {
            slipId: transactionId,
            requestedAmount: ((type == 'full') ? maxAmount.amount ? maxAmount.amount : maxAmount : _get(values, 'partialAmount')),
            type: `cashout${_capitalize(type)}Amount`
        };

        const customerAcceptedAmountChange = _get(values, 'customerAcceptedAmountChange');
        if (customerAcceptedAmountChange) {
            config['customerAcceptedAmountChange'] = customerAcceptedAmountChange;
        }

        try {
            const { currentCashoutAmount = null } = await cachoutOnSubmit(config, abortController.current.signal);
            if (type !== "full" && currentCashoutAmount) {
                config['requestedAmount'] = currentCashoutAmount;
            }
            const successMsg = `${translation(`account_cashout_cashoutSuccess_${type}`)} ${config.requestedAmount} ${currencyCode}`;

            setInfoModalSuccessMsg(successMsg);
            toggleInfoModal(true);

        } catch ({ message, oddsHasChanged = null, newCashoutAmount = null }) {
            if (oddsHasChanged) {
                if (newCashoutAmount > stake) {
                    toggleAmountChangeChbox(true);
                    toggleInfoModalAmountChange(true);
                } else {
                    toggleInfoCashoutBelowStake(true);
                }
            } else {
                toggleAmountChangeChbox(false)
            }

            toggleLiveCurtainForTxId(transactionId, false);
            throw new SubmissionError({ _error: message });
        }

    };

    const closeAllModal = async() => {
        toggleCashoutModal();
        try{
            await dispatch(refreshBetHistoryList());
        }catch(error){}
    };

    return (
        <S.CashoutForm method="post">

            {curtainActive && (
                <S.Overlay>
                    <Loader size="5"/>
                </S.Overlay>
            )}

            <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false}
                          toggleOpen={closeAllModal} padding="8px">

                <Box flexDirection="column" width="100%" margin="-44px 0 0">
                    <Box justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{infoModalSuccessMsg}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_ok')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>

            <ModalOpacity isOpen={infoModalAmountChange}
                showHeaderIcon={false}
                showHeaderTitle={false}
                toggleOpen={toggleInfoModalAmountChange.bind(null, false)}
                padding="0"
                showHeader={false}>

                <Box flexDirection="column" width="100%" padding="20px">
                    <Box justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{translation('account_cashout_amountChangeInfo')}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={toggleInfoModalAmountChange.bind(null, false)}>{translation('common_ok')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>

            <ModalOpacity isOpen={infoCashoutBelowStake}
                showHeaderIcon={false}
                showHeaderTitle={false}
                toggleOpen={closeAllModal}
                padding="0"
                showHeader={false}>

                <Box flexDirection="column" width="100%" padding="20px">
                    <Box justifyContent="center" margin="0 0 20px">
                        <S.ConclusionMsg>{translation('account_cashout_cashoutBelowStake')}</S.ConclusionMsg>
                    </Box>
                    <Box justifyContent="center">
                        <S.Button onClick={closeAllModal}>{translation('common_close')}</S.Button>
                    </Box>
                </Box>

            </ModalOpacity>

            <Box flexDirection="column" padding="8px">

                {error && <S.SubmissionError className="submission-error">{translation(`account_${error}`)}</S.SubmissionError>}
                <S.InfoContainer className="transactionInfoContainer">
                    <S.Title>{translation('account_bettingHistory_cashout')}</S.Title>
                    <S.CloseIcon className="close" onClick={toggleCashoutModal}></S.CloseIcon>
                </S.InfoContainer>

                <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={50}>
                    <S.Label>
                        <span>{translation('account_cashout_amountTitle')}</span>
                    </S.Label>

                    <S.Stake>
                        <span>{formatMoney(stake)}</span>&nbsp;
                        <span>{currencyCode}</span>
                    </S.Stake>
                </Box>

                <S.CashoutWrapper className="full-cashout">

                    <S.CashoutLabel>
                        <span>{translation('account_cashout_fullTitle')}</span>
                    </S.CashoutLabel>

                    <S.CashoutAmount>
                        <span>{(maxAmount?.amount ?? maxAmount)}</span>&nbsp;
                        <span>{currencyCode}</span>
                    </S.CashoutAmount>

                    <S.SubmitBtn type="button"onClick={handleSubmit(onFormSubmit.bind(null, 'full'))}
                                    disabled={submitting}>{translation('account_cashout_full')}</S.SubmitBtn>


                </S.CashoutWrapper>

                <Box width='100%'>
                    <Field
                        name="maximumAmount"
                        type="hidden"
                        component={renderField}
                        extraData={(maxAmount)}
                        disabled={true}
                    />
                </Box>

                <Box flexDirection="row" justifyContent="space-between" alignItems="center" height={50}>
                    <S.Label>
                        <span>{translation('account_cashout_possibleWinTitle')}</span>
                    </S.Label>

                    <S.PossibleReturn>
                        <span>{formatMoney(possibleReturn)}</span>&nbsp;
                        <span>{currencyCode}</span>
                    </S.PossibleReturn>
                </Box>

                {(maxAmount?.amount ?? maxAmount) > cashoutTaxStartingValue && <S.TaxInfoMessage> {translation('cashoutForm_taxInfo')} </S.TaxInfoMessage> }
                
                {showAmountChangeChbox && (
                    <Box flexDirection="row" justifyContent="flex-end" alignItems="center" height={50}>
                        <Field
                            name="customerAcceptedAmountChange"
                            id="customerAcceptedAmountChange"
                            component={CheckboxWithLabel}
                            label={translation('account_cashout_acceptAmountChange')}
                        />
                    </Box>)
                }


            </Box>

        </S.CashoutForm>
    )
};

CashoutForm = reduxForm({
    form: 'cashoutForm',
    validate
})(CashoutForm);

export default CashoutForm;

