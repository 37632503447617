import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';
const S = {};
const baseFont = `12px`;

S.AccountMenu = styled(List)`
    width: 100%;
`;
S.AccountMenuItem = styled.li`
    display: inline-block;
    width: calc(100% / 9 - 0.5rem + 0.5rem / 9);
    margin-right: 0.5rem
    cursor: pointer;
    &:last-of-type {
        margin-right: 0px;
    }
`;

S.Image = styled.img`

    height: ${rem('32px', baseFont)};
    width: ${rem('32px', baseFont)};
    margin: auto;
    display: block;
    margin-top: ${rem('19px', baseFont)};
    margin-bottom: ${rem('8px', baseFont)};
    
    &.transactionImg {
        width: ${rem('60px', baseFont)};
    }

    &.cashbackImg {
        width: ${rem('42px', baseFont)};
    }

    &.transferImg {
        width: ${rem('30px', baseFont)};
    }

    &.responsibleGamingImg {
        width: ${rem('28px', baseFont)};
    }
`;

S.AccountMenuLabel = styled.div`
    width: 100%;
    text-align: center;
`;
 
S.AccountMenuLink = styled.a`
    height: ${rem('87px', baseFont)};
    display: block;
    width: 100%;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: ${({theme: {$accountMenuBackground}}) => $accountMenuBackground};
    color: ${({theme: {$accountMenuColor}}) => $accountMenuColor};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.active,
    &:hover{ 
        background-color: ${({theme: {$accountMenuActiveBackground}}) => $accountMenuActiveBackground};
        color: ${({theme: {$accountMenuActiveColor}}) => $accountMenuActiveColor};
        border-right: none;
        text-decoration: none;
        & .cls-1 {
            fill: ${({theme: {$accountMenuActiveColor}}) => $accountMenuActiveColor};
        }
    }
`;

export default S;