import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledPagination from 'StyledPagination.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = '12px';
const S = {};

S.AccountSessionHistory = styled.div`
   margin: ${rem('30px', baseFont)} auto 0;
    max-width: ${rem('600px', baseFont)};
    width: 100%;
     font-family: ${FontFamily};
     
    ${StyledPagination.Pagination}{
      margin-bottom: ${rem('20px', baseFont)}; 
    }
`;

S.AccountSessionHistoryTimer = styled.div``;

S.AccountSessionHistoryFiltersDates = styled.div`
    display: flex;
    margin-right: ${rem('5px', baseFont)};
    margin-bottom:  ${rem('35px', baseFont)};
    & > div{
        &:last-of-type{
            margin-right: 0;
        }
    }
`;

S.AccountSessionHistoryListWrapper = styled.div``;

S.AccountSessionHistoryList = styled.div``;

S.AccountSessionHistoryListItem = styled.div``;

S.NoResult  = styled.div`
     height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.DateIcon = styled.i`
  position: absolute;
    left: ${rem('12px', baseFont)};
    height: ${rem('16px', baseFont)};
    width: ${rem('16px', baseFont)};
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
    & svg{
      width: 100%;
    }
`;

S.DateWrapper = styled.div`
    width: ${rem('297px', baseFont)};
    height: ${rem('42px', baseFont)};
    display: flex;
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.24);
    margin-right: ${rem('5px', baseFont)};
    border-radius: 3px;
    padding: 0 ${rem('13px', baseFont)};
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
    width: 100%;
    display: flex;
    }
    & .react-datepicker__input-container{
    padding: 0;
    }

    ${StyledDatepicker.Datepicker}{
        background-color: transparent;
        flex: 1;
        padding-left: 2rem;
        font-size: ${rem('14px', baseFont)};
        font-weight: ${FwRegular};
        border-radius: 0;
        width: 100%;
        color: rgba(0, 0, 0, 0.54);
    }
`;

S.AccountSessionHistoryListItemsWrapper = styled.div``;

S.AccountSessionHistoryListItemsHeader = styled.div`
     line-height: 1;
     color: rgba(0, 0, 0, 0.87);
     font-size:  ${rem('12px', baseFont)};
     font-weight: ${FwSemibold};
     border-bottom: 2px solid #D32F2F;
     height:  ${rem('36px', baseFont)};
     margin-bottom:  ${rem('14px', baseFont)};
     display: flex;
     align-items: center;
`;

S.AccountSessionHistoryListItem = styled.div`
display: flex;
  height:  ${rem('64px', baseFont)};
  padding: 0  ${rem('15px', baseFont)};
  background-color: #fff;
  &.even{
    background-color: rgba(255, 255, 255, 0.6);
  }
`;

S.SessionOpen = styled.div`
  width: 33%;
  display: flex;
`;
S.SessionClose = styled(S.SessionOpen)``;
S.SessionTime = styled(S.SessionOpen)``;

S.SessionOpenIcon = styled.i`
  align-self: center;
  margin-right: ${rem('10px', baseFont)};
  width: ${rem('20px', baseFont)};
  height: ${rem('20px', baseFont)};
  & > svg{
    width: 100%;
    & path:nth-of-type(2){
      fill: #000 !important;
    }
  }
`;
S.SessionCloseIcon = styled(S.SessionOpenIcon)``;
S.SessionTimeIcon = styled(S.SessionOpenIcon)``;

S.SessionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

S.SessionDetailsText = styled.div`
   color: rgba(0, 0, 0, 0.87);
   font-size:  ${rem('14px', baseFont)};
`;

S.SessionDetailsTime = styled.div`
   color: rgba(0, 0, 0, 0.87);
   font-weight: ${FwSemibold};
`;

S.CurrentSessionTimer = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    margin: ${rem('36px', baseFont)} 0;
`;

S.CurrentSessionIcon = styled.i`
  margin-right: ${rem('20px', baseFont)};
  width: ${rem('30px', baseFont)};
  height: ${rem('30px', baseFont)};
  & > svg{
    width: 100%;
    & path:nth-of-type(2){
      fill: #000 !important;
    }
  }

`;

S.CurrentSessionLabel = styled.div`
   color: rgba(0, 0, 0, 0.87);
   font-weight: ${FwSemibold};
   font-size:  ${rem('16px', baseFont)};
    margin-right: ${rem('32px', baseFont)};
  
`;

export default S;