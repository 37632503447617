import React, { useState, useEffect} from 'react';
import S from 'StyledTotalBonus.js';
import {translation} from 'helpers/utilsHelper';

const TotalBonus = ({
    winning,
    totalBonus: {
        currentBonusValue = 0,
        futureBonusValue = 0,
        isGameWithoutTax,
        isTotalbonusAvailable,
        minOutcomeOdds = 0,
        outcomesLeftToPlayWithoutTax
    },
    winningForDefaultTaxFactor = 0,
    bonus,

}) => {

    const [bonusIncrease, setBonusIncrease] = useState(0);

    useEffect(() => {
        (futureBonusValue > 0) ? setBonusIncrease(futureBonusValue - currentBonusValue) : setBonusIncrease(0);
    }, [currentBonusValue, futureBonusValue]);

    return (
        <>
            {bonus && bonus > 0 && (
                <S.TotalBonusWrapper className="total-bonus-wrapper">
                    <S.TotalBonusTitle
                        dangerouslySetInnerHTML={{ __html: translation('betSlip_customBonusTitle', [bonus]) }}>
                    </S.TotalBonusTitle>
                    {minOutcomeOdds > 0 && bonusIncrease > 0 && (
                        <S.TotalBonusAdditionalInfo
                            dangerouslySetInnerHTML={{ __html: translation('betSlip_customBonusAdditionalInfo', [bonusIncrease, minOutcomeOdds]) }}>
                        </S.TotalBonusAdditionalInfo>
                    )}
                </S.TotalBonusWrapper>
            )}
        </>
    );
};

export default TotalBonus;
