import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const baseFont = `12px`;
const S = {};

S.LeftMenu = styled.div`
    float: left;
    width: 100%;
`;
S.DropdownMenuWrapper = styled.div`
    position: relative;
    float: left;
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    
    & ${StyledLoader.LoaderWrapper}{}
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem('16px', baseFont)};
  height: ${rem('40px', baseFont)};
  background-color: #D9D9D9;
`;

S.HeaderIcon = styled.div`
  flex-shrink: 0;
  width: ${rem('16px', baseFont)};
  height: ${rem('16px', baseFont)};
  margin-right: ${rem('10px', baseFont)};
  
  & > svg{
    & rect,
    & path{
      fill: #000 !important;
    }
  }
`;

S.HeaderIconCashback = styled.div`
  flex-shrink: 0;
  width: ${rem('16px', baseFont)};
  height: ${rem('16px', baseFont)};
  margin-right: ${rem('10px', baseFont)};
  & > svg{
    & rect{
      fill: transparent;
    }
  }
`;

S.HeaderText = styled.div`
  color: #000;
  font-size: ${rem('18px', baseFont)};
  font-weight: ${FwSemibold};
`;

S.EventsCount = styled.div`
    width: 16.6666666667%;
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme:{$categoriesEventsCount}}) => $categoriesEventsCount};
    text-align: right;
    font-size: 14px;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
    flex: 1;
    font-weight: 600;
`;

S.PrematchCalendar = styled(S.Header)`
  background-color: #C62828;
  cursor: pointer;
  margin: ${rem('8px', baseFont)} 0;
`;

S.PrematchCalendarIcon = styled(S.HeaderIcon)`
   & > svg{
    & rect,
    & path{
      fill: #fff !important;
    }
  }
`;

S.PrematchCalendarText = styled(S.HeaderText)`
  color: #fff;
`;

S.CashbackPlusHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 ${rem('16px', baseFont)};
  height: ${rem('40px', baseFont)};
  background-color: ${({theme: {$cashbackCategoriesHeaderBgColor}}) => $cashbackCategoriesHeaderBgColor};
  margin: 0 0 10px;
  &:hover {
    background-color: ${({theme: {$cashbackCategoriesHeaderBgColorHover}}) => $cashbackCategoriesHeaderBgColorHover};
  }
`;

S.BoostHeader = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 0 ${rem('16px', baseFont)};
  height: ${rem('40px', baseFont)};
  background-color: ${({theme: {$cashbackCategoriesHeaderBgColor}}) => $cashbackCategoriesHeaderBgColor};
  margin: 0 0 10px;
  &:hover {
    background-color: ${({theme: {$cashbackCategoriesHeaderBgColorHover}}) => $cashbackCategoriesHeaderBgColorHover};
  }
`;

export default S;