import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledPagination from 'StyledPagination.js';
import {Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const VisuallyHidden = ({theme:{visuallyHidden}}) => visuallyHidden();
const LineClamp = ({theme:{lineClamp}}) => lineClamp(2);

const baseFont = '12px';
const S = {};

S.AccountWallet = styled.div`
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 0 auto;
    font-family: ${FontFamily};
    
    ${StyledPagination.Pagination}{
      margin-bottom: ${rem('20px', baseFont)}; 
    }
`;

S.WalletTransactionsList = styled.div`
    margin: ${rem('30px', baseFont)} auto 0;
    max-width: ${rem('600px', baseFont)};
    width: 100%;
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.WalletTransactionsListItem = styled.div`
display: flex;
height: ${rem('52px', baseFont)}; 
&>div {
	border: none;
	font-size: ${rem('14px', baseFont)}; 
	color: rgba(0, 0, 0, 0.87);
	font-weight: ${FwRegular};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
	justify-content: center;
	align-items: center;
	
	&.wallet-toggle{
	  justify-content: flex-start;
	  flex: 1;
	  padding-left: ${rem('20px', baseFont)}; 
	}
	
	&.wallet-status {
		width: 15%;
		&.red{
		    & .status-icon{
		        background-image: url(/images/svg/status_red.svg);
		    }
		}
		&.green{
		    & .status-icon{
		      background-image: url(/images/svg/status_green.svg);
		    }
		}
	    &.gray{
		    & .status-icon{
		      background-image: url(/images/svg/status_gray.svg);
		      width: ${rem('25px', baseFont)}; 
              height: ${rem('25px', baseFont)};
              background-position-x: -1px; 
		    }
		}
		
		& .status-icon{
		    width: ${rem('20px', baseFont)}; 
            height: ${rem('20px', baseFont)}; 
            background-color: transparent;
            background-position: 0 0;
            background-size: cover;
		}
	}
	
	&.wallet-type {
	  width: 30%;
	  text-align: center;
	  white-space: normal;

	  & > div{
	  	  ${LineClamp};
	  }
	}
	
	&.wallet-amount {
		width: 18%;
		font-weight: ${FwSemibold};
		font-size: ${rem('16px', baseFont)};
		&.green{}
		&.red{}
		
		& .currency{
		font-size: ${rem('15px', baseFont)};
		margin-left:  ${rem('3px', baseFont)};
		}
	}
	
	&.wallet-date {
		width: 18%;
		display: flex;
	}
}
`;

S.WalletTransactionsListItemDetails = styled.div`
    width: 100%;
    position: relative;
    max-height: 0;
    transition-property: max-height;
    transition-duration: .5s;
    overflow: hidden;
    padding: 0 ${rem('110px', baseFont)};
    ${({isOpen}) => {
    return isOpen == true && 'max-height: 1000px';
}}
`;

S.WalletTransactions = styled.div``;

S.WalletTransactionsListItemWrapper = styled.div``;

S.FakeTableHead = styled.div`
    display: flex;
    border-bottom: 2px solid #D32F2F;
    padding-bottom: ${rem('5px', baseFont)};
    margin-bottom: ${rem('14px', baseFont)};
    
    & > div{
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: ${rem('12px', baseFont)};
        font-weight: 400;
        line-height: 1;
        border-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        &.wallet-toggle{
            flex:1 ;
        }
        &.wallet-type{
           width: 30%;
         
         }
           &.wallet-amount{
              width: 18%;
       
           }
           &.wallet-date{
               width: 18%;
           }
              &.wallet-status{
               width: 15%;
           }
    }
`;

S.WalletFilters = styled.div`
    display: flex;
    flex-direction: column;
`;

S.WalletFiltersTop = styled.div`
display: flex;
 margin: ${rem('30px', baseFont)} 0 ${rem('20px', baseFont)}
 `;
 
S.WalletFiltersBottom = styled.div`
display: flex;
`;

S.WalletFiltersDates = styled.div`
    display: flex;
    margin-right: 0.67rem;
    & > div{
         margin-right: 0.42rem;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;


S.DateWrapper = styled.div`
    width: 24.75rem;
    height: 3.5rem;
    display: flex;
    position: relative;
    border: 1px solid ${({theme:{$accountProfileBetHistoryDatepickerBorder}})=> $accountProfileBetHistoryDatepickerBorder};
    border-radius: 3px;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 1.17rem;
        font-weight: 400;
        width: 100%;
        border-radius: 3px;
        color: ${({theme:{$accountProfileBonusDatepickerLabelColor}})=> $accountProfileBonusDatepickerLabelColor};
    }
`;

S.DateLabel = styled.div`
    display: flex;
    font-weight: 400;
    color: ${({theme:{$accountProfileBonusDatepickerLabelColor}})=> $accountProfileBonusDatepickerLabelColor};
    background-color: ${({theme:{$accountProfileBetHistoryDatepickerLabelBg}})=> $accountProfileBetHistoryDatepickerLabelBg};
    padding-left: 1.08rem;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
`;

S.DateIcon = styled.i`
    position: absolute;
    right: 0.25rem;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.WalletFiltersBtns = styled.div`
    display: flex;
    flex: 1;
`;

S.FilterBtn = styled(Button)`
    width: ${rem('145px', baseFont)};
    flex-grow: 1;
    font-size: ${rem('16px', baseFont)};
    font-weight: ${FwSemibold};
    margin-top: 0;
    background-color: ${({isActive})=>(isActive == true ? '#D32F2F ': '#fff')};
    color:  ${({isActive})=>(isActive == true ? '#fff ': 'rgba(0, 0, 0, 0.87)')};
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    margin-right: ${rem('5px', baseFont)};
    &:last-of-type{
    margin-right: 0;
    }
`;

S.RadioButtons = styled.div`
  display: flex;
`;

S.RadioButton = styled.label`
  display: flex;
  align-items: center;
  margin-right: ${rem('55px', baseFont)};
`;

S.RadioButtonText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size:  ${rem('12px', baseFont)};
  line-height: 1;
  font-weight: ${FwRegular};
`;

S.RadioButtonControlIndicator = styled.div`
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #D32F2F;
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: ${rem('10px', baseFont)};
  flex-shrink: 0;
  cursor: pointer;
  
  &:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #fff;
    width: 6px;
    height: 6px;
    display: none;
    border-radius: 50%;
  }
`;

S.RadioButtonInput = styled(Input)`
   ${VisuallyHidden};
  
   &:checked,
   &.active{
     & + ${S.RadioButtonControlIndicator}{
      background-color: #D32F2F;
      &:before{
         display: block;
      }
   }
   }
`;

S.FakeTableBody = styled.div`

& > ${S.WalletTransactionsListItemWrapper}{
  &:nth-child(odd){
    background-color: #fff;
  }
  &:nth-child(even){
  background-color: rgba(255, 255, 255, 0.6);
  }
}
  
`;

S.ArrowIcon = styled.div`
  cursor: pointer;
  width: ${rem('16px', baseFont)};
  height: ${rem('16px', baseFont)};
  transition: transform 0.2s linear;
  transform: ${({isOpen})=>(isOpen==true? 'rotate(180deg)': 'rotate(0deg)')};
  & svg{
    width: 100%;
  }
`;

S.ItemDetailsTitle = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-weight: ${FwSemibold};
  font-size: ${rem('15px', baseFont)};
`;

S.ItemDetailsHeader = styled.div`
 color: rgba(0, 0, 0, 0.87);
 text-transform: uppercase;
 font-size: ${rem('11px', baseFont)};
 white-space: nowrap;
 text-overflow: ellipsis;
 overflow:hidden;
 width: 100%;
 text-align: center;
  margin-bottom: ${rem('2px', baseFont)};
`;

S.ItemDetailsBody = styled(S.ItemDetailsHeader)`
 font-size: ${rem('14px', baseFont)};
 text-transform: none;
 margin-bottom: 0;
 &.red{
 color: red;
 }
 &.green{
 color: green;
 }
`;

S.CancellPayoutBtn = styled(Button)`
 width: ${rem('90px', baseFont)};
 height: ${rem('24px', baseFont)};
 background-color: #D32F2F;
 color: #fff;
 border-radius: 4px;
`;

S.ConfirmModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #000;
    padding: 20px 60px 20px 60px;
    border: 1px solid #ccc;
`;

S.BellContainer = styled.div`
   display: flex;
   width: 100%;
   margin: 20px auto 20px auto;
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

S.ButtonCancel = styled(Button)`
    border: 1px solid ${({theme:{$accountWalletCancellPayoutRejectBtnColor}})=> $accountWalletCancellPayoutRejectBtnColor};
    height: 2.625rem;
    border-radius: 0.1875rem;
    box-shadow: none;
    padding: 0 0.75rem;
    margin-top: 1rem;
    flex: 1;
    background: ${({theme:{$accountWalletCancellPayoutRejectBtnBackground}})=> $accountWalletCancellPayoutRejectBtnBackground};
    color: ${({theme:{$accountWalletCancellPayoutRejectBtnColor}})=> $accountWalletCancellPayoutRejectBtnColor};
   
`;
S.ButtonAccept = styled(S.ButtonCancel)`
    margin-right: 0;
    border: 1px solid ${({theme:{$accountWalletCancellPayoutConfirmBtnColor}})=> $accountWalletCancellPayoutConfirmBtnColor};
    color: ${({theme:{$accountWalletCancellPayoutConfirmBtnColor}})=> $accountWalletCancellPayoutConfirmBtnColor};
    margin-right: 1rem;
    background: ${({theme:{$accountWalletCancellPayoutConfirmBtnBackground}})=> $accountWalletCancellPayoutConfirmBtnBackground};
`;

S.BellIcon = styled.div`
    width: 30px;
    height: 30px;
    margin: auto;
    display: flex;
     & svg {
       width: 100%;
        & .a {
            fill: #c62828 !important;
        }
    }
`;

S.Description = styled.div`
    text-align: center;
    display: flex;
    color: #000;
`;

export default S;