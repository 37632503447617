import React, {Component} from 'react';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import {Helmet} from 'react-helmet';

class MetaTags extends Component {
    
    changeMetaTags(newState, addedTags, removedTags) {
      if(addedTags && !_isEmpty(addedTags)){
        for(let helmetTag of addedTags.metaTags) {
            helmetTag.removeAttribute('data-react-helmet');
        }
      }
    };

    render() {
        const {title, tags, metaDescriptions, og_title, og_description, isPlaces, noIndex} = this.props;
        console.log('meta',this.props);
        return (
            <Helmet onChangeClientState={this.changeMetaTags}>
                {og_title && !isPlaces && <meta property="og:title" content={og_title}/>}
                {og_description && !isPlaces && <meta property="og:description" content={og_description}/>}
                {title && !isPlaces && <title>{title}</title>}
                {metaDescriptions && !isPlaces && <meta name="description" content={metaDescriptions}/>}
                {noIndex && <meta name="robots" content="noindex, nofollow" />}
                {_size(tags) && !isPlaces && <meta name="keywords" content={tags.join(',')}/>}
            </Helmet>
        )
    }
}

export default MetaTags;
