import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.ProgressBar = styled.div`
    position: relative;
    height: 1.6667rem;
    width: 100%;
    border: 1px solid ${({theme:{$accountProfileBonusTabActiveBonusProgressBarMainColor}})=> $accountProfileBonusTabActiveBonusProgressBarMainColor};
    border-radius: 1.6667rem; 
    background-color: ${({theme:{$accountProfileBonusTabActiveBonusProgressBarBgColor}})=> $accountProfileBonusTabActiveBonusProgressBarBgColor};
    overflow: hidden;
`;

S.ProgressBarPercentage = styled.div`
    position: absolute;
    top: 2px;
    width: 100%;
    font-size: 0.75rem;
    font-weight: 700;
    & .light {
        color: ${({theme:{$accountProfileBonusTabActiveBonusProgressBarPercColor}})=> $accountProfileBonusTabActiveBonusProgressBarPercColor};
    }
`;

S.ProgressFiller = styled.div`
    height: 100%;
    background-color: ${({theme:{$accountProfileBonusTabActiveBonusProgressBarMainColor}})=> $accountProfileBonusTabActiveBonusProgressBarMainColor};
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: width .2s ease-in;
`;

export default S;