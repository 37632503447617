import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ProgressBarLine from 'ProgressBarLine.js';
import S from 'StyledAccountHistoryBonus.js';

import {
    fetchBonusListByFilter,
    setCurrentPage,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure
} from 'accountBonusActions.js';

class AccountHistoricalBonus extends Component {

    componentDidMount = async() => {
        const {fetchBonusListPending, fetchBonusListByFilter, fetchBonusListFailure, fetchBonusListFulfilled, endDate, startDate, limit} = this.props;

        fetchBonusListPending();

        try {
            const data = await fetchBonusListByFilter({status: 'HISTORY', endDate, startDate});
            fetchBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountHistoricalBonus:', error);
            fetchBonusListFailure(error)
        }
    };

    goPage = async(status, currentPage) => {
        const {fetchBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        let calcPage = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage: calcPage,
            status
        };

        try {
            await fetchBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    checkStatus = (status_txt, wager_percentage) => {
        if(status_txt === "OFFER_COMPLETED_AND_CLOSED" && wager_percentage === 100) {
            return  <span className="positive">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs></defs><circle className="a" cx="10" cy="10" r="10"/><path className="b" d="M4.444,10h0L0,5.191,1.246,3.845l3.2,3.462L11.2,0l1.243,1.346L4.445,10Z" transform="translate(3.777 5.001)"/></svg>
                    </span>;
        } else {
            return  <span className="negative">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><path className="a" d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"/><path className="b" d="M0,0H24V24H0Z"/></svg>
                    </span>
        }
    };


    render() {

        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const historyBonus = _get(bonus, 'history');
        const historyBonusData = _get(historyBonus, 'data');

        return (
            <S.AccountHistoryBonus className="account-history-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <S.BonusList>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr className="historyHeader">
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_validFromTo')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_wageredPercent')}</S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(historyBonusData) ?
                                        (
                                            <>
                                            {_map(historyBonusData, ({offerName, offerId, activationTime, statusUpdatedOn, maxBonusAmount, totalToWager, leftToWager, wageredPercent, wagerBonusId, descriptionKey, offerStatus}, index) => {

                                                return (
                                                    <React.Fragment key={offerId}>
                                                        <S.Tr key={wagerBonusId} className="activeBonusRow">
                                                            <S.Td className="td">
                                                                <span>{offerName}</span>
                                                            </S.Td>
                                                            <S.Td className="activeBonusRowDate">
                                                                <span>{formatDate(activationTime, 'dd/MM/yyyy')}</span>
                                                                <span>{formatDate(statusUpdatedOn, 'dd/MM/yyyy')}</span>
                                                            </S.Td>
                                                            <S.Td>
                                                                <span
                                                                    className="ammount">{formatMoney(maxBonusAmount)}</span>&nbsp;
                                                                <span className="currency">{translation('common_pln')}</span>
                                                            </S.Td>
                                                            <S.Td className="wageredPercent">
                                                                <S.ProgressLineWrapper>
                                                                    <ProgressBarLine percentage={wageredPercent}/>
                                                                </S.ProgressLineWrapper>
                                                            </S.Td>
                                                            <S.Td className="bonus-actions">
                                                                {this.checkStatus(offerStatus, wageredPercent)}
                                                            </S.Td>
                                                        </S.Tr>
                                                        <S.Tr className="tableSeparator"></S.Tr>
                                                        <S.Tr className="offerRow">
                                                            <S.Td colSpan="6" className="offerStatus">
                                                                {(offerStatus === "OFFER_COMPLETED_AND_CLOSED" && wageredPercent === 100)?translation(`account_bonus_offer_OFFER_WAGERED_AND_COMPLETED`) : translation(`account_bonus_offer_${offerStatus}`)}
                                                            </S.Td>
                                                        </S.Tr>
                                                        <S.Tr className="spacer"></S.Tr>
                                                    </React.Fragment>
                                                )
                                            })}

                                            {_size(historyBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="8" className="pagination">
                                                        <Pagination currentPage={_get(historyBonus, 'currentPage')}
                                                                    limit={limit} data={historyBonusData}
                                                                    cb={this.goPage.bind(this, 'history')}/>
                                                    </S.Td>
                                                </S.Tr>
                                            )}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td className="noResults" colSpan="8">{translation('account_bonus_notFound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountHistoryBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchBonusListPending: bindActionCreators(fetchBonusListPending, dispatch),
        fetchBonusListFulfilled: bindActionCreators(fetchBonusListFulfilled, dispatch),
        fetchBonusListFailure: bindActionCreators(fetchBonusListFailure, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountHistoricalBonus);
