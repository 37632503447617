import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountBonus = styled.div`
    width: 100%;
    min-height: 41.67rem;
    padding: 1rem 2.56rem 0;
`;

S.CmsPage = styled.div`
    margin: auto;
    width: 63rem;
`
S.BonusFilters = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.4rem;
`;
S.BonusFiltersDates = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 50rem;
    margin-top: 1.5rem;
    padding-bottom: 1.17rem;
    border-bottom: 1px solid #fff;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 15rem;
    height: 46px;
    display: flex;
    position: relative;
    border: 1px solid #ececec;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 1.17rem;
        font-weight: 500;
        width: 100%;
        color: #565656;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #fff;
    font-weight: 700;
    color: ${({theme:{$accountProfileBonusDatepickerLabelColor}})=> $accountProfileBonusDatepickerLabelColor};
    width: 4.5rem;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #e6e6e6;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    color: #565656;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.BonusFiltersBtns = styled.div`
    display: flex;
    width: 50rem;
    & > a{
        flex-basis: 33.33333%;
        &:hover,
        &:link,
        &:visited,
        &:active {
            text-decoration: none;
        }
        &:last-of-type{
            flex-basis: 33.33334%;
        }
    }
`;
S.FilterBtn = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 4.3333rem;
    margin-top: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: ${({theme:{$accountProfileBonusTabBg}})=> $accountProfileBonusTabBg};
    color: ${({theme:{$accountProfileBonusTabTxt}})=> $accountProfileBonusTabTxt};
    font-size: 1.3333rem;
    font-weight: 400;
    width: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-decoration: none;
   
    &.active{
            background-color: ${({theme:{$accountProfileBonusTabActiveBg}})=> $accountProfileBonusTabActiveBg};
            font-weight: 600;
            text-overflow: ellipsis;
           
           &:after{
            content: '';
            position: absolute;
            bottom:-17px;
            width:0;
            height:0;
            border-top: 10px solid ${({theme:{$accountProfileBonusTabActiveBg}})=> $accountProfileBonusTabActiveBg};
            border-bottom:10px solid transparent;
            border-left:10px solid transparent;
            border-right:10px solid transparent;
           } 
    }
`;

S.Table = styled.table`
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
`;

S.Head = styled.thead`
`;

S.Body = styled.tbody``;

S.Row = styled.tr`
    border-top: 0;
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
        border-top: .08rem solid #fff;
      
        & > td{
            text-align: center;
        }
    }
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 4rem!important;
    font-size: 1rem;
    background-color: #f3f3f3;
    color: #000;
    border-right: .08rem solid #fff;
    border-top: .08rem solid #fff;
    vertical-align: top;
`;

S.Th = styled.th`
    background-color: #eaeaea;
    color: #a3a3a3;
    padding: .4rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    border-right: .08rem solid #fff;
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
`;

S.BonuskList = styled.div``;
export default S;