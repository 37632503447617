import styled from 'styled-components';
import {rem} from 'polished';
import {List} from 'global/styles/styles.js';

const BetSlipFreebetWrapperBackground = ({theme:{$betSlipFreebetWrapperBackground}}) => `${$betSlipFreebetWrapperBackground}`;

const baseFont = `12px`;

const S = {};

S.FreebetListWrapper = styled.div`
    font-family: "Roboto Condensed", sans-serif;
    background-color: ${BetSlipFreebetWrapperBackground};
    padding: ${rem('8px', baseFont)};
    display: ${({isVisible})=>{ return (isVisible==true) ? 'block': 'none';}}
`;

S.FreebetList = styled(List)`
    padding: 0;
    margin: 0;
    line-height: 1;
    & > li{
      margin-bottom: ${rem('5px', baseFont)};
      &:last-of-type{
        margin-bottom: 0;
      }
    }
`;

S.FreebetListItem = styled.li`
    display: flex;
    align-items: center;
    font-size: ${rem('12px', baseFont)};
    color: #000;
    & > span{
        margin-right: ${rem('10px', baseFont)};
    }
   ${({isActive}) => {
        if(isActive == true){
            const css = `
                color: #CE0006;
            `;
            return css; 
        }
    }}
 `;

export default S;