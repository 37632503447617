import styled from 'styled-components';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;

const S = {};
const baseFont = `12px`;

S.AccountSessionTimer = styled.div`
    font-family: ${FontFamily};
    display: flex;
    color: ${({theme: {$accountTopHeaderColor}}) => $accountTopHeaderColor};
    padding: 0;
`;

S.TimeParts = styled.span`
display: flex;
        font-weight: ${FwSemibold};
        color: #D32F2F;
        font-size: ${rem('28px', baseFont)};
        margin-right: ${rem('10px', baseFont)};
        line-height: 1;
        &:last-of-type{
          margin-right: 0;
        }
        & > span{
         margin-top: ${rem('9px', baseFont)};
         font-size: ${rem('16px', baseFont)};
         color: rgba(0, 0, 0, 0.87);
         margin-left: ${rem('2px', baseFont)};
        }
`;

export default S;