import styled from 'styled-components';
import {rem} from 'polished';

const S = {};
const baseFont = `12px`;

S.UploadUserAvatar = styled.div`

    width: ${rem('144px', baseFont)}; 
    height: ${rem('144px', baseFont)};
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    
    & input {
        width: 100%;
        height: 100%;
        cursor: pointer;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        opacity: 0;
        -moz-opacity: 0;
        filter:progid:DXImageTransform.Microsoft.Alpha(opacity=0)
    }

`;

S.ModalMessage = styled.div`
    color: #000;
    font-size: 1.6rem;
    font-weight: 100;
    text-align: center;
`;


S.Icon = styled.div`

    & > svg {
        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 26px;
        height: 24px;

        & circle {
            fill: #c62828;
        }
    }


`;

export default S;

