import React, { useRef, useEffect } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import useToggle from 'UseToggle.js';

import AvatarUploadIcon from 'camera.svg';
import { uploadCustomerAvatar, getCustomerAvatar } from 'customerActions.js';
import ModalOpacity from 'ModalOpacity.js';
import S from 'StyledUploadUserAvatar';
import {translation} from 'helpers/utilsHelper.js';


const UploadUserAvatar = (props) => {

    const avatarPlacementRef = useRef(null);
    const [showUploadCustomerMessageModal, toggleUploadCustomerMessageModal] = useToggle(false);

    // useEffect(() => {

    //     const fetchCustomerAvatar = async () => {
    //         let path = await getCustomerAvatar();
    //         displayAvatar(path);
    //     }

    //     fetchCustomerAvatar();

    // },[]);

    useEffect(() => {

        displayAvatar(customerAvatarUrl);
        
    });

    const {
        uploadCustomerAvatar,
        getCustomerAvatar,
        customerAvatarUrl,
        dispatch
    } = props;
    
    const onFileUpload = async(event)=> {

        const inputFile = event.target;
        const fileData = inputFile.files[0];
        var imgMimeType = fileData.type;
        var ext = imgMimeType.substr(imgMimeType.indexOf('/')+1);

        if (['jpg','jpeg','png'].indexOf(ext) == -1) {
            toggleUploadCustomerMessageModal();
            return;
        }

        var fileSize = fileData.size;
        if (fileSize > 51200) { // MAX accepted 50KB
            toggleUploadCustomerMessageModal();
            return; 
        }

        var formData = new FormData();
        formData.append('file', fileData);

        let path = await uploadCustomerAvatar(formData);
        displayAvatar(path);

    };

    const displayAvatar = (path) => {

        if (path){
            path = JSON.stringify(path).replace(/\\\\/g, '/');
            path = JSON.parse(path).toString();
            let completePath = `${process.env.DOMAIN_URL}/${path}`;
            avatarPlacementRef.current.style.backgroundImage = `url('${completePath}')`;
        }

    };

    return (
        <S.UploadUserAvatar ref={avatarPlacementRef}>

            <ModalOpacity isOpen={showUploadCustomerMessageModal}
                            toggleOpen={toggleUploadCustomerMessageModal}
                            showHeaderIcon={false}
                            showHeaderTitle={false}
                            showCloseIcon={true}>

                <S.ModalMessage>
                    { translation('account_uploadCustomerAvatar_fileRequirements') }
                </S.ModalMessage>

            </ModalOpacity>

            <S.Icon dangerouslySetInnerHTML={{__html: AvatarUploadIcon}}></S.Icon>
            
            <input
                id={`avatar-upload`}
                name={`avatar-upload`}
                className={`avatar-upload`}
                type="file"
                onChange={(e) => onFileUpload(e)}
                accept=".jpg, .jpeg, .png"
                title=""
            />

        </S.UploadUserAvatar>
    );

}

const mapStateToProps = (state, props) => {
    const {Customer:{ customerAvatarUrl }} = state;
    return {
        customerAvatarUrl
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadCustomerAvatar: bindActionCreators(uploadCustomerAvatar, dispatch),
        getCustomerAvatar: bindActionCreators(getCustomerAvatar, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadUserAvatar);

