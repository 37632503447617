import React, {useEffect} from 'react';
import S from 'StyledRegisterWizardFormStep1.js';
import {Field, reduxForm, change} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import {isValueUnique} from 'authActions.js';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import classNames from 'classnames';
import Box from 'react-styled-box';
import useToggle from 'UseToggle.js'
import Loader from 'Loader.js';
import store from 'store';
// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.REGISTER_STEP1);
    return errors;
};

const asyncValidate = async (values, dispatch, props, field) => {
    const previousErrors = props.asyncErrors;
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];
        try {
            const result = await dispatch(isValueUnique(fieldName, fieldValue));
            if(previousErrors) {
                throw 'prevErrors';
            } else {
                return result;
            }
        } catch (err) {
            if(err == 'prevErrors') {
                throw previousErrors;
            } else {
                throw Object.assign({}, previousErrors, { [fieldName]: [translation("account_registerForm_" + err)]});
            }
        }
    }
};

const PasswordToggler = () => {
    const [showPassword, togglePassword] = useToggle(false);

    useEffect(() => {
        const passwordFields = document.querySelectorAll('[name="password"]');
        if (passwordFields.length) {
            const passwordFieldsDOM = [...passwordFields];
            if (showPassword) {
                passwordFieldsDOM.forEach(el => el.type = 'text');
            } else {
                passwordFieldsDOM.forEach(el => el.type = 'password');
            }
        }
    }, [showPassword]);

    return (
        <S.PasswordToggler onClick={togglePassword}>
            <S.PasswordTogglerIcon isActive={showPassword} className="fa fa-eye"></S.PasswordTogglerIcon>
        </S.PasswordToggler>
    );
};

const renderField = ({
                         input,
                         label,
                         type,
                         disabled,
                         meta: {touched, error, warning, valid, asyncValidating, submitFailed}
                     }) => {
    const isDisabled = (typeof disabled != 'undefined') ? disabled : false;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid)
    });

    return (
        <S.Row>
            <S.InputWrapper>

                {input.name == 'password' && <PasswordToggler/>}

                <S.Input {...input}
                         type={type}
                         required="required"
                         className={inputClass}
                         disabled={isDisabled}
                />

                <S.Label dangerouslySetInnerHTML={{__html: label}}></S.Label>

                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    )
};

const CheckboxWithLabelAndError = ({
                                       input,
                                       labelPart1,
                                       labelPart1Additional,
                                       labelPart2,
                                       required,
                                       meta: {touched, error, warning, submitFailed}
                                   }) => {
    return (
        <S.Row>
            <S.InputWrapper className="with-checkbox">

                <S.Input {...input}
                         value={input.value}
                         checked={input.value}
                         onChange={event => input.onChange(event.target.checked)}
                         type="checkbox"
                         required={required}
                />

                <S.FakeChbox>
                    {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                </S.FakeChbox>

                <S.Label>

                    <S.LabelPart dangerouslySetInnerHTML={{__html: `${labelPart1}${labelPart1Additional ? `${' '}${labelPart1Additional}`:''}`}}></S.LabelPart>

                    {labelPart2 && (
                        <S.Spoiler>
                            <S.Details>
                                <S.Summary></S.Summary>
                                <S.LabelPart dangerouslySetInnerHTML={{__html: labelPart2}}></S.LabelPart>
                            </S.Details>
                        </S.Spoiler>
                    )}


                </S.Label>


                {(touched || submitFailed) && ((error && <S.Error>{translation(error[0])}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}

            </S.InputWrapper>
        </S.Row>
    );
};

const handleSelectAllFields = (event, newValue, prevValue) => {
    const {dispatch} = store;
    if(newValue && newValue != prevValue) {
        dispatch(change('registerWizardForm', 'marketingConsent', true));
        dispatch(change('registerWizardForm', 'rodoClause', true));
    } else {
        dispatch(change('registerWizardForm', 'marketingConsent', false));
        dispatch(change('registerWizardForm', 'rodoClause', false));
    }
}


const RegisterWizardFormStep1 = (props) => {

    const {handleSubmit, submitting, error} = props;
    return (
        <S.RegisterWizardFormStep1 onSubmit={handleSubmit} autocomplete="off" noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            <S.Body>

                {error && (<S.SubmissionError className="submission-error">{error}</S.SubmissionError>)}

                <Box flexDirection="column">

                    <Box>
                        <Field
                            name="email"
                            type="text"
                            component={renderField}
                            label={translation('account_registerForm_email')}
                            autocomplete="off"
                        />
                    </Box>

                    <Box>
                        <Field
                            name="password"
                            type="password"
                            component={renderField}
                            autocomplete="new-password"
                            label={translation('account_registerForm_password')}
                        />
                    </Box>

                    <Box>
                            <Field
                                name="mobile"
                                type="text"
                                component={renderField}
                                normalize={(value)=>value.replace(/[\s\-]/g,'')}
                                label={translation('account_registerForm_phoneNumber')}
                            />
                    </Box>

                    <Box margin="10px 0 0">
                        <Field
                            name="selectAllFields"
                            component={CheckboxWithLabelAndError}
                            labelPart1={translation('account_registerForm_marketing_selectAll')}
                            required={false}
                            onChange={handleSelectAllFields}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="rodoClause"
                            component={CheckboxWithLabelAndError}
                            labelPart1={translation('account_registerForm_rodoClause')}
                            labelPart2={translation('account_registerForm_rodoClauseAdditional')}
                            required={true}
                        />
                    </Box>

                    <Box>
                        <Field
                            name="marketingConsent"
                            component={CheckboxWithLabelAndError}
                            labelPart1={translation('account_registerForm_marketing_receiveEmail')}
                            labelPart1Additional={translation('account_registerForm_marketing_receiveEmailAdditional')}
                            required={false}
                        />
                    </Box>

                    <Box margin="10px 0 0">
                        <S.Info>{translation('account_registerForm_info')}</S.Info>
                    </Box>

                    <S.SubmitBtn type="submit"
                                 disabled={submitting}
                                 data-test='account_registerForm_next'>
                        {translation('account_registerForm_next')}
                    </S.SubmitBtn>

                </Box>

            </S.Body>

        </S.RegisterWizardFormStep1>
    );
};

export default reduxForm({
    form: 'registerWizardForm',
    validate,
    asyncValidate,
    asyncBlurFields: ['email', 'mobile'],
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(RegisterWizardFormStep1);

