import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledPagination from 'StyledPagination.js';
import {Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = '12px';
const S = {};

S.AccountFreebet = styled.div`
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 0 auto;
    font-family: ${FontFamily};
    
      ${StyledPagination.Pagination}{
      margin-bottom: ${rem('20px', baseFont)}; 
    }
`;

S.FreebetFilters = styled.div`

    display: flex;
 margin: ${rem('30px', baseFont)} 0 ${rem('20px', baseFont)}
`;

S.FreebetFiltersDates = styled.div`
    display: flex;
    margin-left: ${rem('5px', baseFont)};
    & > div{
        &:first-of-type{
            margin-left: 0;
        }
    }
`;

S.DateWrapper = styled.div`
   width: ${rem('297px', baseFont)};
    height: ${rem('42px', baseFont)};
    display: flex;
    background-color: #fff;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.24);
    margin-left : ${rem('5px', baseFont)};
    border-radius: 3px;
    padding: 0 ${rem('13px', baseFont)};
    
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
    width: 100%;
    display: flex;
    }
    & .react-datepicker__input-container{
    padding: 0;
    }
    
    ${StyledDatepicker.Datepicker}{
        background-color: transparent;
        flex: 1;
        padding-left: 0;
        font-size: ${rem('14px', baseFont)};
        font-weight: ${FwRegular};
        border-radius: 0;
        width: 100%;
        color: rgba(0, 0, 0, 0.54);
        &[disabled]{
          cursor: not-allowed;
        }
    }
`;

S.DateLabel = styled.div`
    display: flex;
    background-color: #E8E8E8;
    font-weight: 700;
    color: #676767;
    width: 38px;
    justify-content: center;
    align-items: center;
    border-radius: 3px 0 0 3px;
`;

S.DateIcon = styled.i`
    position: absolute;
    right: ${rem('12px', baseFont)};
    height: ${rem('16px', baseFont)};
    width: ${rem('16px', baseFont)};
    top: 0;
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
    & svg{
      width: 100%;
    }
`;

S.FreebetList = styled.div``;
S.FreebetActiveList= styled.div`
   margin-top: 2.5rem;
`;
S.FreebetHistoryList= styled(S.FreebetActiveList)``;
S.FreebetListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;

S.FreebetFakeTable = styled.div``;

S.Th = styled.div`
    color: rgba(0, 0, 0, 0.87);
        text-align: center;
        font-size: ${rem('12px', baseFont)};
        font-weight: 400;
        line-height: 1;
        border-top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    
    & > span{
        overflow: hidden;
    text-overflow: ellipsis;
    }
    
    &.freebet_id{
        width: 10%;
    }
    &.freebet_ammount{
      width: 15%;
    }
    &.freebet_name{
        width: 55%;
    }
    &.freebet_expirationDate{
       width: 20%;
    }
`;

S.Tr = styled.div`
  display: flex;
 width: 100%;
`;
S.Td = styled(S.Th)`

border: none;
font-size: ${rem('14px', baseFont)}; 
color: rgba(0, 0, 0, 0.87);
font-weight: ${FwRegular};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
display: flex;
justify-content: center;
align-items: center;


    &.freebet-id{
        width: 10%;
    }
    &.freebet-ammount{
      width: 15%;
    }
    &.freebet-name{
        width: 55%;
    }
    &.freebet-expirationDate{
       width: 20%;
    }
`;

S.NoResult = styled.div`
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

S.Thead = styled.div`
     display: flex;
    border-bottom: 2px solid #D32F2F;
    padding-bottom: ${rem('5px', baseFont)};
    margin-bottom: ${rem('14px', baseFont)};
    
    ${S.Tr}{
       & > ${S.Th}{}
    }
`;
S.Tbody = styled.div`
    ${S.Tr}{
    height: ${rem('52px', baseFont)};
       &:nth-child(odd){
    background-color: #fff;
  }
  &:nth-child(even){
  background-color: rgba(255, 255, 255, 0.6);
  }
    
       & > ${S.Td}{}
    }
`;

S.FreebetFiltersBtns = styled.div`
    display: flex;
    flex-grow: 0;
`;

S.FilterBtn = styled(Button)`
    width: ${rem('145px', baseFont)};
    font-size: ${rem('16px', baseFont)};
    font-weight: ${FwSemibold};
    margin-top: 0;
    background-color: ${({isActive})=>(isActive == true ? '#D32F2F ': '#fff')};
    color:  ${({isActive})=>(isActive == true ? '#fff ': 'rgba(0, 0, 0, 0.87)')};
    padding: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative;
    white-space: nowrap;
    margin-right: ${rem('5px', baseFont)};
    &:last-of-type{
    margin-right: 0;
    }
`;

export default S;