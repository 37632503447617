import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;
const S = {};

S.SelfExclusion = styled.div`
    font-family: ${FontFamily};
    width: 100%;
    padding: 0;
    font-weight: ${FwRegular};
`;

S.SelfExclusionBoxArea = styled.div`
    display: flex;
    flex-direction: column;
`;

S.SelfExclusionBoxWrapper = styled.div`
`;

S.SelfExclusionBoxForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: ${rem('600px', baseFont)};
    padding: ${rem('10px', baseFont)} 0 ${rem('45px', baseFont)} 0;
`;

S.SelfExclusionBox = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: ${rem('600px', baseFont)};
    padding: ${rem('30px', baseFont)} 0 0 0;
`;

S.Title = styled.span`
    font-size: ${rem('22px', baseFont)};
    font-weight: bold;
`;

S.SelectWrapper = styled.div`
    width: 100%;
`;

S.RowWrapper = styled.div`
    display: flex;
    margin-bottom: ${rem('15px', baseFont)};
    width: 100%;
    padding: ${rem('20px', baseFont)} ${rem('25px', baseFont)} ${rem('20px', baseFont)} ${rem('20px', baseFont)};
    justify-content: space-between;
    background: #FFFFFF;
    font-size: ${rem('16px', baseFont)};
`;

S.RowLabel = styled.div`
    font-size: ${rem('16px', baseFont)};
`;

S.RadioButton = styled.div`
    display: flex;
`;

S.RadioButtonInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none; 
    appearance: none;
    border: 2px solid white;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    background: white;
    outline: 2px solid black;

    &:checked, &:focus {
        background: black;
        outline: 2px solid black !important;
        outline-offset: 0 !important;
    }
`;

S.AccountExclusionPeriodSelect = styled.div`
  margin-top: 1rem;
  width: 18rem;

  .react-select__control {
      border: 1px solid #E8E8E8;
      box-shadow: 0 0 0 1px #E8E8E8;
      background: #FCFCFC;

      .react-select__indicator-separator {
          display: none;
      }

      .react-select__dropdown-indicator {
          color: #000;
      }

      &--is-focused {
          border-color: transparent!important;
      }
  }

  & .react-select__value-container{
          
      & .react-select__single-value{
          color: #777D85;
      }
  }

  & .react-select__menu {
      & .react-select__menu-list {
          & .react-select__option {
              color: #000;
              background-color: #EFF1F2;
              &:hover {
                background-color: #C62828;
                color: #fff;
              }
              &--is-focused {
                  color: #000;
              }
              &--is-selected {
                  background-color: #C62828;
                  color: #fff;
              }
          }
      }
  }
`;

S.Button = styled(Button)`
    border-radius: 5px;
    height: ${rem('48px', baseFont)};
    width: 100%;
    background-color: #D3302F;
    font-size: ${rem('14px', baseFont)};
    font-family: ${FontFamily};
    font-weight: ${FwSemibold};
    text-align: center;
    text-transform: uppercase;
    color: #fff;

    ${({disabled})=>{
        if(disabled){
            return `
                   background-color: #757575;
                `;
        }
    }}
`;

S.Error = styled.span`
    color: #D32F2F;
    font-size: ${rem('13px', baseFont)};
    font-weight: ${FwSemibold};
    margin: ${rem('10px', baseFont)} 0 0;
`;

S.SelfExclusionValidTo = styled(S.Error)``;

S.Description = styled.div`
    padding: ${rem('15px', baseFont)} 0 ${rem('25px', baseFont)} 0;
    font-size: ${rem('16px', baseFont)};
`;

S.Dialog = styled.dialog`
   width:  ${rem('335px', baseFont)};
   padding: ${rem('16px', baseFont)};
   box-shadow: 0 19px 38px #00000042;
   border: none;
   position: fixed;


   & + .backdrop{
     background-color: transparent;
   }
   
   &::backdrop {
    background-color: transparent;
   }

`;

S.DialogBtnOk = styled(Button)`
 width:  ${rem('142px', baseFont)};
 min-height:  ${rem('42px', baseFont)};
 text-transform: uppercase;
 color: #fff;
 background-color: #C62828;
 border-radius: 3px;
 font-weight: ${FwSemibold};
`;

S.DialogBtnCancel = styled(S.DialogBtnOk)`
 color: #C62828;
 background-color: #fff;
 border: 1px solid #C62828;
`;

S.DialogText =styled.div`
  font-size: ${rem('13px', baseFont)};
  color: #000;
`;

S.BellIcon = styled.div`
  width:  ${rem('32px', baseFont)};
`;


export default S;