import CouponLogo from 'account/Kupony.svg';
import BonusesLogo from 'account/Bonusy.svg';
import CashbackLogo from 'account/Cashback.svg';
import FreebetsLogo from 'account/Freebet.svg';
import DepositLogo from 'account/Wplata.svg';
import WithdrawLogo from 'account/Wyplata.svg';
import TransferLogo from 'account/Transfery.svg';
import ResponsibleGamingLogo from 'account/OdpowiedzialnaGra.svg';
import LogoutLogo from 'account/Wyloguj.svg';

export default {

    SLIP_HISTORY: { 
        href: '/konto/historia-kuponow',
        translationKey: 'account_account_betHistory',
        dataTest: 'account_account_betHistory',
        file: CouponLogo,
    },
    BONUS: {
        href: '/konto/bonus',
        translationKey: 'account_account_bonus',
        dataTest: 'account_account_bonus',
        file: BonusesLogo,
    },
    CASHBACK: {
        href: '/konto/cashback',
        translationKey: 'account_account_cashback',
        dataTest: 'account_account_cashback',
        file: CashbackLogo,
    },
    FREEBETS: {
        href: '/konto/freebety',
        translationKey: 'account_account_coupon',
        dataTest: 'account_account_freebets',
        file: FreebetsLogo,
    },
    DEPOSIT: {
        href: '/konto/wplata',
        translationKey: 'account_account_deposit',
        dataTest: 'account_account_deposit',
        file: DepositLogo,
    },
    WITHDRAW: {
        href: '/konto/wyplata',
        translationKey: 'account_account_withdraw',
        dataTest: 'account_account_withdraw',
        file: WithdrawLogo,
    },
    TRANSFER: { 
        href: '/konto/portfel',
        translationKey: 'account_account_transfer',
        dataTest: 'account_account_transfer',
        file: TransferLogo,
    },
    RESPONSIBLE_GAMING: {
        href: '/konto/odpowiedzialna-gra',
        translationKey: 'account_account_responsibleGaming',
        dataTest: 'account_account_responsibleGaming',
        file: ResponsibleGamingLogo,
    },
    LOGOUT: {
        href: '/',
        translationKey: 'account_account_logout',
        dataTest: 'account_account_logout',
        file: LogoutLogo,
    }

};