import styled, { keyframes } from 'styled-components'

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Loader = styled.div`
  animation: ${rotate} 1s linear infinite;
  border: 4px solid #C62828;
  border-top-color: transparent;
  border-radius: 50%;
  height: 32px;
  width: 32px;
`

export const Iframe = styled.iframe.attrs({
  allowFullScreen: true
})`
  border: 0;
  display: block;
  width: 100%;
`
