import styled,{css} from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {List} from 'global/styles/styles.js';
import {rem} from 'polished';

const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const baseFont = `12px`;
const S = {};

S.FavouriteLeagues = styled.div`
    margin: 0 0 10px;
    font-size: 13px;
    background-color: ${({theme:{$arrowFavouriteLeaguesBgColor}})=> $arrowFavouriteLeaguesBgColor};
    overflow: hidden;
`;

S.FavouriteIcon = styled.div`
    flex-shrink: 0;
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    margin-right: ${rem('10px', baseFont)};

    & > svg {
        height: 100%;
    }
`;

S.Header = styled.div`
    background-color: ${({theme:{$favouriteLeaguesHeaderBgColor}})=> $favouriteLeaguesHeaderBgColor};
    border-radius: 0;
    padding: 0 ${rem('16px', baseFont)};
    height: ${rem('40px', baseFont)};
    color: ${({theme:{$favouriteLeaguesHeaderColor}})=> $favouriteLeaguesHeaderColor};
    display: flex;
    align-items: center;
`;

S.HeaderText = styled.div`
    color: ${({theme:{$favouriteLeaguesHeaderColor}})=> $favouriteLeaguesHeaderColor};
    font-size: ${rem('18px', baseFont)};
    font-weight: ${FwSemibold};
`;

S.Body = styled.div`
    position: relative; 
`;

S.EventsCount = styled.div`
    margin-left: auto;
    font-size: ${rem('14px', baseFont)};
    color: ${({theme:{$favouriteLeaguesEventsCountColor}})=> $favouriteLeaguesEventsCountColor};
    text-align: right;
`;

S.List = styled(List)`
    position: relative;
`;

S.ListItem = styled.li`
    height: ${rem('37px', baseFont)};
    display: flex;
    align-items: center;
    padding: 0 ${rem('16px', baseFont)};
    background-color: ${({theme:{$favouriteLeaguesItemBg}})=> $favouriteLeaguesItemBg};
    cursor: pointer;

    & ${S.FavouriteIcon} {
        margin-right: ${rem('8px', baseFont)};
        width: ${rem('13px', baseFont)};
        height: ${rem('13px', baseFont)};
        flex-shrink: 0;
        & > svg {
            height: 100%;
            & rect,
            & path {
                fill: ${({theme:{$favouriteLeaguesItemIconColor}})=> $favouriteLeaguesItemIconColor};;
            }
        }
    }
`;

S.LeagueName = styled.div`
    font-size: ${rem('14px', baseFont)};
    color: ${({theme:{$favouriteLeaguesItemColor}})=> $favouriteLeaguesItemColor};
    opacity: 0.87;
`;

S.ParticipaneName = styled(S.LeagueName)``;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    justify-content: center;
    align-items: center;
    display: flex;

    & ${StyledLoader.LoaderWrapper}{

    }
`;

S.EventsCount = styled.div`
    width: 16.6666666667%;
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme:{$categoriesEventsCount}}) => $categoriesEventsCount};
    text-align: right;
    font-size: 14px;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
    flex: 1;
`;

S.HeaderEventsCount = styled(S.EventsCount)`
    font-weight: 600;
`;

export default S;