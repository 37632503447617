import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
import {rem} from 'polished';
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;
const S = {};

S.UnloggedPanel = styled.div`
    display: flex;
    margin-left: auto;
    padding-top: ${rem('13px', baseFont)};
`;

S.Button = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({theme:{$topMenuUnloggedBtnColor}})=> $topMenuUnloggedBtnColor};
    font-size: ${rem('14px', baseFont)};
    font-weight: ${FwSemibold};
    border-radius: 3px;
    padding: 0;
    border: none;
    background:  ${({theme:{$topMenuUnloggedBtnBgColor}})=> $topMenuUnloggedBtnBgColor};
    margin-right: ${rem('8px', baseFont)};
    width:  ${rem('113px', baseFont)};
    height: ${rem('37px', baseFont)};
    flex-shrink: 0;
    text-transform: uppercase;

    &:hover {
      background-color: ${({theme: {$topMenuUnloggedBtnBgColorHover}}) =>$topMenuUnloggedBtnBgColorHover};
      color: ${({theme: {$topMenuUnloggedBtnColorHover}}) =>$topMenuUnloggedBtnColorHover};
    }

    ${({theme: {mqZoomLarge}}) => mqZoomLarge}{
      width: 6.25rem;
      font-size: 0.9rem;
    }
`;

export default S;