import React, {Component} from 'react';
import _size from 'lodash/size';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import _find from 'lodash/find';
import _get from 'lodash/get';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DropdownMenu from 'DropdownMenu.js';
import {fetchCategories, selectLeagueById, deselectLeagueById, changeSportId, fetchPopularCategories,toggleSelectedLeague, toggleSelectedLeaguesForSports} from 'categoriesActions.js';
import {dropdownMenuSelector} from 'categoriesSelector.js';
import S from 'StyledLeftMenu.js';
import SportsTreeIcon from 'sports_tree_icon.svg';
import FavouriteLeagues from 'FavouriteLeagues.js';
import {toggleFavouriteForLeague, getFavouriteLeagues, toggleFavouriteByType, getFavouriteByType, removeFavouriteByType} from 'favouritesActions.js';
import {favouriteLeaguesSelector} from 'favouriteLeaguesSelector.js';
import PrematchCalendarIcon from 'prematch_calendar_icon.svg';
import CashbackPlusIcon from 'cashback_svg_16x.svg';
import BoostIcon from 'boost_svg_16x.svg';
import QuickSearch from "QuickSearch.js";
import {translation, redirect} from 'utilsHelper.js';
import PopularCategories from 'PopularCategories.js';
import {getAllEventsForLeaguesIds} from 'eventActions.js';

class LeftMenu extends Component {

    static propTypes = {};

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {
        hasError: false,
        cashbackEventsCount: 0,
        boostEventsCount: 0
    };

    cashbackPlusSportIsActive = (categories) => {
        const cashback = categories.filter((o)=>o.categoryId == process.env.CASHBACK_PLUS_SPORT_ID);
        return cashback;
    };

    omitCustomCategories = (categories) => {
        return categories.filter((o) => o.categoryId != process.env.CASHBACK_PLUS_SPORT_ID)
                         .filter((o) => o.categoryId != process.env.BOOST_SPORT_ID);
    };

    boostIsActive = (categories) => {
        const boost = categories.filter((o)=>o.categoryId == process.env.BOOST_SPORT_ID);
        return boost;
    };

    componentDidMount = () => {
        const {dispatch} = this.props;
        try {
            const {fetchCategories, categories, getFavouriteLeagues, getAllEventsForLeaguesIds} = this.props;

            const categoriesPromise = !_size(categories) ? fetchCategories().catch((err)=>new Error(err)) : Promise.resolve(categories);
            const favouritePromise = getFavouriteLeagues().catch((err)=>new Error(err))
            const promises = [categoriesPromise, favouritePromise];
            Promise.all(promises).then(([categoriesResponse]) => {
                const categories = categoriesResponse;

                const cashbackLeagues = this.cashbackPlusSportIsActive(categories);
                if (_size(cashbackLeagues)) {
                    const ids = process.env.CASHBACK_PLUS_LEAGUE_ID;
                    getAllEventsForLeaguesIds(ids).then((cashbackEventsResponse)=>{
                        const  cashbackEventsCount = _size(cashbackEventsResponse);
                        if(cashbackEventsCount){
                            this.setState((prevState)=>{
                                return {...prevState, cashbackEventsCount}
                            });
                        }
                    });
                }

                const boostLeagues = this.boostIsActive(categories);
                if (_size(boostLeagues)) {
                    const ids = process.env.BOOST_LEAGUE_ID;
                    getAllEventsForLeaguesIds(ids).then((boostEventsResponse)=>{
                        const  boostEventsCount = _size(boostEventsResponse);
                        if(boostEventsCount){
                            this.setState((prevState)=>{
                                return {...prevState, boostEventsCount}
                            });
                        }
                    });
                }

            });

           if (location.pathname && /\/zaklady-bukmacherskie\/.+(?:,.+)*\/.+(?:,.+)*\/.+(?:,.+)*\/\d+(,\d+)*\/.*/u.test(location.pathname)) {
            let categoriesFromPath = location.pathname.replace(/\/zaklady-bukmacherskie\/.+(?:,.+)*\/.+(?:,.+)*\/.+(?:,.+)*\/(\d+(,\d+)*)\/.*/u, '$1');

            categoriesFromPath = String(categoriesFromPath).split(',');

            const favouriteLeaguesGroupBySport = _reduce(categoriesFromPath, (initialArray, leagueId) => {

                leagueId = Number(leagueId);
                const category = _find(categories, {categoryId: leagueId});
                const sportId = _get(category, 'sportId');

                const leaguesIdsMappedToSportExist = _find(initialArray, {sportId});
                if (leaguesIdsMappedToSportExist) {
                    const leaguesFromSport = _get(leaguesIdsMappedToSportExist, 'leagues');
                    leaguesFromSport.push(leagueId);
                } else {
                    initialArray.push({sportId, leagues: [].concat(leagueId)})
                }
                return initialArray;
            }, []);

            dispatch(toggleSelectedLeaguesForSports(favouriteLeaguesGroupBySport));

            try {
                Promise.all([
                    waitUntilComponentRendered(app.component.EventsList),
                    waitUntilComponentRendered(app.component.SportCategoryFilter)
                ]).then(([eventListComponent, sportCategoryFilterComponent]) => {

                    const waitUntilRequestFullfilled = (component) => {
                        var d = $.Deferred();
                        var isRendered = () => {
                            if (!component.dataLoaded) {
                                setTimeout(isRendered, 100);
                            } else {
                                d.resolve(component);
                            }
                        };
                        isRendered();
                        return d.promise();
                    };

                    waitUntilRequestFullfilled(app.component.EventsList).then(() => {
                        this.dispatchCustomEvent(eventListComponent, sportCategoryFilterComponent);
                    })

                });

            } catch (e) {
                console.log(e.message);
            }
        }
        } catch (error) {
            console.log('fetchCategories error:', error);
        }
    };

    render() {
        const {cashbackEventsCount, boostEventsCount} = this.state;
        const {isPending, categories, fetchPopularCategories, popularCategories, isPopularCategoriesPending, ...rest} = this.props;
        const {toggleFavouriteForLeague, favouriteLeagues, isLogged, orgCategories, sportsOrder, outrights, getFavouriteLeagues, getFavouriteByType, toggleFavouriteByType, favouritesByType, removeFavouriteByType} = rest;

        const popularCategoriesProps = {
            fetchPopularCategories,
            popularCategories,
            isPopularCategoriesPending
        };
        const favouriteLeaguesProps = {
            favouriteLeagues,
            getFavouriteLeagues,
            orgCategories,
            outrights,
            sportsOrder,
            toggleFavouriteForLeague,
            toggleSelectedLeague,
            toggleSelectedLeaguesForSports,
            getFavouriteByType,
            toggleFavouriteByType,
            favouritesByType,
            removeFavouriteByType
        };
        const {hasError} = this.state;
        if (hasError) {
            return <div>Error fallback component!</div>
        }
        if (isPending) {
            return <div>Loading ...</div>
        }

        return (
            <S.LeftMenu className="left-menu">

                <QuickSearch/>

                {/* prematch calendar */}
                <S.PrematchCalendar onClick={redirect.bind(null, '/kalendarz')}>
                    <S.PrematchCalendarIcon dangerouslySetInnerHTML={{__html: PrematchCalendarIcon}}/>
                    <S.PrematchCalendarText>{translation('prematch_calendar')}</S.PrematchCalendarText>
                </S.PrematchCalendar>

                {cashbackEventsCount>0 &&
                <S.CashbackPlusHeader onClick={redirect.bind(null, `${process.env.CASHBACK_PLUS_CATEGORY_URL}`)}>
                    <S.HeaderIconCashback dangerouslySetInnerHTML={{__html: CashbackPlusIcon}}/>
                    <S.HeaderText>{translation('cashbackPlus_tab')}</S.HeaderText>
                    <S.EventsCount>{cashbackEventsCount}</S.EventsCount>
                </S.CashbackPlusHeader>}
                
                {boostEventsCount>0 &&
                <S.BoostHeader onClick={redirect.bind(null, `${process.env.BOOST_CATEGORY_URL}`)}>
                    <S.HeaderIconCashback dangerouslySetInnerHTML={{__html: BoostIcon}}/>
                    <S.HeaderText>{translation('leftMenu_boost_tab')}</S.HeaderText>
                    <S.EventsCount>{boostEventsCount}</S.EventsCount>
                </S.BoostHeader>}

                {isLogged && <FavouriteLeagues {...favouriteLeaguesProps}/>}

                <PopularCategories {...popularCategoriesProps}/>

                {/* categories */}
                <S.Header>
                    <S.HeaderIcon dangerouslySetInnerHTML={{__html: SportsTreeIcon}}/>
                    <S.HeaderText>{translation('all_sports_header')}</S.HeaderText>
                </S.Header>
                {categories.length && <DropdownMenu config={this.omitCustomCategories(categories)} {...rest} />}

            </S.LeftMenu>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Auth:{isLogged}, Categories:{isPending, error, selectedLeagues, prevSortId, categories:orgCategories, isEsport, time, categoriesByTime, selectedLeaguesBySport, sportsOrder, outrights}, PopularCategories:{popularCategories, isPending:isPopularCategoriesPending}, Favourites:{favouritesByType}} = state;
    const categories = dropdownMenuSelector(state, props);
    const favouriteLeagues = favouriteLeaguesSelector(state, props);

    return {
        categories,
        categoriesByTime,
        error,
        favouriteLeagues,
        isEsport,
        isLogged,
        isPending,
        isPopularCategoriesPending,
        orgCategories,
        outrights,
        popularCategories,
        prevSortId,
        selectedLeagues,
        selectedLeaguesBySport,
        sportsOrder,
        time,
        favouritesByType
    }
};


const mapDispatchToProps = (dispatch) => {
    return {
        getAllEventsForLeaguesIds: bindActionCreators(getAllEventsForLeaguesIds, dispatch),
        removeFavouriteByType: bindActionCreators(removeFavouriteByType, dispatch),
        getFavouriteByType:  bindActionCreators(getFavouriteByType, dispatch),
        toggleFavouriteByType:  bindActionCreators(toggleFavouriteByType, dispatch),
        selectLeagueById: bindActionCreators(selectLeagueById, dispatch),
        deselectLeagueById: bindActionCreators(deselectLeagueById, dispatch),
        changeSportId: bindActionCreators(changeSportId, dispatch),
        fetchCategories: bindActionCreators(fetchCategories, dispatch),
        fetchPopularCategories: bindActionCreators(fetchPopularCategories, dispatch),
        toggleSelectedLeague: bindActionCreators(toggleSelectedLeague, dispatch),
        toggleSelectedLeaguesForSports: bindActionCreators(toggleSelectedLeaguesForSports, dispatch),
        toggleFavouriteForLeague: (categoryId, status, e) => {
            if(e){
                e.stopPropagation();
            }
            return dispatch(toggleFavouriteForLeague(categoryId, status));
        },
        getFavouriteLeagues: bindActionCreators(getFavouriteLeagues, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenu);
