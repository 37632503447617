const accountExclusionPeriod = {
    "1": {
        days: 1,
        nameTranslation: 'account_exclusionPeriod_24h'
    },
    "7": {
        days: 7,
        nameTranslation: 'account_exclusionPeriod_7days',
    },
    "30": {
        days: 30,
        nameTranslation: 'account_exclusionPeriod_30days'
    },
    "42": {
        days: 42,
        nameTranslation: 'account_exclusionPeriod_6weeks',
    },
    "90": {
        days: 90,
        nameTranslation: 'account_exclusionPeriod_3months',
    }
};

export default accountExclusionPeriod;