/* global app */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { SBPayments } from 'sb-payments'
import S from 'StyledAccountWithdraw.js';
import CmsPage from 'CmsPage.js';

const { Withdraw, useSession } = SBPayments

const { WithdrawSection, SummaryProvider, Summary } = Withdraw

const AccountWithdraw = ({ isLogged, balance, currencyCode }) => {
  const session = useSession()

  const [lang, setLang] = useState(() => `lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase())

  useEffect(() => {
    setLang(`lang_${app.config.lang}_${app.config.mainCountryCode}_1_1`.toLowerCase())
  }, [app.config])

  return (
    isLogged && (
      <SummaryProvider>
        <S.AccountWithdraw>
          <S.AccountWithdrawInner>
            <CmsPage name="withdraw-main-banner" isPlaces={true} />
            <WithdrawSection lang={lang} session={session} balance={balance} currencyCode={currencyCode} />
          </S.AccountWithdrawInner>
          <CmsPage name="withdraw-footer-desktop" isPlaces={true} />
        </S.AccountWithdraw>
        <Summary />
      </SummaryProvider>
    )
  )
}

const mapStateToProps = ({ Auth: { isLogged, user: { balance, currencyCode } } }) => {
  return {
    isLogged,
    balance,
    currencyCode
  }
}

export default connect(mapStateToProps)(AccountWithdraw)
