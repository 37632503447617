import React, {useMemo, memo, useEffect} from 'react';
import S from 'StyledBetSlipSystem.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _reject from 'lodash/reject';
import _filter from 'lodash/filter';
import _each  from 'lodash/each';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import _head from 'lodash/head';
import classNames from 'classnames';
import Select from 'react-select';
import {translation, formatMoney, decimal} from 'utilsHelper.js';
import BankerIcon from 'svg/banker_icon.svg';
import RemoveIcon from 'remove_outcome_icon.svg';
import {Scrollbars} from 'react-custom-scrollbars';

const BetSlipSystem = (props) => {

    const {
        outcomes,
        removeOutcome,
        blocks,
        toggleOutcomeToBlock,
        createBlock,
        toggleBankerOutcome,
        type,
        types,
        changeCombinationType,
        toggleAccumulator,
        addAccumulator,
        hasApprovalButtonsVisibled,
        removeOutcomeFromBlock,
        updateBlock
    } = props;

    const hasGroupByLetters = process.env.GROUP_BY_LETTERS_SYSTEM_OUTCOMES && JSON.parse(process.env.GROUP_BY_LETTERS_SYSTEM_OUTCOMES);

    const betSlipSystemLettersCount = process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT && JSON.parse(process.env.BETSLIP_SYSTEM_MAX_BLOCK_COUNT);

    const allowUncheckBanker = useMemo(() => {
        if(!betSlipSystemLettersCount){
            return true;
        }
        const outcomesNotInBlock = _reject(outcomes, {inBlock: true});
        const outcomesNotBanker = _reject(outcomesNotInBlock, {banker: true});
        const groupCount = _size(blocks) + _size(outcomesNotBanker);
        return (groupCount < betSlipSystemLettersCount);
    }, [outcomes]);

    const outcomesNotInBlock = useMemo(() => {
        return _reject(outcomes, {inBlock: true});
    }, [outcomes]);

    const outcomesMarkToBlock = useMemo(() => {
        return _filter(outcomes, {selected: true});
    }, [outcomes]);

    const onCombinationTypeChange = ({value}) => {
        const type = value;
        changeCombinationType(type);
    };

    const selectedOption = () => {
        const selectedType = _get(type, ['type']);
        const selectedValue = _find(getCombinationTypesList, {value: Number(selectedType)});
        return selectedValue;
    };

    const addAccumulatorAllowed = useMemo(() => {
        const key = _get(type, ['key']);
        return (['SINGLE', 'ACCUMULATOR'].indexOf(key) == -1);
    }, [type]);

    const getCombinationTypesList = useMemo(() => {
        const options = [];
        _each(types, ({extendedName, type}) => {
            const combinationType = {
                value: type,
                label: extendedName
            };
            options.push(combinationType);
        });
        return options;
    }, [types]);

    const groupSystemOutcomes = useMemo(() => {
        const mapOutcomesToGroup = [];
        const blocksSorted = _sortBy(blocks, ['blockOrder']);
        const bankers = _filter(outcomesNotInBlock, {banker: true});
        const outcomesNotBanker = _reject(outcomesNotInBlock, {banker: true});
        let groupIterator = 0;
        let additionalGroupIterator = 0;

        _each(blocksSorted, ({outcomes}) => {

            if (betSlipSystemLettersCount == groupIterator) {
                return false;
            }

            let groupName = String.fromCharCode(97 + groupIterator).toUpperCase();
            if (groupIterator >= 26) {
                groupName = `A${String.fromCharCode(97 + additionalGroupIterator).toUpperCase()}`;
            }

            const groupProperties = {
                groupName,
                outcomes,
                inBlock: true
            };
            mapOutcomesToGroup.push(groupProperties);

            groupIterator++;
            if (groupIterator >= 27) {
                additionalGroupIterator++;
            }
        });

        _each(outcomesNotBanker, (outcome) => {

            if (betSlipSystemLettersCount == groupIterator) {
                return false;
            }

            let groupName = String.fromCharCode(97 + groupIterator).toUpperCase();
            if (groupIterator >= 26) {
                groupName = `A${String.fromCharCode(97 + additionalGroupIterator).toUpperCase()}`;
            }

            const groupProperties = {
                groupName,
                outcomes: outcome,
                inBlock: false
            };
            mapOutcomesToGroup.push(groupProperties);

            groupIterator++;
            if (groupIterator >= 27) {
                additionalGroupIterator++;
            }

        });

        const groupProperties = {
            groupName: 'BANKER',
            outcomes: bankers,
            inBlock: false
        };

        mapOutcomesToGroup.push(groupProperties);

        return mapOutcomesToGroup;
    }, [outcomes]);

    const getGroupNameFromOutcome = (searchOutcomeId) => {
        const group = _find(groupSystemOutcomes, ({inBlock, outcomes, groupName}) => {
            if (inBlock || groupName == 'BANKER') {
                return _find(outcomes, {outcomeId: searchOutcomeId});
            } else {
                const {outcomeId} = outcomes;
                return outcomeId == searchOutcomeId;
            }
        });
        return _get(group, ['groupName']);
    };

    const groupOptions = useMemo(() => {
        const options = [];
        _each(groupSystemOutcomes, ({groupName}) => {
            const label = groupName == 'BANKER' ? 'P' : `${translation('betSlip_group_header')}  ${groupName}`;
            const combinationType = {
                value: groupName,
                label
            };
            options.push(combinationType);
        });

        return options;
    }, [outcomes]);

    const onGroupChange = (option, outcomeId) => {
        const groupName = _get(option, ['value']);
        const group = _find(groupSystemOutcomes, {groupName});
        const {inBlock, outcomes} = group;

        const outcomesIdsFromNextGroup = _map([].concat(outcomes), ({outcomeId}) => outcomeId);
        const outcomeExistInGroup = outcomesIdsFromNextGroup && outcomesIdsFromNextGroup.indexOf(outcomeId) != -1;
        if (outcomeExistInGroup) {
            return;
        }

        const blockOutcomeIndex = _findIndex(blocks, ({outcomes}) => {
            return (_findIndex(outcomes, {outcomeId}) != -1);
        });

        if (blockOutcomeIndex != -1) {
            removeOutcomeFromBlock(outcomeId);
        }

        if (groupName == 'BANKER') {
            toggleBankerOutcome(outcomeId);
            return;
        }

        const prevGroup = getGroupNameFromOutcome(outcomeId);
        if (prevGroup == 'BANKER') {
            toggleBankerOutcome(outcomeId);
        }

        if (inBlock) {
            const [firstOutcomeFromBlock] = outcomes;
            const {outcomeId:firstOutcomeIdFromBlock} = firstOutcomeFromBlock;
            updateBlock(firstOutcomeIdFromBlock, outcomeId);

        } else {
            const outcomesToBlock = [outcomeId];
            outcomesToBlock.push(outcomes.outcomeId);
            _each(outcomesToBlock, (outcomeId) => {
                toggleOutcomeToBlock(outcomeId);
            });
            createBlock();
        }
    };

    const calculateGroupOdds = (outcomes) => {
        const odds = _reduce(outcomes, (total, {outcomeOdds}) => {
            return total * outcomeOdds;
        }, 1.00);
        return odds.toFixed(2);
    };

    const onRedirectHandler = (isLive, eventId) => {
        const redirectLink = isLive ? `/zaklady-live/event/${eventId}` : `/zaklady-bukmacherskie/event/${eventId}`;
        app.router.redirect(redirectLink);
    }


    return (
        <S.BetSlipSystem className="betslip-system">

            <Scrollbars style={{width: '100%'}} autoHeight autoHeightMax={500}>

                {_map(blocks, ({outcomes}, idx) => {
                    const {outcomeId} = _head(outcomes);
                    const groupName = hasGroupByLetters ? getGroupNameFromOutcome(outcomeId) : (idx + 1);
                    const selectedGroup = _find(groupOptions, {value: groupName});

                    return (
                        <S.Block key={`block-${idx}`} className="block">

                            <S.BlockHeader>
                                <S.BlockName>{translation('betSlip_group_header')} {groupName}</S.BlockName>
                                <S.BlockOdds>{calculateGroupOdds(outcomes)}</S.BlockOdds>
                            </S.BlockHeader>

                            <S.BlockBody>

                                {_map(outcomes, ({eventName, gameName, outcomeName, outcomeId, outcomeOdds, sportId, eventId, outcomeLive, prevOdds, active = true, unavailable = false, oddsChanged = false}, index) => {
                                    const indexNumber = ++index;
                                    const isLive = outcomeLive && JSON.parse(outcomeLive);
                                    const outcomeChangedUp = (prevOdds && (outcomeOdds > prevOdds));
                                    const outcomeChangedDown = (prevOdds && (outcomeOdds < prevOdds));
                                    const outcomeOddsChangedClass = classNames({
                                        'up': outcomeChangedUp,
                                        'down': outcomeChangedDown,
                                        'outcome': true,
                                        'no-active': !active
                                    });
                                    const outcomeClass = classNames({
                                        'unavailable': unavailable,
                                        'oddsChanged': oddsChanged,
                                        'up': outcomeChangedUp,
                                        'down': outcomeChangedDown,
                                        'no-active': !active
                                    });

                                    return (
                                        <S.Outcome key={outcomeId} className={outcomeClass}>

                                            {hasGroupByLetters && (
                                                <S.OutcomeHeader className="outcome-header">
                                                    <S.Select as={Select}
                                                              options={groupOptions}
                                                              captureMenuScroll={false}
                                                              value={selectedGroup}
                                                              onChange={(option) => {
                                                                  onGroupChange(option, outcomeId)
                                                              }}
                                                              isSearchable={false} classNamePrefix="react-select"
                                                              className="react-select-container"
                                                    />
                                                </S.OutcomeHeader>
                                            )}

                                            <S.OutcomeBody className="outcome-body outcome-inside-block">
                                                <S.SportIcon>
                                                    <use href={`#sport-${sportId}`}></use>
                                                </S.SportIcon>
                                                <S.OutcomeLeftSide>

                                                    <S.EventName>
                                                        <span onClick={() => onRedirectHandler(isLive, eventId)}>{indexNumber}. {eventName}</span>
                                                        {isLive &&
                                                        <S.LiveIndicator>{translation('betSlip_live')}</S.LiveIndicator>}
                                                    </S.EventName>

                                                    <S.GameName>{gameName}</S.GameName>

                                                    <S.OutcomeName>{translation('betSlip_outcomeType')} {outcomeName}</S.OutcomeName>

                                                </S.OutcomeLeftSide>

                                                <S.OutcomeRightSide>

                                                    <S.RemoveOutcome onClick={removeOutcome.bind(null, outcomeId)}
                                                                     disabled={hasApprovalButtonsVisibled}
                                                                     dangerouslySetInnerHTML={{__html: RemoveIcon}}>
                                                    </S.RemoveOutcome>

                                                    {prevOdds && (prevOdds != outcomeOdds) && (
                                                        <S.PrevOdds
                                                            className={outcomeOddsChangedClass}>{decimal(prevOdds)}</S.PrevOdds>
                                                    )}

                                                    <S.OutcomeOdds>{decimal(outcomeOdds)}</S.OutcomeOdds>

                                                </S.OutcomeRightSide>

                                            </S.OutcomeBody>
                                        </S.Outcome>
                                    )
                                })}

                            </S.BlockBody>

                        </S.Block>
                    )
                })}

                {_map(outcomesNotInBlock, ({eventName, gameName, outcomeName, outcomeId, outcomeOdds, sportId, eventId, outcomeLive, prevOdds, banker, active = true, selected = false, unavailable = false, oddsChanged = false}, index) => {
                    const indexNumber = ++index;
                    const isLive = outcomeLive && JSON.parse(outcomeLive);
                    const groupName = getGroupNameFromOutcome(outcomeId);
                    const selectedGroup = _find(groupOptions, {value: groupName});

                    const outcomeChangedUp = (prevOdds && (outcomeOdds > prevOdds));
                    const outcomeChangedDown = (prevOdds && (outcomeOdds < prevOdds));
                    const outcomeOddsChangedClass = classNames({
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'outcome': true,
                        'no-active': !active
                    });

                    const outcomeClass = classNames({
                        'unavailable': unavailable,
                        'oddsChanged': oddsChanged,
                        'up': outcomeChangedUp,
                        'down': outcomeChangedDown,
                        'no-active': !active
                    });

                    return (
                        <S.Outcome key={outcomeId} className={outcomeClass}>

                            {hasGroupByLetters && (
                                <S.OutcomeHeader className="outcome-header">
                                    <S.Select as={Select}
                                              options={groupOptions}
                                              value={selectedGroup}
                                              captureMenuScroll={false}
                                              onChange={(option) => {
                                                  onGroupChange(option, outcomeId)
                                              }}
                                              isSearchable={false} classNamePrefix="react-select"
                                              className="react-select-container"
                                              outcomeId={outcomeId}
                                    />
                                </S.OutcomeHeader>
                            )}

                            <S.OutcomeBody className="outcome-body">
                                <S.SportIcon>
                                    <use href={`#sport-${sportId}`}></use>
                                </S.SportIcon>
                                <S.OutcomeLeftSide>

                                    <S.EventName title={eventName}>
                                        <span onClick={() => onRedirectHandler(isLive, eventId)}>{indexNumber}. {eventName}</span>
                                        {isLive && <S.LiveIndicator>{translation('betSlip_live')}</S.LiveIndicator>}
                                    </S.EventName>

                                    <S.GameName>{gameName}</S.GameName>

                                    <S.OutcomeName>{translation('betSlip_outcomeType')} {outcomeName}</S.OutcomeName>

                                    {(!banker && !hasGroupByLetters) && (
                                        <input type="checkbox"
                                               onChange={toggleOutcomeToBlock.bind(null, outcomeId)}
                                               checked={selected}
                                               disabled={hasApprovalButtonsVisibled}
                                        />
                                    )}

                                </S.OutcomeLeftSide>

                                <S.OutcomeRightSide>

                                    {(banker && allowUncheckBanker) ?
                                        (
                                            <S.Banker onClick={toggleBankerOutcome.bind(null, outcomeId)}
                                                      isActive={banker}
                                                      disabled={hasApprovalButtonsVisibled}
                                                      dangerouslySetInnerHTML={{__html: BankerIcon}}/>
                                        ) :
                                        (
                                            <S.RemoveOutcome onClick={removeOutcome.bind(null, outcomeId)}
                                                             disabled={hasApprovalButtonsVisibled}
                                                             dangerouslySetInnerHTML={{__html: RemoveIcon}}>
                                            </S.RemoveOutcome>
                                        )
                                    }

                                    {prevOdds && (prevOdds != outcomeOdds) && (
                                        <S.PrevOdds className={outcomeOddsChangedClass}>{decimal(prevOdds)}</S.PrevOdds>
                                    )}

                                    <S.OutcomeOdds>{decimal(outcomeOdds)}</S.OutcomeOdds>

                                </S.OutcomeRightSide>

                            </S.OutcomeBody>

                        </S.Outcome>
                    )
                })}

                {_size(outcomesMarkToBlock) >= 2 && !hasGroupByLetters && (
                    <S.CreateBlockBtn onClick={createBlock}
                                      disabled={hasApprovalButtonsVisibled}>
                        <span>{translation('betSlip_createBlock')}</span>
                    </S.CreateBlockBtn>
                )}
                {addAccumulatorAllowed && (
                    <S.AccumulatorBtn isActive={addAccumulator}
                                      onClick={toggleAccumulator}
                                      disabled={hasApprovalButtonsVisibled}>
                        <span>{translation('betSlip_addAccumulator')}</span>
                    </S.AccumulatorBtn>
                )}

            </Scrollbars>

            <S.CombinationTypesSelect as={Select} options={getCombinationTypesList} value={selectedOption()}
                                      onChange={onCombinationTypeChange}
                                      isSearchable={false} classNamePrefix="react-select"
                                      className="react-select-container"
                                      disabled={hasApprovalButtonsVisibled}/>

        </S.BetSlipSystem>
    );
};

export default memo(BetSlipSystem);
