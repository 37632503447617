import styled from "styled-components";
// import InputRange from "react-input-range";

const S = {};

S.GetLucky = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background-color: ${({theme:{$getLuckyBackgroundColor}}) => $getLuckyBackgroundColor};
    ${({theme: { boxShadow }}) => boxShadow()};
`;

S.GetLuckyIcon = styled.i`
    width: 12px;
    height: 17px;
    margin: 0 10px;
`;

S.GetLuckyHeader = styled.div`
    width: 100%;
    padding: 1rem 0.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    color: ${({theme:{$getLuckyHeaderColor}}) => $getLuckyHeaderColor};
    background-color: ${({theme:{$getLuckyHeaderBackground}}) => $getLuckyHeaderBackground};
`;

S.GetLuckyHeaderTitle = styled.span`
    display: flex;
    align-items: center;
`;

S.ArrowIcon = styled.i`
    margin-left: auto;
    margin-right: 0.5rem;
`;

S.GetLuckyBody = styled.div`
    position: relative;
    max-height: 1000px;
    transition-property: max-height;
    transition-duration: .5s;
    ${({isHidden}) => {
        return isHidden == true && 'max-height: 0';
    }}
`;

S.GetLuckyBodyContent = styled.div`
    padding: 0.8333rem;
`;

S.FormGroupWrapper = styled.div`
    margin-bottom: 0.7rem;
`;

S.Label = styled.label`
    margin-top: 0.25rem;
    font-size: 1.17rem;
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-weight: 600;
    margin-bottom: 0;
    width: 50%;
`;

S.AmountWrapper = styled.div`
    background-color: ${({theme:{$getLuckyInputBackground}}) => $getLuckyInputBackground};
    height: 2.7rem;
    width: 50%;
    color: ${({theme:{$getLuckyInputColor}}) => $getLuckyInputColor};
`

S.Input = styled.input`
    font-size: 1.3333rem;
    text-align: left;
    background-color: transparent;
    border: 2px solid #D9D9D9;
    border-radius: 3px;
    padding-left: 1rem;
    width: 100%;
    height: 100%;
    color: ${({theme:{$getLuckyInputColor}}) => $getLuckyInputColor};

    &:focus {
        border: 2px solid #D32F2F !important;
    }
`;

S.Button = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 1.3333rem;
    color: ${({theme:{$getLuckyButtonColor}}) => $getLuckyButtonColor};;
    background-color: ${({theme:{$getLuckyButtonBackground}}) => $getLuckyButtonBackground};;
    height: 3.5rem;
    border: none;
    border-radius: 5px;
    text-align: center;
    width: 10.3333rem;
    text-transform: uppercase;
    &:hover {
        background-color: ${({theme: {$getLuckyButtonBackgroundHover}}) => $getLuckyButtonBackgroundHover};
        color: ${({theme: {$getLuckyButtonColorHover}}) => $getLuckyButtonColorHover};
        border: 2px solid ${({theme: {$getLuckyButtonBorderColor}}) => $getLuckyButtonBorderColor};
      }
`;

S.Currency = styled.div`
    font-size: 1.3333rem;
`;

S.CheckboxWrapper = styled.div`
    margin: 1.6667rem 0 0 0.5rem;
`;

S.CheckboxGroup = styled.div`
    flex: 0 0 33.3333333333%;
`;

S.LabelCheckbox = styled.label`
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
`;

S.FakeCheckbox = styled.div`
    /* background-color: ${({theme:{$getLuckyFakeChboxBackground}}) => $getLuckyFakeChboxBackground}; */
    margin-right: 0.5rem;
    position: relative;
    width: 1.3rem;
    height: 1.3rem;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    border-radius: 2px;
    border: 2px solid ${({theme:{$getLuckyInputBorderColor}}) => $getLuckyInputBorderColor};;
    &:before {
        content: "\\002714";
        position: absolute;
        display: none;
        left: 0.2rem;
        top: -0.1rem;
    }
`;

S.InputCheckbox = styled.input`
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;

    &:checked + ${S.FakeCheckbox} {
        background: #D32F2F;
        border: none;
        &:before {
            display: block;
        }
    }
`;

S.CheckboxText = styled.span`
    font-size: 1.17rem;
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-weight: normal;
    line-height: 1;
`;

S.SliderWrapper = styled.div`
    margin: 2rem 0 0.5rem;

    .input-range {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        &__slider {
            height: 1.3rem;
            width: 1.3rem;
            margin-top: -1.05rem;
            background: ${({theme:{$getLuckyInputRangeTrackSlider}}) => $getLuckyInputRangeTrackSlider};
            border: 2px solid #FFF;
            transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
            &-container {
                transition: left 0.1s ease-out;
            }
        }
        &__track {
            background: ${({theme:{$getLuckyInputRangeTrackBackground}}) => $getLuckyInputRangeTrackBackground};
            transition: left 0.1s ease-out, width 0.1s ease-out;
            height: 10px;
            &--active {
                background: ${({theme:{$getLuckyInputRangeTrackActiveBackground}}) => $getLuckyInputRangeTrackActiveBackground};
            }
        }
        &__label {
            display: none;
        }
    }
`;

S.InputRangeValuesWrapper = styled.div`
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
    font-size: 1rem;
`

S.InputRangeValue = styled.div``

S.SliderLabel = styled.span`
    font-size: 1.3rem;
    font-weight: 600;
    color: ${({theme:{$getLuckyLabelColor}}) => $getLuckyLabelColor};
`;

S.GetLuckyFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    flex-wrap: wrap;
`;

S.Error = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-size: 1.5rem;
    color: #262626;
    background-color: red;
    margin-top: 0.5rem;
    font-weight: bold;
    height: 3.5rem;
    line-height: 3.5rem;
`

export default S;
