import styled from 'styled-components';
const S = {};

S.AccountShareBetIconWrapper = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    margin-right: 0.67rem;
    background-size: cover;
    cursor: pointer;

    &.facebook {
      background: url(/images/svg/fb.svg) no-repeat;
      background-size:cover ;
    }
    &.twitter {
      background: url(/images/svg/twitterShare.svg) no-repeat;
    }
    &.link {
      background: url(/images/svg/share.svg) no-repeat;
    }
`;

export default S;
