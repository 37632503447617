import _get from 'lodash/get';
import _each from 'lodash/each';
import _difference from 'lodash/difference';
import _find from 'lodash/find';
import _has from 'lodash/has';
import typeToReducer from 'type-to-reducer';
import update from 'immutability-helper';
import memoize from 'fast-memoize';
import {
    FETCH_CATEGORIES,
    ADD_LEAGUE_BY_ID,
    REMOVE_LEAGUE_BY_ID,
    CHANGE_SPORT_ID,
    SELECT_LEAGUE_FOR_SPORT,
    UNSELECT_LEAGUE_FOR_SPORT,
    CHANGE_SPORT_ORDER,
    SELECT_LEAGUES_GROUP_BY_SPORT,
    REMOVE_SPORT_BY_ID
} from 'categoriesActions.js';

const removeLeagueById = (leagues, searchId) => {
    return leagues.filter(leagueId => leagueId != searchId);
};

const isOutright = (categoryId, categories) => {
    const league = _find(categories, {categoryId});
    const treatAsSport = _get(league, 'treatAsSport');
    return treatAsSport == -500;
};

const isOutrightMemoized = memoize(isOutright);

let initialState = {
    isPending: true,
    error: null,
    categories: [],
    selectedLeagues: [],
    prevSortId: null,
    categoriesByTime: {},
    sportsOrder: [],
    selectedLeaguesBySport: {},
    outrights: [],
    isFilteredCategoriesRequestPending: false
};

export default typeToReducer({
    [REMOVE_SPORT_BY_ID]: (state, {payload:{sportId}}) => {
        let outrights = state.outrights;
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;

        if (_has(selectedLeaguesBySport, [sportId])) {
            const sportIdToRemove = String(sportId);
            selectedLeaguesBySport = update(selectedLeaguesBySport, {$unset: [sportIdToRemove]});

            const indexOfSportInOrder = sportsOrder.indexOf(sportId);
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSportInOrder, 1]]});
        } else {
            outrights = update(outrights, {$set: []});

            const indexOfSportInOrder = sportsOrder.indexOf(sportId);
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSportInOrder, 1]]});
        }

        return {...state, sportsOrder, outrights, selectedLeaguesBySport};
    },
    [SELECT_LEAGUES_GROUP_BY_SPORT]: (state, {payload:{leaguesGroupBySport}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;
        let outrights = state.outrights;

        _each(leaguesGroupBySport, ({sportId, leagues}) => {

            sportId = Number(sportId);

            _each(leagues, (leagueId) => {
                const isOutright = isOutrightMemoized(leagueId, state.categories);
                if (isOutright) {
                    outrights = update(outrights, {$push: [leagueId]});
                } else {
                    selectedLeaguesBySport = update(selectedLeaguesBySport, {
                        [sportId]: (prevLeagues) => {
                            prevLeagues = prevLeagues || [];
                            const newLeaguesIds = _difference([].concat(leagueId), prevLeagues);
                            if (newLeaguesIds.length) {
                                return update(prevLeagues, {$push: newLeaguesIds});
                            } else {
                                return update(prevLeagues, {$set: prevLeagues});
                            }
                        }
                    });
                }

                const idOfSport = isOutright ? -1 : sportId;
                const indexOfSport = sportsOrder.indexOf(idOfSport);
                if (indexOfSport == -1) {
                    sportsOrder = update(sportsOrder, {$push: [idOfSport]});
                }
            });
        });

        return {...state, selectedLeaguesBySport, outrights, sportsOrder};
    },
    [CHANGE_SPORT_ORDER]: (state, {payload:{sportId}}) => {
        const indexOfSport = state.sportsOrder.indexOf(sportId);
        let sportsOrder = state.sportsOrder;
        if (indexOfSport != -1) {
            sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, sportId]]});
        } else {
            sportsOrder = update(sportsOrder, {$unshift: [sportId]});
        }
        return {...state, sportsOrder}
    },
    [SELECT_LEAGUE_FOR_SPORT]: (state, {payload:{leagueId, sportId}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let outrights = state.outrights;
        let sportsOrder = state.sportsOrder;
        let hasNewLeagues = false;
        const isArray = Array.isArray(leagueId);
        const leagues = [].concat(leagueId);

        _each(leagues, (leagueId, idx) => {
            const isOutright = isOutrightMemoized(leagueId, state.categories);
            if (isOutright) {
                const newLeaguesIds = _difference([].concat(leagueId), outrights);
                if (newLeaguesIds.length) {
                    if (isArray) {
                        outrights = update(outrights, {$push: [leagueId]});
                    } else {
                        outrights = update(outrights, {$unshift: [leagueId]});
                    }
                }
            } else {
                selectedLeaguesBySport = update(selectedLeaguesBySport, {
                    [sportId]: (sportId) => {
                        sportId = sportId || [];
                        const newLeaguesIds = _difference([].concat(leagueId), sportId);
                        if (newLeaguesIds.length) {
                            hasNewLeagues = true;
                            if (isArray) {
                                return update(sportId, {$push: newLeaguesIds});
                            } else {
                                return update(sportId, {$unshift: newLeaguesIds});
                            }
                        } else {
                            return update(sportId, {$set: sportId});
                        }
                    }
                });
            }

            const idOfSport = isOutright ? -1 : sportId;
            const indexOfSport = sportsOrder.indexOf(idOfSport);
            if (isArray) {
                if (indexOfSport == -1) {
                    sportsOrder = update(sportsOrder, {$unshift: [idOfSport]});
                }
            } else {
                if (indexOfSport != -1) {
                    sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, idOfSport]]});
                } else {
                    sportsOrder = update(sportsOrder, {$unshift: [idOfSport]});
                }
            }
        });

        if (isArray) {
            let idOfSport = null;
            if (hasNewLeagues) {
                idOfSport = sportId;
            } else {
                idOfSport = -1;
            }
            const indexOfSport = sportsOrder.indexOf(idOfSport);
            if (indexOfSport != -1) {
                sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1], [0, 0, idOfSport]]});
            }
        }

        return {...state, selectedLeaguesBySport, outrights, sportsOrder};
    },
    [UNSELECT_LEAGUE_FOR_SPORT]: (state, {payload:{leagueId, sportId}}) => {
        let selectedLeaguesBySport = state.selectedLeaguesBySport;
        let sportsOrder = state.sportsOrder;
        let outrights = state.outrights;

        const isOutright = isOutrightMemoized(leagueId, state.categories);
        sportId = isOutright ? -1 : sportId;

        if (isOutright) {
            if (outrights.length == 1) {
                const indexOfSport = sportsOrder.indexOf(sportId);
                sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
            }
            const indexOfLeague = outrights.indexOf(leagueId);
            if (indexOfLeague != -1) {
                outrights = update(outrights, {$splice: [[indexOfLeague, 1]]});
            }
        } else {
            const leaguesForSport = _get(state.selectedLeaguesBySport, [sportId], []);
            const indexOfLeague = leaguesForSport.indexOf(leagueId);

            if (indexOfLeague != -1) {
                selectedLeaguesBySport = update(selectedLeaguesBySport, {[sportId]: {$splice: [[indexOfLeague, 1]]}});
            }
            if (leaguesForSport.length == 1) {
                selectedLeaguesBySport = update(selectedLeaguesBySport, {$unset: [sportId]});
                const indexOfSport = sportsOrder.indexOf(sportId);
                sportsOrder = update(sportsOrder, {$splice: [[indexOfSport, 1]]});
            }
        }

        return {...state, selectedLeaguesBySport, sportsOrder, outrights}
    },
    [FETCH_CATEGORIES]: {
        PENDING: (state, action) => {
            return {...state}
        },
        FAILURE: (state, {payload: {error}}) => {
            return {...state, isPending: false, error};
        },
        SUCCESS: (state, {payload: {categories}}) => {
            return {...state, isPending: false, categories};
        }
    },
    [ADD_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        return {...state, selectedLeagues: [...state.selectedLeagues, leagueId]};
    },
    [REMOVE_LEAGUE_BY_ID]: (state, {payload: {leagueId}}) => {
        return {...state, selectedLeagues: removeLeagueById(state.selectedLeagues, leagueId)};
    },
    [CHANGE_SPORT_ID]: (state, {payload: {prevSortId}}) => {
        return {...state, selectedLeagues: [], prevSortId};
    }

}, initialState);
