import styled from 'styled-components';
const S = {};
import {Input} from 'global/styles/styles.js';

S.Datepicker = styled(Input)`
    display: flex;
    border: none;

    &.has-error{
        border: .08rem solid red!important;
        border-radius: .42rem!important;
    }
`;

export default S;