import React from 'react';
import _get from 'lodash/get';
import S from 'StyledTvBetHistoryTransactionItem.js';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect, useHistory} from 'react-router-dom';
import {formatDate} from 'helpers/datesHelper.js';
import {translation} from 'helpers/utilsHelper';
import transactionTypesEnum from 'transactionTypes.enum.js';

const TvBetHistoryTransactionItem = ({
    transaction,
    history
}) => {
    const {transactionId, regDate, stake, confirmedReturn, transactionType} = transaction;

    const getTransactionTypeClass = (transactionType) => {

        let resultText = '';

        switch(transactionType){
            case 3940:
                resultText = 'won';
                break;
            case 3941:
                resultText = 'cancel';
                break;
            case 3942:
                resultText = 'jackpot';
                break;
            case 3945:
                resultText = 'placed';
                break;
            case 3946:
                resultText = 'tax';
                break;
            default:
                resultText = '';
                break;
        }

        return resultText;
    };

    const redirectToTransactionDetails = (e,transactionId) => {
        e.stopPropagation();
        history.push('/konto/historia-kuponow/'+transactionId);
    }

    const transactionStatusClass = getTransactionTypeClass(transactionType);

    return ( transactionType !== 3943 && transactionType !== 3948 &&
        <S.TvBetHistoryItemWrapper className="bet-history-item-wrapper" onClick={(e) => redirectToTransactionDetails(e, transactionId)}>

            <S.TvBetHistoryItem className="bet-history-item">

                <div className={`transaction-status ${transactionStatusClass}`}>
                    <S.StatusIcon className={transactionStatusClass}></S.StatusIcon>
                </div>

                <div className="transaction-type">
                    <span>{translation(`account_tvBetHistory_${transactionType}`)}</span>
                </div>


                <div className="transaction-date">
                    <span>{formatDate(regDate, 'HH:mm')}</span>
                </div>

                <div className="transaction-icon">
                    <div className="tvBet">
                        {translation('account_betHistory_types_tvbet')}
                    </div>
                </div>

                {transactionType === transactionTypesEnum.TV_BET_WON?
                    (
                        <>
                            <div className={`transaction-amount ${transactionStatusClass}`}>
                                {translation('account_tvBetHistory_emptySign')}
                            </div>
                            <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                                {confirmedReturn && confirmedReturn.toFixed(2)}&nbsp;{translation('common_pln')}
                            </div>
                        </>
                    )
                    :
                    (
                    transactionType == transactionTypesEnum.TV_BET_CANCEL?
                        (
                            <>
                                <div className={`transaction-amount ${transactionStatusClass}`}>
                                    {translation('account_tvBetHistory_emptySign')}
                                </div>
                                <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                                    {confirmedReturn && confirmedReturn.toFixed(2)}&nbsp;{translation('common_pln')}
                                </div>
                            </>
                        )
                        :
                        (
                            <>
                            <div className={`transaction-amount ${transactionStatusClass}`}>
                                {stake && stake.toFixed(2)}&nbsp;{translation('common_pln')}
                            </div>
                            <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                                {translation('account_tvBetHistory_emptySign')}
                            </div>
                            </>
                        )
                    )
                }
            </S.TvBetHistoryItem>

        </S.TvBetHistoryItemWrapper>
    );
};


export default withRouter(TvBetHistoryTransactionItem);
