import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.BetHistoryItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 4.33rem;
`;

S.BetHistoryItem = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    padding-bottom: 2px;
    & > div{
            display: flex;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            font-weight: 600;
            text-align: left;
            padding: .67rem;
            min-height: 3.33rem;
            color: #000;
            align-items: center;
            background-color: ${({theme:{$accountProfileBetHistoryTransactionLabelBg}})=> $accountProfileBetHistoryTransactionLabelBg};

            &:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            &:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
           
           &.transaction-amount-cashout{
                flex: .3 1 0;
                font-size: 1.33rem;

                &.lost {
                color: ${({theme:{$accountProfileBetHistoryTransactionLostLabelColor}})=> $accountProfileBetHistoryTransactionLostLabelColor};
                }
                &.won {
                color: ${({theme:{$accountProfileBetHistoryTransactionWonLabelColor}})=> $accountProfileBetHistoryTransactionWonLabelColor};
                }
           }
              
        &.transaction-date-cashout,
        &.transaction-date{
            flex: .2 1 0;
            font-size: 12px;
        }
        
        &.transaction-stake-cashout,
        &.transaction-stake{
            flex: 1 1 0;
        }
        &.transaction-win{
            flex: 1 1 0;
        }
        &.transaction-confirmed-win{
            flex: .3 1 0;
            font-size: 1.33rem;
            &.lost {
                color: ${({theme:{$accountProfileBetHistoryTransactionLostLabelColor}})=> $accountProfileBetHistoryTransactionLostLabelColor};
            }
            &.won {
                color: ${({theme:{$accountProfileBetHistoryTransactionWonLabelColor}})=> $accountProfileBetHistoryTransactionWonLabelColor};
            }
        }
        &.transaction-bonus{
            flex: 1 1 0;
        }
        &.transaction-cashback{
            flex: 2 1 0;
        }
        &.transaction-type{
            flex: .55 1 0;
            font-size: 1.33rem;
        }
        &.transaction-cashout{
            flex: .6 1 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            
            & > button{
                background: #166931;
                text-transform: uppercase;
                border-radius: .33rem;
                border: 0;
                color: #fff;
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 400;
                font-size: .85rem;
                padding: .15rem 1rem;
                height: 1.8rem;
            }
        }
        &.transaction-rebet{
            flex: 1 1 0;
            display: flex;
            align-items: center;
            justify-content: center;
        
            & button{
                vertical-align: middle;
                color: #fff;
                background-image: url(/images/svg/rebet.svg);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 2.25rem;
                padding: .15rem 1.75rem;
                margin-top: 0;
                background-color: #00a651;
                font-weight: 400;
                font-size: .85rem;
                height: 1.8rem;
                border: none;
                border-radius: 4px;
            }
        
        }
        &.transaction-status{
            width: 4rem;
            border-right: none;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
              
              & button{
                    border: none;
                    font-weight: 700;
                    vertical-align: middle;
                    display: block;
                    width: 25%;
                    background: 0 0;
                    color: #fff;
                    padding: 0;
                    height: auto;
                    margin-top: 0;
                    font-size: 1.25rem;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    
                    & > span{
                        font-size: 12px;
                        font-weight: 400;
                    }
                    
              }
        
        }
        
    }
`;

// S.ErrorModal = styled.div`
//      width: 100%;
// `;

// S.ErrorModalTitle = styled.h4`
//     color: red;
//     text-transform: uppercase;
//     font-size: 1.83rem;
//     padding: 0;
//     font-weight: 800;
//     font-style: italic;
//     float: none!important;
//     text-align: center;
//     margin-bottom: 3.42rem;
// `;

// S.ErrorModalMessage = styled.p`
//     text-align: center;
//     color: #000;
//     margin: 0;
//     font-size: 1.2rem;
//     letter-spacing: .2px;
//     font-weight: 400;
// `;

S.StatusIcon = styled.div`
    &.lost,
    &.won,
    &.placed,
    &.cancel,
    &.cashout {
        height: 2rem;
        width: 2rem;
        border-radius: 20px;
        background: none;
    }
    &.lost {
        background-image: url(/images/svg/lost-24px.svg);
    }
    &.won {
        background-image: url(/images/svg/win-24px.svg);
    }
    &.placed {
        background-image: url(/images/svg/placed-24px.svg);
        width: 1.7rem;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.cashout {
        background-image: url(/images/svg/cashout-24px.svg);
    }
    &.cancel {
        background-image: url(/images/svg/cancel-24px.svg);
        width: 1.7rem;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.cashback {
        width: 24px;
        height: 24px;
        background-image: url(/images/svg/cashback_icon.svg);
        background-size: contain;
    }
`;

S.CashoutIcon = styled.div`
    /* &.availableCashout {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        width: 4.8rem;
        border-radius: 3px;
        color: ${({theme:{$accountProfileBetHistoryCashoutBttnColor}})=> $accountProfileBetHistoryCashoutBttnColor};
        background-color: ${({theme:{$accountProfileBetHistoryCashoutBttnBackground}})=> $accountProfileBetHistoryCashoutBttnBackground};
        line-height: 1;
        cursor: pointer;
    } */
    &.cashouted {
        display: flex;
        align-items: center;
        height: 2.5rem;
        width: 2.5rem;
    }
    &.uncashouted{
        height: 2.5rem;
        width: 2.5rem;
    }
    &.cashouted {
        color: ${({theme:{$accountProfileBetHistoryTransactionCashoutedInfoColor}})=> $accountProfileBetHistoryTransactionCashoutedInfoColor};
    }
    &.uncashouted {
        background-image: url(/images/cashout-green.png);
    }
`;

export default S;