import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import StyledAccount from '../../Account/fuksiarz/StyledAccount';
import {rem} from 'polished';
const VisuallyHidden = ({theme:{visuallyHidden}}) => visuallyHidden();
const S = {};

const baseFont = `12px`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.1);
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
`;

S.MainHeader = styled.div`
    width: 100%;
    display: inline-block;
    height: 2rem;
    line-height: 2rem;
    font-weight: bold;
    font-size: ${rem('22px', baseFont)};
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    text-align: center;
    &.on-top {
        z-index: 101;
        position: relative;
    }
`;
S.VipRejectBanner = styled(StyledAccount.VipCtaBanner)`
flex-wrap:wrap`;
S.VipRejectButton = styled(StyledAccount.VipCtaButton)``;
S.PersonalDataContainer = styled.div`
    width: 100%;
    display: inline-block;
`;

S.AccountEditProfileForm = styled(Form)`
    width: 100%;
    position: relative;
`;

S.CoverForm = styled.div`
    background-color: ${({theme: {$accountProfileFormCoverBg}}) => $accountProfileFormCoverBg};
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    z-index: 100;
    width: 100%;
`;

S.Row = styled.div``;

S.Label = styled.label`
    margin-bottom: 0px;
    text-align: left;
    font-size: ${rem('14px', baseFont)};
    font-weight: 400;
    display: inline;
    color: ${({theme: {$accountProfileInputLabel}}) => $accountProfileInputLabel};
    width: 35%;
    line-height: 3.5rem;
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: ${({theme: {$accountProfileInput}}) => $accountProfileInput}!important;
    display: inline-block;
    font-size: ${rem('14px', baseFont)};
    font-weight: 500;
    border: none;
    border: 1px solid ${({theme: {$accountProfileInputBorder}}) => $accountProfileInputBorder};
    background-color: ${({theme: {$accountProfileInputBackground}}) => $accountProfileInputBackground};
    height: ${rem('42px', baseFont)};
    padding-left: ${rem('10px', baseFont)};
    border-radius: 3px;
    opacity: 1;
    margin: auto;
    width: 65%;
    &[disabled]{
        cursor: not-allowed;
        opacity: 0.7;
    }
    &.has-error{
        border: .08rem solid red;
        border-radius: .42rem;
    }
    &.is-valid {

    }
`;

S.SubmitBtn = styled(Button)`
    font-size: ${rem('14px', baseFont)};
    background-color: ${({theme:{$accountProfileSubmitButtonBackgroundColor}})=> $accountProfileSubmitButtonBackgroundColor};
    color: ${({theme:{$accountProfileSubmitButtonColor}})=> $accountProfileSubmitButtonColor};
    cursor: pointer;
    width: 29%;
    height: ${rem('42px', baseFont)};
    line-height: ${rem('42px', baseFont)};
    text-align: center;
    border-radius: 5px;
    text-transform: uppercase;
    z-index: 101;
    position: relative;
    &:disabled {
        cursor: not-allowed;
        background-color: ${({theme:{$accountProfileSubmitButtonDisabledBackgroundColor}})=> $accountProfileSubmitButtonDisabledBackgroundColor};
        opacity: 0.54;
    }
`;

S.Error = styled.div`
    color: ${({theme:{$accountProfileHasErrorsColor}})=> $accountProfileHasErrorsColor};
    float: right;
    text-align: center;
    width: 65%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px .85rem;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: ${({theme:{$accountProfileHasErrorsColor}})=> $accountProfileHasErrorsColor};
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
`;

S.SuccessModal = styled.div`
     width: 100%;
`;

S.SuccessModalTitle = styled.h4`
    color: #2d9ef5;
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    font-style: italic;
    float: none!important;
    text-align: center;
    margin-bottom: 3.42rem;
`;

S.SuccessModalMessage = styled.p`
    text-align: center;
    color: #000;
    margin: 0;
    font-size: 2rem;
    letter-spacing: .2px;
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    height: ${rem('59px', baseFont)};
    position: relative;
    margin-bottom: 1rem;

    &.with-checkbox{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 0rem;
        height: 18px;

        /* &.giif, 
        &.pep {
          margin: auto;
          width: 18px;
          height: 18px;
        } */
        
         ${S.Input}{
            visibility: hidden;
            position: absolute!important;
            height: 18px;
            width: 18px;
            padding: 0!important;
            border: 0!important;
            overflow: hidden;
            z-index: 0;
            left: 0px;
            top: 0px;
            cursor: pointer;
            margin-top: 0px;
         }
    }

`;

S.IconValid = styled.div`
    background-image: url(/images/svg/account/green-check.svg);
    background-repeat: no-repeat;
    vertical-align: middle;
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 1.5rem;
    height: 1.5rem;
`;

S.SelectWrapper = styled.div`
    height: ${rem('59px', baseFont)};
    position: relative;
    margin-bottom: 1rem;
    display: flex;

    & select {
        display: block;
        width: 65%;
        border-radius: 3px;
        height: ${rem('42px', baseFont)};
        border: 1px solid ${({theme: {$accountProfileInputBorder}}) => $accountProfileInputBorder};
        color: ${({theme: {$accountProfileInput}}) => $accountProfileInput};
        padding-left: ${rem('10px', baseFont)};
        padding-right: ${rem('14px', baseFont)};
        font-size: ${rem('14px', baseFont)};

        &[disabled]{
            cursor: not-allowed;
        }
    }
`;



S.DatePickerWrapper = styled.div`
    height: ${rem('59px', baseFont)};
    position: relative;
    margin-bottom: 1rem;
    display: block;

    & .react-datepicker-wrapper {
        width: 65%;
    }

    ${StyledDatepicker.Datepicker}{
        box-shadow: none;
        color: ${({theme: {$accountProfileInput}}) => $accountProfileInput}!important;
        display: inline-block;
        width: 100%;
        font-size: ${rem('14px', baseFont)};
        font-weight: 500;
        border: none;
        border: 1px solid ${({theme: {$accountProfileInputBorder}}) => $accountProfileInputBorder};
        background-color: ${({theme: {$accountProfileInputBackground}}) => $accountProfileInputBackground};
        height: ${rem('42px', baseFont)};
        padding-left: ${rem('10px', baseFont)};
        border-radius: 3px;
        opacity: 1;

        &[disabled]{
            cursor: not-allowed;
            opacity: 0.7;
        }

        &.has-error{
            border: .08rem solid red!important;
            border-radius: .42rem!important;
        }

        &.is-valid { 

        }
}
`;

S.AccountSubscriptionsContainer = styled.div`
    position: relative;
    width: 100%;
    display: inline-block;
    margin-bottom: ${rem('40px', baseFont)};
    z-index: 101;
    position: relative;
`;

S.AccountDocumentsContainer = styled.div`
    width: 100%;
    display: inline-block;
    padding-top: ${rem('24px', baseFont)};
`;

S.AccountPaymentsContainer = styled.div`
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-top: ${rem('18px', baseFont)};
    padding-bottom: ${rem('60px', baseFont)};
`;

S.ChangePasswordLinkContainer = styled.div`
    width: 100%;
    text-align: right;
`;

S.ChangePasswordLink = styled(Button)`
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: ${rem('14px', baseFont)};
    font-weight: 600;
    color: ${({theme: {$accountUserSimpleLinkColor}}) => $accountUserSimpleLinkColor};
    z-index: 101;
    position: relative;
    &.is-full-activated-account {
        background-color: ${({theme: {$accountUserSimpleLinkBgColor}}) => $accountUserSimpleLinkBgColor};
    }
`;

S.CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
    margin: auto;
`;

S.CheckboxGroup = styled.div``;

S.LabelCheckbox = styled.label`
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    font-size: ${rem('12px', baseFont)};
    margin-bottom: 0px!important;
`;

S.FakeCheckbox = styled.span`
    cursor: pointer;
    z-index: 3;
    background-color: ${({theme: {$accountProfileCheckboxBackgroundColor}}) => $accountProfileCheckboxBackgroundColor};
    margin-right: ${rem('10px', baseFont)};
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    border-radius: 3px;

    &:before {
        color: ${({theme: {$accountProfileCheckboxSignColor}}) => $accountProfileCheckboxSignColor};
        content: "\\002713";
        position: absolute;
        display: none;
        left: 0.30rem;
        top: 0.05rem;
    }
`;

S.CheckboxText = styled.span`
    font-size: ${rem('12px', baseFont)}; 
    color: ${({theme: {$accountProfileInputLabel}}) => $accountProfileInputLabel};
    font-weight: normal;
    line-height: 1;
`;

S.VerificationStatusInfo = styled.div` 
    display: flex;
    flex-direction:column;
    width: 100%;
    text-align: center;
    z-index: 101;
    position: relative;
`;

S.VerificationStatusInfo_text = styled.div`
    color: ${({theme:{$accountProfileVerificationStatusColor}})=> $accountProfileVerificationStatusColor};
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    padding-top: ${rem('20px', baseFont)};
    padding-bottom: ${rem('20px', baseFont)};

    &.negative {
        background-color: ${({theme:{$accountProfileVerificationStatusNegativeBgColor}})=> $accountProfileVerificationStatusNegativeBgColor};
    }
    &.positive {
        background-color: ${({theme:{$accountProfileVerificationStatusPossitiveBgColor}})=> $accountProfileVerificationStatusPossitiveBgColor};
    }
`;

 
S.UploadDocumentError = styled(S.Error)`
    font-size: 1rem;
    text-align: center;
`;

S.UploadDocumentSuccessIcon = styled.i`
    margin: auto;
    margin-bottom: ${rem('8px', baseFont)};
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-image: url(/images/svg/account/green-check.svg);
    background-size: 100% 100%;
    width: ${rem('22px', baseFont)};
    height: ${rem('17px', baseFont)};
`;

S.InfoModal = styled.div`
     width: 100%;
     color: ${({theme:{$accountProfileModalColor}})=> $accountProfileModalColor};
`;

S.InfoModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  padding: .75rem 4.75rem;
  font-size: 1.5rem;
`;

S.InfoModalMessage = styled.p`   
    text-align: center;
    margin: 0;
    font-weight: 400;
`;

S.CheckMark = styled.span`
    position: absolute;
    left: 3px;
    top: 0;
    font-weight: bold;
    color: ${({theme: {$accountProfileCheckboxSignColor}}) => $accountProfileCheckboxSignColor};
`;

S.AccountStatementsContainer = styled.div`
    display: flex;
    text-align: center;
    padding-top: ${rem('24px', baseFont)};
    padding-bottom: ${rem('24px', baseFont)};
    flex-direction: column;
`;

S.AccountStatementCol = styled.div`
    width: 50%;
    display: inline-block;

    &.with-cover {
        background-color: ${({theme: {$accountProfileFormCoverBg}}) => $accountProfileFormCoverBg};
        opacity: 0.5;
        z-index: 100;
        pointer-events: none;
    }
`;

S.AccountStatementRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

S.SowWrapper = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    & label{
      font-size: 1rem;
    }
`;

S.SavedSowWrapper = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
`;

S.SavedSow = styled.span`
    font-size: 1rem;
    font-weight: bold;
`;

S.SelectInput = styled.input`
    margin-left: 0.5rem;
    
    &[type=checkbox]:checked{
      background-color: ${({theme: {$accountProfileCheckboxBackgroundColor}}) => $accountProfileCheckboxBackgroundColor};
    }
`;

S.SelectLabel = styled.label`
    margin-left: 0.5rem;
    white-space: nowrap;
`;

S.AccountStatementPep = styled.div`
    width: 100%;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
`;

S.RadioButtons = styled.div`
  display: flex;
  width: 30%;
  margin: auto;
`;

S.RadioButton = styled.label`
  display: flex;
  align-items: center;
  margin: auto;
`;

S.RadioButtonText = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-size:  ${rem('12px', baseFont)};
  line-height: 1;
  font-weight: bold;
`;

S.RadioButtonControlIndicator = styled.div`
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #D32F2F;
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: ${rem('10px', baseFont)};
  flex-shrink: 0;
  cursor: pointer;
  
  &:before{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #fff;
    width: 6px;
    height: 6px;
    display: none;
    border-radius: 50%;
  }
`;

S.RadioButtonInput = styled(Input)`
   ${VisuallyHidden};
  
   &:checked,
   &.active{
     & + ${S.RadioButtonControlIndicator}{
      background-color: #D32F2F;
      &:before{
         display: block;
      }
   }
   }
`;

S.AccountStatementErrorMessage = styled.div`
    width: 100%;
    text-align: center;
    color: ${({theme:{$accountProfileHasErrorsColor}})=> $accountProfileHasErrorsColor};
    font-size: 1.2rem;
    margin-top: 1rem;
`;

// Subscription/consent modal

S.SubscriptionModalButton = styled(Button)`
    width:  ${rem('142px', baseFont)};
    min-height:  ${rem('42px', baseFont)};
    border-radius: 3px;
    color: #C62828;
    background-color: #fff;
    border: 1px solid #C62828;
`;


export default S;