import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withRouter } from 'react-router-dom';
import S from 'StyledAccountCashback.js';
import Loader from 'Loader.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import classNames from 'classnames';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import {changeDateByType, changeActiveTab, fetchCashbackByFilter, setCurrentPage, changeActiveFilterBtn} from 'accountCashbackActions.js';
import {loadCustomerOffersBalances, withdrawCustomerOfferBalance} from 'offersActions.js';
import ModalOpacity from 'ModalOpacity.js';
import {CSSTransition} from 'react-transition-group';
import { getTransactionById } from 'accountBetHistoryActions.js';

class AccountCashback extends Component {

    durationAnimation = 3000; 

    state = {
        showCashbackPlusWithdrawModal: false, 
        cashbackAmountWithdrawed: null,
        cashbackWithdrawStep: 1,
        customerBalanceSaved: null,
        isSubmitBtnActive:true,
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    updateCashbackAmountWithdrawed = (amount) => {
        this.setState({cashbackAmountWithdrawed: amount});
    };

    updateCustomerBalanceSaved = (amount) => {
        this.setState({customerBalanceSaved: amount});
    };

    updateCashbackWithdrawStep = (step) => {
        this.setState({cashbackWithdrawStep: step});
    };

    componentDidMount = async() => {
        const { loadCustomerOffersBalances, fetchCashbackByFilter, endDate, startDate, status, currentPage, limit} = this.props;
        try {
            await Promise.all([
                loadCustomerOffersBalances(),
                fetchCashbackByFilter({
                    status,
                    endDate,
                    startDate,
                    currentPage,
                    limit
                })
            ]);

        } catch (error) {
            console.log('fetchCashbackByFilter:' + error);
        }
    };

    handleChangeStart = (date) => {
        const {changeDateByType, fetchCashbackByFilter, endDate, status, currentPage, limit} = this.props;
        const startDate = new Date(date);
        const params = {
			status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('startDate', startDate);
        fetchCashbackByFilter(params);
    };

    handleChangeEnd = (date) => {
        const {changeDateByType, fetchCashbackByFilter, startDate, status, currentPage, limit} = this.props;
        const endDate = new Date(date);
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        changeDateByType('endDate', endDate);
        fetchCashbackByFilter(params);
    };

    redirectToTransactionDetails = async (e, transactionId) => {
        const { history, transactionsList, getTransactionById} = this.props;
        const hasTransaction = transactionsList.some(transaction => transaction.transactionId === transactionId);
        e.stopPropagation();
        if (!hasTransaction) {
            await getTransactionById(transactionId);
        }
        history.push(`/konto/historia-kuponow/${transactionId}`);
    };

    onChangeFilter = (id) => {

        const {changeActiveFilterBtn, changeDateByType, fetchCashbackByFilter, startDate, endDate, status, currentPage, limit} = this.props;
        const params = {
            status,
            endDate,
            startDate,
            currentPage,
            limit
        };

        const newStartDate = new Date();

        const newEndDate = new Date();
        newEndDate.setDate(newEndDate.getDate() + 1);
       
        if (id == '1month') {
            newStartDate.setDate(newStartDate.getDate() - 30);
            params.startDate = newStartDate;
        } else if (id == '24h') {
            newStartDate.setDate(newStartDate.getDate() - 1);
            params.startDate = newStartDate;
        }

        changeActiveFilterBtn(id);
        changeDateByType('startDate', newStartDate);
        changeDateByType('endDate', newEndDate);

        fetchCashbackByFilter(params);
    };

    goPage = async(currentPage) => {
        const {fetchCashbackByFilter, startDate, endDate, limit, status, setCurrentPage} = this.props;
        const offset = currentPage * limit;
        const params = {
            status,
            endDate,
            startDate,
            currentPage: offset,
            limit
        };
        try {
            await fetchCashbackByFilter(params);
            setCurrentPage(currentPage);
        } catch (error) {
            console.log('goPage:'+error);
        }
    };

    getCashbackBalanceAmount = (list, currencyCode) => {
        const offer = list.find((o)=>o.identifier == process.env.CASHBACK_PLUS_OFFER_IDENTIFIER);
        let balance = '0.00';
        if ( offer ){
            balance = formatMoney(offer.customerBalance);
        }
        return balance + currencyCode;
    };

    getOfferIdDefinitionForCashback = () => {
        const { offersBalances } = this.props;
        const offer = offersBalances.find((o)=>o.identifier == process.env.CASHBACK_PLUS_OFFER_IDENTIFIER);
        let offerCashback = {};
        if ( offer ) {
            offerCashback = {offerDefinitionId: offer.offerIdDefinition, customerBalance: offer.customerBalance};
        }
        return offerCashback;
    };

    handleCashbackWithdrawSubmit = async (event) => {
        event.preventDefault();
        this.setState(prevState=>({isSubmitBtnActive: !prevState.isSubmitBtnActive}))
        const { withdrawCustomerOfferBalance, balance } = this.props;

        const offerCashback = this.getOfferIdDefinitionForCashback();
            try {
                this.updateCustomerBalanceSaved(balance);
                const amount = await withdrawCustomerOfferBalance(offerCashback);
                if (amount){
                    this.updateCashbackAmountWithdrawed(amount);
                    this.toggleModal('showCashbackPlusWithdrawModal');
                    setTimeout(() => this.updateCashbackWithdrawStep(2), this.durationAnimation);
                }
                 this.setState(prevState=>({isSubmitBtnActive: !prevState.isSubmitBtnActive}))
             } catch (error) {
                 console.log(error);
                 this.setState(prevState=>({isSubmitBtnActive: !prevState.isSubmitBtnActive}))
             }
    };

    isWithdrawDisabled = () => {
        let isWithdrawDisabled = false;
        const {offerDefinitionId, customerBalance} = this.getOfferIdDefinitionForCashback();
        if (!offerDefinitionId || !customerBalance){
            isWithdrawDisabled = true;
        }
 
        return isWithdrawDisabled;
    };

    render() {
        const {isPending, error, startDate, endDate, status, data, currentPage, limit, currencyCode, offersBalances, filterBtnSelected} = this.props;
        const {showCashbackPlusWithdrawModal, cashbackAmountWithdrawed, customerBalanceSaved, cashbackWithdrawStep} = this.state;

        return (
            <S.AccountCashback>

                <ModalOpacity isOpen={showCashbackPlusWithdrawModal}
                            toggleOpen={this.toggleModal.bind(this, 'showCashbackPlusWithdrawModal')}
                            showHeader={false}
                            showHeaderIcon={false}
                            showHeaderTitle={false}
                            showCloseIcon={false}
                            padding="0px"
                            width="350px"
                            preventCloseOnOverlay={true}>

                   <S.AccountCashbackWithdrawModal>
                        {cashbackWithdrawStep == 1 &&
                            <S.AccountCashbackWithdrawModalStep1>
                                <S.AccountCashbackWithdrawModalText> {translation('account_cashback')} </S.AccountCashbackWithdrawModalText>
                                <CSSTransition in={showCashbackPlusWithdrawModal} timeout={5000} className="modal-text-animation" classNames="modal-text-animation" unmountOnExit>
                                    <S.AccountCashbackWithdrawModalText> {cashbackAmountWithdrawed && formatMoney(cashbackAmountWithdrawed)}{translation('common_pln')} </S.AccountCashbackWithdrawModalText>
                                </CSSTransition>
                                <S.AccountCashbackWithdrawModalText> {translation('account_cashback')} </S.AccountCashbackWithdrawModalText>
                                <CSSTransition in={showCashbackPlusWithdrawModal} timeout={5000} className="modal-text-animation" classNames="modal-text-animation" unmountOnExit>
                                    <S.AccountCashbackWithdrawModalText> {customerBalanceSaved && formatMoney(customerBalanceSaved)}{translation('common_pln')} </S.AccountCashbackWithdrawModalText>
                                </CSSTransition>
                                <S.AccountCashbackMoney></S.AccountCashbackMoney>
                            </S.AccountCashbackWithdrawModalStep1>
                        }
                        {cashbackWithdrawStep == 2 &&
                            <S.AccountCashbackWithdrawModalStep2>
                                <S.AccountCashbackWithdrawModalText> {translation('account_cashbackSuccess')} </S.AccountCashbackWithdrawModalText>
                                <S.AccountCashbackWithdrawModalTextSmall> {translation('account_cashbackPaid')} </S.AccountCashbackWithdrawModalTextSmall>
                                <S.AccountCashbackWithdrawModalTextSmall> {cashbackAmountWithdrawed && formatMoney(cashbackAmountWithdrawed)}{translation('common_pln')} </S.AccountCashbackWithdrawModalTextSmall>
                                <S.AccountCashbackWithdrawModalTextSmall> {translation('account_cashback')} </S.AccountCashbackWithdrawModalTextSmall>
                                <S.AccountCashbackSuccessIcon></S.AccountCashbackSuccessIcon>
                                <S.AccountCashbackWithdrawModalText> {translation('account_cashbackAccount')} </S.AccountCashbackWithdrawModalText>
                                <S.AccountCashbackWithdrawModalText> {customerBalanceSaved && formatMoney(customerBalanceSaved + cashbackAmountWithdrawed)}{translation('common_pln')} </S.AccountCashbackWithdrawModalText>
                                <S.AccountCashbackWithdrawModalButton onClick={this.toggleModal.bind(this,'showCashbackPlusWithdrawModal')}> 
                                    {translation('account_cashbackPlayNow')} 
                                </S.AccountCashbackWithdrawModalButton>
                            </S.AccountCashbackWithdrawModalStep2>
                        }
                   </S.AccountCashbackWithdrawModal>

                </ModalOpacity>

                <S.AccountCashbackWithdraw>

                    <S.AccountCashbackWrapper onSubmit={this.handleCashbackWithdrawSubmit}>

                        <S.BalanceLabel>{translation('account_cashbackBalanceLabel')}</S.BalanceLabel>
                        <S.BalanceAmount>
                            <S.Amount> {this.getCashbackBalanceAmount(offersBalances, translation('common_pln'))} </S.Amount>
                        </S.BalanceAmount>
                        
                        <S.WithdrawButton disabled={this.isWithdrawDisabled()} data-active={!this.state.isSubmitBtnActive} type="submit">{translation('account_withdrawButton')}</S.WithdrawButton>
                        
                        {error && <S.AccountCashbackMessage>{translation(error.message)}</S.AccountCashbackMessage>}

                    </S.AccountCashbackWrapper>

                </S.AccountCashbackWithdraw>

                <S.AccountCashbackList>

                <S.CashbackFiltersLabel>{translation('cashback_history')}</S.CashbackFiltersLabel>    

                <S.CashbackFilters className="wallet-filters">

                    <S.CashbackFiltersDates className="wallet-filters-dates">

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                            <Datepicker
                                selected={startDate}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeStart}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                        <S.DateWrapper className="date-wrapper">
                            <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                            <Datepicker
                                selected={endDate}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="yyyy-MM-dd"
                                cb={this.handleChangeEnd}
                            />
                            <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
                        </S.DateWrapper>

                    </S.CashbackFiltersDates>

                    <S.CashbackFiltersBtns className="cashback-btns-wrapper">
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, '24h')}
                            className={classNames({active: (filterBtnSelected == '24h')})}>{translation('account_cashback24h')}</S.FilterBtn>
                        <S.FilterBtn
                            onClick={this.onChangeFilter.bind(this, '1month')}
                            className={classNames({active: (filterBtnSelected == '1month')})}>{translation('account_cashback1month')}</S.FilterBtn>
                    </S.CashbackFiltersBtns>


                    </S.CashbackFilters>

                    <S.CashbackList className="cashback-list">
                    {isPending ?
                        <Loader/>
                        :
                        (
                            <>
                            <S.Table className="cashback-table">

                                <S.Head>
                                    <S.Row className="row">
                                        <S.Th>{translation('account_cashback_betSlipId')}</S.Th>
                                        <S.Th>{translation('account_cashback_createTime')}</S.Th>
                                        <S.Th>{translation('account_cashback_offerName')}</S.Th>
                                        <S.Th>{translation('account_cashback_stake')}</S.Th>
                                        <S.Th>{translation('account_cashback_paidValue')}</S.Th>
                                    </S.Row>
                                </S.Head>

                                <S.Body>

                                {_map(data?.[status], ({ betSlipIdEncrypted, createTime, offerId, descriptionKey, paidValue=0, nameKey, offerName, baseValue}, index) => {
                                    const rowClazz = ((index+1) % 2) ? 'row odd':'row even';
                                    return (
                                        <S.Row className={ rowClazz } key={offerId}>
                                            <S.Td className='betSlipId' onClick={(e)=>this.redirectToTransactionDetails(e, betSlipIdEncrypted)}>{betSlipIdEncrypted}</S.Td>
                                            <S.Td>{formatDate(createTime, 'yyyy-MM-dd HH:mm')}</S.Td>
                                            <S.Td>{offerName}</S.Td>
                                            <S.Td>{formatMoney(baseValue)} {translation('common_pln')}</S.Td>
                                            <S.Td>{formatMoney(paidValue)} {translation('common_pln')}</S.Td>
                                        </S.Row>
                                    )
                                })}

                                {!_size(data[status]) && (<S.Row className="no-result"><S.Td colSpan="5">{translation('account_cashback_notFound')}</S.Td></S.Row>)}

                                </S.Body>

                            </S.Table>

                            {_size(data[status])>0 && (<Pagination currentPage={currentPage} limit={limit} data={data[status]} cb={this.goPage}/>)}

                            </>
                        )
                    }
                    </S.CashbackList>

                </S.AccountCashbackList>

            </S.AccountCashback>
        );
    }
}

const mapStateToProps = ({
    AccountCashback:{isPending, startDate, endDate, status, data, currentPage, limit, filterBtnSelected},
    AccountBetHistory:{transactionsList},
    Auth:{user:{currencyCode, balance}},
    Offers:{offersBalances, error},
    }, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        status,
        data,
        currentPage,
        limit,
        currencyCode,
        balance,
        offersBalances,
        filterBtnSelected,
        transactionsList,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeActiveFilterBtn: bindActionCreators(changeActiveFilterBtn, dispatch),
        changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
        changeDateByType: bindActionCreators(changeDateByType, dispatch),
        fetchCashbackByFilter: bindActionCreators(fetchCashbackByFilter, dispatch),
        getTransactionById: bindActionCreators(getTransactionById, dispatch),
        loadCustomerOffersBalances: bindActionCreators(loadCustomerOffersBalances, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        withdrawCustomerOfferBalance: bindActionCreators(withdrawCustomerOfferBalance, dispatch),
        dispatch
    }
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountCashback));