import styled from 'styled-components';
import {rem} from 'polished';
import {Form, Input, Button} from 'global/styles/styles.js';

const SubmitBtnBgColor = ({theme: {$loginFormSubmitBtnBgColor}}) => `${$loginFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme: {$loginFormSubmitBtnColor}}) => `${$loginFormSubmitBtnColor}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;
const FwRegular = ({theme: {$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.LoginForm = styled(Form)`
    display: flex;
    flex-direction: column;
    max-width: 33.33rem;
    margin: auto;
`;

S.Row = styled.div`
  margin-bottom: 1rem;

   ${({theme: {mqZoomLarge}}) => mqZoomLarge}{
      width: 10.66rem;
    }
`;

S.Label = styled.label`
    pointer-events: none;
    position: absolute;
    top: ${rem('12px', baseFont)};
    right: ${rem('15px', baseFont)};
    color: #000 !important;
    font-size:  ${rem('10px', baseFont)};
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;
S.Error = styled.div`
    position:absolute;
    top:50%;
    left:20px;
    transform:translateY(-50%);
    pointer-events:none;
    color: #C62828;
`;

S.Input = styled(Input)`
    font-family: ${FontFamily};
    box-shadow: none;
    color: #000;
    font-size:  ${rem('12px', baseFont)};
    padding: 0 ${rem('60px', baseFont)} 0 ${rem('16px', baseFont)};
    background-color: #fff;
    border: 2px solid #ECEFF1;
    border-radius: 3px;
    display: block;
    width: 100%;
    height: ${rem('37px', baseFont)};
    
     &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0rem 83.33rem #fff inset;
                }
     }
     &:focus,&:active{
      & ~ ${S.Error}{
        display:none;
      }
     }

     ${({theme: {mqZoomLarge}}) => mqZoomLarge}{
      font-size: 0.9rem;
    }

    &.has-error {
      border: 2px solid #C62828;
    }
    
    ::placeholder {
      font-weight: bold;
      color: #727070de;
    }
    
`;

S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('37px', baseFont)};
    font-size: ${rem('14px', baseFont)};
    font-weight: ${FwSemibold};
    padding: 0;
    width: ${rem('111px', baseFont)};
    color: ${SubmitBtnColor};
    cursor: pointer;
    border: 2px solid #C62828;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    width: 100%;
    
    &:hover{
        color: #fff;
        background-color: #CE0006;
    }

    ${({theme: {mqZoomLarge}}) => mqZoomLarge}{
      width: 6.25rem;
      font-size: 0.9rem;
    }
`;

S.VisiblePassword = styled.div`
    background-image: url(/images/svg/visible-password.svg);
    background-repeat: no-repeat;
    width: 28px;
    height: 18px;
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    cursor: pointer;
` 

S.RememberMe = styled.label`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  ${({theme: {mqZoomLarge}}) => mqZoomLarge}{
    margin:  0 ${rem('17px', baseFont)} 0 11.33rem;
  }
`;

S.RememberMeText = styled.div`
  font-size: ${rem('10px', baseFont)};
  color: #000;
  font-weight: normal;
`;

S.RememberMeChbox = styled(Input)`
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding:0 !important;
  border:0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  &:checked + ${S.RememberMeFakeChbox}{

    &:before{
     background-color: #C62828;
     position: absolute;
     content: '\\002714';
     color: #fff;
     font-size: ${rem('10px', baseFont)};
     padding-left: 4px;
     padding-right: 4px;
     padding-top: 1px;
    }
  }
`;

S.RememberMeFakeChbox = styled.div`
  position: relative;
  margin-right: ${rem('8px', baseFont)};
  width: ${rem('19px', baseFont)};
  height: ${rem('19px', baseFont)};
  border: 2px solid #ECEFF1;
  border-radius: 3px;
  cursor: pointer;
`;

S.ForgetPasswordLink = styled.div`
    font-weight: 400;
    font-size: ${rem('10px', baseFont)};
    line-height: 1.33rem;
    display: block;
    text-align: center;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;
    width: 100%;
    color: #000;
`;

S.SubmissionError = styled.div`
    margin: 0;
    width: 100%;
    color: #C62828;
    font-size: 1rem;
    text-align: left;
    padding: 0;
    margin-bottom: 0.5rem;
    &+${S.RememberMe}{
      max-height: ${rem('16px',baseFont)};
      margin-left:0;
    }
`;

S.LoginFormTop = styled.div`
  display: flex;
  flex-direction: column;
  padding:  ${rem('12px', baseFont)};
  padding-bottom: 0;
  margin-bottom: ${rem('4px', baseFont)};
`;

S.LoginFormBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding:  ${rem('30px', baseFont)};
  padding-top: 0;
`;

S.RegisterUserRow = styled.div`
    display: flex;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #000;
`;

S.RegisterUserQuestion = styled.div`
  font-weight: normal;
`;

S.RegisterUserLink = styled.div`
  font-weight: 800;
  text-decoration: underline;
  cursor: pointer;
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('24px', baseFont)} 0;
  position: relative;
`;

S.Title = styled.h2`
   margin: 0;
   color: #000000DE;
    font-size: ${rem('26px', baseFont)};
    padding: 0;
    font-weight: ${FwRegular};
    float: none!important;
    text-align: center;
    text-tranaform: uppercase;
`;

S.LogoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 16.66rem;
  margin: ${rem('15px', baseFont)} auto;
  ${({ theme: { mqForMac } }) => mqForMac}{
    width: 7%;
  }
`;

S.Link = styled.a`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

S.Logo = styled.img`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

S.RegisterButton = styled.button`
  position: absolute;
  top: ${rem('15px', baseFont)};
  right: ${rem('15px', baseFont)};
  height: 3.5rem;
  font-weight: 600;
  padding: 0 ${rem('15px', baseFont)};
  border: 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 1.17rem;
`

export default S;