import styled, {keyframes} from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import { Button } from 'global/styles/styles.js';
import { rem } from 'polished';
import Modal from 'Modal.js';
const baseFont = '12px';
const S = {};

const duration = 1000;
const durationAnimation = 3;

S.AccountCashback = styled.div`
    width: 100%;
    padding: 2.33rem 0 2.33rem 0;
    color: ${({theme:{$accountProfileColor}})=> $accountProfileColor};
`;

S.AccountCashbackWithdraw = styled.div`
    width: 100%;
    padding: 3rem 0 3rem 0;
    text-align: center;
	background-color: ${({theme:{$accountProfileCashbackWithdrawBg}})=> $accountProfileCashbackWithdrawBg};
`;

S.AccountCashbackWrapper = styled.form`
    width: 100%;
    height: ${rem('212px', baseFont)};
`;

S.BalanceLabel = styled.div` 
    width: 100%;
    font-size: ${rem('36px', baseFont)};
`;

S.BalanceAmount = styled.div`
    width: 100%;
    font-size: ${rem('47px', baseFont)};
    margin-bottom: 2rem;
`;

S.Amount = styled.span`
    border-bottom: 2px solid ${({theme:{$accountProfileCashbackButtonBg}})=> $accountProfileCashbackButtonBg};
    font-weight: 600;
`;

S.AccountCashbackMessage = styled.div`
    font-size: ${rem('20px', baseFont)};
`; 

S.WithdrawButton = styled(Button)`
    width: ${rem('295px', baseFont)};
    font-size: ${rem('16px', baseFont)};
    text-transform: uppercase;
    height: ${rem('42px', baseFont)};
    line-height: ${rem('42px', baseFont)};
    border-radius: 2px;
    cursor: pointer;
    background-color: ${({theme:{$accountProfileCashbackButtonBg}})=> $accountProfileCashbackButtonBg};
    color: ${({theme:{$accountProfileCashbackButtonColor}})=> $accountProfileCashbackButtonColor};
    
    &[disabled]{
        visibility: hidden;
    }
    &[data-active=true]{
        pointer-events: none;
    }
`;

S.AccountCashbackList = styled.div`
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 3rem auto;
`;

S.CashbackFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;

S.CashbackFiltersLabel = styled.div`
    font-size: ${rem('28px', baseFont)};
    margin: 2rem 0 2rem 0;
    width: 100%;
    text-align: center;
`;

S.CashbackFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 24.75rem;
    height: 3.5rem;
    display: flex;
    position: relative;
    border: 1px solid ${({theme:{$accountProfileBetHistoryDatepickerBorder}})=> $accountProfileBetHistoryDatepickerBorder};
    border-radius: 3px;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: 100%;
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 1.17rem;
        font-weight: 400;
        width: 100%;
        border-radius: 3px;
        color: ${({theme:{$accountProfileBonusDatepickerLabelColor}})=> $accountProfileBonusDatepickerLabelColor};
    }
`;
S.DateLabel = styled.div`
    display: flex;
    font-weight: 400;
    color: ${({theme:{$accountProfileBonusDatepickerLabelColor}})=> $accountProfileBonusDatepickerLabelColor};
    background-color: ${({theme:{$accountProfileBetHistoryDatepickerLabelBg}})=> $accountProfileBetHistoryDatepickerLabelBg};
    padding-left: 1.08rem;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0.25rem;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.CashbackFiltersBtns = styled.div`
    display: flex;
    width: 50%;
    margin-left: 10px;
    & > button{
        width: calc(50% - 15px);
        margin-right: 15px;
        &:last-of-type{
            margin-right: 0;
        }
    }
`;
S.FilterBtn = styled(Button)`
    margin-top: 0;
    background-color: ${({theme:{$accountProfileCashbackButtonColor}})=> $accountProfileCashbackButtonColor};
    color: ${({theme:{$accountProfileColor}})=> $accountProfileColor};
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    border-radius: 4px;
    display: block;
    justify-content: center;
    align-items: center;
    position: relative
    white-space: nowrap;

    &.active {
        background-color: ${({theme:{$accountProfileCashbackButtonBg}})=> $accountProfileCashbackButtonBg};
        color: ${({theme:{$accountProfileCashbackButtonColor}})=> $accountProfileCashbackButtonColor};
    }
`;

S.Table = styled.table`
    width: 70%;
    min-width: ${rem('600px', baseFont)};
    margin: auto;
    margin-bottom: 20px;
    margin-top: 10px;
`;

S.Head = styled.thead`
    border-bottom: 2px solid ${({theme:{$accountProfileCashbackButtonBg}})=> $accountProfileCashbackButtonBg};
`;

S.Body = styled.tbody``;

S.Row = styled.tr`
    border-top: 0;

    &.odd {
        background-color: ${({theme:{$accountProfileCashbackWithdrawBg}})=> $accountProfileCashbackWithdrawBg};
    }

    &.even {
        background-color: ${({theme:{$accountProfileCashbackEvenRowBg}})=> $accountProfileCashbackEvenRowBg};
    }
    
    &:before,
    &:after{
     display: none !important;
    }
    
    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
      
        & > td{
            text-align: center;
        }
    }
`;

S.Td = styled.td`
    padding: 0 1.25rem;
    line-height: 4rem!important;
    font-size: 1rem;
    vertical-align: top;
    text-align: center;
    &.betSlipId {
        cursor: pointer;
    }
`;

S.Th = styled.th`
    padding: .4rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.42857143;
    vertical-align: bottom;
    border-top: 0;
    text-align: center;
`;

S.CashbackList = styled.div``;

const ModalWithOpacityStyles = styled(Modal)`
  
  &.modal-opacity-enter {
    opacity: 0.01;
  }
  &.modal-opacity-enter-active {
   transition: opacity ${duration}ms;
   opacity: 1;
  }
  &.modal-opacity-exit {
    opacity: 1;
  }
  &.modal-opacity-exit-active {
    opacity: 0.01;
    transition: opacity ${duration}ms ease-in;
  }

  & ${S.ModalWrapper}{
    height: 600px;
  }
`;

S.AccountCashbackWithdrawModal = styled.div`
    background-color: rgb(255, 255, 255);
    background-image: url('/images/cashout-modal-bg.png');
    background-size: cover;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 0.25em 1em 0px;
    color: white;
    height: ${rem('600px', baseFont)};
    width: ${rem('350px', baseFont)};
    padding: 3rem 0 3rem 0;
    text-align: center;
`;

S.AccountCashbackWithdrawModalStep1 = styled.div`
`;

S.AccountCashbackWithdrawModalStep2 = styled.div`
`;

const blurAnimation = keyframes`
  0% {
    filter: blur(0px);
  }
  50% {
    filter: blur(5px);
  }
  100% {
    filter: blur(5px);
  }
`;

S.AccountCashbackWithdrawModalText = styled.div`
    text-transform: uppercase;
    font-size: ${rem('42px', baseFont)};

    &.modal-text-animation {
        animation-direction: normal;
        animation-duration: ${durationAnimation}s;
        animation-iteration-count: 1;
        animation-name: ${blurAnimation};
        animation-timing-function: ease-in;
    }

`;

S.AccountCashbackWithdrawModalTextSmall = styled.div`
    text-transform: uppercase;
    font-size: ${rem('25px', baseFont)};
`;

S.AccountCashbackWithdrawModalButton = styled.div`
    text-transform: uppercase;
    color: #000;
    background-color: #fff;
    font-size: ${rem('20px', baseFont)};
    margin: 10px 30px 0px 30px;
    cursor: pointer;
`;

S.AccountCashbackSuccessIcon = styled.div`
    background: url(/images/svg/check-mark-icon.svg);
    background-position: center;
    margin: auto;
    width: 140px;
    height: 140px;
    margin-top: 30px;
    margin-bottom: 20px;
`;

S.AccountCashbackMoney = styled.div`
    background: url(/images/money.gif);
    background-position: center;
    width: 200px;
    height: 324px;
    margin-top: 0px;
    margin-left: 80px;
`;


export default S;