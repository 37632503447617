import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {loadVeryficationStatus} from 'accountActions.js';
import {
    loadPersonalData,
    updatePersonalData,
    updateCustomerConsents,
    loadDocumentTypes,
    loadDocumentScans,
    uploadDocumentData,
    updateCustomerConsentDefinition,
    updateAllCustomerConsentDefinition,
    loadCustomerConsentsByTagAndNames,
    getCustomerPromotions,
} from 'customerActions.js';
import {fetchFirstDepositInfo} from 'accountWalletActions.js';
import {changePassword} from 'authActions.js';
// import consentContainers from 'consentContainers.enum.js';
import Loader from 'Loader.js';
import AccountEditProfileForm from 'AccountEditProfileForm.js';
import S from 'StyledAccountEditProfile.js';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { bind } from 'core-js/fn/function';

class AccountEditProfile extends Component {

    componentDidMount = async() => {
      this.refreshData();
    };

    refreshData = async() => {
        const {loadPersonalData,  loadDocumentScans , loadVeryficationStatus, loadCustomerConsentsByTagAndNames} = this.props; 
  
        try { 
            await Promise.all([
                loadPersonalData(),
                loadDocumentScans(),
                loadVeryficationStatus(),
                loadCustomerConsentsByTagAndNames({
                    tag: 'personal',
                    names: 'CUSTOMER_EDIT_PROFILE_FORM',
                }),
            ]);
        } catch (error) {
            console.log('loadPersonalData->', error);
        }
    };

    updateUserDataOnSubmit = async(consentData, values, documentsData, giifData, pepData) => {
        const {updatePersonalData, uploadDocumentData, updateAllCustomerConsentDefinition, updateCustomerConsentDefinition} = this.props;
        const promises = [ updateAllCustomerConsentDefinition(consentData) ];
        if (values && documentsData) {
            promises.push(updatePersonalData(values));
        }
        if ( giifData && pepData){
            promises.push(updateCustomerConsentDefinition(giifData));
            promises.push(updateCustomerConsentDefinition(pepData));
        }
        try {
            const result = await Promise.all(promises);
            let responseInfo = {message:"account_editProfileform_succesfullUpdate", code: 200};
            let dataUpdated = true;
            result.forEach(response => {
                if(response.code !== 200) {
                    responseInfo = {message:`error_${response.code}`, code: response.code};
                    dataUpdated = false;
                } else {
                    if (!response.data){
                        dataUpdated = false;
                    }
                }
            });

            if (values && documentsData && dataUpdated){
                uploadDocumentData(documentsData);
            }

            return responseInfo;
        } catch (error) {
            throw error;
        }
    };

    changePasswordOnSubmit = async(values) => {
        const {changePassword} = this.props;

        try {
            const result = await changePassword(values);
            return result;
        } catch (error) {
            throw error;
        }
    };

    documentTypeDefaultSelected = (userData) => {
        
        if(!_isEmpty(userData)){
            const nationality = _get(userData,['nationality']);
            return nationality === 'PL'? "id" : "passport";
	    }    	
    };

    selectNewestDocument = (documentScans) => {
        let sortedDocuments = documentScans.sort((a,b) => { return b.id - a.id});
        return sortedDocuments[0];
    };

    render() {
        const {
            userData,
            isPending,
            userId,
            customerConsents,
            documentScans,
            formData,
            depositInfo,
            accountData,
            groups,
            getCustomerPromotions,
            shouldVipCTABeNotDisplayed
        } = this.props;
        const initialValues = {};
        let pep, giif, sow, receiveEmail, receiveSms, receiveTradeInformation, vipMarketing  = null;

        if (!_isEmpty(userData) && !_isEmpty(customerConsents)) {
            const allEditProfileConsents = _get(customerConsents, 'CUSTOMER_EDIT_PROFILE_FORM');
            giif = _find(allEditProfileConsents,{definitionContainerName: 'GIIF'});
            initialValues['giif'] = _get(giif, 'isAccepted') || false;
            pep = _find(allEditProfileConsents, {definitionContainerName: 'PEP'});
            initialValues['pep'] = _get(pep, 'isAccepted') || false;
            sow = _filter(allEditProfileConsents, ({definitionContainerName}) => definitionContainerName === 'SOW');
            initialValues['sow'] = sow || [];
            receiveEmail = _find(allEditProfileConsents, {definitionContainerName: 'EMAIL'});
            initialValues['receiveEmail'] = _get(receiveEmail, 'isAccepted') || false;
            receiveSms = _find(allEditProfileConsents, {definitionContainerName: 'SMS'});
            initialValues['receiveSms'] = _get(receiveSms, 'isAccepted') || false;
            receiveTradeInformation = _find(allEditProfileConsents, {definitionContainerName: 'TRADE_INFORMATION'});
            initialValues['receiveTradeInformation'] = _get(receiveTradeInformation, 'isAccepted') || false;
            vipMarketing = _get(groups,'VIP_MARKETING');
            if (vipMarketing) {
                initialValues['vipMarketing'] = _get(vipMarketing, 'status') == 'ACCEPTED' ? true : false;
            }
            const dateOfBirth = _get(userData, 'dateOfBirth');
            initialValues['mobile'] = _get(userData, 'mobileNumber');
            initialValues['email'] = _get(userData, 'email');
            initialValues['lastName'] = _get(userData, 'lastName');
            initialValues['firstName'] = _get(userData, 'firstName');
            initialValues['dateOfBirth'] = (dateOfBirth ? new Date(dateOfBirth) : new Date(1));
            initialValues['postalCode'] = _get(userData, 'postalCode'); 
            initialValues['bankAccountNumber'] = _get(userData, 'bankAccountNumber'); 
            initialValues['address'] = _get(userData, 'address');
            initialValues['city'] = _get(userData, 'city');
            initialValues['country'] = _get(userData, 'countryCode');
            initialValues['personalIdentifier'] = _get(userData, 'personalIdentifier');
            initialValues['documentTypes'] = _get(userData, 'documentTypes', this.documentTypeDefaultSelected(userData));
            initialValues['nationality'] = accountData?.registrationDetails.nationality;

            if(!_isEmpty(documentScans) && (documentScans.length > 0)){
                let newestDocInfo = this.selectNewestDocument(documentScans);
                initialValues['documentTypes'] = (newestDocInfo['type'] === undefined)? this.documentTypeDefaultSelected(userData) : newestDocInfo['type'];
                initialValues[`${newestDocInfo['type']}`] = newestDocInfo['number'];           
            }
        }

        return (
            <S.AccountEditProfile>
                {(isPending) ?
                    <Loader/>
                    :
                    (<AccountEditProfileForm initialValues={initialValues}
                                             pep = {pep}
                                             giif = {giif}
                                             sow={sow}
                                             receiveEmail={receiveEmail}
                                             receiveSms={receiveSms}
                                             receiveTradeInformation={receiveTradeInformation}
                                             vipMarketing={vipMarketing}
                                             userData={userData}
                                             userId={userId}
                                             documentScans={documentScans}
                                             formData = {formData}
                                             accountData = {accountData}
                                             depositInfo={depositInfo}
                                             updateUserDataOnSubmit={this.updateUserDataOnSubmit}
                                             refreshData={this.refreshData}
                                             changePasswordOnSubmit={this.changePasswordOnSubmit}
                                             shouldVipCTABeNotDisplayed ={shouldVipCTABeNotDisplayed}
                                             getCustomerPromotions={getCustomerPromotions}
                                             />)
                }
            </S.AccountEditProfile>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {
        Customer:{
            userData,
            customerConsents,
            documentScans,
            error,
            isPending,
            shouldVipCTABeNotDisplayed
        },
        Auth:{user:{userId,groups}}} = state;
    const {AccountWallet:{data}} = state;
    const {form:{ accountEditProfile}} = state;
    return {
        userData,
        customerConsents,
        documentScans,
        shouldVipCTABeNotDisplayed,
        depositInfo: data,
        formData: accountEditProfile,
        error,
        isPending,
        userId,
        groups
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadDocumentTypes: bindActionCreators(loadDocumentTypes, dispatch),
        loadDocumentScans: bindActionCreators(loadDocumentScans, dispatch),
        loadPersonalData: bindActionCreators(loadPersonalData, dispatch),
        loadVeryficationStatus: bindActionCreators(loadVeryficationStatus, dispatch),
        updatePersonalData: bindActionCreators(updatePersonalData, dispatch),
        getCustomerPromotions:bindActionCreators(getCustomerPromotions,dispatch),
        changePassword: bindActionCreators(changePassword, dispatch),
        uploadDocumentData: bindActionCreators(uploadDocumentData, dispatch),
        fetchFirstDepositInfo: bindActionCreators(fetchFirstDepositInfo, dispatch),
        updateCustomerConsents: bindActionCreators(updateCustomerConsents, dispatch),
        loadCustomerConsentsByTagAndNames: bindActionCreators(loadCustomerConsentsByTagAndNames, dispatch),
        updateCustomerConsentDefinition: bindActionCreators(updateCustomerConsentDefinition, dispatch),
        updateAllCustomerConsentDefinition: bindActionCreators(updateAllCustomerConsentDefinition, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountEditProfile);
