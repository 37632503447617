import React, { useState, useMemo, useEffect, useRef } from 'react';
import Box from 'react-styled-box';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js';
import S from 'StyledAccountEditProfileForm.js';
import { Field, reduxForm, SubmissionError, touch, change} from 'redux-form';
import { translation, redirect } from 'helpers/utilsHelper.js';
import { isValueUnique, isBankAccountNumberUniqueForCustomer, updateCustomerConsentDefinition, loadCustomerConsentsByTagAndNames } from 'customerActions.js';
import { proccessFrontError } from 'errorActions.js';
import classNames from 'classnames';
import _concat from 'lodash/concat';
import _pick from 'lodash/pick';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _isEmpty from 'lodash/isEmpty';
import _findKey from 'lodash/findKey';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import ChangePasswordForm from 'ChangePasswordForm.js';
import UploadDocument from 'UploadDocument.js';
import Datepicker from 'Datepicker.js';
import Loader from 'Loader.js';
import { subMonths } from 'date-fns/esm';
import CmsPage from 'CmsPage.js';

import accountStatus from 'accountStatus.enum.js';
import customerVerificationStatus from 'customerVerificationStatus.enum.js';
import documentTypesData from 'documentTypes.enum.js';
import Select, { components } from 'react-select';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.EDIT_ACCOUNT);
    return errors;
};

const asyncValidate = async (values, dispatch, props, field) => {
    const previousErrors = props.asyncErrors;
    if (field) {
        const fieldName = field;
        const fieldValue = values[field];
        let requestAction = null;
        switch (fieldName) {
            case 'bankAccountNumber':
                if (fieldValue) {
                    requestAction = isBankAccountNumberUniqueForCustomer.bind(null, fieldName, fieldValue);
                }
                break;
            default:
                const fName = (fieldName == 'id' || fieldName == 'passport')? 'document-number':fieldName;
                requestAction = isValueUnique.bind(null, fName, fieldValue);
                break;
        }

        try {
            const result = await dispatch(requestAction());
            if (previousErrors) {
                throw 'prevErrors';
            }

            if (fieldName == 'bankAccountNumber') {
                if (Array.isArray(result)){
                    for (i = 0; i<result.length; i++){
                        let error = result[i];
                        if (error.code == 4256){
                            throw translation('account_editProfileForm_bankAccountNumber_wrong');
                        } else {
                            console.log('validate bank acc number err');
                            console.log(error);
                        }
                    }
                }
                if (!result){
                    throw translation('account_editProfileForm_bankAccountNumber_isUsed');
                }
            }
            return result;
        } catch (err) {
            if (err === 'prevErrors') {
                throw previousErrors;
            } else {
                throw { [fieldName]: [translation(err)] };
            }
        }
    }
};

const renderField = ({
    input,
    label,
    type,
    widthBox,
    placeholder,
    markRejected,
    isDisabled,
    hideForActivatedAccount,
    meta: { touched, error, warning, valid, asyncValidating, initial }
}) => {

    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': (error || markRejected),
        'is-valid': (initial && valid)
    });

    widthBox = !widthBox ? '100%' : widthBox;

    return (
        <S.Row className={hideForActivatedAccount ? 'hidden' : ''}>
            <S.InputWrapper>
                <Box width={widthBox} style={{ margin: 'auto', position: 'relative' }}>
                    {label && <S.Label>{label}</S.Label>}
                    <S.Input  {...input} type={type} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                        style={input.name == 'bankAccountNumber' ? { width: '100%' } : {}} required="required"
                        placeholder={placeholder} className={inputClass} disabled={isDisabled} />
                    {valid && (!markRejected && <S.IconValid></S.IconValid>)}
                </Box>
                {touched && ((error && <S.Error style={input.name == 'bankAccountNumber' ? { width: '100%' } : {}}>{error[0]}</S.Error>) || (warning && <S.Error style={input.name == 'bankAccountNumber' ? { width: '100%' } : {}}>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const renderDatepicker = ({
    input,
    label,
    meta: { touched, error, warning, valid, initial }
}) => {

    const inputClass = classNames({
        'has-error': error,
        'is-valid': (initial && valid)
    });

    const val = input.value || initial;

    return (
        <S.Row>
            <S.DatePickerWrapper>
                <Box width="100%">
                    <S.Label>{label}</S.Label>
                    <Datepicker
                        disabled={true}
                        selected={val}
                        selectsStart
                        startDate={val}
                        endDate={val}
                        dateFormat="yyyy-MM-dd"
                        cb={(value) => {
                            input.onChange(value);
                            input.onBlur();
                        }}
                        onBlur={(date) => touch(date)}
                        minDate={subMonths(new Date(), 1836)}
                        maxDate={new Date()}
                        className={inputClass}
                    />
                    {valid && <S.IconValid></S.IconValid>}
                </Box>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.DatePickerWrapper>
        </S.Row>
    )
};

const renderSelect = ({
    input,
    label,
    options,
    hideForActivatedAccount,
    isDisabled,
    onChange,
    meta: { touched, error, warning, valid, initial }
}) => {

    const inputClass = classNames({
        'has-error': error,
        'is-valid': (initial && valid)
    });

    return (
        <S.Row className={hideForActivatedAccount ? 'hidden' : ''}>
            <S.SelectWrapper>
                <S.Label>{label}</S.Label>
                <select {...input} required="required" className={inputClass} disabled={isDisabled}>
                    {_map(options, ({ name, value }) => {
                        return (<option key={value} value={value} onChange={onChange}>{name}</option>)
                    })}
                </select>
                {isDisabled && <S.IconValid></S.IconValid>}
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.SelectWrapper>
        </S.Row>
    )
};

const renderUploadDocumentError = ({ meta: { submitFailed, error } }) => {
    return (
        <S.UploadDocumentError>
            {submitFailed && error &&
                <span className="error">{error}</span>
            }
        </S.UploadDocumentError>
    )
};

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    display: 'flex',
    backgroundColor: '#fff',
    color: '#000'
  }),

  menuList: (provided, state) => ({
    ...provided,
    height: '18rem'
  }),

  container: (provided, state) => ({
    ...provided,
    marginBottom: '1rem',
  }),

  control: (provided, state) => ({
    ...provided,
    marginTop: '1rem',
    border: 'none',
    borderColor: state.isFocused && '#D32F2F',
    boxShadow: state.isFocused ? '0 0 0 1px #D32F2F' : '0 0 0 1px #cfcfcf'
  }),

  multiValueRemove: (provided) => ({
    ...provided,
    display: 'none'
  })
}

const Option = props => {
  const {label} = props.data;
  
  return (

    <> 
      <components.Option {...props}>
        <S.FakeCheckbox>
            {props.isSelected && <S.CheckMark>&#10004;</S.CheckMark>}
        </S.FakeCheckbox>
        <S.SelectLabel>{label}</S.SelectLabel>
      </components.Option> 
    </>

  )

};

const renderSow = ({
  input,
  label,
  placeholder,
  sourceOfWealthList,
  handleSelectChange,
  savedSow,
  selectedSowList,
  meta: { touched, error, warning, valid, initial }}) => {

  const otherSow = _find(sourceOfWealthList, ({withCustomData}) => withCustomData)
  const savedValues = _map(savedSow, (sow) => {
    if(sow.definitionId == otherSow.value){
      return sow.customData
    } else{
      return sow.definitionName
    }
  })
  const isOtherSelected = otherSow && _find(selectedSowList, ({value}) => otherSow.value === value)

  return (
    _isEmpty(savedValues) ?
    ( 
      <S.SowWrapper>
        {label}
        {sourceOfWealthList &&
          <Select 
            name = {input.name}
            options={sourceOfWealthList}
            hideSelectedOptions={false}
            isMulti
            closeMenuOnSelect={false}
            components={{ Option }}
            styles={customSelectStyles}
            placeholder={placeholder}
            onChange={(value) => handleSelectChange(value, input)}
            onBlur={() => input.onBlur()}
          /> 
        }
        {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
        
        {isOtherSelected &&
          <Field
            name="sowOther"
            type="text"
            component={renderField}
            label={translation('account_editProfileForm_sowOtherLabel')}
            isDisabled={false}
          />
        }
      </S.SowWrapper>
    )
    :
    (
      <S.SavedSowWrapper>
        {translation('account_editProfileForm_savedSow')}
        <S.SavedSow>{savedValues.join(', ')}</S.SavedSow>
      </S.SavedSowWrapper>
    )
    
  );
}

const AccountEditProfileForm = ({
        userData,
        initialize,
        initialValues,
        pep,
        giif,
        sow,
        receiveEmail,
        receiveSms,
        receiveTradeInformation,
        vipMarketing,
        userId,
        changePasswordOnSubmit,
        updateUserDataOnSubmit,
        handleSubmit,
        pristine,
        submitting,
        refreshData,
        accountData,
        formData,
        documentScans,
        error,
        valid,
        change,
        dispatch,
        shouldVipCTABeNotDisplayed,
        getCustomerPromotions,
    }) => {
    const [showUpdateModal, toggleUpdateModal] = useToggle(false);
    const [showChangePasswordModal, toggleChangePasswordModal] = useToggle(false);
    const [showSubscriptionModal, toggleSubscriptionModal] = useToggle(false);
    const [subscriptionToChange, setSubscriptionToChange] = useState(null);
    const [infoModalUpdateMsg, setInfoModalUpdateMsg] = useState(null);
    const [allSubscriptionsChecked, setAllSubscriptionsChecked] = useState(null);
    const [showVipRejectModal,toggleVipRejectModal] = useToggle(false)
    const [showInfoStatusModal,toggleInfoStatusModal] = useToggle(false)
    const documentPageOneRef = useRef(null);
    const documentPageTwoRef = useRef(null);
    const [sourceOfWealthList, setSourceOfWealthList] = useState(null);
    const [selectedSowList, setSelectedSowList] = useState(null);
    const [savedSow, setSavedSow] = useState(null);

    const [isFullActivatedAccount, setIsFullActivatedAccount] = useState(false);             // ACCEPTED
    const [blockedForm, setBlockForm] = useState(false);                                     // AWAITING_ADMIN_APPROVAL, ACCEPTED
    const [isVerificationStatusRejected, setIsVerificationStatusRejected] = useState(false); // REJECTED 

    const subscriptionKeysArray = ['receiveEmail', 'receiveTradeInformation', 'receiveSms', 'vipMarketing']; // subscriptions keys
    const handleRejectModal = ()=>{ // temporary solution
            getCustomerPromotions(false).then(res=>{
                toggleVipRejectModal()
                toggleInfoStatusModal()
            }).catch(err=>{
                toggleVipRejectModal()
                toggleInfoStatusModal()
            })

    }
    useEffect(() => {

        (accountData?.verificationStatus == customerVerificationStatus.AWAITING_ADMIN_APPROVAL ||
            accountData?.verificationStatus == customerVerificationStatus.ACCEPTED) ? setBlockForm(true) : setBlockForm(false);
        (accountData?.verificationStatus == customerVerificationStatus.REJECTED) ? setIsVerificationStatusRejected(true) : setIsVerificationStatusRejected(false);

    }, [accountData])

    useEffect(() => {

        (accountData?.verificationStatus == customerVerificationStatus.ACCEPTED &&
            userData?.accountStatus == accountStatus.ACTIVE) ? setIsFullActivatedAccount(true) : setIsFullActivatedAccount(false);
    }, [accountData, userData])

    useEffect(() => {
        try {
            const subsciptionDataArray = subscriptionKeysArray.map(key => _get(formData?.values, key));
            const areUndefined = subsciptionDataArray.every(item => item === undefined);
            if (areUndefined) {
                throw(areUndefined);
            } else {
                const subscriptionsChecked = subsciptionDataArray.every(item => item === true);
                setAllSubscriptionsChecked(subscriptionsChecked);
            }
        }
        catch ({ message }) {
            setAllSubscriptionsChecked(null);
        }
    }, [formData])

    useEffect(() => {
      if(!_isEmpty(sow)){
        const filteredSow = _sortBy(sow, ['definitionId']);
        const sowOptions = _map(filteredSow, ({definitionId, definitionName, withCustomData}) => {
          const option = {};
          option['value'] = definitionId;
          option['label'] = definitionName;
          option['withCustomData'] = withCustomData;
          return option;
        });
        setSourceOfWealthList(sowOptions);
        const acceptedList = _filter(sow, ({isAccepted}) => isAccepted);
        setSavedSow(acceptedList);
      }
    }, [sow])

    const getEditedDocumentsIds = (documentType) => {
        let documentIdsArray = [];
        documentScans.map(scan => {
            if (scan.type === documentType) {
                documentIdsArray = [...documentIdsArray, String(scan.id)];
            }
        });
        return documentIdsArray;
    };

    const renderCheckbox = ({
        input,
        isDisabled,
        clazzName,
        required,
        checked,
        definitionId,
        meta: { touched, error, warning, valid, initial }
    }) => {
        clazzName = clazzName ? `with-checkbox ${clazzName}` : 'with-checkbox';


        const toggleCheckbox = async (event) => {
            if (input.name === "receiveSms" || input.name === "receiveEmail" || input.name === "receiveTradeInformation") {
                if (input.value) {
                    setSubscriptionToChange(
                        {name: input.name,
                            definitionId
                        });
                    toggleSubscriptionModal();
                } else {
                    try {
                        const consentChange = await dispatch(updateCustomerConsentDefinition({
                            definitionId,
                            accepted: true,
                        }));
                        change(input.name, true);
                    } catch (error) {
                        dispatch(proccessFrontError(error,'AccountEditProfileForm.toggleCheckbox()'));
                    }
                }
            } else if(input.name === "vipMarketing"){
                toggleVipRejectModal()
                input.onChange(event.target.checked);
            } else {
                input.onChange(event.target.checked);
            }
        }

        return (
            <S.Row>
                <S.InputWrapper className={clazzName}>
                    <S.Input {...input} value={input.value} checked={input.value} disabled={isDisabled} required={required}
                        onChange={event => toggleCheckbox(event)} type="checkbox" />
                    <S.FakeCheckbox>
                        {input.value && <S.CheckMark>&#10004;</S.CheckMark>}
                    </S.FakeCheckbox>
                    {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
                </S.InputWrapper>
            </S.Row>
        );
    };

    const handleSubscriptionConfirm = async () => {
        try {
            const consentChange = await dispatch(updateCustomerConsentDefinition({
                definitionId: subscriptionToChange.definitionId,
                accepted: false,
            }));
            change(subscriptionToChange.name, false);
            toggleSubscriptionModal();
        } catch (error) {
            dispatch(proccessFrontError(error,'AccountEditProfileForm.handleSubscriptionConfirm()'));
            toggleSubscriptionModal();
        }
    }

    const SubscriptionPanel = () => {
        const subscriptionCmsItemName = allSubscriptionsChecked ? 'consents-marked' : 'consents-unmarked';
        return (
            <>
                <S.MainHeader className="on-top">{translation('account_editProfileForm_consents')}</S.MainHeader>
                <S.AccountSubscriptionsContainer>
                    <S.CheckboxWrapper
                        as={Box}
                        justifyContent="space-between"
                        alignItems="center">
                        <S.CheckboxGroup>
                            <S.LabelCheckbox>
                                <Field
                                    component={renderCheckbox}
                                    name="receiveEmail"
                                    definitionId={receiveEmail?.definitionId}
                                    isDisabled={receiveEmail?.definitionId ? false: true}
                                />
                                <S.CheckboxText>
                                    {translation("account_editProfileForm_email")}
                                </S.CheckboxText>
                            </S.LabelCheckbox>
                        </S.CheckboxGroup>
                        <S.CheckboxGroup>
                            <S.LabelCheckbox>
                                <Field
                                    component={renderCheckbox}
                                    name="receiveSms"
                                    definitionId={receiveSms?.definitionId}
                                    isDisabled={receiveSms?.definitionId ? false: true}
                                />
                                <S.CheckboxText>
                                    {translation("account_editProfileForm_smsPhone")}
                                </S.CheckboxText>
                            </S.LabelCheckbox>
                        </S.CheckboxGroup>
                        {(vipMarketing?.status == 'ACCEPTED' || vipMarketing?.status == 'REJECTED') && (
                               <S.CheckboxGroup>
                               <S.LabelCheckbox>
                                   <Field
                                       component={renderCheckbox}
                                       name="vipMarketing"
                                       isDisabled={vipMarketing?.status == 'NOT_SET' ? true: false}
                                   />
                                   <S.CheckboxText>
                                       {translation("account_editProfileForm_vipmarketing")}
                                   </S.CheckboxText>
                               </S.LabelCheckbox>
                           </S.CheckboxGroup>
                        )}
                     
                    </S.CheckboxWrapper>
                    {allSubscriptionsChecked !== null && (() => {
                        return <CmsPage name={subscriptionCmsItemName} key={subscriptionCmsItemName} data-cmsname={subscriptionCmsItemName} isPlaces={true}/>
                    })()}
                </S.AccountSubscriptionsContainer>
            </>
        )
    }


    const onFormSubmit = async (values) => {
        try {

            // personal data
            let pickValues = ['address', 'city', 'postalCode', 'bankAccountNumber'];
            if (accountData.registrationDetails.nationality != 'PL') {
                pickValues = ['city', 'bankAccountNumber'];
            }

            const userInfoData = _pick(values, pickValues);

            const requestAction = isBankAccountNumberUniqueForCustomer.bind(null, null, userInfoData.bankAccountNumber);
            const resultValidateBankAccountNumber = await dispatch(requestAction());

            if (Array.isArray(resultValidateBankAccountNumber)){
                return;
            }
            if (!resultValidateBankAccountNumber){
                return;
            }
            

            let personalDataUpdateObject = {};

            if (userInfoData?.bankAccountNumber) {
                userInfoData.bankAccountNumber = userInfoData.bankAccountNumber.replace(/\s+/g, '');
            }

            Object.entries(userInfoData).map(([k, v], i) => {
                if (initialValues[k] !== userInfoData[k]) {
                    personalDataUpdateObject = {
                        ...personalDataUpdateObject,
                        [k]: userInfoData[k]
                    }
                }
            })

            const giifAccepted = _get(values, 'giif');
            const pepAccepted = _get(values, 'pep');
            const pepData = { definitionId: pep.definitionId, accepted: pepAccepted };
            const giifData = { definitionId: giif.definitionId, accepted: giifAccepted };

            // consents

            const subscriptionData = [];
            subscriptionKeysArray.map((consent) => {
                let consentDefId = eval(consent)?.definitionId;
                if(consentDefId){
                  let isConsentAccepted = _get(values, consent);
                  let newConsent = {definitionId: consentDefId, accepted: isConsentAccepted};
                  subscriptionData.push(newConsent);
                }
            });
            
            const sowList = _map(selectedSowList, ({value, withCustomData}) => {
              const sowObj = {
                definitionId: value,
                accepted: true,
              }
              if(withCustomData) sowObj['customData'] = _get(values, 'sowOther');
              return sowObj
            });
            const consentData = _concat(subscriptionData, sowList);

            //documents
            let selectedDocument = _get(values, 'documentTypes');
            let documentNumber = _get(values, selectedDocument);

            //gather images
            let photoSource = [documentPageOneRef.current, documentPageTwoRef.current];

            var documentsData = new FormData();
            [...photoSource].map((file, i) => {
                if (file !== null) {
                    documentsData.append([`file${i + 1}`], file);
                    //documentsData.append([`file${i+1}Name`], file.name);
                }
            });

            documentsData.append('documentType', selectedDocument);
            documentsData.append('documentNumber', documentNumber);

            if (getEditedDocumentsIds(selectedDocument).length > 0) {
                documentsData.append('documentIds', getEditedDocumentsIds(selectedDocument));
            }

            const param = blockedForm ? [consentData] :
                (accountData?.verificationStatus == customerVerificationStatus.TO_CHECK ?
                    [consentData, personalDataUpdateObject, documentsData, giifData, pepData, sowList] :
                    [consentData, personalDataUpdateObject, documentsData, sowList]);
                    
            const successMsg = await updateUserDataOnSubmit(...param);

            setInfoModalUpdateMsg(successMsg);

            await refreshData();
            toggleUpdateModal();
            app.router.reloadView();
        } catch ({ message }) {
            throw new SubmissionError({ _error: message });
        }
    };

    const handleSelectChange = (list, input) => {
      const selectedList = _map(list, ({label, value, withCustomData = false}) => {
          return {
            label, value, withCustomData
          }
      })
      input.onChange(selectedList)
      setSelectedSowList(selectedList)
    }

    const countries = useMemo(() => {
        const countries = app.service.Language.cachedDict.countries;
        const mainCountryCode = process.env.MAIN_COUNTRY_CODE;
        const options = _map(countries, (countryName, countryCode) => {
            const option = {};
            option['name'] = countryName;
            option['value'] = countryCode;
            return option;
        });
        return options;
    }, []);

    const documentTypesOptions = useMemo(() => {

        const options = _map(documentTypesData, ({ nameTranslation, name }) => {
            const option = {};

            option['name'] = translation(`${nameTranslation}`);
            option['value'] = name;
            return option;
        });
        return options;

    }, []);

    const verificationStatusInfo = () => {

        if (accountData?.verificationStatus) {

            switch (accountData?.verificationStatus) {
                case customerVerificationStatus.ACCEPTED:
                    return <S.VerificationStatusInfo_text className="positive">
                        {translation('account_editProfileForm_positiveVerification')}
                    </S.VerificationStatusInfo_text>
                case customerVerificationStatus.AWAITING_ADMIN_APPROVAL:
                    return <S.VerificationStatusInfo_text className="negative">
                        {translation('account_editProfileForm_awaitingVerification')}
                    </S.VerificationStatusInfo_text>
                case customerVerificationStatus.REJECTED:
                    return <S.VerificationStatusInfo_text className="negative">
                        {translation('account_editProfileForm_negativeVerification')}
                    </S.VerificationStatusInfo_text>
                case customerVerificationStatus.TO_CHECK:
                    return <S.VerificationStatusInfo_text className="negative">
                        {translation('account_editProfileForm_emptyPlaceholder')}
                    </S.VerificationStatusInfo_text>
            }

        } else {
            return <S.VerificationStatusInfo_text className="negative">
                {translation('account_editProfileForm_emptyPlaceholder')}
            </S.VerificationStatusInfo_text>
        }
    }

    const prepareDocumentSubField = (renderField) => {

        let selectedDocumentTypeId = (formData?.values && Object.entries(formData?.values).length > 0) ? formData?.values['documentTypes'] : "id";

        if (!selectedDocumentTypeId) {
            selectedDocumentTypeId = "id";
        }

        //   var docTypeName = documentTypesData[selectedDocumentTypeId]['name'];
        if (documentTypesData.hasOwnProperty(selectedDocumentTypeId)) {
            return <Field
                name={documentTypesData[selectedDocumentTypeId]['name']}
                type="text"
                component={renderField}
                markRejected={isVerificationStatusRejected}
                normalize={(value) => value.replace(/\s+/g, '')}
                label={translation(`${documentTypesData[selectedDocumentTypeId]['subFieldName']}`)}
                isDisabled={blockedForm}
            />
        } else {
            return;
        }

    }

    const uploadedDocumentData = (page, documentformData) => {
        page === 1 ? documentPageOneRef.current = documentformData : documentPageTwoRef.current = documentformData;
    }

    const generateUpdateProfileResult = () => {
        if (infoModalUpdateMsg['code'] !== 200) {
            return <S.InfoModal>
                <S.InfoModalTitle>{translation('account_editProfileForm_dataUpdate_error')}</S.InfoModalTitle>
                <S.InfoModalMessage>{translation(`account_editProfileForm_dataUpdate_${infoModalUpdateMsg['message']}`)}</S.InfoModalMessage>
            </S.InfoModal>
        } else {
            return <S.InfoModal>
                <S.InfoModalTitle>{translation('account_editProfileForm_dataUpdate_success')}</S.InfoModalTitle>
                <S.InfoModalMessage>{translation('account_editProfileForm_dataUpdate_succesfull')}</S.InfoModalMessage>
            </S.InfoModal>
        }
    }

    const isPristineCustom = () => {

        const userInfoData = _pick(formData?.values, ['address', 'city', 'postalCode', 'passport', 'bankAccountNumber', 'sow', 'id', 'pep', 'giif', 'documentTypes']);
        let isPristine = false;
        Object.entries(userInfoData).map(([k, v], i) => {
            const value = userInfoData[k];
            if(k === 'sow' && !_isEmpty(sourceOfWealthList) && _isEmpty(savedSow) && _isEmpty(selectedSowList)) {
              isPristine = true
            } else if(k === 'documentTypes'){
              if(value === 'passport' && documentPageOneRef.current === null){
                isPristine = true
              } else if(value === 'id' && (documentPageOneRef.current === null || documentPageTwoRef.current === null)){
                isPristine = true
              }
            } else if(!value){
              isPristine = true
            }
        });
        return isPristine;
    }

    const postalCodeFormat = (value) => {
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length <= 2) {
            return onlyNums;
        }

        return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
    }

    const bankAccountNumberFormat = (value) => {
        if (!value) {
            return '';
        }
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length <= 2) {
            return onlyNums;
        }
        if (onlyNums.length <= 6) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2)}`;
        }
        if (onlyNums.length <= 10) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6)}`;
        }
        if (onlyNums.length <= 14) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6, 10)} ${onlyNums.slice(10)}`;
        }
        if (onlyNums.length <= 18) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6, 10)} ${onlyNums.slice(10, 14)} ${onlyNums.slice(14)}`;
        }
        if (onlyNums.length <= 22) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6, 10)} ${onlyNums.slice(10, 14)} ${onlyNums.slice(14, 18)} ${onlyNums.slice(18)}`;
        }
        if (onlyNums.length <= 26) {
            return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6, 10)} ${onlyNums.slice(10, 14)} ${onlyNums.slice(14, 18)} ${onlyNums.slice(18, 22)} ${onlyNums.slice(22)}`;
        }

        return `${onlyNums.slice(0, 2)} ${onlyNums.slice(2, 6)} ${onlyNums.slice(6, 10)} ${onlyNums.slice(10, 14)} ${onlyNums.slice(14, 18)} ${onlyNums.slice(18, 22)} ${onlyNums.slice(22, 26)} ${onlyNums.slice(26)}`;
    }



    const pristineCustom = isPristineCustom();

    return (
        <>

            {submitting && (<S.Cover><Loader size="10" /></S.Cover>)}

            <S.AccountEditProfileForm method="post" onSubmit={handleSubmit(onFormSubmit)}>

                <ModalOpacity isOpen={showChangePasswordModal}
                    toggleOpen={toggleChangePasswordModal}
                    showHeaderIcon={false}
                    showHeaderTitle={true}
                    title={translation('account_editProfileForm_changePassword_formHeader')}
                    showCloseIcon={true}>

                    <ChangePasswordForm
                        changePasswordOnSubmit={changePasswordOnSubmit}
                        userId={userId}
                        data-test='account_editProfileForm_changePassword_submit' />

                </ModalOpacity>
                <ModalOpacity isOpen={showVipRejectModal}
                            toggleOpen={toggleVipRejectModal}
                            showHeader={false}
                            showHeaderIcon={false}
                            showHeaderTitle={false}
                            showCloseIcon={false}
                            padding="0px"
                            preventCloseOnOverlay={true}>
                                <S.VipRejectBanner isModal={true}>
                                    <CmsPage name="vip-marketing-modal-reject" isPlaces={true} />
                                    <S.VipRejectButton onClick={()=>{handleRejectModal()}}>{translation('account_vipmarketing_reject')}</S.VipRejectButton>
                                </S.VipRejectBanner>
                </ModalOpacity>
                <ModalOpacity 
                    isOpen={showInfoStatusModal} 
                    showCloseIcon={true} 
                    showHeader={false}
                    showHeaderIcon={false}
                    showHeaderTitle={false}
                    toggleOpen={toggleInfoStatusModal}
                    padding="0px">
                        <CmsPage 
                        name={shouldVipCTABeNotDisplayed || shouldVipCTABeNotDisplayed !== undefined? `vip-marketing-modal-success`:`vip-marketing-modal-failure`} 
                        isPlaces={true} />
                        <S.VipRejectButton onClick={()=>{redirect('/konto/edycja')}}>{translation('account_vipmarketing_close')}</S.VipRejectButton>

                </ModalOpacity>
                <ModalOpacity isOpen={showSubscriptionModal && subscriptionToChange ? true : false}
                    toggleOpen={toggleSubscriptionModal}
                    showHeaderIcon={false}
                    showHeaderTitle={false}
                    showCloseIcon={false}>
                        <Box flexDirection="column" justifyContent="center">
                            <CmsPage name='subscription-off-information' data-cmsname='subscription-off-information' isPlaces={true}/>
                            <Box justifyContent="space-evenly" margin="20px 0 0">
                                <S.SubscriptionModalButton
                                    data-translation='account_subscriptionOff_confirm'
                                    onClick={()=>handleSubscriptionConfirm()}
                                >
                                        {translation('account_subscriptionOff_confirm')}
                                </S.SubscriptionModalButton>
                                <S.SubscriptionModalButton
                                    data-translation='account_subscriptionOff_cancel'
                                    onClick={()=>{
                                        toggleSubscriptionModal();
                                    }}>
                                        {translation('account_subscriptionOff_cancel')}
                                </S.SubscriptionModalButton>
                            </Box>
                        </Box>
                </ModalOpacity>
                <ModalOpacity isOpen={showUpdateModal}
                    toggleOpen={toggleUpdateModal}
                    showHeaderIcon={false}
                    showHeaderTitle={false}
                    showCloseIcon={true}
                    headerPadding={"0rem"}>

                    {infoModalUpdateMsg !== null && generateUpdateProfileResult()}

                </ModalOpacity>

                <S.CoverForm className={blockedForm ? '' : 'hidden'}></S.CoverForm>
                <S.MainHeader>{translation('account_editProfileForm_personalData')}</S.MainHeader>

                <S.PersonalDataContainer className="personal-data-container">
                    <Box width="100%">
                        <Box flexDirection="column" width="33.3%" padding="0 40px 0 0">
                            <Box flexDirection="column" flexGrow={1} margin="0 0 20px 0">

                                <Field
                                    name="firstName"
                                    type="text"
                                    isDisabled={true}
                                    component={renderField}
                                    label={translation('account_editProfileForm_firstName')}
                                />

                                <Field
                                    name="dateOfBirth"
                                    type="text"
                                    component={renderDatepicker}
                                    label={translation('account_editProfileForm_dateOfBirth')}
                                />


                                <Field name="country"
                                    component={renderSelect}
                                    options={countries}
                                    isDisabled={true}
                                    hideForActivatedAccount={false}
                                    label={translation('account_editProfileForm_country')}
                                />

                                <Field name="nationality"
                                    type="text"
                                    component={renderField}
                                    hideForActivatedAccount={true}
                                />


                                <Field
                                    name="city"
                                    type="text"
                                    component={renderField}
                                    markRejected={isVerificationStatusRejected}
                                    isDisabled={blockedForm || accountData?.city}
                                    hideForActivatedAccount={false}
                                    label={translation('account_editProfileForm_city')}
                                />

                            </Box>
                        </Box>

                        <Box flexDirection="column" width="33.3%" padding="0 40px 0 0">
                            <Box flexGrow={1} flexDirection="column" margin="0 0 20px 0">
                                <Field
                                    name="lastName"
                                    type="text"
                                    isDisabled={true}
                                    component={renderField}
                                    label={translation('account_editProfileForm_lastName')}
                                />

                                {accountData?.registrationDetails.nationality == 'PL' && <Field
                                    name="personalIdentifier"
                                    type="text"
                                    isDisabled={true}
                                    component={renderField}
                                    label={translation('account_editProfileForm_pesel')}
                                />}

                                <Field
                                    name="address"
                                    type="text"
                                    component={renderField}
                                    markRejected={isVerificationStatusRejected && accountData?.registrationDetails.nationality == 'PL'}
                                    isDisabled={blockedForm || accountData?.registrationDetails.nationality != 'PL'}
                                    hideForActivatedAccount={false}
                                    label={translation('account_editProfileForm_address')}
                                />

                            </Box>
                        </Box>

                        <Box flexDirection="column" width="33.3%" padding="0 40px 0 0">
                            <Box flexGrow={1} flexDirection="column" margin="0 0 20px 0">

                                <Field
                                    name="email"
                                    type="text"
                                    isDisabled={true}
                                    component={renderField}
                                    label={translation('account_editProfileForm_email')}
                                />

                                <Field
                                    name="mobile"
                                    type="text"
                                    isDisabled={true}
                                    component={renderField}
                                    label={translation('account_editProfileForm_telNumber')}
                                />

                                <Field
                                    name="postalCode"
                                    type="text"
                                    component={renderField}
                                    normalize={postalCodeFormat}
                                    markRejected={isVerificationStatusRejected && accountData?.registrationDetails.nationality == 'PL'}
                                    isDisabled={blockedForm || accountData?.registrationDetails.nationality != 'PL'}
                                    hideForActivatedAccount={false}
                                    label={translation('account_editProfileForm_postalCode')}
                                />

                                <S.ChangePasswordLinkContainer>
                                    <S.ChangePasswordLink
                                        className={blockedForm ? 'is-full-activated-account' : ''}
                                        onClick={toggleChangePasswordModal}
                                        data-test='account_editProfileForm_changePassword'>
                                        {translation('account_editProfileForm_changePassword')}
                                    </S.ChangePasswordLink>
                                </S.ChangePasswordLinkContainer>

                            </Box>
                        </Box>

                    </Box>
                </S.PersonalDataContainer>

                {blockedForm && <SubscriptionPanel/>}

                <S.MainHeader>{translation('account_editProfileForm_documents')}</S.MainHeader>

                <S.AccountDocumentsContainer>

                    <Box flexDirection="row" margin="0 0 20px 0">
                        <Box flexDirection="column" width="33.33%">
                            <Field
                                name="documentTypes"
                                component={renderSelect}
                                options={documentTypesOptions}
                                label={translation('account_editProfileForm_documentTypes')}
                                disabledInitialOmitValidation={true}
                                isDisabled={blockedForm}
                                formData={formData}
                                onChange={()=> documentPageOneRef.current = null}
                            />
                            {prepareDocumentSubField(renderField)}
                        </Box>

                        <Box flexDirection="column" width="66.66%">
                            <UploadDocument uploadedDocumentData={uploadedDocumentData} />
                        </Box>
                    </Box>

                </S.AccountDocumentsContainer>

                <S.MainHeader>{translation('account_editProfileForm_statements')}</S.MainHeader>

                <S.AccountStatementsContainer>

                  <S.AccountStatementRow>

                    <S.AccountStatementCol className={blockedForm || isVerificationStatusRejected ? 'with-cover' : ''}>
                      <S.AccountStatementPep>{giif?.definitionName}</S.AccountStatementPep>
                      <S.LabelCheckbox>
                          <Field
                              component={renderCheckbox}
                              required="required"
                              isDisabled={blockedForm || isVerificationStatusRejected}
                              clazzName="giif"
                              name="giif"
                          />
                      </S.LabelCheckbox>    
                    </S.AccountStatementCol>

                    <S.AccountStatementCol className={blockedForm || isVerificationStatusRejected ? 'with-cover' : ''}>
                        <S.AccountStatementPep>{pep?.definitionName}</S.AccountStatementPep>
                        <S.LabelCheckbox>
                              <Field
                                component={renderCheckbox}
                                required="required"
                                isDisabled= { blockedForm || isVerificationStatusRejected }
                                clazzName="pep"
                                name="pep"
                            />
                        </S.LabelCheckbox>
                    </S.AccountStatementCol>

                  </S.AccountStatementRow>

                  <S.AccountStatementRow>
                    <Field
                      name="sow"
                      component={renderSow}
                      label={translation('account_editProfileForm_sow')}
                      placeholder={translation('account_editProfileForm_selectSow')}
                      sourceOfWealthList={sourceOfWealthList}
                      handleSelectChange={handleSelectChange}
                      savedSow={savedSow}
                      selectedSowList={selectedSowList}
                    />

                  </S.AccountStatementRow>

                </S.AccountStatementsContainer>

                <S.MainHeader>{translation('account_editProfileForm_payments')}</S.MainHeader>

                <S.AccountPaymentsContainer>

                    <Field name="bankAccountNumber"
                        widthBox="29%"
                        type="text"
                        component={renderField}
                        normalize={bankAccountNumberFormat}
                        format={bankAccountNumberFormat}
                        markRejected={false}
                        isDisabled={blockedForm || accountData?.bankAccountNumber}
                        placeholder={translation('account_editProfileForm_bankAccount')}>
                    </Field>

                    {!blockedForm && <SubscriptionPanel/>}
 
                    <S.SubmitBtn type="submit" disabled={blockedForm || pristineCustom || submitting || error || formData?.asyncErrors || formData?.syncErrors.bankAccountNumber}>
                        {!blockedForm ?
                            translation('account_editProfileForm_send')
                            :
                            translation('account_editProfileForm_save')
                        }

                    </S.SubmitBtn>

                    {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                </S.AccountPaymentsContainer>

                <Box flexDirection="row" justifyContent="center" css={{ marginBottom: '30px' }}>
                    <S.VerificationStatusInfo>
                        <Field name="documentTypeNeedUpload" component={renderUploadDocumentError} />
                        {verificationStatusInfo()}
                    </S.VerificationStatusInfo>
                </Box>

            </S.AccountEditProfileForm>
        </>
    );
};

export default reduxForm({
    form: 'accountEditProfile',
    validate,
    enableReinitialize: true,
    asyncValidate,
    asyncBlurFields: ['id','passport', 'bankAccountNumber']
})(AccountEditProfileForm);

