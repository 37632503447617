import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountCurrentBonus.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ProgressBarLine from 'ProgressBarLine.js';
import ModalOpacity from 'ModalOpacity.js';
import {
    fetchBonusListByFilter,
    setCurrentPage,
    manageBonus,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure
} from 'accountBonusActions.js';


class AccountCurrentBonus extends Component {

    state = {
        selectedOfferId: null,
        showConfirmationModal: false,
        showInfoModal: false,
        modalTitle: '',
        modalDescription: '',
        infoModalTitle: '',
        infoModalDescription: '',
        status: '',
        isPendingBonusAction: false
    };

    setSelectedOfferId = (selectedOfferId) => {
        this.setState((prevState) => {
            return {...prevState, selectedOfferId}
        });
    };

    setStatus = (status) => {
        this.setState((prevState) => {
            return {...prevState, status}
        });
    };

    setInfoModalTextElements = (infoModalTitle, infoModalDescription) => {
        this.setState((prevState) => {
            return {...prevState, infoModalTitle, infoModalDescription};
        });
    };

    setConfirmModalTextElements = (action) => {
        let modalTitle = '';
        let modalDescription = '';
        switch (action) {
            case 'activate':
                modalTitle = translation('account_bonus_activationTitle');
                modalDescription = translation('account_bonus_confirmActivate');
                break;
            case 'cancel':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmCancel');
                break;
            case 'reject':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmReject');
                break;
        }

        this.setState((prevState) => {
            return {...prevState, modalTitle, modalDescription};
        });
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    renderBonusActionConfirmation = (offerId, status) => {
        this.setStatus(status);
        this.setConfirmModalTextElements(status);
        this.setSelectedOfferId(offerId);
        this.toggleModal('showConfirmationModal');
    };

    componentDidMount = async() => {
        const {fetchBonusListPending, fetchBonusListByFilter, fetchBonusListFailure, fetchBonusListFulfilled, limit} = this.props;

        fetchBonusListPending();

        try {
            const data = await fetchBonusListByFilter({status: 'ACTIVE', limit: limit + 1});
            fetchBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountCurrentBonus:', error);
            fetchBonusListFailure(error)
        }

    };

    goPage = async(status, currentPage) => {
        const {fetchBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        currentPage = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage,
            status
        };

        try {
            await fetchBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    toggleBonusPendingStatus = () => {
        const {isPendingBonusAction} = this.state;
        this.setState((prevState) => {
            return {...prevState, isPendingBonusAction: !prevState['isPendingBonusAction']}
        });
    }

    bonusActionAccept = async(offerId, action) => {
        const {manageBonus} = this.props;
        this.toggleBonusPendingStatus();
        try {
            const result = await manageBonus(offerId, action);
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');
            this.setInfoModalTextElements(translation('account_bonus_action_success'), '');
        } catch (error) {
            this.toggleBonusPendingStatus();
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');
            this.setInfoModalTextElements(translation('account_bonus_action_error'), translation(error));
        }
    };

    render() {
        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const {showInfoModal, showConfirmationModal, modalTitle, modalDescription, infoModalTitle, infoModalDescription, status, selectedOfferId, isPendingBonusAction} = this.state;

        const activeBonus = _get(bonus, 'active');
        const activeBonusData = _get(activeBonus, 'data');


        return (
            <S.AccountCurrentBonus className="account-current-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <ModalOpacity isOpen={showConfirmationModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}>
                            <S.ActivateModal>
                                <S.Title>{modalTitle}</S.Title>
                                <S.Description>{modalDescription}</S.Description>
                                {isPendingBonusAction ? 
                                            <Loader/>
                                            : (
                                            <S.BtnsWrapper>
                                                <S.ButtonCancel onClick={this.toggleModal.bind(this, 'showConfirmationModal')}
                                                    data-test='account_bonus_active_cancel'>
                                                    {translation('common_cancel')}
                                                </S.ButtonCancel>
                                                <S.ButtonAccept onClick={this.bonusActionAccept.bind(this, selectedOfferId, status)}
                                                    data-test='account_bonus_active_accept'>
                                                    {translation('common_accept')}
                                                </S.ButtonAccept>
                                            </S.BtnsWrapper>
                                            )}
                            </S.ActivateModal>
                        </ModalOpacity>

                        <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showInfoModal')}>

                            <S.ModalInfo>
                                <S.InfoTitle>{infoModalTitle}</S.InfoTitle>
                                {infoModalDescription && <S.InfoDescription>{infoModalDescription}</S.InfoDescription>}
                            </S.ModalInfo>

                        </ModalOpacity>

                        <S.BonusList>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr className="activeHeader">
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_validFromTo')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_wageredPercent')}</S.Th>
                                        <S.Th>{'\u00A0'}</S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(activeBonusData) ?
                                        (
                                            <>

                                            {_map(activeBonusData, ({offerName, activationTime, validTo, maxBonusAmount, totalToWager, leftToWager, wageredPercent, wagerBonusId, descriptionKey}) => {
                                                return (
                                                    <S.Tr key={offerName} className="activeBonusRow">
                                                        <S.Td>
                                                            <span>{offerName}</span>
                                                        </S.Td>
                                                        <S.Td className="activeBonusRowDate">
                                                            <span>{formatDate(activationTime, 'dd/MM/yyyy')}</span>
                                                            <span>{formatDate(validTo, 'dd/MM/yyyy')}</span>
                                                        </S.Td>
                                                        <S.Td>
                                                            <span
                                                                className="ammount">{formatMoney(maxBonusAmount)}</span>&nbsp;
                                                            <span className="currency">{translation('common_pln')}</span>
                                                        </S.Td>
                                                        <S.Td className="wageredPercent">
                                                            <S.ProgressLineWrapper>
                                                                <ProgressBarLine percentage={wageredPercent}/>
                                                            </S.ProgressLineWrapper>
                                                        </S.Td>
                                                        <S.Td className="bonus-actions">
                                                            <S.Button className="reject"
                                                                      onClick={this.renderBonusActionConfirmation.bind(this, wagerBonusId, 'cancel')}
                                                                data-test='account_bonus_active_reject'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><defs></defs><path className="a" d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"/><path className="b" d="M0,0H24V24H0Z"/></svg>
                                                                      </S.Button>
                                                        </S.Td>
                                                    </S.Tr> 
                                                )
                                            })}

                                            {_size(activeBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="9" className="pagination">
                                                        <Pagination currentPage={_get(activeBonus, 'currentPage')}
                                                                    limit={limit} data={activeBonusData}
                                                                    cb={this.goPage.bind(this, 'active')}/>
                                                    </S.Td>
                                                </S.Tr>
                                            )}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td className="noResults" colSpan="8">{translation('account_bonus_notFound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>
                        </>
                    )
                }

            </S.AccountCurrentBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchBonusListPending: bindActionCreators(fetchBonusListPending, dispatch),
        fetchBonusListFulfilled: bindActionCreators(fetchBonusListFulfilled, dispatch),
        fetchBonusListFailure: bindActionCreators(fetchBonusListFailure, dispatch),
        manageBonus: bindActionCreators(manageBonus, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountCurrentBonus);
