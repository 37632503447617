import styled, {css} from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipTypesTabsBackground = ({theme:{$betSlipTypesTabsBackground}}) => `${$betSlipTypesTabsBackground}`;
const BetSlipTypesTabsTabColor = ({theme:{$betSlipTypesTabsTabColor}}) => `${$betSlipTypesTabsTabColor}`;
const BetSlipTypesTabsTabActiveBackgroundColor = ({theme:{$betSlipTypesTabsTabActiveBackgroundColor}}) => `${$betSlipTypesTabsTabActiveBackgroundColor}`;
const BetSlipTypesTabsAnchor = ({theme:{$betSlipTypesTabsAnchor}}) => `${$betSlipTypesTabsAnchor}`;
const BetSlipTypesTabsClearSlip = ({theme:{$betSlipTypesTabsClearSlip}}) => `${$betSlipTypesTabsClearSlip}`;
const BetSlipTypesTabsBorderColor = ({theme:{$betSlipTypesTabsBorderColor}}) => `${$betSlipTypesTabsBorderColor}`;

const baseFont = `12px`;

const S = {};

S.BetSlipTypesTabs = styled.div`
    display: flex;
    height: ${rem('40px', baseFont)};
    background-color: ${BetSlipTypesTabsBackground};
    line-height: 1;
    margin-bottom: ${rem('6px', baseFont)};
    margin-left: -0.5rem;
    margin-right: -0.5rem;
`;

S.BetSlipTypeTab = styled(Button)`
    padding: 0 1rem;
    font-size: ${rem('12px', baseFont)};
    background-color: transparent;
    color: #FFF;
    ${({isActive}) => {
        return (isActive == true) ?
        css`
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: ${BetSlipTypesTabsTabActiveBackgroundColor};
            font-weight: bold;
            position: relative;
            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: -4px;
                z-index: 100;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 7px 0 7px;
                border-color: ${BetSlipTypesTabsTabActiveBackgroundColor} transparent transparent transparent;
            }
        `: '';
    }}};
`;

S.ClearBetSlip = styled(Button)`
    padding: 0;
    background-color: transparent;
    line-height: 1;
    align-self: center;
    color: ${BetSlipTypesTabsClearSlip};
    
    &:before{
        font-size: ${rem('16px', baseFont)};
    }
`;

S.LockBetSlip = styled(Button)`
    margin-left: auto;
    padding: 0;
    margin-right: 0.9rem;
    background-color: transparent;
    line-height: 1;
    align-self: center;
    color: ${({isSticked}) => {
        return (isSticked == true) ? '#A1A1A1' : css`${BetSlipTypesTabsAnchor}`;
    }}};
    
    &:before{
        font-size: ${rem('14px', baseFont)};
    }
`;

export default S;