import React, {useEffect, useMemo} from 'react';
import _map from 'lodash/map';
import Loader from 'Loader.js';
import S from 'StyledPopularCategories.js';
import {redirect, translation, getUrlPreparedString} from 'utilsHelper.js';
import PrematchCalendarIcon from 'popular_categories_icon.svg';
import classNames from 'classnames';
import _find from "lodash/find";
import _reduce from "lodash/reduce";

const PopularCategories = (props) => {
    const {fetchPopularCategories, popularCategories, isPopularCategoriesPending} = props;
    const normalizeURL = (name) => getUrlPreparedString(name).toLowerCase();

    useEffect(() => {
        fetchPopularCategories();
    }, []);

    const popularEventsCount = useMemo(()=>{
        return _reduce(popularCategories, (initial, {eventsCount})=>{
            return initial+eventsCount;
        }, 0);
    }, [popularCategories]);

    const redirectToEventList = (leagueId) => {

        let {treatAsSport, parentName, categoryName, sportName, sportId} = _find(popularCategories, {categoryId: leagueId});

        parentName = normalizeURL(parentName);
        categoryName =  normalizeURL(categoryName);
        sportName = normalizeURL(sportName);

        const params = {
            selectedSports: sportName,
            filterSportId: sportId,
            categories: leagueId,
            selectedCountries: parentName,
            selectedLeagues: categoryName
        };

        const isEsport = (treatAsSport == -100);
        if (isEsport) {
            redirect('/esport/:selectedSports/:selectedCountries/:selectedLeagues/:categories/:filterSportId', params);
        } else {
            redirect('/zaklady-bukmacherskie/:selectedSports/:selectedCountries/:selectedLeagues/:categories/:filterSportId', params);
        }
    };

    const parsePopularCategoriesURL = (data) => {
        const output = data;
        const mainPath = 'zaklady-bukmacherskie'

        const parentName = normalizeURL(output?.parentName);
        const categoryName = normalizeURL(output?.categoryName);
        const sportName = normalizeURL(output?.sportName);
        const categoryId = output?.categoryId;
        const sportId = output?.sportId;

        const params = [mainPath, sportName, parentName, categoryName, categoryId, sportId ];
        const normalizedParams = params.join('/')

        return normalizedParams;
    }

    return (
        <S.PopularCategories>

            <S.PopularCategoriesHeader>
                <S.PopularCategoriesHeaderIcon dangerouslySetInnerHTML={{__html: PrematchCalendarIcon}}/>
                <S.PopularCategoriesHeaderText>
                    {translation('popularCategoriesWidget_title')}
                </S.PopularCategoriesHeaderText>
                <S.PopularCategoriesHeaderEventsCount>
                    {popularEventsCount}
                </S.PopularCategoriesHeaderEventsCount>
            </S.PopularCategoriesHeader>

            <S.PopularCategoriesList>

                {isPopularCategoriesPending ?
                    (<Loader/>)
                    :
                    (
                        <>
                            {_map(popularCategories, ({ categoryName, parentName, categoryId, sportName, sportId, eventsCount, treatAsSport}) => {
                                const sportIconClass = classNames('sport-icon', {[`sport-${sportId}`]: true});
                                return (
                                    <S.PopularCategoriesListItem key={categoryId} onClick={redirectToEventList.bind(null, categoryId)}>
                                            <S.SportIcon className={sportIconClass}/>
                                            <S.PopularCategoriesItemName>
                                                {categoryName}
                                            </S.PopularCategoriesItemName>

                                        <S.PopularCategoriesListItemLink href={parsePopularCategoriesURL({ categoryName, parentName,  categoryId, sportName, sportId, eventsCount })}>
                                        </S.PopularCategoriesListItemLink>
                                            <S.EventsCount>{eventsCount}</S.EventsCount>
                                    </S.PopularCategoriesListItem>
                                )
                            })}
                        </>
                    )
                }

            </S.PopularCategoriesList>

        </S.PopularCategories>
    );
};

export default PopularCategories;