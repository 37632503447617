export default {
    TV_BET_PLACED: 3945,
    TV_BET_WON: 3940,
    TV_BET_CANCEL: 3941,
    TV_BET_JACKPOT: 3942,
    TV_BET_TAX: 3946,
    
    TV_BET_TRANSACTIONS: [3940, 3941, 3942, 3945, 3946],
};

