import React, {PureComponent} from 'react';
import _head from 'lodash/head';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import S from 'StyledDropdownMenu.js';
import classNames from 'classnames';
import {findCategoryItemByIdLevel, getUrlPreparedString} from 'utilsHelper';
import {CSSTransition} from 'react-transition-group';
import FavouriteIcon from 'star_selected.svg';

class DropdownMenu extends PureComponent {

    static propTypes = {};

    constructor(props) {
        super(props);

        const menuItems = [];
        props.config.forEach((item) => {
            if (item.children) {
                const id = item.categoryId;
                const isOpen = false;
                menuItems.push({id, isOpen});
            }
        });
        this.state = {menuItems};
    }

    toggleDropdown = (e, id) => {
        e.preventDefault();

        const indexOfItem = this.findIndexOfItem(id);
        const item = _head(this.state.menuItems.slice(indexOfItem, indexOfItem + 1));

        this.updateCategoryItem(indexOfItem, item);
    }

    updateCategoryItem = (indexOfItem, item) => {
        this.setState((prevState) => {

            const itemsBeforeIndex = prevState.menuItems.slice(0, indexOfItem);
            const itemsAfterIndex = prevState.menuItems.slice(indexOfItem + 1);
            const nextState = {
                ...prevState,
                menuItems: [...itemsBeforeIndex, {...item, isOpen: !item.isOpen}, ...itemsAfterIndex]
            };
            return nextState;
        })
    }

    addCategoryItem = (id, isOpen = false) => {
        this.setState((prevState) => {
            return {...prevState, menuItems: [...prevState.menuItems, {id, isOpen}]}
        });
    }

    findIndexOfItem = (searchId) => {
        return this.state.menuItems.findIndex(({id}) => id == searchId);
    }

    findItemByCategoryId = (searchId) => {
        return this.state.menuItems.find(({id}) => id == searchId);
    }

    syncFavouriteLeague = (categoryId) => {
        app.util.Utils.syncFavouriteLeague(categoryId);
    }

    checkIfIsEsport = (sportId) => {
        const items = this.props.orgCategories;
        const level1Items = _filter(items, {treatAsSport:-100, level:1});
        let filteredItems = [];
        if (level1Items.length) {
            for (let i = 0; i < items.length; i++) {
                for (let j = 0; j < level1Items.length; j++) {
                    if (items[i].sportId == level1Items[j].sportId) {
                        filteredItems.push(items[i].sportId);
                    }
                }
            }
            return filteredItems.includes(sportId) ? true : false;
        } else {
            return false;
        }
    }

    isInvisibleInEsport = (sportId) => {
        if (this.props.isEsport && this.checkIfIsEsport(sportId)) {
            return false;
        } else if (!this.props.isEsport) {
            return false;
        } else {
            return true;
        }
    }

    getMenuItem = (menuItem) => {

        const title = menuItem.categoryName;
        const categoryId = menuItem.categoryId;
        const sportId = menuItem.sportId;
        const parentCategory = menuItem.parentCategory;
        const level = menuItem.level;
        const eventsCount = menuItem.eventsCount;
        const sportCategory = menuItem.sportName
        
        const countryName = _get(findCategoryItemByIdLevel(categoryId, 2, this.props.orgCategories), 'categoryName');
        const flagsCache = app.service.FlagsApi.__flagsCache.data;

        if (menuItem.children && menuItem.children.length > 0) {

            const {isOpen} = this.findItemByCategoryId(categoryId);
            const isInvisible = this.isInvisibleInEsport(sportId);
            const itemClass = classNames({'is-active': isOpen, 'has-submenu': true, 'dropdown-menu-item': true, 'hidden-in-esport': isInvisible});
            return (
                <S.DropdownMenuItem key={categoryId} className={itemClass} data-level={level} data-parent-id={parentCategory}>
                    <S.DropdownMenuItemInner className="dropdown-menu-item-inner" onClick={(e) => {
                        this.toggleDropdown(e, categoryId);
                    }}
                    data-test={`dropdownMenu_category_level${level}`}>
                        <S.ItemInnerWrapper>
                            {level == 1 && <S.Icon className={`sport-icon`}><use href={`#sport-${sportId}`}></use></S.Icon>}
                            {level == 2 && flagsCache && (categoryId in flagsCache) && <S.FlagIcon className={`flag-icon`}><use href={`#flag-${flagsCache[categoryId]}`}></use></S.FlagIcon>}

                            <S.Title className="title">{title}</S.Title>
                        </S.ItemInnerWrapper>

                        { level == 1 && (
                            <>
                            <S.EventsCount className="events-count">{eventsCount}</S.EventsCount>
                            <S.ArrowDown className="arrow-down"></S.ArrowDown>
                            </>
                        )}

                        {level == 2 && <S.EventsCount className="events-count">{eventsCount}</S.EventsCount>}

                    </S.DropdownMenuItemInner>

                    <CSSTransition in={isOpen} timeout={500}   classNames="slide-transition" unmountOnExit>
                        <DropdownMenu {...this.props} config={menuItem.children} submenu={true}/>
                    </CSSTransition>

                </S.DropdownMenuItem>
            );
        } else {

            const sportName = _get(findCategoryItemByIdLevel(categoryId, 1, this.props.orgCategories), 'categoryName');


            const isChecked = this.isChecked(categoryId);
            const chboxClass = classNames({'is-active': isChecked, 'fake-chbox': true});
            const params = [sportName, sportId, categoryId];
            const {isLogged, favouriteLeagues} = this.props;
            const isFavourite = !!_find(favouriteLeagues, {categoryId});

            const path = () => {
                const mainPath = 'zaklady-bukmacherskie'
                const params = [sportCategory, countryName, title]
                const paramsNormalized = params.map(el => getUrlPreparedString(el)).join('/')
                const path = `${mainPath}/${paramsNormalized}/${categoryId}/${sportId}`
                return path
            }

            return (
                <S.DropdownMenuItem
                    key={categoryId}
                    className="dropdown-menu-item"
                    data-level={level}
                    data-parent-id={parentCategory}
                    data-test={`dropdownMenu_category_level${level}`}>
                    <S.DropdownMenuItemInner 
                        className="dropdown-menu-item-inner">
                        <S.PopularCategoriesListItemLink href={path()}>
                        </S.PopularCategoriesListItemLink>
                        <S.ItemInnerWrapper>
                            <S.FakeChbox className={chboxClass} onClick={(e) => {
                                this.handleCheckbox(e, ...params);
                            }}></S.FakeChbox>

                            <S.Title className="title" onClick={(e)=>{
                                this.props.deselectLeagueById(this.props.selectedLeagues);
                                this.redirectToEventList(...params);
                                this.syncFavouriteLeague(categoryId);
                            }}>{title}</S.Title>
                        </S.ItemInnerWrapper>

                        {isLogged && (<S.FavouriteIcon dangerouslySetInnerHTML={{__html: FavouriteIcon}} onClick={this.toggleFavouriteLeague.bind(null, categoryId, isFavourite)} isFavourite={isFavourite}></S.FavouriteIcon>)}

                        {level == 3 && <S.EventsCount className="events-count" classNames="events-count">{eventsCount}</S.EventsCount>}

                    </S.DropdownMenuItemInner>
                </S.DropdownMenuItem>
            );
        }
    };

    toggleFavouriteLeague = (categoryId, isFavourite)=>{
        const {toggleFavouriteForLeague} = this.props;
        toggleFavouriteForLeague(categoryId, !isFavourite).catch((error={})=>{
            const {code, data = []} = error;
            if(code == 400){
                const maxLimitReached = 4010;
                const errorObj = _.findWhere([].concat(data), {code: maxLimitReached});
                if(_.size(errorObj)){
                    const maxLimit = errorObj?.max??null;
                    let property = errorObj?.property??null;
                    if(maxLimit&&property){
                        property = property.toLowerCase();
                        const title = app.message.get('error_favourites_maxLimitReached');
                        const message = app.message.get(`error_${property}_${maxLimitReached}`, [maxLimit])
                        app.system.render(app.modal.Confirm, {title, message});
                    }
                }
            }else{
                console.log(error);
            }
        });
    };

    isChecked = (leagueId) => {
        const {selectedLeagues} = this.props;
        return (selectedLeagues.indexOf(leagueId) != -1);
    };

    redirectToEventList = (sportName, sportId, leagueId) => {
        const {orgCategories} = this.props;
        const normalizeURL = (name) => getUrlPreparedString(name)
        const findCategoryNameByLevel = (categoryId, level) => {
            return _get(findCategoryItemByIdLevel(categoryId, level, orgCategories), 'categoryName');
        };

        const leaguesArr = String(leagueId).split(',');
        let countryName = _map(leaguesArr, (id) => findCategoryNameByLevel(id, 2));
        let leagueName = _map(leaguesArr, (id) => findCategoryNameByLevel(id, 3));
        countryName = _map([...new Set(countryName)], normalizeURL);
        leagueName = _map([...new Set(leagueName)], normalizeURL);
        sportName = _map([].concat(sportName), normalizeURL);

        const params = {
            selectedSports: sportName,
            filterSportId: sportId,
            categories: leagueId,
            selectedCountries: countryName,
            selectedLeagues: leagueName
        };
        const isEsport = this.checkIfIsEsport(sportId);
        if (isEsport) {
            app.router.redirect('/esport/:selectedSports/:selectedCountries/:selectedLeagues/:categories/:filterSportId', params);
        } else {
            app.router.redirect('/zaklady-bukmacherskie/:selectedSports/:selectedCountries/:selectedLeagues/:categories/:filterSportId', params);
        }
    };

    handleCheckbox = (e, sportName, sportId, leagueId) => {
        e.preventDefault();

        let {selectedLeagues, ...rest} = this.props;
        let tempLeagues = [];
        const {prevSortId, changeSportId, selectLeagueById, deselectLeagueById} = rest;
        const target = e.target;

        const div = target.closest('div');
        if (div) {
            if (!div.classList.contains('is-active')) {
                if (sportId != prevSortId) {
                    changeSportId(sportId);
                    selectedLeagues = [];
                }
                if (selectedLeagues.indexOf(leagueId) == -1) {
                    selectLeagueById(leagueId);
                    tempLeagues = [...selectedLeagues, leagueId];
                }
            } else {
                deselectLeagueById(leagueId);
                tempLeagues = selectedLeagues.filter(id => id != leagueId);
            }

            if (!tempLeagues.length) {
                app.router.redirect('/zaklady-bukmacherskie');
            } else {
                const leaguesIds = tempLeagues.join(',');
                this.redirectToEventList(sportName,  sportId, leaguesIds);
            }
        }
    };

    render() {

        const {config} = this.props;
        const options = [];
        config.map((item) => {
            options.push(this.getMenuItem(item));
        });

        if (this.props.submenu && this.props.submenu === true)
            return <S.DropdownSubmenuList className="dropdown-submenu">{options}</S.DropdownSubmenuList>;

        return (
            <S.DropdownMenuList className="dropdown-menu">
                {options}
            </S.DropdownMenuList>
        );
    }
}

export default DropdownMenu;
