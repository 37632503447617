const theme = {
  // Smartphones Mobiles (Portrait)
  mqMobile: `@media (min-width: 320px) and (max-width: 480px)`,
  // Smartphones Mobiles (Landscape)
  mqMobileHorz: `@media (min-width: 481px) and (max-width: 767px)`,
  // Tablets, Ipads (Portrait)
  mqTablets: `@media all (min-width: 768px) and (max-width: 1024px)`,
  // Tablets, Ipads (Landscape)
  mqTabletsHorz: `@media all (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
  // Desktops
  mqSmallDesktops: `@media (min-width: 1025px)`,
  mqMediumDesktops: `@media (min-width: 1281px)`,
  mqLargeDesktops: `@media (min-width: 1600px)`,
  mqXLargeDesktops: `@media (min-width: 1920px)`,

  mqZoomLarge: `@media (max-width: 1280px)`,
  mqForMac: `@media (max-width: 1440px)`,

  boxShadow: (
    hOffset = 0,
    vOffset = 0,
    blur = 3,
    spread = 0,
    color = 'rgba(99,94,99,0.2)'
  ) => {
    return `box-shadow: ${hOffset}px ${vOffset}px ${blur}px ${spread}px ${color}`;
  },

  lineClamp: (lineNumber) => {
    return `
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: ${lineNumber};
            overflow: hidden;
        `;
  },

  visuallyHidden: () => {
    return `
          position: absolute !important;
          clip: rect(1px 1px 1px 1px);
          clip: rect(1px, 1px, 1px, 1px);
          padding:0 !important;
          border:0 !important;
          height: 1px !important;
          width: 1px !important;
          overflow: hidden;
        `;
  },

  // Main colors
  $color_1: `#E3F4FF`,
  $color_2: `#2D9EF5`,
  $color_3: `#3EE2EF`,
  $color_4: `#2999BF`,
  $color_5: `#FFF200`,
  $color_6: `#70BF44`,
  $color_7: `#51A517`,
  $color_8: '#626973',
  $color_9: `#EFEFEF`,
  $color_10: `#3F3F3F`,
  $color_11: `#E8E8E8`,
  $color_12: `#3C3C3C`,
  $color_13: `#F97447`,
  $color_14: `#F9C023`,
  $color_15: `#F82B00`,
  $color_16: `#E1E1E1`,

  // Additional colors
  $color_17: `#F75703`,
  $color_18: `#BC4405`,
  $color_19: `#F8A072`,
  $color_20: `#404040`,
  $color_21: `#222222`,
  $color_22: `#383838`,
  $color_23: `#333333`,
  $color_24: `#191919`,
  $color_25: `#ffffff`,
  $color_26: `#A1A1A1`,
  $color_27: `#000000`,
  $color_28: `#00A651`,
  $color_29: `#078143`,
  $color_30: `#004D26`,
  $color_31: `#007639`,
  $color_32: `#85C9A6`,
  $color_33: `#CACACA`,
  $color_34: `#242c2f`,
  $color_35: `#282828`,
  $color_36: `#A3A3A3`,
  $color_37: `#F3F3F3`,
  $color_38: `#EAEAEA`,
  $color_39: `#BCC0C5`,
  $color_40: `#CBD0D6`,
  $color_41: `#787878`,
  $color_42: `#2d9ef5`,
  $color_43: `#2d9ef5`,
  $color_44: `#777D85`,
  $color_45: `#5E0E0E`,
  $color_46: `#E9D3D4`,
  $color_47: `#4D555E`,
  $color_48: `#CE0006`,
  $color_49: `#2E343D`,
  $color_50: `#262626`,
  $color_51: `#FCFCFC`,
  $color_52: `rgba(0,0,0,0)`,
  $color_53: `#F2f2f2`,
  $color_54: `#FAFAFA`,
  $color_55: `#F2F2F2`,
  $color_56: `#494F55`,
  $color_57: `transparent`,
  $color_58: `#EAEAEA`,
  $color_59: `#fba59f`,
  $color_60: `#808080`,
  $color_61: `#54C341`,
  $color_62: `#C62828`,
  $color_63: `#EFF1F2`,
  $color_64: `#D32F2F`,
  $color_65: `#D9D9D9`,
  $color_66: `#EFF2F3`,
  $color_67: `#c5b8b9`,
  $color_68: `#2A9B1A`,
  $color_69: `#02A64F`,
  $color_70: `#00003D`,
  $color_71: `#cfcfcf`,
  $color_72: `#8b8b8b`,
  $color_73: `#C2C2C2`,
  $color_74: `#565656`,
  $color_75: `#278e19`,
  $color_76: `#31bd79`,
  $color_77: `#F9F9FA`,
  $color_78: `#f6f6f6`,
  $color_79: `#FABD21`,
  $color_80: `rgba(0, 0, 0, 0.87)`,
  $color_81: `00CFFF`,

  $fontFamily: `'Source Sans Pro', sans-serif`,
  $fwRegular: 400,
  $fwMedium: 500,
  $fwSemibold: 600,
  $fwBold: 700,
};

theme['$fontFamily'] = theme['$fontFamily'];
theme['$fwRegular'] = theme['$fwRegular'];
theme['$fwMedium'] = theme['$fwMedium'];
theme['$fwSemibold'] = theme['$fwSemibold'];
theme['$fwBold'] = theme['$fwBold'];
theme['$loaderColor'] = theme['$color_44'];

// top menu
theme['$topMenuBackgroundColor'] = theme['$color_25'];
theme['$topMenuTopBarColor'] = theme['$color_25'];
theme['$topMenuListItemActiveColor'] = theme['$color_48'];
theme['$topMenuLinkColor'] = theme['$color_27'];
theme['$topMenuActiveLinkColor'] = theme['$color_27'];
theme['$topMenuUnloggedBtnColor'] = theme['$color_25'];
theme['$topMenuUnloggedBtnColorHover'] = theme['$color_27'];
theme['$topMenuUnloggedBtnBgColor'] = theme['$color_62'];
theme['$topMenuUnloggedBtnBgColorHover'] = theme['$color_63'];
theme['$topMenuLoggedOutBtnColor'] = theme['$color_27'];
theme['$topMenuLoggedOutBtnBgColor'] = theme['$color_52'];
theme['$topMenuLoggedUserLoginColor'] = theme['$color_27'];
theme['$topMenuSubmenuBarColor'] = theme['$color_62'];

// categories
theme['$categoriesDropdownItemBgColor'] = theme['$color_25'];
theme['$categoriesDropdownItemBgColorHover'] = theme['$color_63'];
theme['$categoriesDropdownItemBorderColor'] = theme['$color_65'];
theme['$categoriesDropdownItemColor'] = theme['$color_27'];
theme['$categoriesDropdownItemActiveBg'] = theme['$color_65'];
theme['$categoriesDropdownItemActiveColor'] = theme['$color_27'];
theme['$categoriesDropdownItemBorderBottom'] = theme['$color_63'];
theme['$categoriesActiveEventsCountBgColor'] = theme['$color_63'];
theme['$categoriesActiveEventsCountColor'] = theme['$color_62'];
theme['$categoriesDropdownArrowColor'] = theme['$color_41'];

theme['$categoriesDropdownItemLvl2ArrowColor'] = theme['$color_40'];
theme['$categoriesDropdownItemLvl2BorderBottom'] = theme['$color_44'];
theme['$categoriesDropdownItemLvl2BgColor'] = theme['$color_25'];
theme['$categoriesDropdownItemLvl2BgColorHover'] = theme['$color_63'];
theme['$categoriesDropdownItemLvl2ActiveBgColor'] = theme['$color_65'];
theme['$categoriesDropdownItemLvl2ActiveTitleColor'] = theme['$color_40'];
theme['$categoriesDropdownItemLvl2ActiveArrowColor'] = theme['$color_40'];
theme['$categoriesDropdownItemLvl3BgColor'] = theme['$color_25'];
theme['$categoriesEventsCount'] = theme['$color_64'];
theme['$categoriesLvl3CheckboxBgColor'] = 'transparent';
theme['$categoriesLvl3CheckboxActiveBgColor'] = theme['$color_64'];
theme['$categoriesLvl3CheckboxBorderColor'] = theme['$color_27'];
theme['$categoriesLvl3CheckboxActiveBorderColor'] = theme['$color_64'];

// cashback plus category tab
theme['$cashbackCategoriesHeaderBgColor'] = theme['$color_65'];
theme['$cashbackCategoriesHeaderBgColorHover'] = theme['$color_63'];

// popular categories/top leagues
theme['$popularCategoriesHeaderBgColor'] = theme['$color_65'];
theme['$popularCategoriesItemBgColor'] = theme['$color_63'];
theme['$popularCategoriesItemBgColorHover'] = theme['$color_25'];
theme['$popularCategoriesSportIconColor'] = theme['$color_62'];
theme['$popularCategoriesEventsCountColor'] = theme['$color_62'];

// favourites
theme['$favouriteLeaguesHeaderBgColor'] = theme['$color_65'];
theme['$favouriteLeaguesHeaderColor'] = theme['$color_27'];
theme['$favouriteLeaguesItemBg'] = theme['$color_63'];
theme['$favouriteLeaguesItemColor'] = theme['$color_27'];
theme['$favouriteLeaguesItemIconColor'] = theme['$color_62'];
theme['$favouriteLeaguesEventsCountColor'] = theme['$color_62'];

// login form
theme['$loginFormSubmitBtnColor'] = theme['$color_25'];
theme['$loginFormSubmitBtnBgColor'] = theme['$color_62'];

// register form
theme['$registerFormSubmitBtnColor'] = theme['$color_25'];
theme['$registerFormSubmitBtnBgColor'] = theme['$color_62'];

// account

theme['$accountProfileColor'] = theme['$color_27'];
theme['$accountTopHeaderBackground'] = theme['$color_66'];
theme['$accountTopHeaderColor'] = theme['$color_27'];
theme['$accountCustomerDataButtonBackground'] = theme['$color_62'];
theme['$accountCustomerDataButtonColor'] = theme['$color_25'];
theme['$accountUserSimpleLinkColor'] = theme['$color_62'];
theme['$accountUserSimpleLinkBgColor'] = theme['$color_25'];
theme['$accountMenuBackground'] = theme['$color_25'];
theme['$accountMenuActiveBackground'] = theme['$color_64'];
theme['$accountMenuColor'] = theme['$color_27'];
theme['$accountMenuActiveColor'] = theme['$color_25'];
theme['$accountWalletCancellPayoutConfirmBtnColor'] = theme['$color_25']; 
theme['$accountWalletCancellPayoutConfirmBtnBackground'] = theme['$color_64'];
theme['$accountWalletCancellPayoutRejectBtnColor'] = theme['$color_64']; 
theme['$accountWalletCancellPayoutRejectBtnBackground'] = theme['$color_25']; 

// profile
theme['$accountProfileStepIndicator'] = theme['$color_61'];
theme['$accountProfileInputLabel'] = theme['$color_27'];
theme['$accountProfileInput'] = theme['$color_72'];
theme['$accountProfileInputBackground'] = theme['$color_25'];
theme['$accountProfileInputBorder'] = theme['$color_71'];
theme['$accountProfileHasErrorsColor'] = theme['$color_48']; // ??? brak w projekt
theme['$accountProfilErrorRedColor'] = theme['$color_62']; // ??? brak w projekt
theme['$accountProfileHasSuccessColor'] = theme['$color_76'];
theme['$accountProfileHasErrorsBackground'] = theme['$color_51'];
theme['$accountProfileValidData'] = theme['$color_61'];
theme['$accountProfileAproveedDocs'] = theme['$color_61'];
theme['$accountProfileRejectedDocs'] = theme['$color_48'];
theme['$accountProfileValidUpload'] = theme['$color_61'];
theme['$accountProfileButtonUploadBackground'] = theme['$color_62'];  
theme['$accountProfileButtonUploadColor'] = theme['$color_25'];
theme['$accountProfileSaveIconBackground'] = theme['$color_57'];
theme['$accountProfileSubmitButtonBackground'] = theme['$color_25'];
theme['$accountProfileSubmitButtonColor'] = theme['$color_50'];
theme['$accountProfileSubmitBorderColor'] = theme['$color_48'];
theme['$accountProfileSubmitDisabledBackground'] = theme['$color_58'];
theme['$accountProfileSubmitDisabledColor'] = theme['$color_27'];
theme['$accountProfileSubmitDisabledColorHover'] = theme['$color_25'];
theme['$accountProfileSubmitDisabledBackgroundHover'] = theme['$color_48']; 
theme['$accountProfileChangePassColor'] = theme['$color_50']; 
theme['$accountProfileChangePassDisabledColorHover'] = theme['$color_25'];
theme['$accountProfileChangePassDisabledBackgroundHover'] = theme['$color_48']; 
theme['$accountProfileModalSuccessTitle'] = theme['$color_42'];
theme['$accountProfileModalSuccessMessageColor'] = theme['$color_27'];
theme['$accountProfileFieldLabel'] = theme['$color_44'];
theme['$accountProfileFieldIsEditableBorder'] = theme['$color_11'];
theme['$accountProfileFieldIsEditableBackground'] = theme['$color_51'];
theme['$accountProfileSelectBackground'] = theme['$color_57'];
theme['$accountProfileHelpButtonColor'] = theme['$color_44'];
theme['$accountProfileHelpIconBackground'] = theme['$color_40'];
theme['$accountProfileHelpIconColor'] = theme['$color_25'];
theme['$accountProfileHelpBeforeBackground'] = theme['$color_57'];
theme['$accountProfileSubmissionErrorBackground'] = theme['$color_59'];
theme['$accountProfileDisableSelectBackground'] = theme['$color_25']; 
theme['$accountProfileDisableSelectSpecialStateBorder'] = theme['$color_27']; 
theme['$accountProfileDisableSelectIndicator'] = theme['$color_60']; 
theme['$accountProfileSelectSingleValueColor'] = theme['$color_44'];
theme['$accountProfileUpdateModalMessage'] = theme['$color_44']; 
theme['$accountProfileCheckboxBackgroundColor'] = theme['$color_64'];
theme['$accountProfileCheckboxSignColor'] = theme['$color_25'];
theme['$accountProfileSubmitButtonBackgroundColor'] = theme['$color_62'];
theme['$accountProfileSubmitButtonDisabledBackgroundColor'] = theme['$color_27'];
theme['$accountProfileSubmitButtonColor'] = theme['$color_25'];
theme['$accountProfileSubmitBtnBackgroundColor'] = theme['$color_27'];
theme['$accountProfileSubmitBtnColor'] = theme['$color_25'];
theme['$accountProfileSubmitBtnDisabledBackgroundColor'] = theme['$color_25'];
theme['$accountProfileSubmitBtnDisabledColor'] = theme['$color_27'];
theme['$accountProfileVerificationStatusColor'] = theme['$color_25'];
theme['$accountProfileVerificationStatusPossitiveBgColor'] = theme['$color_75'];
theme['$accountProfileVerificationStatusNegativeBgColor'] = theme['$color_62'];
theme['$accountProfileFormCoverBg'] = theme['$color_66'];
theme['$accountProfileModalColor'] = theme['$color_27'];

//profile bonuses
theme['$accountProfileBonusTabBg'] = theme['$color_62']; 
theme['$accountProfileBonusTabTxt'] = theme['$color_25']; 
theme['$accountProfileBonusTabActiveBg'] = theme['$color_64']; 
theme['$accountProfileBonusTabActiveHeaderBg'] = theme['$color_57']; 
theme['$accountProfileBonusTabActiveHeaderTxt'] = theme['$color_27']; 
theme['$accountProfileBonusTabActiveHeaderBorder'] = theme['$color_64']; 
theme['$accountProfileBonusTabActiveBonusRowBg'] = theme['$color_25']; 
theme['$accountProfileBonusTabActiveBonusProgressBarMainColor'] = theme['$color_62']; 
theme['$accountProfileBonusTabActiveBonusProgressBarBgColor'] = theme['$color_25']; 
theme['$accountProfileBonusTabActiveBonusProgressBarPercColor'] = theme['$color_25']; 
theme['$accountProfileBonusTabActiveBonusCancelBg'] = theme['$color_62']; 
theme['$accountProfileBonusTabActiveBonusCanceledIcon'] = theme['$color_67']; 
theme['$accountProfileBonusTabActiveBonusCheckIconBg'] = theme['$color_68']; 
theme['$accountProfileBonusTabActiveBonusCheckIconSign'] = theme['$color_25']; 
theme['$accountProfileBonusModalsTextColor'] = theme['$color_27']; 
theme['$accountProfileBonusModalsCancelBttnBg'] = theme['$color_64']; 
theme['$accountProfileBonusModalsSuccessBttnBg'] = theme['$color_69']; 
theme['$accountProfileBonusDatepickerLabelColor'] = theme['$color_74']; 

//profile bet history
theme['$accountProfileBetHistoryDatepickerBorder'] = theme['$color_73']; 
theme['$accountProfileBetHistoryDatepickerLabelBg'] = theme['$color_25']; 
theme['$accountProfileBetHistoryFilterBttnTxt'] = theme['$color_27']; 
theme['$accountProfileBetHistoryFilterBttnActive'] = theme['$color_64']; 
theme['$accountProfileBetHistoryFilterBttnActiveTxt'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionHeaderTxtColor'] = theme['$color_27']; 
theme['$accountProfileBetHistoryTransactionHeaderBorder'] = theme['$color_64']; 
theme['$accountProfileBetHistoryTransactionLabelBg'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionLostLabelColor'] = theme['$color_62']; 
theme['$accountProfileBetHistoryTransactionWonLabelColor'] = theme['$color_68']; 
theme['$accountProfileBetHistoryTransactionCashoutedInfoColor'] = theme['$color_68']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBgActions'] = theme['$color_62']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBgInfo'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderTitle'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionDetailsMainColor'] = theme['$color_27']; 
theme['$accountProfileBetHistoryTransactionDetailsBg'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockBg'] = theme['$color_62']; 
theme['$accountProfileBetHistoryTransactionDetailsHeaderBlockTxt'] = theme['$color_25']; 
theme['$accountProfileBetHistoryTransactionDetailsFooterTxt'] = theme['$color_27']; 
theme['$accountProfileBetHistoryTransactionDetailsRebetBackground'] = theme['$color_25']; 
theme['$accountProfileBetHistoryCashoutBttnBackground'] = theme['$color_62']; 
theme['$accountProfileBetHistoryCashoutBttnColor'] = theme['$color_25'];
theme['$accountProfileBetHistoryCashoutBttnDisabledBackground'] = theme['$color_25']; 
theme['$accountProfileBetHistoryCashoutBttnDisabledColor'] = theme['$color_27'];
theme['$accountProfileBetHistoryTvBetBorderColor'] = theme['$color_79'];
theme['$accountProfileBetHistoryTvBetActiveBackgroundColor'] = theme['$color_79'];
theme['$accountProfileBetHistoryTvBetTextColor'] = theme['$color_80'];
theme['$accountProfileBetHistoryTvBetPlacedTypeColor'] = theme['$color_81'];       
theme['$accountProfileBetHistoryTvBetInfoSecionSeparator'] = theme['$color_66'];

//profile cashback
theme['$accountProfileCashbackButtonBg'] = theme['$color_62']; 
theme['$accountProfileCashbackButtonColor'] = theme['$color_25']; 
theme['$accountProfileCashbackWithdrawBg'] = theme['$color_25'];
theme['$accountProfileCashbackEvenRowBg'] = theme['$color_78'];

//cashout modal
theme['$accountProfileBetHistoryCashoutHeader'] = theme['$color_27'];
theme['$accountProfileBetHistoryCashoutLabel'] = '#787d83';
theme['$accountProfileBetHistoryCashoutLightText'] = theme['$color_25'];
theme['$accountProfileBetHistoryCashoutDarkText'] = theme['$color_27'];
theme['$accountProfileBetHistoryCashoutThemeColor'] = theme['$color_62'];
theme['$accountProfileBetHistoryCashoutSubmitDisabledText'] = theme['$color_62'];
theme['$accountProfileBetHistoryCashoutSubmitDisabledBackground'] = theme['$color_62'];

//profile bet history share
theme['$accountProfileBetHistoryShareLinkInfoSuccessBackground'] = theme['$color_68'];
theme['$accountProfileBetHistoryShareLinkInfoSuccessColor'] = theme['$color_25']; 

//get lucky
theme['$getLuckyBackgroundColor'] = theme['$color_63'];
theme['$getLuckyHeaderBackground'] = theme['$color_65'];
theme['$getLuckyHeaderColor'] = theme['$color_27'];
theme['$getLuckyBodyContentBackground'] = theme['$color_25'];
theme['$getLuckyInputBackground'] = theme['$color_51'];
theme['$getLuckyInputBorderColor'] = theme['$color_27'];
theme['$getLuckyInputColor'] = theme['$color_27'];
theme['$getLuckyLabelColor'] = theme['$color_27'];
theme['$getLuckyFakeChboxBackground'] = theme['$color_51'];
theme['$getLuckyInputRangeTrackBackground'] = theme['$color_25'];
theme['$getLuckyInputRangeTrackActiveBackground'] = theme['$color_64'];
theme['$getLuckyInputRangeTrackSlider'] = theme['$color_64'];
theme['$getLuckyButtonBackground'] = theme['$color_48'];
theme['$getLuckyButtonBackgroundHover'] = theme['$color_63'];
theme['$getLuckyButtonBorderColor'] = theme['$color_48'];
theme['$getLuckyButtonColor'] = theme['$color_25'];
theme['$getLuckyButtonColorHover'] = theme['$color_27'];
theme['$getBestsellersOutcomesButtonColor'] = theme['$color_25'];
theme['$getBestsellersOutcomesBackground'] = theme['$color_48'];

//betslip check
theme['$betSlipCheckBackgroundColor'] = theme['$color_53'];
theme['$betSlipCheckHeaderBackground'] = theme['$color_44'];
theme['$betSlipCheckHeaderColor'] = theme['$color_40'];
theme['$betSlipCheckBodyContentBackground'] = theme['$color_25'];
theme['$betSlipCheckInputBackground'] = theme['$color_51'];
theme['$betSlipCheckInputBorderColor'] = theme['$color_11'];
theme['$betSlipCheckInputColor'] = theme['$color_44'];
theme['$betSlipCheckLabelColor'] = theme['$color_44'];
theme['$betSlipCheckButtonBackground'] = theme['$color_48'];
theme['$betSlipCheckButtonColor'] = theme['$color_25'];
theme['$betSlipCheckButtonTextFont'] = theme['$textRegular'];

//betslip details
theme['$betSlipDetailsDataBackground'] = theme['$color_44'];
theme['$betSlipDetailsDataTitleColor'] = theme['$color_12'];
theme['$betSlipDetailsDataColor'] = theme['$color_25'];

theme['$betSlipDetailsListHeaderBackground'] = theme['$color_11'];
theme['$betSlipDetailsListHeaderColor'] = theme['$color_12'];
theme['$betSlipDetailsListEventDataBackground'] = theme['$color_51'];
theme['$betSlipDetailsListEventDataColor'] = theme['$color_56'];
theme['$betSlipDetailsListEventDataColorDate'] = theme['$color_44'];

theme['$betSlipDetailsListStatusPending'] = theme['$color_49'];
theme['$betSlipDetailsListStatusPlaced'] = theme['$color_49'];
theme['$betSlipDetailsListStatusWon'] = '#54C341';
theme['$betSlipDetailsListStatusLost'] = theme['$color_40'];
theme['$betSlipDetailsListStatusCancel'] = '#CE0006';
theme['$betSlipDetailsListStatusCashout'] = '#54C341';
theme['$betSlipDetailsListStatusDefault'] = theme['$color_49'];

// sport/time/game filters
theme['$sportFilterContainerBackground'] = theme['$color_8'];
theme['$sportFilterBackground'] = theme['$color_44'];
theme['$sportFilterText'] = theme['$color_40'];
theme['$sportFilterHeaderBorder'] = theme['$color_44'];
theme['$sportFilterButtonBackground'] = theme['$color_40'];
theme['$sportFilterButtonColor'] = theme['$color_44'];
theme['$sportFilterDropdownBackground'] = theme['$color_49'];
theme['$sportFilterDropdownText'] = theme['$color_40'];
theme['$sportFilterDropdownBackgroundHover'] = theme['$color_47'];
theme['$sportFilterDropdownTextHover'] = theme['$color_25'];

// betSlipTabs
theme['$betSlipTabsBackground'] = theme['$color_49'];
theme['$betSlipTabsActiveBackground'] = theme['$color_48'];
theme['$betSlipTabsTabNumberColor'] =  theme['$color_44'];
theme['$betSlipTabsTabColor'] = theme['$color_25'];
theme['$betSlipTabsBorderColor'] = theme['$color_44'];
theme['$betSlipTabsActiveBorderColor'] = theme['$color_48'];

// betSlipTypesTabs
theme['$betSlipTypesTabsBackground'] = theme['$color_62'];
theme['$betSlipTypesTabsTabColor'] = theme['$color_44'];
theme['$betSlipTypesTabsTabActiveBackgroundColor'] = theme['$color_64'];
theme['$betSlipTypesTabsAnchor'] = theme['$color_50'];
theme['$betSlipTypesTabsClearSlip'] = theme['$color_50'];
theme['$betSlipTypesTabsBorderColor'] = theme['$color_11'];

// bestSlip outcome
theme['$betSlipOutcomeOddsColor'] = theme['$color_27'];
theme['$betSlipOutcomeEventNameColor'] = theme['$color_50'];
theme['$betSlipOutcomeRemoveIconColor'] = theme['$color_40'];
theme['$betSlipOutcomeGameNameColor'] = theme['$color_27'];
theme['$betSlipOutcomeNameColor'] = theme['$color_27'];
theme['$betSlipOutcomeBorderColor'] = theme['$color_11'];
theme['$betSlipOutcomeEvenBackgroundColor'] = theme['$color_77'];
theme['$betSlipOutcomeBackgroundColor'] = theme['$color_25'];

// betslip buttons
theme['$betSlipButtonBorderColor'] = theme['$color_48'];
theme['$betSlipButtonBgColor'] = theme['$color_48'];
theme['$betSlipButtonBgColorHover'] = theme['$color_63'];
theme['$betSlipButtonColorHover'] = theme['$color_27'];

// betslip stake/total stake
theme['$betSlipFormInputBackground'] = theme['$color_51'];
theme['$betSlipFormInputBorderColor'] = theme['$color_11'];
theme['$betSlipFormInputColor'] = theme['$color_44'];
theme['$betSlipFormLabelColor'] = theme['$color_27'];

// betslip success message
theme['$betSlipPlaceBetSuccessBackground'] = theme['$color_48'];
theme['$betSlipPlaceBetSuccessColor'] = theme['$color_25'];

// betslip combination types
theme['$betSlipCombinationTypesSelectBackground'] = theme['$color_40'];
theme['$betSlipCombinationTypesSelectArrowColor'] = theme['$color_50'];
theme['$betSlipCombinationTypesSelectTextColor'] = theme['$color_49'];

// betslip select
theme['$betSlipSelectBackground'] = theme['$color_11'];
theme['$betSlipSelectArrowColor'] = theme['$color_50'];
theme['$betSlipSelectTextColor'] = theme['$color_49'];

// betslip select in block
theme['$betSlipBlockSelectBackground'] = theme['$color_25'];
theme['$betSlipBlockSelectArrowColor'] = theme['$color_50'];
theme['$betSlipBlockSelectTextColor'] = theme['$color_49'];
theme['$betSlipBlockSelectBorderColor'] = theme['$color_11'];

// betslip system block header, block border
theme['$betSlipBlockHeaderTextColor'] = theme['$color_44'];
theme['$betSlipBlockHeaderBackground'] = theme['$color_51'];
theme['$betSlipBlockBodyBorderColor'] = theme['$color_11'];

// betslip button wrapper
theme['$betSlipButtonsWrapperBackground'] = theme['$color_55'];

// betslip checkboxs
theme['$betSlipCheckboxWrapperBackground'] = theme['$color_55'];
theme['$betSlipCheckboxColor'] = theme['$color_48'];
theme['$betSlipFakeChboxBackground'] = theme['$color_51'];
theme['$betSlipChboxInputBorderColor'] = theme['$color_11'];
theme['$betSlipChboxLabelColor'] = theme['$color_44'];

// betslip bonus information
theme['$betSlipBonusWrapperBackground'] = theme['$color_65'];
theme['$betSlipBonusWrapperText'] = theme['$color_27'];

// bestlip form group
theme['$betSlipFormGroupColor'] = theme['$color_27'];

// betslip freebet
theme['$betSlipFreebetWrapperBackground'] = theme['$color_55'];

// betslip cashback amount
theme['$betSlipCashbackAmountBorderColor'] = theme['$color_64'];

export default theme;
