import styled from 'styled-components';
import StyledLoader from 'StyledLoader.js';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$registerFormSubmitBtnBgColor}}) => `${$registerFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$registerFormSubmitBtnColor}}) => `${$registerFormSubmitBtnColor}`;
const WarningBgColor = ({theme:{$accountProfileFormCoverBg}}) => `${$accountProfileFormCoverBg}`;
const TextMainColor = ({theme:{$accountMenuColor}}) => `${$accountMenuColor}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.ResponsibleGamingForm = styled(Form)`
    width: ${rem('500px', baseFont)};
`;

S.CloseModalButton = styled(Button)`
    position: absolute;
    right: ${rem('16px', baseFont)};
    top: ${rem('10px', baseFont)};
    padding: 0;
    transform: none;
    line-height: 1;
    cursor: pointer;
    color: #000;
    background-color: transparent;
    &:before{
      content: "\\00d7";
      font-size: ${rem('35px', baseFont)};
    }
`;

S.Warning = styled.div`
    display: flex;
    padding: 1rem 0;
    font-size: 16px;
    text-align: center;
    color: black;
    background-color: ${WarningBgColor};
    &.modalView{
        justify-content: center;
        font-size: 14px;
    }

`;

S.LoaderWrapper = styled.div`
    width: ${rem('584px', baseFont)};
`;

S.Row = styled.div`
    width: 100%;
`;

S.Cover = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 5;
    
    & ${StyledLoader.LoaderWrapper}{
    }
`;


S.Label = styled.label`
    display: flex;
    overflow: hidden;
    margin-bottom: 2px;
    font-size: 14px;
    font-weight: normal;
    text-overflow: ellipsis;
    color: ${TextMainColor};
`;

S.Input = styled(Input)`
    display: block;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    width: 100%;
    min-width: 100px;
    height: 100%;
    background-color: #fff;
    font-size:  ${rem('16px', baseFont)};
    font-weight: ${FwSemibold};
    text-align: center;
    color: rgba(0, 0, 0, 0.7);
    
    &:valid{
    }
    &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                }
    }

    .modal-view & {
        border: 1px solid rgba(0, 0, 0, 0.25);
    }

    &.has-error{
       border: 1px solid #D32F2F;
    }
    
    &.is-valid{
        border: 1px solid #63dd7d;
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
   
`;

S.SubmitBtn = styled(Button)`
    display: block;
    margin: 10px 0 45px 0;
    border: 1px solid #CE0006;
    border-radius: 5px;
    width: ${rem('600px', baseFont)};
    height: ${rem('48px', baseFont)};
    background-color: #CE0006;
    cursor: pointer;
    font-weight: ${FwSemibold};
    font-size: ${rem('14px', baseFont)};
    text-transform: uppercase;
    color: ${SubmitBtnColor};
    &[disabled]{ 
        border: 1px solid gray;
        background-color: gray
    }
`;

S.SubmitBtnModalView = styled(S.SubmitBtn)`
    margin: 0;
    width: 45%;
`;

S.CancelButton = styled(S.SubmitBtn)`
    background-color: #fff;
    border: 1px solid ${SubmitBtnBgColor};
    color: ${SubmitBtnBgColor};
`;

S.CancelButtonModalView = styled(S.CancelButton)`
    margin: 0;
    width: 45%;
`;

S.Error = styled.div`
    color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0 0 2px 2px;
    padding: 2px .85rem 2px 0;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.LimitUpdateResponse = styled(S.SubmissionError)`
    background-color: transparent;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
`;

S.Select = styled.div`
    cursor: pointer;
    align-items: stretch;
    display: block;

    & .react-select__menu{
        margin-top: 0;
        z-index: 5;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
                  .react-select__option {
               color: rgba(0, 0, 0, 0.7);
              }
              
            &--is-multi {
                .react-select__option {
                    color: #777D85;
                    &--is-focused {
                       background-color: #E8E8E8;
                    }
                    &--is-selected {
                        background-color: inherit;
                        font-weight: 600;
                        color: #262626;
                        
                        &.react-select__option {
                            &--is-focused {
                                background-color: #E8E8E8;
                            }
                        }
                    }
                }                
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: ${rem('42px', baseFont)};
        border: 1px solid rgba(0, 0, 0, 0.25);
        align-items: flex-start;
        background-color: #fff;
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
        
        & .react-select__clear-indicator{
            display: none;
        }
        
        & .react-select__indicator-separator{
            display: none;
        }
        
        &--is-focused {
            border-color: #E8E8E8;
        }
    
        & .react-select__value-container{
             margin-top: ${rem('10px', baseFont)};
             margin-left: ${rem('5px', baseFont)};
             margin-right: ${rem('15px', baseFont)};
            & .react-select__single-value{
                color: rgba(0, 0, 0, 0.7);
                font-size: 1.1666666666666667rem;
            }
            &--is-multi {
                flex-wrap: nowrap;
                text-overflow: ellipsis;
                max-width: 90%;
                white-space: nowrap;
                overflow: hidden;
                display: initial;
            }
        }
    
    }

`;

S.Currency = styled.div`
    position: absolute;
    right: 8px;
    top:  ${rem('13px', baseFont)}
`;

S.Hour = styled(S.Currency)`
    text-transform: lowercase;
`;

S.InputWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 60px;
    &.with-select{
        height: auto;
    }
`;

S.Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem('42px', baseFont)} 0 ${rem('50px', baseFont)}
`;

S.Title = styled.h2`
   margin: 0;
   color: rgba(0, 0, 0, 0.87);
    font-size: ${rem('26px', baseFont)};
    line-height: ${rem('33px', baseFont)};
    padding: 3rem 0;
    font-weight: ${FwRegular};
    float: none!important;
    text-align: center;
`;

S.Body = styled.div`
      padding: 0 ${rem('30px', baseFont)};
`;

S.BodyInner = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

S.Header = styled.div`
    font-size: 22px;
    font-weight: bold;
`;

S.Footer = styled.div`
    display: flex;
    flex-direction: column;
`;

S.Info = styled.div`
 font-size: ${rem('13px', baseFont)};
  line-height: ${rem('20px', baseFont)};
 color: #777D85;
color: rgba(0, 0, 0, 0.87);
 margin-bottom:  ${rem('20px', baseFont)};
`;

S.InfoTitle = styled.div`
 font-size: ${rem('16px', baseFont)};
  line-height: ${rem('20px', baseFont)};
  color: rgba(0, 0, 0, 0.87);
   margin-bottom:  ${rem('20px', baseFont)};
   font-weight: ${FwSemibold};
   text-align: center;
`;

S.LimitInfo  = styled.div`
   font-size: 12px;
   color: #808080;
`;

S.LimitValue = styled.div`
  font-weight: ${FwSemibold};
  font-size: ${rem('13px', baseFont)};
  color: rgba(0,0,0, 0.87);
  margin-left: ${rem('5px', baseFont)};
  align-self: center;
  line-height: 1;
`;

S.ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2.5rem;
`;

S.Limit = styled.div`
  color: ${({status})=>(status?'green':'red')};
  margin-bottom: ${rem('10px', baseFont)};
  &:last-of-type{
    margin-bottom: 0;
  }
`;

S.LimitName = styled.div`
  font-weight: ${FwSemibold};
`;

S.LimitText = styled.div``;

export default S;