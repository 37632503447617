import React from 'react';
import S from 'StyledPagination.js';

const Pagination = ({ currentPage, limit, data, prevData, nextData, cb, useNewPagination }) => {
  const setPage = (pageNumber) => {
    if (pageNumber < 0) pageNumber = 0;
    cb(pageNumber);
  };

  const isPreviousPageDisabled = useNewPagination
    ? prevData.length === 0
    : currentPage === 0

  const isNextPageDisabled = useNewPagination
    ? nextData.length === 0 || data.length < limit
    : data.length < limit

  return (
    <S.Pagination className="pagination">
      <S.PaginationItem className="pagination-item">
        <S.PaginationBtn
          className="pagination-btn-prev ion ion-ios-arrow-left"
          onClick={() => setPage(currentPage - 1)}
          disabled={isPreviousPageDisabled}
        ></S.PaginationBtn>
      </S.PaginationItem>
      <S.PaginationItem className="pagination-item">
        <S.PaginationBtn
          className="pagination-btn-next ion ion-ios-arrow-right"
          onClick={() => setPage(currentPage + 1)}
          disabled={isNextPageDisabled}
        ></S.PaginationBtn>
      </S.PaginationItem>
    </S.Pagination>
  );
};

export default Pagination;
