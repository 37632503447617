import React, {useState}  from 'react';
import _get from 'lodash/get';
import S from 'StyledBetHistoryTransactionItem.js';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect, useHistory} from 'react-router-dom';
import useToggle from 'UseToggle.js'
import {formatDate} from 'helpers/datesHelper.js';
import {translation, redirect} from 'helpers/utilsHelper';
import {getTransactionResultStatusText} from 'helpers/transactionsHelper.js'
import betSlipTypes from 'betSlipTypes.enum.js';

const BetHistoryTransactionItem = ({
    transaction,
    cashouts,
    activeFreebets,
    history
}) => {

    const {transactionId, regDate, stake, possibleReturn, cashoutCommisionAmount, confirmedReturn, bonus, statusCode, slipType, curtainActive = false, maxAmount = '', cashoutAmount, cashBackReturn, transactionType} = transaction;
    const [showTransactionDetails, toggleTransactionDetails] = useToggle(false);

    const isCashoutBelowStake = confirmedReturn - cashoutCommisionAmount < stake  && statusCode == 5

    // poprawić
    const getTransactionStatusClass = (statusCode, stake, confirmedReturn, pretty, cashBackReturn) => {
        if (cashBackReturn) {
            return 'cashback';
        }

        let resultText = '';

        if (statusCode == 2) {
            resultText = getTransactionResultStatusText(app.config.betIsWonIfConfirmedReturnIsGtZero,stake,confirmedReturn)

        } else {

            switch (statusCode) {
                case 0:
                    resultText = 'pending_acceptation';
                    break;
                case 1:
                    resultText = 'placed';
                    break;
                case 2 :
                    resultText = 'placed';
                    break;
                case 4 :
                case 10 :
                    resultText = 'cancel';
                    break;
                case 5 :
                    resultText = 'cashout';
                    break;
                default:
                    resultText = 'default';
                    break;
            }
        }

        if (pretty && resultText) {
            resultText = resultText.substring(0, 1).toUpperCase() + resultText.substring(1, resultText.length);
        }

        return resultText;
    };

    const checkCashout = (slipId) => {
        const multiCashoutAvailable = process.env.MULTI_CASHOUT_AVAILABLE && JSON.parse(process.env.MULTI_CASHOUT_AVAILABLE);
        if (multiCashoutAvailable) {
            return cashouts && (slipId in cashouts) && getCashoutAmmountForTxId(slipId) != null;
        } else {
            return (cashouts && cashouts.indexOf(slipId) !== -1);
        }
    };

    const getCashoutAmmountForTxId = (slipId) => {
        return _get(cashouts, [slipId, 'currentCashoutAmount']);
    };

    const checkFreebet = (slipId) => {
        return (activeFreebets && activeFreebets.indexOf(slipId) !== -1);
    };

    const parseSlipType = (slipType) => {
        return betSlipTypes.getByType(slipType).name;
    };

    const redirectToTransactionDetails = (e,transactionId) => {
        e.stopPropagation();
        history.push('/konto/historia-kuponow/'+transactionId);
    }

    const transactionStatusClass = getTransactionStatusClass(statusCode, stake, confirmedReturn, null, cashBackReturn);

    const renderCashoutOptions = () => {
        if(checkCashout(transactionId) && !checkFreebet(transactionId)){
            return <S.CashoutIcon className={"uncashouted"}></S.CashoutIcon>
        } else if(statusCode == 5) {
            return <S.CashoutIcon className={"cashouted"}>{translation('account_betHistory_cashouted')}</S.CashoutIcon>
        } else {
            return <S.CashoutIcon></S.CashoutIcon>
        }
    }

    return ( transactionType !== 3943 && transactionType !== 3948 &&
        <S.BetHistoryItemWrapper className="bet-history-item-wrapper" onClick={(e) => redirectToTransactionDetails(e, transactionId)}>

            <S.BetHistoryItem className="bet-history-item">

                <div className={`transaction-status ${transactionStatusClass}`}>
                    <S.StatusIcon className={transactionStatusClass}></S.StatusIcon>
                </div>

                <div className="transaction-type">
                    <span>{translation(parseSlipType(slipType))}</span>
                </div>


                <div className={`transaction-date${(process.env.CASHOUT) ? '-cashout' : ''}`}>
                    <span>{formatDate(regDate, 'HH:mm')}</span>
                </div>
                {process.env.CASHOUT && (<div className="transaction-cashout">
                    {renderCashoutOptions()}
                </div>)}

                <div className="transaction-cashback">
                    {transactionStatusClass === "cashback" && (
                        <S.StatusIcon className={transactionStatusClass}></S.StatusIcon>
                        )
                    }
                </div>
                
                <div className={`transaction-amount${(process.env.CASHOUT) ? '-cashout' : ''} ${transactionStatusClass}`}>
                    {stake && stake.toFixed(2)}&nbsp;{translation('common_pln')}
                </div>

                <div className={`transaction-confirmed-win ${transactionStatusClass}`}>
                    {isCashoutBelowStake ?
                        `${(confirmedReturn - cashoutCommisionAmount).toFixed(2)} ${translation('common_pln')}` :
                        `${confirmedReturn.toFixed(2)} ${translation('common_pln')}`
                    }
                </div>

            </S.BetHistoryItem>

        </S.BetHistoryItemWrapper>
    );
};


export default withRouter(BetHistoryTransactionItem);
