import React from 'react';
import {Route, Switch, Redirect, useParams} from 'react-router-dom';
import AccountBetHistory from 'AccountBetHistory.js';
import AccountBetHistoryItemDetails from 'BetHistoryItemDetails.js';
import AccountEditProfile from 'AccountEditProfile.js';
import AccountWallet from 'AccountWallet.js';
import AccountCashback from 'AccountCashback.js';
import AccountFreebet from 'AccountFreebet.js';
import AccountBonus from 'AccountBonus.js';
import AccountDeposit from 'AccountDeposit.js';
import AccountWithdraw from 'AccountWithdraw.js';
import AccountResponsibleGame from 'AccountResponsibleGame.js';
import AccountSessionHistory from 'AccountSessionHistory.js';

const AccountRoute = (props) => {

    const { accountData } = props;
    
    return (
        <Switch>
            <Route path="/konto/edycja" render={(props) => <AccountEditProfile {...props} accountData={accountData}/>}/>
            <Route path="/konto/portfel"  render={(props) => <AccountWallet {...props}/>}/>
            <Route path="/konto/historia-kuponow/:transactionId" render={(props) => <AccountBetHistoryItemDetails {...props}/>}/>
            <Route path="/konto/historia-kuponow" render={(props) => <AccountBetHistory {...props}/>}/>
            <Route path="/konto/cashback" render={(props) => <AccountCashback {...props}/>}/>
            <Route path="/konto/freebety" render={(props) => <AccountFreebet {...props}/>}/>
            <Route path="/konto/bonus" render={(props) => <AccountBonus {...props}/>}/>
            <Route path="/konto/wplata" render={(props) => <AccountDeposit {...props}/>}/>
            <Route path="/konto/wyplata" render={(props) => <AccountWithdraw {...props}/>}/>
            <Route path="/konto/odpowiedzialna-gra" render={(props) => <AccountResponsibleGame {...props}/>}/>
            <Route path="/konto/historia-sesji" render={(props) => <AccountSessionHistory {...props}/>}/>
            <Redirect to="/konto/edycja"/>
        </Switch>
    )
};

export default AccountRoute;