import styled from 'styled-components';

const S = {};

S.GameSelect = styled.div`
    background-color: ${({ theme: { $sportFilterContainerBackground } }) => $sportFilterContainerBackground};
    padding: 1px 0;
    display: flex;

    & .react-select__control {
        box-shadow: none;
        min-height: 0;
        border: none;
        cursor: pointer;
        background-color: ${({ theme: { $sportFilterBackground } }) => $sportFilterBackground};
        color: ${({ theme: { $sportFilterText } }) => $sportFilterText};
        border-radius: 0;
        width: 100%;
        height: 28px;

        & .react-select__indicators{
            background-color: ${({ theme: { $sportFilterButtonBackground } }) => $sportFilterButtonBackground };
            margin: 8px;
            border-radius: 3px;
            width: 14px;
            height: 14px;
            & .react-select__indicator-separator {
                display: none;
            }

            & .react-select__indicator {
                padding: 0;
                & svg {
                    width: 14px;
                    height: 14px;
                    & path {
                        fill: ${({ theme: { $sportFilterButtonColor } }) => $sportFilterButtonColor};
                    }
                }
            }
        }

        & .react-select__value-container{
            font-size: 14px;
            & .react-select__placeholder, 
            & .react-select__single-value {
                color: ${({ theme: { $sportFilterText } }) => $sportFilterText };
            }
        }
    }

    & .react-select__menu {
        margin-top: 0;
        background-color: ${({ theme: { $sportFilterDropdownBackground } }) => $sportFilterDropdownBackground };
        color: ${({ theme: { $sportFilterDropdownText } }) => $sportFilterDropdownText };
        border-radius: 0;

        & .react-select__menu-list{
            padding: 0;
            & .react-select__option--is-selected,
            & .react-select__option--is-focused {
                background-color: ${({ theme: { $sportFilterDropdownBackgroundHover } }) => $sportFilterDropdownBackgroundHover};
                color: ${({ theme: { $sportFilterDropdownTextHover } }) => $sportFilterDropdownTextHover };
            }

            & > div{
                cursor: pointer;
            }
        }
    }
`;

export default S;