import styled from 'styled-components';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;

const baseFont = `12px`;
const S = {};

S.ModalBackground = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FontFamily};
  background-color: ${({backgroundCurtain})=> (backgroundCurtain!=null) ? backgroundCurtain : 'rgba(0,0,0,.65)' }};
`;

S.ModalWrapper = styled.div`
    z-index: 99;
    margin: ${({positionByMargin})=> (positionByMargin!=null) ? positionByMargin : '0 auto 0 auto' }};
    position: relative;
    border-radius: 0;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: ${({ height }) => (height != null) ? height : 'auto' }};
    padding: ${({padding})=> (padding!=null) ? padding : '30px' }};
    width: ${({ width }) => (width != null) ? width : 'auto' }};
    background-color: ${({backgroundWrapper})=> (backgroundWrapper!=null) ? backgroundWrapper : '#fff' }};
`;

S.ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({headerPadding})=> (headerPadding!=null) ? headerPadding : '42px 0 50px' }};
`;

S.ModalTitle = styled.h2`
   margin: 0;
   color: #000000DE;
    font-size: ${rem('26px', baseFont)};
    padding: 0;
    font-weight: ${FwRegular};
    float: none!important;
    text-align: center;
    text-transform: uppercase;
`;


S.UserIconContainer = styled.div`
    width: 3rem;
    height: 3rem;
`;

S.CloseIconContainer = styled.div`
    position: absolute;
    right: ${rem('16px', baseFont)};
    top: ${rem('10px', baseFont)};
    padding: 0;
    transform: none;
    line-height: 1;
    cursor: pointer;
    color: ${({closeIconColor})=> (closeIconColor!=null) ? closeIconColor : '#000' }};
    &:before{
      content: "\\00d7";
      font-size: ${rem('35px', baseFont)};
    }
`;

S.ModalBody = styled.div`
  display: flex;
  max-height: 100%;
  min-height: ${({minHeightBody})=> (minHeightBody!=null) ? minHeightBody : 'inherit' }};
  min-width: ${({minWidthBody})=> (minWidthBody!=null) ? minWidthBody : 'inherit' }};
  justify-content: ${({justifyContentBody})=> (justifyContentBody != null) ? justifyContentBody : 'inherit' }};
`;

export default S;