import React, { Component } from 'react';
import Select from 'react-select';
import _find from 'lodash/find';
import _each from 'lodash/each';
import { translation, redirect } from 'helpers/utilsHelper.js';
import S from 'StyledGameFilter.js';


class GameFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gameList: null,
            selectedGame: {
                value: null,
            },
        };
    };

    componentDidMount = () => {
        this.setState({
            gameList: app.service.Dict.getGameTypes(),
        })
    };

    getGamesList = () => {
        const options = [];

        _each(this.state.gameList, ( {value, name} ) => {
            const gameCategory = {
                value: value,
                label: name,
            };
            options.push(gameCategory);
        })
        return options;
    };

    selectedOption = () => {
        const selectedGame = this.state.selectedGame;
        const selectedValue = _find(this.getGamesList(), { value: Number(selectedGame) });
        return selectedValue;
    };

    onGameChange = ({ value }) => {
        const selectedGame = value;
        this.setState({
            selectedGame: _find(this.getGamesList(), { value: Number(selectedGame) })
        })

        this.redirectToEventList(selectedGame);
    };

    redirectToEventList = (selectedGame) => {
        const params = {
            gameType: selectedGame,
        };

        redirect('/search/type/:gameType', params);
    };

    render() {
        return (
            <S.GameSelect
                as={Select}
                options={this.getGamesList()}
                value={this.selectedOption()}
                onChange={this.onGameChange}
                placeholder={translation('filters_gameFilterPlaceholder')}
                classNamePrefix={'react-select'}
            />
        )
    }
}

export default GameFilter;