import React, {memo} from 'react';
import {translation} from 'utilsHelper.js';
import S from 'StyledBetSlipTypesTabs.js';

const BetSlipTypesTabs = (props) => {
    const {changeSlipType, slipType, clearBetslip, isSingle, hasApprovalButtonsVisibled, sticked, toggleSticked} = props;

    return (
        <S.BetSlipTypesTabs className="betslip-types-tabs">

            <S.BetSlipTypeTab className="betslip-types-tab"
                              isActive={slipType == 'ACCUMULATOR'}
                              onClick={changeSlipType.bind(null, 'ACCUMULATOR')}
                              data-test='betSlip_tab_accumulator'
                              disabled={hasApprovalButtonsVisibled}>
                {isSingle ? translation('betSlip_tab_single') : translation('betSlip_tab_accumulator')}
            </S.BetSlipTypeTab>

            <S.BetSlipTypeTab className="betslip-types-btn"
                              isActive={slipType == 'SYSTEM'}
                              onClick={changeSlipType.bind(null, 'SYSTEM')}
                              data-test='betSlip_tab_system'
                              disabled={hasApprovalButtonsVisibled}>
                {translation('betSlip_tab_system')}
            </S.BetSlipTypeTab>

            <S.LockBetSlip onClick={toggleSticked} isSticked={sticked} className="fa fa-anchor"></S.LockBetSlip>

        </S.BetSlipTypesTabs>
    );
};

export default memo(BetSlipTypesTabs);
