import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import Box from "react-styled-box";
import S from "StyledQuickSearch.js";
import _debounce from "lodash/debounce";
import { translation, highlightMatchesInString, getUrlPreparedString, getUrlPreparedEventName } from "helpers/utilsHelper.js";
import { searchIconSVG } from "global/svgIcons/svgIcons.js";
import { getQuickSearchResults, clearQuickSearchResults, saveChoiceHint  } from "quickSearchActions.js";
import { CSSTransition } from 'react-transition-group';

const ResultsBox = ({ events, error, inputValue, hideResults, saveChoiceHint}) => {


    

    const returnHtmlObject = (stringHtml, inputValue) => {
        return {
            __html: highlightMatchesInString(stringHtml, inputValue)
        }
    }

    const goToEvent = (eventId, area, name, extras) => {

        process.env.SAVE_CHOICE_FOR_HINT && saveChoiceHint(area, eventId, name)
        const extrasCategoryName = extras.replaceAll('|@|', '/')
        const eventName = getUrlPreparedString(name)

        const url = (area === 'PREMATCH_EVENT') ? `zaklady-bukmacherskie/${extrasCategoryName}/${eventName}/:eventId/full` : `zaklady-live/event/${eventId}`;

        app.router.redirect(url, {eventId});
        hideResults();
    }

    const renderEvent = ({name, id, area, extras}) => {
        let urlEncodedCategoryName;
        if(extras) {
            let { CATEGORY_NAME_1, CATEGORY_NAME_2, CATEGORY_NAME_3 } = extras;
            urlEncodedCategoryName = getUrlPreparedString(CATEGORY_NAME_1 || '') + splitter + getUrlPreparedString(CATEGORY_NAME_2 || '') + splitter + getUrlPreparedString(CATEGORY_NAME_3 || '');
        }
        let urlEncodedEventName = getUrlPreparedEventName(name);

        const eventName = returnHtmlObject(name, inputValue.current.value);
        return (
           <S.ResultItem key={id} onClick={() => goToEvent(id, area, urlEncodedEventName, urlEncodedCategoryName)} dangerouslySetInnerHTML={eventName}></S.ResultItem> 
        )
    }

    const splitter = '|@|';

    return (
        events.length 
            ? <S.ResultsBox> { events.map(renderEvent) } </S.ResultsBox>
            : <S.ResultsBox as="div" center={true}>{error ? error : translation('quickSearch_noResults')}</S.ResultsBox>
    )
}

class QuickSearch extends Component {
    state = {
        showResultBox: false
    };

    searchInput = createRef();

    onFormSubmitHandler = e => {
        e.preventDefault();
        if(this.searchInput.current.value.length >= 3) {
            setTimeout(() => {
                const encodedPhrase = encodeURIComponent(this.searchInput.current.value);
                this.hideResultsAndClearInput();
                app.router.redirect(`/quick-search-results/${encodedPhrase}`);
            }, 150)
        }
    };

    debouncedOnInputChange = _debounce(async () => {
        if (this.searchInput.current.value.length >= 3) {
            await this.props.getQuickSearchResults(this.searchInput.current.value);
            this.setState({ showResultBox: true });
        } else {
            this.setState({ showResultBox: false });
        }
    }, 150);

    clearResults = () => {
        this.props.clearQuickSearchResults();
    }

    hideResultsAndClearInput = () => {
        this.setState({showResultBox: false});
        this.searchInput.current.value = '';
    }


    render() {
        const { isPending, events, error, saveChoiceHint } = this.props;
        const { showResultBox } = this.state;

        const classNames= {
            enter: 'animated bounceIn',
            exit: 'animated bounceOut'
        }

        return (
            <S.QuickSearchWrapper
                as={Box}
                alignSelf="center"
                justifyContent="center"
                flexDirection="column"
            >
                <S.QuickSearch
                    className="search-keyword"
                    onSubmit={this.onFormSubmitHandler}
                >
                    <S.QuickSearchInput
                        ref={this.searchInput}
                        className="search-input"
                        autocomplete="off"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="false"
                        onChange={this.debouncedOnInputChange}
                    />
                    <S.Label>{translation("search_placeholder")}</S.Label>
                    {!showResultBox && !isPending
                        ? <S.SubmitButton
                            type="submit"
                            dangerouslySetInnerHTML={searchIconSVG()}
                        ></S.SubmitButton> 
                        : null
                    }
                    {isPending ? <S.Loader size="2" /> : null}
                    {showResultBox && !isPending ? <S.ClearResults onClick={this.hideResultsAndClearInput}></S.ClearResults> : null}
                </S.QuickSearch>
                <CSSTransition in={showResultBox} unmountOnExit mountOnEnter timeout={700} classNames={{...classNames}} onExited={this.clearResults}>
                    <ResultsBox events={events} error={error} inputValue={this.searchInput} hideResults={this.hideResultsAndClearInput} saveChoiceHint={saveChoiceHint}/>
                </CSSTransition>
            </S.QuickSearchWrapper>
        );
    }
}

const mapStateToProps = ({ QuickSearch: { isPending, events, error } }) => {
    return {
        isPending,
        events,
        error
    };
};

const mapDispatchToProps = {
    getQuickSearchResults,
    clearQuickSearchResults,
    saveChoiceHint
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
