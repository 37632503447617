import React from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import S from 'StyledAccountMenu.js';
import _map from 'lodash/map';
import accountMenuItems from 'accountMenuItems.enum.js';
import {translation, redirect} from 'helpers/utilsHelper';
import classNames from 'classnames';


const AccountMenu = ({logOut}) => {

    const logOutUserBtnAction = () => {
        redirect('/zaklady-bukmacherskie');
        logOut(true);
    };


    const onUserDataLinkClick = (event, url) => {
        event.preventDefault();
        redirect(url);
    };

   
    return (
        <S.AccountMenu className="account-menu">
            {_map(accountMenuItems, ({translationKey, href, file, dataTest, ...rest}, itemName) => {
               
                let isImageContainerClass = classNames({
                    'transactionImg': itemName == 'DEPOSIT' || itemName == 'WITHDRAW' ? true : false,
                    'cashbackImg': itemName == 'CASHBACK' ? true : false,
                    'transferImg': itemName == 'TRANSFER' ? true : false,
                    'responsibleGamingImg': itemName == 'RESPONSIBLE_GAMING' ? true : false
                });

                return (
                   
                    <S.AccountMenuItem key={itemName} className="account-menu-item">

                        { itemName == 'LOGOUT' 
                            ?
                            (
                                <>
                                <S.AccountMenuLink onClick={ () => logOutUserBtnAction() }>

                                    <S.Image as="div" className={isImageContainerClass} dangerouslySetInnerHTML={{__html: file}}
                                            data-test={dataTest}/>                   
                                    <S.AccountMenuLabel className="account-menu-label"> {translation(translationKey)} </S.AccountMenuLabel>

                                </S.AccountMenuLink>
                                </>
                            )
                            :
                            (
                                <>
                                <S.AccountMenuLink {...rest} as={NavLink} to={href} onClick={(e) => onUserDataLinkClick(e,href) }
                                                data-test={dataTest}
                                                activeClassName="active">
                    
                                    <S.Image as="div" className={isImageContainerClass} dangerouslySetInnerHTML={{__html: file}}/>                   
                                    <S.AccountMenuLabel className="account-menu-label"> {translation(translationKey)} </S.AccountMenuLabel>
            
                                </S.AccountMenuLink>
                                </>
                            )
                        }

                    </S.AccountMenuItem>
                )
            })}
        </S.AccountMenu>
    );

};

export default withRouter(AccountMenu);
