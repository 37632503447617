import styled from 'styled-components';
import StyledDatepicker from 'StyledDatepicker.js';
import StyledModal from 'StyledModal.js';
import {List, Button} from 'global/styles/styles.js';
const S = {};

S.AccountQueueBonus = styled.div`
    width: 100%;
    padding: .8333rem 0 0;
`;
S.BonusFilters = styled.div`
    display: flex;
    justify-content: space-between;
`;
S.BonusFiltersDates = styled.div`
    display: flex;
    & > div{
         margin-right: 10px;
         &:last-of-type{
             margin-right: 0;
         }
    }
`;
S.DateWrapper = styled.div`
    width: 237px;
    height: 46px;
    display: flex;
    position: relative;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
    border-right: 2px solid #eee;
    & .react-datepicker-wrapper,
    & .react-datepicker__input-container{
       display: flex;
    }
    & .react-datepicker-wrapper{
       width: calc(100% - 4.5rem);
    }
    & .react-datepicker__input-container{
       width: 100%;
    }
    
    ${StyledDatepicker.Datepicker}{
        flex: 1;
        padding-left: 10px;
        border: none;
        font-size: 17px;
        font-weight: 700;
        width: 100%;
    }
`;
S.DateLabel = styled.div`
    display: flex;
    background-color: #eee;
    font-weight: 700;
    color: #676767;
    width: 4.5rem;
    justify-content: center;
    align-items: center;
`;
S.DateIcon = styled.i`
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 33px;
    font-size: 1.67rem;
    justify-content: center;
    align-items: center;
    display: flex !important;
    pointer-events: none;
`;

S.BonusListHeader= styled.div`
    background-color: #a3a3a3;
    color: #fff;
    padding: 1rem;
    font-weight: 500;
    border-bottom: .1rem solid #fff;
    font-size: 1.5rem;
    font-size: 1.5rem;
`;

S.Table = styled.table`
    width: 50rem;
    margin-bottom: 20px;
    margin-top: 0;
`;

S.Thead = styled.thead`
`;

S.Tbody = styled.tbody`
    &:before {
        content:"@";
        display:block;
        line-height:1.1667rem;
        text-indent:-99999px;
    }
`;

S.Tr = styled.tr`
    border-top: 0;

    &.queueHeader {
        height: 3.1667rem;
        border-bottom: 2px solid ${({theme:{$accountProfileBonusTabActiveHeaderBorder}})=> $accountProfileBonusTabActiveHeaderBorder};
    }

    &:before,
    &:after{
        display: none;
    }

    &.no-result{
        height: 40px;
        padding: 0 1.25rem;
        line-height: 4rem!important;
        font-size: 1rem;
        background-color: #f3f3f3;
        color: #000;
        border-right: .08rem solid #fff;
        border-top: .08rem solid #fff;
    
        & > td{
            text-align: center;
        }
    }
    &.spacer {
        display: block;
        height: 2px;
        background: none;
    }
    
`;

S.Td = styled.td`
    height: 5rem;
    padding: 0 1.25rem;
    font-size: 1rem;
    color: #000;
    vertical-align: middle;
    text-align: center;
    background-color: ${({theme:{$accountProfileBonusTabActiveBonusRowBg}})=> $accountProfileBonusTabActiveBonusRowBg};

    &.activeBonusRowDate {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &.bonus-actions{
        text-align: center;
    }
    &.amount {
        width: 25%;
    }
    &.pagination{
        background-color: transparent;
    }
        &.wageredPercent{
        width: 15%;
    }
    &:first-child {
        padding-left: 1.3333rem;
        font-size: 1.3333rem;
        font-weight: 600;
        text-align: left;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        & span {
            display: inline-block;
            width: 152px;
        }
    }
    &:last-child {
        width: 8%;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
    &:nth-child(2){
        width: 33%;
    }

    &.noResults {
        background-color: none;
        text-align: center;
    }

    & span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;

S.Th = styled.th`
    background-color: ${({theme:{$accountProfileBonusTabActiveHeaderBg}})=> $accountProfileBonusTabActiveHeaderBg};
    color: ${({theme:{$accountProfileBonusTabActiveHeaderTxt}})=> $accountProfileBonusTabActiveHeaderTxt};
    padding: 0 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    vertical-align: center;
    text-align: center;
    border-top: 0;

    &:first-child {
        width: 40%;
        padding-left: 3.4167rem;
        text-align: left;
    }
    &:nth-child(4) {
        width: 18%;
    }
`;

S.BonusList = styled.div`
    display: flex;
    justify-content: center;
`;


S.NoResult = styled.tr`
    height: 40px;
    text-align: center;
`;

S.Button = styled(Button)`
    display: block;
    line-height: 1.6rem;
    text-align: center;
    color: #fff;
    border-radius: 0.25rem;
    border: 0;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    &.reject{
        background-color: #bebebe;
    }
    &.accept{
        background-color: #00a651;
    }
    &[disabled]{
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

S.ActivateModal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: -30px;
    color: ${({theme:{$accountProfileBonusModalsTextColor}})=> $accountProfileBonusModalsTextColor};
`;

S.BtnsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

S.ButtonCancel = styled(Button)`
    color: #fff;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    padding: 6px 12px;
    background: #00a651;
    margin-right: 1rem;
    margin-top: 1rem;
    background: ${({theme:{$accountProfileBonusModalsCancelBttnBg}})=> $accountProfileBonusModalsCancelBttnBg};
`;
S.ButtonAccept = styled(S.ButtonCancel)`
    margin-left: 1rem;
    margin-right: 0;
    background: ${({theme:{$accountProfileBonusModalsSuccessBttnBg}})=> $accountProfileBonusModalsSuccessBttnBg};
`;
S.Title = styled.div`
    font-size: 1.2307692308rem;
    letter-spacing: .5px;
    font-weight: 600;
    line-height: 2rem;
    text-align: center;
`;
S.Description = styled.div`
    text-align: center;
`;

S.ProgressCircularWrapper = styled.div`
    width: 50%;
    margin: 0 auto;
    padding: 5px;
`;


S.ModalInfo = styled.div`
    width: 100%;
    margin-top: -44px;
`;

S.InfoTitle = styled.h4`
    color: ${({theme:{$accountProfileBonusModalsTextColor}})=> $accountProfileBonusModalsTextColor};
    text-transform: uppercase;
    font-size: 1.83rem;
    padding: 0;
    font-weight: 800;
    float: none!important;
    text-align: center;
    margin-bottom: 2rem;
`;

S.InfoDescription = styled.div`
    text-align: center;
    color: ${({theme:{$accountProfileBonusModalsTextColor}})=> $accountProfileBonusModalsTextColor};
`;

export default S;