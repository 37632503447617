import React, {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {compose, bindActionCreators} from 'redux';
import S from 'StyledResponsibleGamingForm.js';
import {Field, Fields, reduxForm, SubmissionError, formValueSelector, destroy} from 'redux-form';
import {translation} from 'utilsHelper.js';
import {formatDate} from 'datesHelper.js';
import {loadCustomerLimits, updateCustomerLimits} from 'customerActions.js';
import classNames from 'classnames';
import Box from 'react-styled-box';
import Loader from 'Loader.js';
import Select from 'react-select';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _reduce from 'lodash/reduce';
import _set from 'lodash/set';
import _each from 'lodash/each';
import _compact from 'lodash/compact';
import _sortBy from 'lodash/sortBy';
import _omit from 'lodash/omit';
import {getLimitIdByKey} from 'limitTypes.enum.js';

const renderField = ({input, meta, disabled}, fieldName) => {

    const {touched, error, warning, asyncValidating, submitFailed} = meta;
    const inputClass = classNames({
        'async-validating': asyncValidating,
        'has-error': ((touched || submitFailed) && error),
    });

    const containsTimeLimits = ['limitType13', 'limitType15'].includes(fieldName);

    return (
        <Box key={fieldName} margin="0 0 15px 0" alignItems="center" height="60px">
            <Box flexDirection="column" flexGrow={1}>

                <S.Label>
                    {translation(`account_responsibleGaming_${fieldName}`)}
                    {disabled && (
                        <S.LimitValue>
                            {containsTimeLimits ? `${input.value} h` : `${input.value} ${translation('common_pln')}`}
                        </S.LimitValue>
                    )}
                </S.Label>

                {containsTimeLimits && (
                    <S.LimitInfo>{translation(`account_responsibleGameInfo_${fieldName}`)}</S.LimitInfo>
                )}

                {(touched || submitFailed) && ((error && <S.Error>{error}</S.Error>) || (warning &&
                                    <S.Error>{warning}</S.Error>))}

            </Box>

            {!disabled ? (
                    <Box width="100px">
                        <S.Row>
                            <S.InputWrapper>

                                <S.Input {...input} type="number" required="required" className={inputClass}
                                         disabled={disabled}/>

                            </S.InputWrapper>
                        </S.Row>
                    </Box>
                )
                :
                (
                    <input {...input} type="hidden"/>
                )
            }
        </Box>
    )
};

const renderSelect = ({
                          input,
                          options,
                          disabled,
                          resetFieldsToInitial,
                          meta: {touched, error, warning, valid, submitFailed}
                      }) => {
    const inputClass = classNames({
        'has-error': ((touched || submitFailed) && error),
        'is-valid': ((touched || submitFailed) && valid),
        'react-select-container': true
    });
    return (
        <S.Row>
            <S.InputWrapper className="with-select">
                <S.Select as={Select}
                          isDisabled={disabled}
                          options={options}
                          value={_find(options, {value: input.value})}
                          isSearchable={false}
                          classNamePrefix="react-select"
                          className={inputClass}
                          onChange={({value}) => {
                              input.onChange(value);
                              if (value == 'standard') {
                                  resetFieldsToInitial();
                              }
                          }}
                />
                {(touched || submitFailed) && ((error && <S.Error>{error[0]}</S.Error>) || (warning &&
                    <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

// validation rules
const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const isMonthlyLimitHigherThanDaily = (id) => {
    return (value, allValues, {customerLimits}) => {
        const monthlyTimeLimitId = getLimitIdByKey('MONTHLY_SESSION_TIME_LIMIT');
        let dailyLimitValue = 0;
        if (monthlyTimeLimitId == id) {
            const dailyTimeLimitId = getLimitIdByKey('DAILY_SESSION_TIME_LIMIT');
            dailyLimitValue = _get(allValues, [`limitType${dailyTimeLimitId}`], 0);
        } else {
            const dailyTimeLimitId = getLimitIdByKey('DAILY_STAKE_LIMIT');
            dailyLimitValue = _get(allValues, [`limitType${dailyTimeLimitId}`], 0);
        }

        return (parseFloat(value) < parseFloat(dailyLimitValue)) ? `${translation('limitUpdateInfo_monthlyLimitlowerThanDaily')}` : undefined;
    }
};

const minLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let defaultMinLimitValue = _get(limitById, ['minLimitValue'], 0);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            defaultMinLimitValue = Math.floor(defaultMinLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value < defaultMinLimitValue) ? `${translation('limitUpdateInfo_lowerThanDefault')} ${defaultMinLimitValue} ${(limits.indexOf(id) != -1)?' h':' pln'}` : undefined;
    }
};
const maxLimitByTypeId = (id) => {
    return (value, allValues, {customerLimits}) => {
        id = Number(id);
        const limitById = _find(customerLimits, {limitType: id});
        let defaultMaxLimitValue = _get(limitById, ['defaultMaxLimitValue']);

        const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
        if (limits.indexOf(id) != -1) {
            defaultMaxLimitValue = Math.floor(defaultMaxLimitValue / 60);
            value = Number(value);
            value = Math.floor(value / 60);
        }

        return (value > defaultMaxLimitValue) ? `${translation('limitUpdateInfo_error502')} ${defaultMaxLimitValue} ${(limits.indexOf(id) != -1)?' h':' pln'}` : undefined;
    }
};

const renderFields = (fields) => {
    const fieldNames = _get(fields, ['names']);
    const disabled = _get(fields, ['disabled']);
    let pickedFields = _pick(fields, fieldNames);
    pickedFields = _reduce(pickedFields, (initialObject, nextObj) => {
        const {input: {name}} = nextObj;
        _set(initialObject, [name], {...nextObj, disabled});
        return initialObject;
    }, {});
    return _map(pickedFields, renderField);
};

let ResponsibleGamingForm = (props) => {
    const {
        toggleConfirmationModal,
        isStandardLimitsSelected,
        destroyLimitFormOnUnload,
        updateCustomerLimits,
        loadCustomerLimits,
        isPending,
        toggleOpen,
        handleSubmit,
        submitting,
        error,
        change,
        initialValues,
        customerLimits,
        accountView,
        submitSucceeded,
        submitFailed,
        dirty,
        initialize
    } = props;

    useEffect(() => {
        loadCustomerLimits();

        if (!accountView) {
            window.addEventListener('beforeunload', handleBeforeUnload);
            window.addEventListener('unload', handleUnload);

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
                window.removeEventListener('unload', handleUnload);
            };
        }
    }, []);

    const handleBeforeUnload = (e) => {
        const confirmationMessage = 'Uwaga: Zostaniesz wylogowany jeśli zamkniesz ten widok przed zakończeniem procesu potwierdzania limitów.';
        e.returnValue = confirmationMessage;
        return confirmationMessage;
    };

    const handleUnload = () => {
        destroyLimitFormOnUnload();
    };

    const resetFieldsToInitial = () => {
        _each(fieldsMap, (fieldName) => {
            change(fieldName, _get(initialValues, [fieldName]));
        });
    };

    const onFormSubmit = async (values) => {
        try {
            let limitData = _pick(values, fieldsMap);
            limitData = _map(customerLimits, (limit) => {
                const {limitType} = limit;
                const limitAmount = _get(limitData, [`limitType${limitType}`]);
                if (limitAmount) {
                    let limitUpdated = {...limit, limitAmount};
                    return _omit(limitUpdated, ['exceptionCode']);
                }
                return null;
            });

            limitData = _compact(limitData);
            const updatedLimits = await updateCustomerLimits(limitData);

            if (!accountView) {
                toggleOpen();
                toggleConfirmationModal();
            }else{

                const data =_reduce(updatedLimits, (initialObject, {limitType, limitAmount})=>{
                    return {...initialObject, [`limitType${limitType}`]: limitAmount};
                }, {});

                data['limitSetting'] = 'standard';

                initialize(data);
            }

        } catch (errors) {
            throw new SubmissionError(errors);
        }
    };

    const options = useMemo(() => {
        const options = [
            {label: translation('account_responsibleGame_standardLimits'), value: 'standard'},
            {label: translation('account_responsibleGame_customLimits'), value: 'custom'}
        ];
        return options;
    }, []);

    const limitKeysToIdMap = useMemo(() => {
        const limitKeys = [
            'DAILY_SESSION_TIME_LIMIT',
            'MONTHLY_SESSION_TIME_LIMIT',
            'DAILY_STAKE_LIMIT',
            'MONTHLY_STAKE_LIMIT'
        ];
        return _map(limitKeys, (key) => {
            return getLimitIdByKey(key);
        });
    }, []);

    const fieldsMap = useMemo(() => {
        return _reduce(limitKeysToIdMap, (initialArray, id) => {
            return initialArray.concat(`limitType${id}`);
        }, []);
    }, []);

    const fieldsValidation = useMemo(() => {
        let validationRules = _reduce(limitKeysToIdMap, (initObject, id) => {
            const fieldName = `limitType${id}`;
            const maxLimit = maxLimitByTypeId(id);
            const minLimit = minLimitByTypeId(id);
            const allRules = [required, minLimit, maxLimit];

            const limits = _map(['MONTHLY_STAKE_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
            if (limits.indexOf(id) != -1) {
                allRules.push(isMonthlyLimitHigherThanDaily(id));
            }
            return _set(initObject, [fieldName], allRules);
        }, {});
        return validationRules;
    }, []);

    const fieldsParse = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToParse = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToParse.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value * 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const fieldsFormat = (value, name) => {
        const limitTypeKeys = ['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'];
        const fieldsToFormat = _map(limitTypeKeys, (key) => `limitType${getLimitIdByKey(key)}`);
        if (fieldsToFormat.indexOf(name) != -1) {
            if (value) {
                value = Number(value);
                value = Math.floor(value / 60);
            } else {
                value = '';
            }
        }
        return value;
    };

    const onCloseHandler = () => {
        var result = confirm('Uwaga: Zostaniesz wylogowany jeśli zamkniesz ten widok przed zakończeniem procesu potwierdzania limitów.');
        if (result) {
            toggleOpen();
        }
    };

    const showLimitUpdateResponse = (customerLimits) => {
        const todayUnix = new Date().getTime();
        const filteredCustomerLimits = _filter(customerLimits, ({limitType})=>limitKeysToIdMap.includes(limitType));
        const sortOrder = {
            13: 1,
            15: 2,
            1: 3,
            3: 4
        };
        const sortedCustomerLimits = _sortBy(filteredCustomerLimits, ({limitType})=>sortOrder[limitType]);
        const result = _reduce(sortedCustomerLimits, (initalArray, {limitType, limitAmount, oldLimitAmount, exceptionCode, requestedLimitDate = todayUnix, requestedLimitValidFrom = todayUnix, requestedLimitAmount}) => {

            let requestedLimit = null;
            if(requestedLimitAmount){
                const limits = _map(['DAILY_SESSION_TIME_LIMIT', 'MONTHLY_SESSION_TIME_LIMIT'], (key) => getLimitIdByKey(key));
                if (limits.indexOf(limitType) != -1) {
                    requestedLimit = Math.floor(requestedLimitAmount / 60);
                    requestedLimit+=' h';
                }else{
                    requestedLimit = `${requestedLimitAmount} ${translation('common_pln')}`;
                }
            }

            const limitName = translation(`account_responsibleGaming_limitType_${limitType}`);
            const requestedLimitValidFromDate = formatDate(new Date(requestedLimitValidFrom), 'yyyy-MM-dd HH:mm');
            const requestedNewLimitDate = formatDate(new Date(requestedLimitDate), 'yyyy-MM-dd HH:mm');
            const typeOfChange = requestedLimitAmount <= limitAmount ? 'decrease' : 'increase';

            let descriptionKey = exceptionCode && getLimitIdByKey(exceptionCode);
            descriptionKey = descriptionKey ?? exceptionCode;

            let message = translation(`account_responsibleGaming_limitUpdateInfo_${typeOfChange}Success`);
            if (typeOfChange == 'increase') {
                if (exceptionCode) {
                    message = translation(`account_responsibleGaming_limitUpdateInfo_error_${descriptionKey}`);
                    if (exceptionCode != 500) {
                        message += (' ' + translation(`account_responsibleGaming_limits_newLimitsStartsFrom`, [requestedNewLimitDate, requestedLimitValidFromDate, requestedLimit]));
                    }
                } else {
                    message += (' ' + translation(`account_responsibleGaming_limits_newLimitsStartsFrom`, [requestedNewLimitDate, requestedLimitValidFromDate, requestedLimit]));
                }
            }

            const status = !exceptionCode ? true : false;
            const limit = (
                <S.Limit key={limitType} status={status}>
                    <S.LimitName>{limitName}</S.LimitName>
                    <S.LimitText dangerouslySetInnerHTML={{__html: message}}/>
                </S.Limit>
            )
            return (exceptionCode!=500) ? [...initalArray, limit] : initalArray;

        }, []);
        return result;
    };

    if (isPending) {
        return <S.LoaderWrapper as={Loader} color="#F05A22"/>;
    }

    return (
        <S.ResponsibleGamingForm className={`responsible-gaming ${accountView ? '' : 'modal-view'}`} onSubmit={handleSubmit(onFormSubmit)} autocomplete="off"
                                 noValidate>

            {submitting && (<S.Cover><Loader color="#F05A22"/></S.Cover>)}

            {!accountView && (
                <S.Header>
                    <S.CloseModalButton type="button" onClick={onCloseHandler}></S.CloseModalButton>
                    <S.Title dangerouslySetInnerHTML={{__html: translation('account_responsibleGame_title')}}/>
                </S.Header>
            )}

            {accountView && (submitSucceeded||submitFailed) && (<S.LimitUpdateResponse>{showLimitUpdateResponse(customerLimits)}</S.LimitUpdateResponse>)}

            <S.Body>

            {!accountView &&
                <S.InfoTitle dangerouslySetInnerHTML={{__html: translation('account_responsibleGame_infoTitle')}}/>}

                {!accountView &&
                <S.Info dangerouslySetInnerHTML={{__html: translation('account_responsibleGame_info')}}/>}

                {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

                <Box flexDirection="column">

                    <S.BodyInner>
                        {accountView &&
                        <S.Header>{translation('account_responsibleGaming_header')}</S.Header>}
                        {accountView &&
                        <S.Warning>{translation('account_responsibleGame_restrictionMore')}</S.Warning>}

                        {!accountView && (
                            <Box margin="0 0 15px">
                                <Field
                                    name="limitSetting"
                                    component={renderSelect}
                                    options={options}
                                    resetFieldsToInitial={resetFieldsToInitial}
                                />
                            </Box>
                        )}

                        <Fields names={fieldsMap} validate={fieldsValidation} parse={fieldsParse} format={fieldsFormat}
                                component={renderFields} disabled={isStandardLimitsSelected}/>

                    </S.BodyInner>

                </Box>

            </S.Body>

            {accountView && (
                <S.SubmitBtn type="submit"
                    disabled={submitting||(!dirty&&accountView)}
                    data-test='account_responsibleGame_setLimits'>
                    {translation('account_responsibleGame_setLimits')}
                </S.SubmitBtn>)}

            {!accountView && (
            <S.Footer>
                
                <S.Warning className="modalView" dangerouslySetInnerHTML={{__html: translation('account_responsibleGame_warning')}}/>

                <S.ButtonsWrapper>

                    <S.CancelButtonModalView onClick={onCloseHandler}>
                        {translation('common_cancel')}
                    </S.CancelButtonModalView>

                    <S.SubmitBtnModalView type="submit"
                        disabled={submitting||(!dirty&&accountView)}
                        data-test='account_responsibleGame_setLimits'>
                        {translation('account_responsibleGame_setLimits')}
                    </S.SubmitBtnModalView>

                </S.ButtonsWrapper>

            </S.Footer>
            )}

        </S.ResponsibleGamingForm>
    );
};

const selector = formValueSelector('responsibleGamingForm');
const prepareInitialValues = (values, accountView) => {
    if (values) {
        values = _reduce(values, (initialObject, {limitType, defaultLimitValue, limitAmount}) => {
            const key = `limitType${limitType}`;
            const limitValue = accountView ? limitAmount: defaultLimitValue;
            _set(initialObject, [key], limitValue);
            return initialObject;
        }, {});
    }
    return values;
};

const mapStateToProps = (state, {accountView}) => {
    const {Customer: {customerLimits, isCustomerLimitsPending: isPending}} = state;
    const initialValues = prepareInitialValues(customerLimits, accountView);

    _set(initialValues, ['limitSetting'], 'standard');
    const isStandardLimitsSelected = (!accountView && selector(state, 'limitSetting') == 'standard');

    return {
        customerLimits,
        isPending,
        initialValues,
        isStandardLimitsSelected
    }
};

const mapDispatchToProps = (dispatch) => {
    const actionsToBind = {updateCustomerLimits, loadCustomerLimits};
    return {
        ...bindActionCreators(actionsToBind, dispatch),
        destroyLimitFormOnUnload: () => {
            dispatch(destroy('responsibleGamingForm'));
        }
    }
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'responsibleGamingForm',
        enableReinitialize: true
    })
)(ResponsibleGamingForm);
