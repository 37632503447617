import React, { useEffect, useState} from 'react';
import {connect} from 'react-redux';
import S from 'StyledBetHistoryItemDetails.js';
import {withRouter} from 'react-router-dom';
import {translation, decimal, redirect} from 'helpers/utilsHelper.js'; 
import {formatDate} from 'helpers/datesHelper.js';
import { getPathTransaction } from 'transactionsSelectors.js';
import useToggle from 'UseToggle.js';
import betSlipTypes from 'betSlipTypes.enum.js';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import AccountShareBetIcon from 'AccountShareBetIcon.js';
import transactionTypesEnum from 'transactionTypes.enum.js';
import MetaTags from 'MetaTags.js';
import { addOutcome, clearBetslip, changeSlipStake } from 'betSlipActions.js';
import ModalOpacity from 'ModalOpacity.js';
import CashoutForm from 'CashoutForm.js';
import {
    fetchCashoutsMaximumAmount,
    processCashoutAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    refreshBetHistoryList
} from 'accountBetHistoryActions.js';

const BetHistoryItemDetails = ({
    fetchCashoutsMaximumAmount,
    transaction,
    clearBetslip,
    addOutcome,
    changeSlipStake,
    source = "history",
    processCashoutAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    refreshBetHistoryList,
    cashouts,
    history: {goBack},
}) => {

    

    const [sortedBlocks, setSortedBlocks] = useState({});
    const [taxFactor, setTaxFactor] = useState(0);
    const [metaParams, setMetaParams] = useState(null);
    const [maxAmount, setMaxAmount] = useState(0);
    const [countDownRequired, setCurtainCountdownRequired] = useState(false);
    const [showCopyInfo, toggleCopyInfo] = useToggle(false);
    const [showCashoutModal, toggleCashoutModal] = useToggle(false);
    const [showCashoutErrorModal, toggleCashoutErrorModal] = useToggle(false);
    const [cashoutErrorCode, setCashoutErrorCode] = useState('');
    const { transactionId, stake, possibleReturn, confirmedReturn, cashoutCommisionAmount, curtainActive, regDate, slipType, transactionType, cashBackId, cashBackReturn, transactionDetails, statusCode, tax, bonus, slipCombinations} = transaction;
    const [isTvbetTransaction, _] = useState(() => {
        return transactionTypesEnum.TV_BET_TRANSACTIONS.indexOf(transactionType) > -1
    });
    const isCashoutBelowStake = confirmedReturn - cashoutCommisionAmount < stake  && statusCode == 5

    const removeTagsForHelmet = () => {
        const customMetaTagsNames = ["og_title", "og_description"]; //should be same as property in setMetaParams
        customMetaTagsNames.forEach(tagName => {
            let parsedTagProperty = tagName.replace('_',':');
            let tempMetaVariable = document.querySelector(`head meta[property="${parsedTagProperty}"]`);
            tempMetaVariable && tempMetaVariable.remove();
        });
    };

    useEffect(() => {
        //prepare transaction data
        let blockInfo = prepareBlocks();
        setSortedBlocks(blockInfo);
        //prepare meta data

        setMetaParams({
            og_title: `${translation('account_betHistory_ogTitle')} ${stake} ${translation('common_pln')}`,
            og_description: `${translation('account_betHistory_ogDescription')}`
        })

        //clean meta params
        removeTagsForHelmet();

        return()=> {
            removeTagsForHelmet();
        };
    }, []);

    const getTransactionDetailsStatus = (statusCode, cashBackReturn, earlyPayoutAvailable) => {

        if(earlyPayoutAvailable){
            return "earlyPayout";
        }

        if (cashBackReturn) {
            return 'cashback';
        }

        let resultText = 'details_placed';

        switch (statusCode) {
            case 0 :
                resultText = 'details_placed';
                break;
            case 1 :
                resultText = 'details_placed';
                break;
            case 2 :
                resultText = 'details_won';
                break;
            case 3 :
                resultText = 'details_lost';
                break;
            case 4 :
            case 10 :
                resultText = 'details_cancel';
                break;
            case 5 :
                resultText = 'details_cashouted';
                break;
        }

        return resultText;
    };

    const parseSlipType = (slipType) => {
        return betSlipTypes.getByType(slipType).name;
    };

    const sumOdds = (transaction) => {

        let sum = 1;
        if (transaction && transactionDetails) {

            for (var i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].outcomeId != -1) {
                    sum *= parseFloat(transactionDetails[i].odds);
                }
            }

        }
        return decimal(sum);
    };

    const checkFactor = (block) => {
        block.map( event => {
            if(event.eventId === -1) {
                setTaxFactor(event.odds)
            }
        });
    }

    const prepareBlocks = () => {
        let pureBlocksObject = {}
        let blocks = _get(transaction, 'blocks')
        for (const id in blocks) {    
            //get and set factor from data #wspolczynnik 
            checkFactor(blocks[id]);

            if(id != -1){
                pureBlocksObject[blocks[id].blockName] = blocks[id]
            }
        }
        const sortedData = {};
        Object.keys(pureBlocksObject).sort().forEach(function(key) {
            sortedData[key] = pureBlocksObject[key];
        });
        return sortedData;
    };

    const showCopyInfoAction = () => {
        toggleCopyInfo();
    };

    const isRebetPossible = () => {
        let possibleRebet = false;
        const now = new Date().getTime();

        for (let i = 0; i < transactionDetails.length; i++) {
            if (transactionDetails[i].eventStart >= now && transactionDetails[i].eventId !== -1) {
                possibleRebet = true;
                break;
            }                                
        }
        return possibleRebet;
    };

    const addRebet = () => {
        try {

            clearBetslip();
            const now = new Date().getTime();

            for (let i = 0; i < transactionDetails.length; i++) {
                if (transactionDetails[i].eventStart >= now) {
                    const outcome = transactionDetails[i];
                    if(outcome.eventId === -1) {
                        continue;
                    } else {
                        addOutcomeToSlip(outcome);
                    }
                }
            }
            changeSlipStake(stake);
            redirect('/zaklady-bukmacherskie');

        } catch (error) {
            console.log({error});
        }
    };

    const openCashoutModal = (transactionId, e) => {
        e.stopPropagation();
        toggleCashout(transactionId);
    };

    

    const addOutcomeToSlip = (singleOutcome) => {
        const {eventId, outcomeId, eventType, gameId, sportId, outcomeName, eventName, gameName, odds} = singleOutcome;
        
        const outcome = {
            outcomeId: outcomeId,
            outcomeLive: eventType === 2,
            gameId: gameId,
            eventType: eventType,
            eventId: eventId,
            sportId: sportId,
            combinationType: 1,
            outcomeName: outcomeName,
            eventName: eventName,
            gameName: gameName,
            outcomeOdds: odds
        };
        addOutcome(outcome, true)
    };

    const checkCashout = (slipId) => {
        const multiCashoutAvailable = process.env.MULTI_CASHOUT_AVAILABLE && JSON.parse(process.env.MULTI_CASHOUT_AVAILABLE);
        if (multiCashoutAvailable) {
            return cashouts && (slipId in cashouts) && getCashoutAmmountForTxId(slipId) != null;
        } else {
            return (cashouts && cashouts.indexOf(slipId) !== -1);
        }
    };

    const getCashoutAmmountForTxId = (slipId) => {
        return _get(cashouts, [slipId, 'currentCashoutAmount']);
    };

    const toggleCashout = async (transactionId) => {
        try {
            const maxAmount = await prepareCashout(transactionId);
            setMaxAmount(maxAmount);
            toggleCashoutModal();
        } catch (errorCode) {
            setCashoutErrorCode(errorCode);
            toggleCashoutErrorModal();
        }
    };

    const prepareCashout = async (slipId) => {
        try {
            const maxAmount = await fetchCashoutsMaximumAmount(slipId);
            if (maxAmount.countDownRequired != undefined) {
                setCurtainCountdownRequired(maxAmount.countDownRequired);
            }
            return maxAmount;
        } catch (error) {
            throw error;
        }
    };



    const processCashout = async (config, abortControllerSignal) => {

        const curtainDelay = async (delayMS) => {
            const p = new Promise((resolve, reject) => {
                delayMS = Number(delayMS);
                const timerId = setTimeout(resolve, delayMS);
                abortControllerSignal.addEventListener('abort', () => {
                    clearTimeout(timerId);
                    reject({ message: translation('account_cashoutForm_aborted') });
                });
            });
            return p;
        };

        try {
            let slipId = _get(config, ['slipId']);
            slipId = Number(slipId);

            const hasCashoutLiveCurtain = process.env.CASHOUT_LIVE_CURTAIN && JSON.parse(process.env.CASHOUT_LIVE_CURTAIN);
            if (hasCashoutLiveCurtain && countDownRequired) {
                const curtainData = _omit({ ...config }, ['slipId', 'type']);
                const delayMS = await processLiveCurtainForTxId(slipId, curtainData);
                await curtainDelay(delayMS);
                const result = await processCashoutAmount(config);
                return result;
            } else {
                const result = await processCashoutAmount(config);
                return result;
            }
        } catch (error) {
            throw error;
        }
    };

    const cashoutFormProps = {
        toggleLiveCurtainForTxId,
        refreshBetHistoryList,
        transactionId,
        stake,
        possibleReturn,
        maxAmount,
        toggleCashoutModal,
        toggleCashoutErrorModal,
        prepareCashout,
        currencyCode: app.config.currency,
        curtainActive,
        cachoutOnSubmit: processCashout,
        initialValues: { maximumAmount: maxAmount },
    };

    if(!isTvbetTransaction){
        return (
            <>
            <MetaTags {...metaParams}/>
            <ModalOpacity isOpen={showCashoutModal} toggleOpen={toggleCashoutModal} showHeaderIcon={false}
                showHeader={false} padding="0px">
                <CashoutForm {...cashoutFormProps}></CashoutForm>
            </ModalOpacity>

            <ModalOpacity isOpen={showCashoutErrorModal} toggleOpen={toggleCashoutErrorModal} showHeaderIcon={false}
                showHeaderTitle={false} padding="8px">
                <S.ErrorModal>
                    <S.ErrorModalTitle>{translation('account_betHistoryTransactionItem_cashout_error_title')}</S.ErrorModalTitle>
                    <S.ErrorModalMessage>{cashoutErrorCode ?? translation(`account_betHistoryTransactionItem_cashout_error_${cashoutErrorCode}`)}</S.ErrorModalMessage>
                </S.ErrorModal>
            </ModalOpacity>
            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">

                

                <S.BetHistoryDetailsHeader className="bet-history-item-details-header">
                    <S.BetHistoryDetailsHeaderActions>
                        {source === "history" && <S.BetHistoryDetailsHeaderGoBack onClick={goBack}></S.BetHistoryDetailsHeaderGoBack>}
                        <S.BetHistoryDetailsHeaderTitle>{translation('account_betHistoryTransactionItem_detailsName')}</S.BetHistoryDetailsHeaderTitle>
                        {source === "history" && app.config.betslipShare && (
                            <S.BetHistoryDetailsHeaderShareActions>
                                <AccountShareBetIcon type="facebook" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon type="twitter" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon showCopyInfo={showCopyInfoAction} type="link" transaction={transaction}></AccountShareBetIcon>
                            </S.BetHistoryDetailsHeaderShareActions>
                            )
                        }
                        {process.env.REBET_ALLOWED && source === "share" && !isTvbetTransaction && (
                            <>
                                {(() => {
                                    const hasRebet = isRebetPossible();
                                    if (hasRebet) {
                                        return <S.BetHistoryDetailsHeaderRebet onClick={() => addRebet()}>{translation('common_rebet')}</S.BetHistoryDetailsHeaderRebet>
                                    }
                                })()}
                            </>
                        )}
                    </S.BetHistoryDetailsHeaderActions>
                    <S.InfoContainer className="transactionInfoContainer" inView={showCopyInfo} onClick={toggleCopyInfo}>
                        <span>{translation('account_betHistoryTransactionItem_shareLinkInfo')}</span>
                        <span className="close"></span>
                    </S.InfoContainer>
                    <S.BetHistoryDetailsHeaderInfo>
                        <S.BetHistoryDetailsHeaderInfoSliptype>{translation(parseSlipType(slipType))}</S.BetHistoryDetailsHeaderInfoSliptype>
                        <S.BetHistoryDetailsHeaderInfoDate>{formatDate(regDate, 'dd-MM-yyyy HH:mm')}</S.BetHistoryDetailsHeaderInfoDate>
                        <S.BetHistoryDetailsHeaderInfoId>{`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transactionId}`}</S.BetHistoryDetailsHeaderInfoId>
                    </S.BetHistoryDetailsHeaderInfo>
                </S.BetHistoryDetailsHeader>
                {_map(sortedBlocks, (blocks) => {

                    return (!_isEmpty(blocks) && (
                        <React.Fragment key={`block_${blocks[0].eventId}_${blocks[0].gameId}`}>
                            {Object.keys(transaction.blocks).length !== 1 && (
                                <S.betHistoryBlockHeader classname={blocks.blockName == "P"? "banker" : "block"}>{blocks.blockName == "P"? translation('account_betHistoryTransactionItem_detailsBanker') : `${translation('account_betHistoryTransactionItem_detailsBlock')} ${blocks.blockName}`}</S.betHistoryBlockHeader>
                            )}
                            <S.BetHistoryItemDetails className="bet-history-item-details">
                                
                                {_map(blocks, ({ eventId, eventStart, eventName, gameName, outcomeName, outcomeResult, odds, score, statusCode, sportId, categoryName1, categoryName2, categoryName3, earlyPayoutAvailable, gameId }) => {
                                    //po prawej stronie ikona wskazuje status zdarzenia, nie czy został zcashbackowany, więc poniżej idzie null
                                    const statusResult = getTransactionDetailsStatus(statusCode, null, earlyPayoutAvailable);
                                    return (eventId != -1) && (
                                         
                                        <S.BetHistoryDetailsBody key={`details_${eventId}_${gameId}`}>
                                            <S.BetHistoryDetailsBodyInfo>
                                                <div className="details-time">
                                                    <span>{formatDate(eventStart, 'dd-MM-yyyy')}&nbsp;{formatDate(eventStart, 'HH:mm:ss')}</span>
                                                </div>
                                                <div className="details-category">
                                                    <span>{categoryName1}</span>
                                                    <span className="separator"></span>
                                                    <span>{categoryName2}</span>
                                                    <span className="separator"></span>
                                                    <span>{categoryName3}</span>
                                                </div>
                                            </S.BetHistoryDetailsBodyInfo>
                                            <S.BetHistoryDetailsBodyTransactionData>
                                                <S.BetHistoryDetailsBodyTransactionDataIcon>
                                                    { sportId !== parseInt(process.env.CASHBACK_PLUS_SPORT_ID)
                                                    ? 
                                                        (<div className={`sport-icon sport-${sportId}`}></div>) 
                                                    : 
                                                        (<S.StatusIcon className="cashback"></S.StatusIcon>)
                                                    }
                                                </S.BetHistoryDetailsBodyTransactionDataIcon>
                                                <S.BetHistoryDetailsBodyTransactionDataTypes>
                                                    <div className={`details-bet status-${statusCode}`}>
                                                        <span className="event-name">{eventName}</span>
                                                        <span className="game-name">{gameName}</span>
                                                        <span className="outcome-name">{outcomeName}</span>
                                                    </div>  
                                                </S.BetHistoryDetailsBodyTransactionDataTypes>
                                                <S.BetHistoryDetailsBodyTransactionDataResults>
                                                    <div className="details-result">
                                                        <div className="details-score">{score}</div>
                                                    </div>
                                                    <div>
                                                        <div className={`details-odds status-${statusCode}`}>{decimal(odds)}</div>
                                                        <S.StatusIcon className={`item-icon ${statusResult}`}></S.StatusIcon>
                                                    </div>
                                                </S.BetHistoryDetailsBodyTransactionDataResults>
                                            </S.BetHistoryDetailsBodyTransactionData>

                                        </S.BetHistoryDetailsBody>

                                    )     
                                })}
                            </S.BetHistoryItemDetails>
                        </React.Fragment>
                    ));

                })}
                <S.BetHistoryItemsFooter>
                    <S.BetHistoryItemsFooterLabels>
                        <div className="ako">{translation('account_betHistoryTransactionItem_detailsAkoLabel')}</div>
                        <div className="stake">{translation('account_betHistoryTransactionItem_detailsStakeLabel')}</div>
                        {statusCode == 1? <div className="possibleReturn">{translation('account_betHistoryItemDetails_possibleReturn')}</div> : null }
                        <div className="confirmedWin">{isCashoutBelowStake ? translation('account_betHistoryTransactionItem_detailsCashoutLabel') : translation('account_betHistoryTransactionItem_detailsConfirmedWinLabel')}</div>
                        {cashBackReturn && (<div className="cashback">
                            <S.StatusIcon className="cashback"></S.StatusIcon>
                            <div className="cashBackLabel">{translation('account_account_cashback')}</div>
                        </div>)}
                        {tax > 0 && <div data-translation='account_betHistoryTransactionItem_detailsTaxValueLabel' className="taxOnWinning">{translation('account_betHistoryTransactionItem_detailsTaxValueLabel')}</div>}
                        <div className="taxFactor">{translation('account_betHistoryTransactionItem_detailsTaxLabel')}</div>
                    </S.BetHistoryItemsFooterLabels>
                    <S.BetHistoryItemsFooterInfo>
                        <div className="ako">{sumOdds(transaction)}</div>
                        {}
                        <div className="stake">{`${stake} ${translation('common_pln')}`}</div>
                        {statusCode == 1? <div className="possibleReturn">{`${possibleReturn.toFixed(2)} ${translation('common_pln')}`}</div> : null}
                        <div className="confirmedWin">
                            {isCashoutBelowStake ?
                            `${(confirmedReturn - cashoutCommisionAmount).toFixed(2)} ${translation('common_pln')}` :
                            `${confirmedReturn.toFixed(2)} ${translation('common_pln')}`}
                        </div>
                        {cashBackReturn && (<div className="cashback">
                            <div className="cashBackInfo">{cashBackReturn} {translation('common_pln')}</div>
                        </div>)}
                        {tax > 0 && <div className="taxOnWinning">{`${tax.toFixed(2)} ${translation('common_pln')}`}</div>}
                        <div className="taxFactor">{taxFactor}</div>
                    </S.BetHistoryItemsFooterInfo>
                </S.BetHistoryItemsFooter>

                <S.BetHistoryItemsAdditionalOptions>
                        {(process.env.CASHOUT && checkCashout(transactionId)) && (
                        <S.CashoutButton onClick={openCashoutModal.bind(null, transactionId)}>
                                <S.CashoutButtonTxt dangerouslySetInnerHTML={{ __html: translation('account_betHistoryTransactionItem_detailsCashout') }} />
                                <S.CashoutButtonAmmount>
                                    {getCashoutAmmountForTxId(transactionId)}&nbsp;{translation('common_pln')}
                                </S.CashoutButtonAmmount>
                            </S.CashoutButton>
                        )}
                </S.BetHistoryItemsAdditionalOptions>


            </S.BetHistoryDetailsWrapper>
            </>
        );
    } else {
        return (
            <>
            <MetaTags {...metaParams}/>
            <S.BetHistoryDetailsWrapper className="bet-history-details-wrapper">
                <S.BetHistoryDetailsHeader className="bet-history-item-details-header tvbet">
                    <S.BetHistoryDetailsHeaderActions>
                        {source === "history" && <S.BetHistoryDetailsHeaderGoBack onClick={goBack}></S.BetHistoryDetailsHeaderGoBack>}
                        <S.BetHistoryDetailsHeaderTitle>{translation('account_tvBetHistoryTransactionItem_detailsName')}</S.BetHistoryDetailsHeaderTitle>
                        
{//dojdzie w kolejnej itteracji, teraz w public/transactions nie mamy danych o tv bet gry...
/*                         {source === "history" && app.config.betslipShare && (
                            <S.BetHistoryDetailsHeaderShareActions>
                                <AccountShareBetIcon type="facebook" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon type="twitter" transaction={transaction}></AccountShareBetIcon>
                                <AccountShareBetIcon showCopyInfo={showCopyInfoAction} type="link" transaction={transaction}></AccountShareBetIcon>
                            </S.BetHistoryDetailsHeaderShareActions>
                            )
                        } */}
                    </S.BetHistoryDetailsHeaderActions>
                    <S.InfoContainer className="transactionInfoContainer" inView={showCopyInfo} onClick={toggleCopyInfo}>
                        <span>{translation('account_betHistoryTransactionItem_shareLinkInfo')}</span>
                        <span className="close"></span>
                    </S.InfoContainer>
                    <S.BetHistoryDetailsHeaderInfo>
                        <S.BetHistoryDetailsHeaderInfoSliptype>{translation(`account_tvBetHistory_${transactionType}`)}</S.BetHistoryDetailsHeaderInfoSliptype>
                        <S.BetHistoryDetailsHeaderInfoDate>{formatDate(regDate, 'dd-MM-yyyy HH:mm')}</S.BetHistoryDetailsHeaderInfoDate>
                        <S.BetHistoryDetailsHeaderInfoIcon>
                            <div className="tvBet">
                                {translation('account_betHistory_types_tvbet')}
                            </div>
                        </S.BetHistoryDetailsHeaderInfoIcon>
                        <S.BetHistoryDetailsHeaderInfoId>{`${translation('account_betHistoryTransactionItem_detailsIdPrefix')} ${transactionId}`}</S.BetHistoryDetailsHeaderInfoId>
                    </S.BetHistoryDetailsHeaderInfo>
                    <S.BetHistoryDetailsHeaderInfo>
                        <div className="ako">{translation('account_tvBetHistoryTransactionItem_detailsInfo')}</div>
                    </S.BetHistoryDetailsHeaderInfo>
                </S.BetHistoryDetailsHeader>
                <S.BetHistoryItemsFooter>
                    <S.BetHistoryItemsFooterLabels>
                        <div className="stake">{translation('account_betHistoryTransactionItem_detailsStakeLabel')}</div>
                        <div className="confirmedWin">{translation('account_betHistoryTransactionItem_detailsConfirmedWinLabel')}</div>
                    </S.BetHistoryItemsFooterLabels>
                    <S.BetHistoryItemsFooterInfo>
                        {transactionType === (transactionTypesEnum.TV_BET_WON || transactionTypesEnum.TV_BET_JACKPOT)?
                            (
                                <>
                                    <div className="stake">{translation('account_tvBetHistory_emptySign')}</div>
                                    <div className="confirmedWin won">{`${confirmedReturn.toFixed(2)} ${translation('common_pln')}`}</div>
                                </>
                            )
                            :
                            (
                                transactionType == transactionTypesEnum.TV_BET_CANCEL?
                                (
                                    <>
                                        <div className="stake">{translation('account_tvBetHistory_emptySign')}</div>
                                        <div className="confirmedWin">{confirmedReturn && `${confirmedReturn.toFixed(2)} ${translation('common_pln')}`}</div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <div className="stake">{`${stake} ${translation('common_pln')}`}</div>
                                        <div className="confirmedWin">{translation('account_tvBetHistory_emptySign')}</div>
                                    </>
                                )

                                
                            )
                        }
                    </S.BetHistoryItemsFooterInfo>
                </S.BetHistoryItemsFooter>
            </S.BetHistoryDetailsWrapper>
            </>
        );
    }
};

const mapStateToProps = ({AccountBetHistory:{transactionsList, cashouts}}, {match: {params}}) => {
    return {
        transaction: getPathTransaction(transactionsList, params),
        cashouts
    }
};

const mapDispatchToProps = {
    clearBetslip,
    addOutcome,
    changeSlipStake,
    fetchCashoutsMaximumAmount,
    processLiveCurtainForTxId,
    toggleLiveCurtainForTxId,
    processCashoutAmount,
    refreshBetHistoryList,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BetHistoryItemDetails));

