import styled, {keyframes} from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';
import StyledLoader from 'StyledLoader.js'

const BetSlipButtonBorderColor = ({theme:{$betSlipButtonBorderColor}}) => `${$betSlipButtonBorderColor}`;
const BetSlipButtonBgColor = ({theme:{$betSlipButtonBgColor}}) => `${$betSlipButtonBgColor}`;
const BetSlipButtonBgColorHover = ({theme:{$betSlipButtonBgColorHover}}) => `${$betSlipButtonBgColorHover}`;
const BetSlipButtonColorHover = ({theme:{$betSlipButtonColorHover}}) => `${$betSlipButtonColorHover}`;

const BetSlipFormInputBackground = ({theme:{$betSlipFormInputBackground}}) => `${$betSlipFormInputBackground}`;
const BetSlipFormInputBorderColor = ({theme:{$betSlipFormInputBorderColor}}) => `${$betSlipFormInputBorderColor}`;
const BetSlipFormInputColor = ({theme:{$betSlipFormInputColor}}) => `${$betSlipFormInputColor}`;
const BetSlipFormLabelColor = ({theme:{$betSlipFormLabelColor}}) => `${$betSlipFormLabelColor}`;
const BetSlipPlaceBetSuccessBackground = ({theme:{$betSlipPlaceBetSuccessBackground}}) => `${$betSlipPlaceBetSuccessBackground}`;
const BetSlipPlaceBetSuccessColor = ({theme:{$betSlipPlaceBetSuccessColor}}) => `${$betSlipPlaceBetSuccessColor}`;
const BetSlipButtonsWrapperBackground = ({theme:{$betSlipButtonsWrapperBackground}}) => `${$betSlipButtonsWrapperBackground}`;
const BetSlipCheckboxWrapperBackground = ({theme:{$betSlipCheckboxWrapperBackground}}) => `${$betSlipCheckboxWrapperBackground}`;
const BetSlipCheckboxColor = ({theme:{$betSlipCheckboxColor}}) => `${$betSlipCheckboxColor}`;
const BetSlipFakeChboxBackground = ({theme:{$betSlipFakeChboxBackground}}) => `${$betSlipFakeChboxBackground}`;
const BetSlipChboxInputBorderColor = ({theme:{$betSlipChboxInputBorderColor}}) => `${$betSlipChboxInputBorderColor}`;
const BetSlipChboxLabelColor = ({theme:{$betSlipChboxLabelColor}}) => `${$betSlipChboxLabelColor}`;
const BetSlipFormGroupColor = ({theme:{$betSlipFormGroupColor}}) => `${$betSlipFormGroupColor}`;

const baseFont = `12px`;

const S = {};
const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;

S.BetSlipWrapper = styled.div`
    position: relative;
    &.is-sticked{
        position: fixed;
        top: 0;
        width: inherit;
        z-index: 1;
        right: 0;
    }
`;

S.Info = styled.div`
   padding: ${rem('10px', baseFont)};
   ${({status})=>{
    if(status == 'error'){
        return `
            border: 1px solid red;
            color: red;
           `;
    }else if(status == 'success'){
        return `
            border: 1px solid green;
            color: green;
           `;
    }
}};
    border-radius: 3px;
    margin: ${rem('10px', baseFont)} 0;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

S.InfoMessage = styled.div``;

S.InfoCloseIcon = styled.div`
  cursor: pointer;
    font-size: ${rem('30px', baseFont)};
    line-height: 1;
`;

S.BetSlipHeader = styled.div`
    width: 100%;
    padding: 1rem 0.5rem;
    font-weight: 600;
    font-size: 1.4rem;
    color: #000;
    background-color: #D9D9D9;
    display: flex;
    align-items: center;
`;

S.BetSlipIcon = styled.i`
    width: 15px;
    height: 18px;
    margin: 0 10px;
`;

S.BetSlipBody = styled.div`
    overflow: hidden;
    position: relative;
    max-height: 1500px;
    transition-property: max-height;
    transition-duration: .5s;
    ${({isHidden}) => {
        return isHidden == true && 'max-height: 0';
    }}
`;

S.BetSlipContainer = styled.div`
    right: 500px;
    width: 100%;
    z-index: 1000000;
    background-color: #fff;
    padding: 0;

    .betslip-inner {
        background-color: #EFF1F2;
        padding: 0 0.5rem 0.5rem;
    }
`;

S.Outcome = styled.div`
   padding: 5px 0;
   border-bottom: 1px solid grey;
   display: flex;
`;

S.PrevOdds = styled.span`
    position: relative;
    margin-left: 30px;
    &.up,
    &.down{
         padding-right: 25px;
         &:before{
              right: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 8px 8px 8px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 8px 8px 0px 8px;
        }
     }
`;

S.Error = styled.div`
   color: red;
   padding: 5px;
   text-align: center;
`;


S.BetSlipOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    display: flex;
     z-index: 1;
     & ${StyledLoader.LoaderWrapper}{
        position: absolute;
    }
    
`;

S.ArrowIcon = styled.i`
    margin-left: auto;
    margin-right: 0.5rem;
`;

S.ClearBetSlipIcon = styled.i`
    width: 24px;
    height: 24px;
    margin-left: auto;
`

S.ClearBetSlip = styled(Button)`
    background-color: transparent;
    height: ${rem('45px', baseFont)};
    padding: 0  ${rem('20px', baseFont)};
    border-radius: 3px;
    border: 2px solid ${BetSlipButtonBorderColor};
    text-transform: uppercase;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
    &:hover{
         background-color: ${BetSlipButtonBgColor};
         color: #fff;
    }
`;

S.PlaceBet = styled(S.ClearBetSlip)`
    background-color: ${BetSlipButtonBgColor};
    color: #fff;
    &:hover {
        background-color: ${BetSlipButtonBgColorHover};
        color: ${BetSlipButtonColorHover};
    }
`;

S.BetInShop = styled(S.ClearBetSlip)`
  background-color: ${BetSlipButtonBgColor};
  color: #fff;
`;

S.AcceptTraderChanges = styled(S.ClearBetSlip)``;

S.RejectTraderChanges = styled(S.ClearBetSlip)``;

S.ButtonsWrapper = styled.div`
    background-color: ${BetSlipButtonsWrapperBackground};
    display: grid;
    margin-top: 1rem;
    grid-gap: ${rem('3px', baseFont)} ${rem('3px', baseFont)}
    /* grid-template-columns: repeat(2, 50%); */
`;

S.BalanceSelect = styled.div`
    margin-top: 1rem;

    .react-select__control {
        border: 1px solid #E8E8E8;
        box-shadow: 0 0 0 1px #E8E8E8;
        background: #FCFCFC;

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            color: #000;
        }

        &--is-focused {
            border-color: transparent!important;
        }
    }

    & .react-select__value-container{
            
        & .react-select__single-value{
            color: #777D85;
        }
    }

    & .react-select__menu {
        & .react-select__menu-list {
            & .react-select__option {
                color: #000;
                background-color: #EFF1F2;
                &--is-focused {
                    color: #000;
                }
                &--is-selected {
                    background-color: #C62828;
                    color: #fff;
                }
            }
        }
    }
`;

S.WinningTaxBox = styled.div`
    color: ${BetSlipChboxLabelColor};
    border: 1px solid #0000003D;
    border-radius: 3px;
    padding: 1rem;
    text-align: center;
`;

S.FormGroupWrapper = styled.div`
    display: flex;
    margin-bottom: 0.7rem;
    padding: 0 ${rem('8px', baseFont)};
`;

S.InputWrapper = styled.div`
    display: flex;
    border-radius: 4px;
    border: 1px solid #0000003D;
    height: ${rem('42px', baseFont)};
    padding: 0 0.5rem 0 0;
    width: 80px;
    color: ${BetSlipFormInputColor};
    background-color: ${BetSlipFormInputBackground};
    border: 1px solid ${BetSlipFormInputBorderColor};
    margin-left: auto;
`;

S.Input = styled(Input)`
    font-size: 1.3333rem;
    text-align: left;
    background-color: transparent;
    border: none;
    padding-left: 1rem;
    width: 100%;
    color: ${BetSlipFormInputColor};
    &[type="number"]{
      -moz-appearance:textfield;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button{
      -webkit-appearance: none;
       margin: 0;
    }
`;

S.Label =  styled.label`
    width: 22%;
    margin-top: 0.25rem;
    font-size: ${rem('14px', baseFont)};
    font-weight: bold;
    margin-bottom: 0;
    width: 45%;
    color: ${BetSlipFormLabelColor};
    align-self: center;
`;

S.Currency = styled.div`
    font-size: 1.3333rem;
    display: flex;
    align-items: center;
`;

S.EmptySlip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${rem('10px', baseFont)};
    color: #000;
    background: ${BetSlipButtonsWrapperBackground};
    text-align: center;
    min-height: 60px;
`;

S.LoginInfo = styled.div`
    border: 1px solid #CE0006;
    text-align: center;
    border-radius: 3px;
    color: #CE0006;
    padding: 0.5rem;
`

S.PlaceBetSuccess = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url(/images/placed_bet.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 3rem;
    padding-bottom: 1rem;
`;

S.PlaceBetPotentialWinning = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0;
    font-size: ${rem('14px', baseFont)};
    font-weight: bold;

    .value {
        font-size: ${rem('48px', baseFont)};
        text-align: center;
    }
`;

S.PlaceBetStake = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;

    .value {
        font-size: ${rem('28px', baseFont)};
    }
`;

S.PlaceBetOdds = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;

    .value {
        font-size: ${rem('28px', baseFont)};
    }
`;

S.CashbackOffer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;

    .value {
        font-size: ${rem('28px', baseFont)};
    }
`;

S.PlaceBetInfo = styled.div`
    margin: 1.5rem 0rem;
    text-align: center;
    font-size: ${rem('13px', baseFont)};
`;

S.PlaceAgainBtn = styled(Button)`
    background-color: #FFF;
    height: ${rem('45px', baseFont)};
    padding: 0  ${rem('20px', baseFont)};
    border-radius: 3px;
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
    color: #C62828;
    margin: 0 1rem;
`;

S.ClearBetSlipBtn = styled(S.PlaceAgainBtn)``;

S.PlaceBetSuccessTop = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${BetSlipPlaceBetSuccessColor};
    border-radius: 3px;
    min-height: ${rem('125px', baseFont)};
    margin-bottom: ${rem('6px', baseFont)};
    padding: ${rem('17px', baseFont)};
`;

S.PlaceBetSuccessBottom = styled.div`
    display: grid;
    grid-gap: ${rem('6px', baseFont)};
    grid-template-rows: repeat(2, 1fr);
`;

S.PlaceBetIcon = styled.div`
    width: ${rem('47px', baseFont)};
    height: ${rem('47px', baseFont)};
    margin-bottom: ${rem('8px', baseFont)};
`;

S.PlaceBetMessage = styled.div`
    font-weight: bold;
    font-size: ${rem('14px', baseFont)};
    text-align: center;
`;

S.CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${rem('8px', baseFont)};
    background-color: ${BetSlipCheckboxWrapperBackground};
`;

S.CheckboxGroup = styled.div``;

S.LabelCheckbox = styled.label`
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
`;

S.InputCheckbox = styled(Input)`
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;

    &:checked + ${S.FakeCheckbox} {
        &:before {
            display: block;
        }
    }
`;

S.FakeCheckbox = styled.span`
    cursor: pointer;
    background-color: ${BetSlipFakeChboxBackground};
    margin-right: ${rem('15px', baseFont)};
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    border-radius: 2px;
    border: 1px solid ${BetSlipChboxInputBorderColor};
    color: ${BetSlipCheckboxColor};
    &:before {
        content: "\\002714";
        position: absolute;
        display: none;
        left: 0.30rem;
        top: 0.05rem;
    }
`;

S.CheckboxText = styled.span`
    font-size: ${rem('12px', baseFont)};
    color: ${BetSlipChboxLabelColor};
    font-weight: normal;
    line-height: 1;
`;

S.FormGroup = styled.div`
    padding: ${rem('2px', baseFont)} ${rem('8px', baseFont)};
    display: flex;
    justify-content: space-between;
    font-size: ${rem('14px', baseFont)};

    &.small-text {
        ${S.FormGroupText} {
            font-size: ${rem('10px', baseFont)};
        }
    }

    &.medium-text {
        ${S.FormGroupText} {
            font-size: ${rem('12px', baseFont)};
            font-weight: 600;
        }
    }
`;

S.FormGroupText = styled.div`
    color: ${BetSlipFormGroupColor};
`;

S.StakeControls = styled.div`
    display: flex;
    align-items: center;
    margin-right: 5px;
`;

S.StakeControl = styled.div`
    width: ${rem('35px', baseFont)};
    height: ${rem('35px', baseFont)};
    cursor: pointer;
`;

S.UnexpectedError = styled.div`
   padding: ${rem('15px', baseFont)};
   border-radius: 3px;
   background-color: red;
   margin: ${rem('10px', baseFont)} 0 0;
   display: flex;
   align-items: center;
`;

S.UnexpectedErrorIcon = styled.div`
    flex-shrink: 0;
    font-size: ${rem('20px', baseFont)};
    margin-right: ${rem('10px', baseFont)};
`;

S.UnexpectedErrorMessage = styled.div``;

S.ErrorContainer = styled.div`
    margin-top: ${rem('10px', baseFont)};
    max-height: ${rem('55px', baseFont)};
    overflow-y: auto;
`;

export default S;