import React, {Component} from 'react';
import {MemoryRouter as Router, Route, Switch, NavLink, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from 'react-select';
import AccountSessionTimer from 'AccountSessionTimer.js';
import AccountMenu from 'AccountMenu.js';
import AccountRoute from 'AccountRoute.js';
import S from 'StyledAccount.js';
import {translation, formatMoney, redirect} from 'utilsHelper.js';
import _find from 'lodash/find';
import _each from 'lodash/each';
import _size from 'lodash/size';
import _isEmpty from 'lodash/isEmpty';
import {loadVeryficationStatus} from 'accountActions.js';
import {logOutUser} from 'authActions.js';
import UploadUserAvatar from 'UploadUserAvatar.js';
import {loadCustomerOffersBalances} from 'offersActions.js';
import CmsPage from 'CmsPage.js';
import ModalOpacity from 'ModalOpacity.js';
import { getCustomerPromotions,updateCustomerPromotions } from 'customerActions.js';
import {fetchFreebetByStatus} from 'freebetActions.js';

/**
 * balance to separate component 
 * remove setState
 * connect params via redux
 */
class Account extends Component {

    static getDerivedStateFromError = (error) => {
        return {hasError: true};
    };

    state = {
        hasError: false,
        showVipModal:false,
        showInfoStatusModal:false,
    };

    componentDidMount = async() => {
        const {loadVeryficationStatus, accountTab, getCustomerPromotions, fetchFreebetByStatus} = this.props;
        await getCustomerPromotions(true,true)
        try {
            const config = {
                status: 'ACTIVE'
            };
            await fetchFreebetByStatus(config);
        } catch (error) {
            console.log('freebet:', error)
        }

        if (!this.props.user) {
            redirect('/');
            return; 
        } else if (accountTab){
            this.props.history.push('/konto/'+accountTab);
        }

        loadVeryficationStatus();
    
    };

    getBalanceList = () => {
        
        const {user:{bonusBalanceList, currencyCode, balance}} = this.props;
        const options = [];
        const mainBalance = {
            value: 0,
            label: `${translation('account_balance')}: ${formatMoney(balance)} ${currencyCode}`
        };

        options.push(mainBalance);

        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${formatMoney(amount)} ${currency}`
            };
            options.push(bonusBalance);
        });

        return options;
    };

    getBonusBalanceSingleAmount = () => {
        const {user:{bonusBalanceList, currencyCode}} = this.props;

        let bonusAmount = `0.00 ${translation('common_pln')}`;
        if (bonusBalanceList && bonusBalanceList.length > 0){
            const amount = formatMoney(bonusBalanceList[0].amount); 
            bonusAmount = `${amount} ${translation('common_pln')}`;
        }

        return bonusAmount;
    };

    selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || this.props.balanceId;
        const selectedValue = _find(this.getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };

    onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        this.props.toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    onUserDataLinkClick = (event) => {
        event.preventDefault();
        redirect('/konto/edycja');
    };

    getCashbackBalanceAmount = (list, currencyCode) => {
        const offer = list.find((o)=>o.identifier == process.env.CASHBACK_PLUS_OFFER_IDENTIFIER);
        let balance = '0.00';
        if ( offer ){
            balance = formatMoney(offer.customerBalance);
        }
        return balance + currencyCode;
    };
    
    refreshCashbackBalance = async() => {
        const {loadCustomerOffersBalances} = this.props;
        try {
            await loadCustomerOffersBalances();
        } catch (error) {
            console.log('refreshCashbackBalance: ' + error);
        }
    };
    handleModalView = (isModalShown)=>{
        const {getCustomerPromotions} = this.props;
        if(!isModalShown){
            this.toggleModal('showVipModal');
        }else{
            getCustomerPromotions(true).then(res=>{
                this.toggleModal('showVipModal');
                this.toggleModal('showInfoStatusModal')
               }).catch(err=>{
                this.toggleModal('showVipModal');
                this.toggleModal('showInfoStatusModal')
               })
        }
    }
    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    getSumAmountForActiveFreebets = () => {
        const { freebets } = this.props;
        
        let balance = 0;
        if (freebets) {
            for (let index in freebets){
                if (freebets[index].offerStatus == 'ACTIVE') {
                    balance += freebets[index].freebetStake;
                }

            }
        }

        return `${formatMoney(balance)} ${translation('common_pln')}`;
    };

    handleCloseVipModal = () => {
        const {getCustomerPromotions} = this.props;
        
        getCustomerPromotions(false).then(res=>{
                this.toggleModal('showVipModal');
                this.toggleModal('showInfoStatusModal')
               }).catch(err=>{
                this.toggleModal('showVipModal');
                this.toggleModal('showInfoStatusModal')
               })
    };
 
    render() {
        if (!this.props.user) {
            return null;
        }

        const {user:{login, userId, balance, currencyCode, firstName, lastName,groups},constensPromotion, accountData, shouldVipCTABeNotDisplayed, logOutUser, offersBalances} = this.props;
        const {hasError, showVipModal, showInfoStatusModal} = this.state;
        const isVipModalVisible = typeof groups !== 'undefined' && groups["VIP_MARKETING"]?.status =="NOT_SET" && !shouldVipCTABeNotDisplayed;
        return (
            <S.AccountWrapper className="account-wrapper">
                   {isVipModalVisible && (
                    <S.VipContainer>
                        <S.VipCtaBanner className="direction-row" isModal={false}>
                            <CmsPage name="vip-marketing-cta" isPlaces={true} />
                            <S.VipCtaButton  onClick={()=>this.handleModalView(showVipModal)}>Dołącz!</S.VipCtaButton>
                        </S.VipCtaBanner>
                        <S.UserNameDataContainer className="user-name-data-container">
                            <S.UserName className="user-name">{firstName} {lastName}</S.UserName>
                            <S.UserDataButtonContainer  onClick={(e) => { this.onUserDataLinkClick(e); }}>
                                <S.UserDataButton className="user-data-button"
                                data-test='account_userPanel_userData'>
                                        {translation('account_userPanel_userData')}
                                </S.UserDataButton>
                            </S.UserDataButtonContainer>
                            <S.UserDataSessionTimerContainer className="user-data-session-timer-container">
                                {translation('account_userSessionTime')}
                                <AccountSessionTimer/>
                            </S.UserDataSessionTimerContainer>
                            <S.UserActivityLink className="user-activity-link" onClick={redirect.bind(this, '/konto/historia-sesji')}>{translation('account_userPanel_activity')}</S.UserActivityLink>
                      </S.UserNameDataContainer>
                    </S.VipContainer>
                            )}
                        <ModalOpacity isOpen={showVipModal}
                            toggleOpen={()=>this.handleCloseVipModal()}
                            showHeader={false}
                            showHeaderIcon={false}
                            showHeaderTitle={false}
                            showCloseIcon={false}
                            padding="0px"
                            preventCloseOnOverlay={true}>
                                <S.VipCtaBanner isModal={true}>
                                    <CmsPage name="vip-marketing-modal-not-set" isPlaces={true} />
                                    <S.VipCtaButtonsContainer>
                                        <S.VipCtaButton className="extra-margin" onClick={()=>this.handleModalView(showVipModal)}>{translation('account_vipmarketing_accept')}</S.VipCtaButton>
                                        <S.VipCtaButton className="extra-margin" onClick={()=>this.handleCloseVipModal()}>{translation('account_vipmarketing_reject_btn')}</S.VipCtaButton>
                                    </S.VipCtaButtonsContainer>
                                </S.VipCtaBanner>
                        </ModalOpacity>
                    <ModalOpacity 
                    isOpen={showInfoStatusModal} 
                    showCloseIcon={true} 
                    showHeader={false}
                    showHeaderIcon={false}
                    showHeaderTitle={false}
                    padding="0px"
                    toggleOpen={this.toggleModal.bind(this,"showInfoStatusModal")}>
                        <S.VipCtaBanner isModal={true}>
                            <CmsPage name={constensPromotion?.length > 0 ? `vip-marketing-modal-success`:`vip-marketing-modal-failure`} isPlaces={true} />
                            <S.VipCtaButtonsContainer>
                                <S.VipCtaButton  onClick={()=>redirect('/konto/edycja')}>{translation('account_vipmarketing_close')}</S.VipCtaButton>
                            </S.VipCtaButtonsContainer>
                        </S.VipCtaBanner>
                    </ModalOpacity>
                    <S.AccountTopPanel className="account-top-panel">

                        <S.UserAvatarContainer className="user-avatar-container">
                            <S.UserAvatar className="user-avatar">
                                <UploadUserAvatar></UploadUserAvatar>
                            </S.UserAvatar>
                        </S.UserAvatarContainer>
                        <S.UserBalanceContainer className="user-balance-container">

                            <S.UserMainBalance>
                                <S.UserMainBalanceLabel>{translation('account_userPanel_balance')}</S.UserMainBalanceLabel>
                                <S.UserMainBalanceContainer className="user-main-balance-container">
                                    <S.UserMainBalanceValue>{ formatMoney(balance) }</S.UserMainBalanceValue>
                                    <S.UserMainBalanceCurrency>{translation('common_pln')}</S.UserMainBalanceCurrency>
                                </S.UserMainBalanceContainer>
                            </S.UserMainBalance>

                            <S.UserBonusBalanceWrapper>
                                <S.UserBonusBalance>
                                    <S.UserBonusBalanceLabel>{translation('account_userPanel_bonus')}</S.UserBonusBalanceLabel>
                                    <S.UserBonusBalanceAmount className="user-bonus-balance-amount">{ this.getBonusBalanceSingleAmount() }</S.UserBonusBalanceAmount>
                                </S.UserBonusBalance>
                            </S.UserBonusBalanceWrapper>


                            <S.UserCashbackWrapper>
                                <S.UserCashbackBalanceLabel>{translation('account_cashback')}</S.UserCashbackBalanceLabel>
                                <S.UserCashbackBalanceAmount>{this.getCashbackBalanceAmount(offersBalances, translation('common_pln'))} </S.UserCashbackBalanceAmount>
                                {/*<S.UserCashbackRefreshButton className="user-data-button" data-test='account_userPanel_userData' onClick={this.refreshCashbackBalance.bind(this)}>*/}
                                {/*    {translation('account_userPanel_refreshCashbackBalance')}*/}
                                {/*</S.UserCashbackRefreshButton>*/}
                            </S.UserCashbackWrapper>

                            <S.UserFreebets className="user-freebets">
                                <S.FreebetLabel>{translation('account_userPanel_freebetCount')}</S.FreebetLabel>
                                <S.FreebetsBalanceValue>{ this.getSumAmountForActiveFreebets() }</S.FreebetsBalanceValue>
                            </S.UserFreebets>

                        </S.UserBalanceContainer>
                        {!isVipModalVisible && (
                            <S.UserNameDataContainer className="user-name-data-container">
                               <S.UserName className="user-name">{firstName} {lastName}</S.UserName>
                               <S.UserDataButtonContainer  onClick={(e) => { this.onUserDataLinkClick(e); }}>
                                   <S.UserDataButton className="user-data-button"
                                   data-test='account_userPanel_userData'>
                                           {translation('account_userPanel_userData')}
                                   </S.UserDataButton>
                               </S.UserDataButtonContainer>
                               <S.UserDataSessionTimerContainer className="user-data-session-timer-container">
                                   {translation('account_userSessionTime')}
                                   <AccountSessionTimer/>
                               </S.UserDataSessionTimerContainer>
                               <S.UserActivityLink className="user-activity-link" onClick={redirect.bind(this, '/konto/historia-sesji')}>{translation('account_userPanel_activity')}</S.UserActivityLink>
                         </S.UserNameDataContainer>
                        )}
                    </S.AccountTopPanel>

                    <S.AccountMainMenuContainer className="account-main-menu-container">

                        <AccountMenu logOut={logOutUser}/>
                            
                    </S.AccountMainMenuContainer>

                    <S.AccountBottom className="account-bottom">
                        <S.AccountBody className="account-body">
                            <AccountRoute accountData={accountData}/>
                        </S.AccountBody>
                    </S.AccountBottom>

            </S.AccountWrapper>
        );
    }
}

const mapStateToProps = (state, props) => {
    const {Account:{ accountData}, Auth: {user},Customer:{constensPromotion,shouldVipCTABeNotDisplayed}, Offers:{offersBalances}, BetSlip:{freebets} } = state;
    return {
        accountData,
        user,
        offersBalances,
        freebets,
        constensPromotion,
        shouldVipCTABeNotDisplayed
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadVeryficationStatus: bindActionCreators(loadVeryficationStatus, dispatch),
        logOutUser: bindActionCreators(logOutUser, dispatch),
        getCustomerPromotions:bindActionCreators(getCustomerPromotions, dispatch),
        loadCustomerOffersBalances: bindActionCreators(loadCustomerOffersBalances, dispatch),
        fetchFreebetByStatus: bindActionCreators(fetchFreebetByStatus, dispatch),
        dispatch
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account));
