import styled from 'styled-components';
import {Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme: {$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;
const S = {};

S.LoggedPanel = styled.div`
    position: relative;
    display: flex;
    margin-left: auto;
    flex-basis: auto;
    align-items: center;
    font-family: ${FontFamily};
`;

S.LogoutBtn = styled(Button)`
    background-color: ${({theme: {$topMenuLoggedOutBtnBgColor}}) => $topMenuLoggedOutBtnBgColor};
    height:  ${rem('37px', baseFont)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    font-size: ${rem('14px', baseFont)};
    padding: 0;
    font-weight: ${FwSemibold};
    color: ${({theme: {$topMenuLoggedOutBtnColor}}) => $topMenuLoggedOutBtnColor};
    flex-shrink: 0;
    width: ${rem('111px', baseFont)};
    border: 2px solid #C62828;
`;

S.UserAccount = styled.div`
    border: 0;
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-right: 0;
    flex-shrink: 0;
    cursor: pointer;
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
`;

S.UserLogin = styled.div`
    color: ${({theme:{$topMenuLoggedUserLoginColor}})=> $topMenuLoggedUserLoginColor};
    font-size: 1.2rem;
    font-family: Roboto,sans-serif;
    line-height: 1.2rem;
    padding-left: .9rem;
    cursor: pointer;
    flex-shrink: 0;
`;

S.UserAccountIcon = styled.i`
    box-sizing: border-box;
    width: ${rem('16px', baseFont)};
    height: ${rem('16px', baseFont)};
    flex-shrink: 0;
    display: none;
    & svg {
      path{
       fill: #D32F2F !important;
      }
    }
`;

S.FreebetsCount = styled.div`
align-self: flex-start;
color: #fff;
font-size: ${rem('10px', baseFont)};
background-color: #D32F2F;
display: flex;
justify-content: center;
align-items: center;
flex-shrink: 0;
width: ${rem('12px', baseFont)};
height: ${rem('12px', baseFont)};
border-radius: 2px;
position: relative;
top: -5px;
`;

S.BalanceSelect = styled.div`
    min-width: 300px;
    cursor: pointer;
    align-items: stretch;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        background-color: #fff;
        height: 54px;
        border: 2px solid #ECEFF1;
        border-radius: 3px;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }

        & .react-select__indicator-separator {
            display: none;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-weight: 700;
                 font-family: ${FontFamily};
                 color: #000;
                 padding-left: 15px;
            }
        }
    
    }
`;


S.Cashback = styled.div`
  display: flex;
  align-items: center;
  margin-right:  ${rem('30px', baseFont)};
`;

S.CashbackIcon = styled.i`
    width: ${rem('32px', baseFont)};
    height: ${rem('32px', baseFont)};
    margin-right: ${rem('10px', baseFont)};
    flex-shrink: 0;
    & svg {
      circle{
        fill: #000 !important;
      }
      path{
       fill: #fff !important;
      }
    }
`;

S.CashbackAmount = styled.div`
  font-weight: ${FwSemibold};
  font-size:  ${rem('15px', baseFont)};
  color: rgba(0,0,0,0.87);
`;

S.Balance = styled(S.Cashback)``;

S.BalanceIcon = styled(S.CashbackIcon)`
    & svg {
      circle{
        fill: #D32F2F  !important;
      }
    }
`;

S.BalanceAmount  = styled(S.CashbackAmount)`
  color: #C62828;
`;

S.DepositButton = styled(Button)`
  background-color: #D32F2F;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem('76px', baseFont)};
  height: ${rem('37px', baseFont)};
  color: #fff;
  margin-right: ${rem('30px', baseFont)};
  border-radius: 3px;
  text-transform: uppercase;
  font-family: ${FwSemibold};
  font-size: ${rem('14px', baseFont)};
`;

S.TemporaryAccountInformation = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: ${rem('12px', baseFont)};
  color: #D32F2F;
  white-space: no-wrap;
`;

export default S;