import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const baseFont = `12px`;
const S = {};

S.TopMenu = styled.div`
    z-index: 10;
    height: auto;
    background: ${({theme: {$topMenuBackgroundColor}}) => $topMenuBackgroundColor};
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    float: left;
    width: 100%;
`;

S.MainRow = styled.div`
    display: flex;
    
    justify-content: flex-end;
    background-color: ${({theme: {$topMenuTopBarColor}}) => $topMenuTopBarColor};
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 0 2rem 0 .70834rem;
`;

S.SubmenuRow = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({theme: {$topMenuSubmenuBarColor}}) => $topMenuSubmenuBarColor};
    height: ${rem('50px', baseFont)};
    padding: 0 ${rem('23px', baseFont)};
`;

S.MenuRow = styled.div`
    display: flex;
    width: 100%;
    padding: 0 ${rem('22px', baseFont)} 0 ${rem('35px', baseFont)};
    background-color: ${({theme: {$topMenuBackgroundColor}}) => $topMenuBackgroundColor};
    min-height: ${rem('80px', baseFont)};
    height: ${rem('90px', baseFont)};
`;

S.ForbiddenAccess = styled.div`
    display: flex;
    width: 100%;
    background-color: ${({theme: {$topMenuSubmenuBarColor}}) => $topMenuSubmenuBarColor};
    height: ${rem('50px', baseFont)};
    padding: 0 ${rem('44px', baseFont)};
    font-size: 14px;
    align-items: center;
`;

S.CloseIcon = styled.div`
    position: absolute;
    right: ${rem('44px', baseFont)};
    top: ${rem('10px', baseFont)};
    padding: 0;
    transform: none;
    line-height: 1;
    cursor: pointer;
    &:before{
      content: "\\00d7";
      font-size: ${rem('35px', baseFont)};
    }
`;

S.MainMenuWrapper = styled.div`

`;

S.LogoWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 16.66rem;
  margin: 0 ${rem('35px', baseFont)} 0 0;
  ${({ theme: { mqForMac } }) => mqForMac}{
    width: 7%;
  }
`;

S.Link = styled.a`
  display: flex;
  width: 100%;
  cursor: pointer;
`;

S.Logo = styled.img`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
`;

S.SocialIcons = styled.div`
  display: flex;
  margin-left: auto;
`;

S.SocialIconLink = styled.a`
  width: ${rem('32px', baseFont)};
  height: ${rem('32px', baseFont)};
  margin-right: ${rem('8px', baseFont)};
  &:last-child{
    margin-right: 0;
  }

  svg {
    circle {
      fill: #fff;
    }

    path {
      fill: #c62828;
    }
  }
`;

export default S;