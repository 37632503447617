import styled from 'styled-components';
import {List} from 'global/styles/styles.js';
const S = {};

const duration = 1000; 

S.DropdownMenuList = styled(List)`
    float:left;
    width: 100%;

    &.slide-transition-enter {
        max-height: 0px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-enter-active {
        max-height: 1000px;
    }

    &.slide-transition-exit {
        max-height: 1000px;
        overflow: hidden;
        transition: max-height ${duration}ms;
    }
        
    &.slide-transition-exit-active {
        max-height: 0px;
    }  
`;

S.DropdownSubmenuList = styled(S.DropdownMenuList)`
    float:left;
    width: 100%;
`;

S.FlagIcon = styled.svg`
    flex-shrink: 0;
    margin-right: 10px;
    height: 16px;
    width: 16px;
`;

S.Title = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
    margin-top: 4px;
    margin-bottom: 5px;
    font-weight: ${({theme:{$fwMedium}}) => $fwMedium};
`;

S.FavouriteIcon = styled.div`
    width: 9px;
    height: 9px;
    cursor: pointer;
    line-height: 0;
    display: none;
    & svg{
        & path{
            fill: #C62828;
        }
    }
    
    &:hover{
        & svg{
            & rect,
            & path {
                fill: #C62828;
            }
        }
    }
    
    ${({isFavourite}) => {
    return (isFavourite == true) && `
        display: block;
        & svg {
            & rect,
            & path {
                fill: #C62828;
            }
        }`;
    }};
`;

S.Icon = styled.svg`
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-right: 1rem;
    color: ${({theme:{$categoriesSportIconColor}}) => $categoriesSportIconColor};
`;

S.ItemInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 90%;
`;

S.Arrow = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    transition: transform 0.5s, background-color 0.5s;
    &:before {
        content: '';
        width: 1.2rem;
        height: .87rem;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background-color: ${({theme:{$categoriesDropdownArrowColor}}) => $categoriesDropdownArrowColor};
        mask-size:  1.2rem .87rem;
        -webkit-mask-size:  1.2rem .87rem;
        mask: url(/images/svg/arrow_icon_black.svg) no-repeat 50% 50%;
        -webkit-mask: url(/images/svg/arrow_icon_black.svg) no-repeat 50% 50%;
    }
`;

S.ArrowDown = styled(S.Arrow)`
    &:before {
        width: 0.9rem;
        mask: url(/images/svg/arrow_icon_black.svg) no-repeat 30% 30%;
        -webkit-mask: url(/images/svg/arrow_icon_black.svg) no-repeat 30% 30%;
    }
`;

S.EventsCount = styled.span`
    width: 16.6666666667%;
    display: inline-block;
    line-height: 1.3rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme:{$categoriesEventsCount}}) => $categoriesEventsCount};
    text-align: right;
    font-size: 14px;
    border-radius: 3px;
    padding: 3px 0;
    flex-shrink: 0;
    flex: 1;
`;

S.DropdownMenuItemInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 40px;
    overflow: hidden;
    box-shadow: none;
    border-radius: 0;
    font-size: 14px;
    margin-top: 0;
    background: 0 0;
    background-color: ${({theme:{$categoriesDropdownItemBgColor}}) => $categoriesDropdownItemBgColor};
    padding: 1rem 1.4rem;
    color: ${({theme:{$categoriesDropdownItemColor}}) => $categoriesDropdownItemColor};
    &:hover {
        background-color: ${({theme:{$categoriesDropdownItemBgColorHover}}) => $categoriesDropdownItemBgColorHover};
    }      
`;

S.PopularCategoriesListItemLink = styled.a `
    display: none;
    width: 0%;
    height: 0%;
    text-decoration: none;
`;

S.DropdownMenuItem = styled.li`

    &[data-level="1"]{
        border-bottom: 1px solid ${({theme:{$categoriesDropdownItemBorderColor}}) => $categoriesDropdownItemBorderColor};
        &>${S.DropdownMenuItemInner}{
            & ${S.Title} {
            font-weight: ${({theme:{$fwSemibold}}) => $fwSemibold};
            }
                & ${S.EventsCount}{
                    color: ${({theme:{$categoriesActiveEventsCountColor}}) => $categoriesActiveEventsCountColor};
                    background-color: transparent;
                    margin-right: 15px;
                    font-weight: 600;
              }
        }
    }

    &[data-level="1"].is-active {
        &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemActiveBg}}) => $categoriesDropdownItemActiveBg};
     
         & ${S.Title}
            {
                color: ${({theme:{$categoriesDropdownItemActiveColor}}) => $categoriesDropdownItemActiveColor};
            }

            & ${S.Arrow} {
                transform: rotate(180deg);
                &:before {
                    background-color: ${({ theme: { $categoriesDropdownActiveArrowColor } }) => $categoriesDropdownActiveArrowColor};
                }
            }
            
          
        }
    }
    
    &[data-level="2"]{
        &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemLvl2BgColor}}) => $categoriesDropdownItemLvl2BgColor};
            padding-left: 30px;

            & ${S.Title} {
            font-weight: ${({theme:{$fwSemibold}}) => $fwSemibold};
            }

            &:hover{
            background-color: ${({theme:{$categoriesDropdownItemLvl2BgColorHover}}) => $categoriesDropdownItemLvl2BgColorHover};
            }

            & ${S.EventsCount} {
            font-weight: ${({theme:{$fwSemibold}}) => $fwSemibold};
            }
        
            & ${S.Arrow}{
                &:before {
                    background-color: ${({theme:{$categoriesDropdownItemLvl2ArrowColor}}) => $categoriesDropdownItemLvl2ArrowColor};
                }
            }
        }
    }

    &[data-level="2"].is-active {
        &>${S.DropdownMenuItemInner}
        {
       
            background-color: ${({theme:{$categoriesDropdownItemLvl2ActiveBgColor}}) => $categoriesDropdownItemLvl2ActiveBgColor};
            & ${S.Arrow}
            {
                &:before {
                   background-color: ${({theme:{$categoriesDropdownItemLvl2ActiveArrowColor}}) => $categoriesDropdownItemLvl2ActiveArrowColor};
                }
            }
        }
    }
    
    &[data-level="3"]{
        &>${S.DropdownMenuItemInner}{
            background-color: ${({theme:{$categoriesDropdownItemLvl3BgColor}}) => $categoriesDropdownItemLvl3BgColor};
            padding-left: 40px;



            &:hover {
                ${S.FavouriteIcon}{
                    display: block;
                }
            }

        }
    }
    &.hidden-in-esport {
        display: none;
    }

`;

S.FakeChbox = styled.div`
    width: 12px;
    height: 12px;
    border: 1px solid ${({theme:{$categoriesLvl3CheckboxBorderColor}}) => $categoriesLvl3CheckboxBorderColor};
    background-color: ${({theme:{$categoriesLvl3CheckboxBgColor}}) => $categoriesLvl3CheckboxBgColor};
    border-radius: 3px;
    margin-right: 10px;
    &.is-active {
        border: none;
        border-color: ${({theme:{$categoriesLvl3CheckboxActiveBorderColor}}) => $categoriesLvl3CheckboxActiveBorderColor};
        background-color:  ${({theme:{$categoriesLvl3CheckboxActiveBgColor}}) => $categoriesLvl3CheckboxActiveBgColor};
        &:after {
            content: "\f3fd";
            display: block;
            margin-left: 2px;
            color: #000;
            font-family: Ionicons;
            font-size: 22px;
            font-size: 1.6923076923rem;
            margin-top: -.7rem;
            color: #fff;
        }
}
`;

export default S;