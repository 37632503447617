import React, {useState, useEffect, useMemo, useRef} from 'react';
import S from 'StyledRegisterWizardForm.js';
import _pick from 'lodash/pick';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _size from 'lodash/size';
import {substractYears, formatDate} from 'datesHelper.js';
import { getPromoCookie, setGoogleDataLayerCookie, getBtagCookie } from 'cookiesHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import RegisterWizardFormStep1 from 'RegisterWizardFormStep1.js';
import RegisterWizardFormStep2 from 'RegisterWizardFormStep2.js';
import CmsPage from 'CmsPage.js';
import {SubmissionError, change, formValueSelector, destroy} from 'redux-form';
import {connect, batch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {addIncomeAccessData, setIncomeAccessIframe} from 'integrationActions.js';
import {getFavouriteLeagues} from 'favouritesActions.js';
import { fetchPromoCodeSetting } from 'topMenuActions.js';
import {
    logInUser,
    registerUser,
    preregisterUser,
    verificationCode,
} from 'authActions.js';
import store from 'store';
import usePrevious from 'UsePrevious.js';
import useToggle from 'UseToggle.js'
import {translation, triggerNativeEvent, pushCustomEventToGoogleAnalytics, redirect} from 'utilsHelper.js';
import {getDateOfBirthFromPesel} from 'validateHelper.js';
import { loadPublicRegConsents } from 'customerActions.js';
import Box from 'react-styled-box';
import BellIcon from 'bell-icon.svg';
import Logo from 'bell-icon.svg';
import BackBtnArrowIcon from 'back-btn-icon.svg';

const RegisterWizardForm = (props) => {

    const {
        smsVerificationCode,
        personalIdentifier,
        isPromoCodeVisible,
        loadPublicRegConsents,
        logInUser,
        registerUser,
        preregisterUser,
        verificationCode,
        fetchPromoCodeSetting,
        getFavouriteLeagues,
    } = props;

    const [affiliateParam, setAffiliateParam] = useState();
    const [step, setNextStep] = useState(1);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const [showMarketingConsentModal, toggleMarketingConsentModal] = useToggle(false);
    const prevStep = usePrevious(step);
    const prevFirstStepValues = usePrevious({smsVerificationCode, personalIdentifier});
    const incomeAccessIframeRef = useRef();
    const {dispatch} = store;
    const customerRegStepTwoTag = 'CUSTOMER_REGISTRATION_STEP_2';
    const [registrationConsents, setRegConsents] = useState([]);

    useEffect(() => {
        destroy('registerWizardForm'); //remove store on init
    }, []);

    const toggleRegistrationTag = (addTag, tagName) => {
        const loc = window.location;
        if (addTag) {
            history.replaceState({}, document.title, `${loc.href.substr(0, loc.href.length - loc.hash.length)}#${tagName}`);
        } else {
            if ('pushState' in history) {
                history.replaceState({}, document.title, loc.href.substr(0, loc.href.length - loc.hash.length));
            }
        }
    };

    //read btag query param and call CMS place with banner based on it
    useEffect(() => {
        const btagParam = getBtagCookie();
        let firstPartBtagParam = btagParam?.split("b")[0];
        firstPartBtagParam = firstPartBtagParam?.replace('_', '');
        firstPartBtagParam = firstPartBtagParam ? firstPartBtagParam : 'default';
        setAffiliateParam(firstPartBtagParam);
    }, []);

    useEffect(() => {
        getPublicRegConsents().then(data => setRegConsents(data)); 
        toggleRegistrationTag(true, 'registerStart');
        return toggleRegistrationTag.bind(this, false, 'registerStart');
    }, []);

    useEffect(() => {
        fetchPromoCodeSetting();
    }, []);


    useEffect(() => {
        if (step == 1 && step != prevStep) {
            setTimeout(() => dispatch(change('registerWizardForm', 'password', '')), 500);
        } else if (step == 2 && step != prevStep) {
            if (prevFirstStepValues.personalIdentifier){
                triggerNativeEvent('[name="personalIdentifier"]', 'blur');
            }
            if (prevFirstStepValues.smsVerificationCode){
                triggerNativeEvent('[name="verificationCode"]', 'blur');
            }

        }

    }, [step]);

    const goToNextPage = () => {
        setNextStep((prevStep) => prevStep + 1);
    };

    const goToPrevPage = () => {
        if (step > 1) {
            setNextStep((prevStep) => prevStep - 1);
        }
    };

    const getPublicRegConsents = async() => {
        try {
            const data = await loadPublicRegConsents(customerRegStepTwoTag);
            return data.CUSTOMER_REGISTRATION_STEP_2 || [];
        } catch (error) {
            throw error;
        }
    };

    const registerFirstStep = () => {
        const values = store.getState().form.registerWizardForm.values;
        const pickedValues = ['email', 'marketingConsent', 'mobile'];
        const registerData = _pick(values, pickedValues);
        const marketingConsentInner = _get(registerData, ['marketingConsent']);

            try {

                if (marketingConsentInner == true) {
                    preregisterUser(registerData);
                }

                const mobile = _pick(values, ['mobile']);
                const previousMobile = _pick(values, ['previousMobileNumber']);
                if(mobile.mobile != previousMobile.previousMobileNumber){
                    dispatch(change("registerWizardForm","previousMobileNumber", mobile.mobile));
                    verificationCode(mobile);
                }

                goToNextPage();

            } catch (error) {
                throw new SubmissionError({_error: error});
            } 
        
    };

   const registerOnSubmit = async (postData) => {
        try {
            const newUser = await registerUser(postData);
            return newUser;
        } catch (error) {
            throw error;
        }
    };

    const loginOnSubmit = async (postData) => {
        try {
            const user = await logInUser(postData);
            const {userId} = user;
            if (userId) {
                setGoogleDataLayerCookie(userId);
            }
            getFavouriteLeagues();
            app.partial.EventListCountryList.showFavouritesOnSubmit();
            return user;
        } catch (error) {
            throw error;
        }
    };

    const registerSecondStep = async (values) => {
        try {

            let pickedValues = [
                'firstName',
                'lastName',
                'nationality',
                'password',
                'email',
                'mobile',
                'verificationCode'
            ];

            const nationalityPL = _get(values, ['nationality']);
            if (nationalityPL == 'PL') {
                pickedValues = [...pickedValues, 'personalIdentifier'];
            } else {
                pickedValues = [...pickedValues, 'postalCode', 'address', 'dateOfBirth'];
            }

            const fieldPromoCode = _get(values, ['promoCode']);
            // Jeżeli jest widoczny i uzupełniony ręcznie lub pobrany z cookies, wczytaj normalnie z values
            if (fieldPromoCode){
                pickedValues = [...pickedValues, 'promoCode'];
            }

            let registerData = _pick(values, pickedValues);
            if ('personalIdentifier' in registerData) {
                registerData['personalIdentificationNumber'] = registerData['personalIdentifier'];
                const dateOfBirthFromPesel = getDateOfBirthFromPesel(registerData['personalIdentificationNumber']);
                registerData['dateOfBirth'] = formatDate(dateOfBirthFromPesel, 'yyyy-MM-dd');
                delete registerData['personalIdentifier'];
            }

            const emailVerification = _get(values, ['emailVerification']);
            if(emailVerification){
                registerData['verificationCode'] = null;
            }

            // opis działania w tasku 80019
            const promoCode = getPromoCookie();
            if(!isPromoCodeVisible && promoCode){
                registerData['promoCode'] = promoCode;
            } else if (!promoCode && !fieldPromoCode) {
                registerData['promoCode'] = app.config.defaultPromoCode;
            }

            registerData = addIncomeAccessData(registerData);
            
            const btag = registerData.affiliateId;
            if (btag){
                registerData['btag'] = btag;
                registerData = _.omit(registerData, 'affiliateId');
            }
            
            registerData.countryCode = process.env.MAIN_COUNTRY_CODE;
            registerData.currencyCode = process.env.MAIN_CURRENCY_CODE;

            const offerContainers = [];
            for(let i in registrationConsents){
              offerContainers.push({
                "container": "OFFERS", "accepted": _get(values, ['isBonus']), "definitionId": registrationConsents[i].definitionId
              })
            }

            registerData['consents'] = [
                { "container": "LOTTERY_PARTICIPATION", "accepted": false },
                { "container": "REGULATIONS", "accepted": true },
                ...offerContainers
            ];
            //temporary solution for my affiliate token
            const marketingConsent = _get(values, ['marketingConsent']);
            if (marketingConsent) {
                registerData['consents'].push(
                    { "container": "EMAIL", "accepted": marketingConsent },
                    { "container": "SMS", "accepted": marketingConsent }
                );
            } else if (emailVerification) {
                registerData['consents'].push(
                    { "container": "EMAIL", "accepted": true },
                    { "container": "SMS", "accepted": false }
                );
            } else {
                registerData['consents'].push(
                    { "container": "EMAIL", "accepted": false },
                    { "container": "SMS", "accepted": true }
                );
            };

            const myAffiliateToken = Cookies.get('myAffiliateToken');
            if (myAffiliateToken) registerData['affiliateToken'] = myAffiliateToken;

            const customerId = await registerOnSubmit(registerData);
            setIncomeAccessIframe(customerId, incomeAccessIframeRef);
            if (customerId) {
                setGoogleDataLayerCookie(customerId);
                pushCustomEventToGoogleAnalytics({
                    'event': 'registerEnd',
                    'category': 'conversion',
                    'userId': customerId,
                })
            }
            
            if(!emailVerification){
                const loginData = _pick(values, ['password']);
                loginData['login'] = _get(_pick(values, ['email']), ['email']);
                await loginOnSubmit(loginData);
                toggleRegistrationTag(true, 'registerEnd');
            }else{
                toggleConfirmationModal();
                toggleRegistrationTag(true, 'registerEnd');
            }

        } catch (error) {
            throw new SubmissionError({_error: error});
        }
    };

    const secondStepInitialValues = useMemo(() => {
        const date = substractYears(new Date(), 18);
        
        let data = {
            isBonus: true,
            dayOfBirth: date.getDate(),
            monthOfBirth: date.getMonth(),
            yearOfBirth: date.getFullYear(),
            nationality: process.env.MAIN_COUNTRY_CODE
        };

        const promo = getPromoCookie();
        promo?  data['promoCode'] = promo :  data['promoCode'] = app.config.defaultPromoCode
        return data;
    }, []);

    const handleSubmitOnClick = async (values)=>{ // method to check if marketingConsent is true and if not modal will appear
        const pickedValues = ['email', 'marketingConsent', 'mobile'];
        const registerData = _pick(values, pickedValues);
        const marketingConsentInner = _get(registerData, ['marketingConsent']);

        if(marketingConsentInner === true){
            registerFirstStep();
        }else{
            toggleMarketingConsentModal(); 
        }
    }

    const handleButtonClick = (marketingConsent = false) => { //buttons in marketing consent modal
        dispatch(change("registerWizardForm","marketingConsent", marketingConsent)); 
        toggleMarketingConsentModal(); 
        registerFirstStep();
    }

    const onConfirmationModalClose = () => {
        toggleConfirmationModal();
        redirect('/');
    }

    return (
            <S.RegisterWizardForm>
                <S.LogoWrapper>
                    <S.Link href={`/`}>
                        <S.Logo src='/images/svg/logo-desktop.svg' alt='fuksiarz.pl'/>
                    </S.Link>
                </S.LogoWrapper>
                <S.LoginButton onClick={()=>redirect('/login')} data-translation='registerForm_loginButton'>{translation('registerForm_loginButton')}</S.LoginButton>
                <S.BannerWrapper>
                    {affiliateParam && <CmsPage name={`affiliate-${affiliateParam}`} alternativeContent="affiliate-default" isPlaces={true}/> }
                    {step > 1 && (
                        <S.PrevStepBtn onClick={goToPrevPage}>
                            <S.PrevIcon dangerouslySetInnerHTML={{ __html: BackBtnArrowIcon }}></S.PrevIcon>
                        </S.PrevStepBtn>
                    )}
                </S.BannerWrapper>
                <S.Header>
                    <S.Title>
                        {translation('registerForm_title')}
                    </S.Title>
                </S.Header>

                <S.Stepper step={step}>
                    <S.StepWrapper>
                        <S.Step onClick={goToPrevPage} isActive={(step == 1 || step == 2)} hasPointer={(step==2)}>
                            <S.StepCircle>1</S.StepCircle>
                            <S.StepLabel>{translation('registerForm_accountData')}</S.StepLabel>
                        </S.Step>
                    </S.StepWrapper>

                    <S.StepWrapper>
                        <S.Step isActive={(step == 2)}>
                            <S.StepCircle>2</S.StepCircle>
                            <S.StepLabel>{translation('registerForm_personalData')}</S.StepLabel>
                        </S.Step>
                    </S.StepWrapper>
                </S.Stepper>

                {/* MARKETING CONSENT ACCEPTATION MODAL */}
                <ModalOpacity isOpen={showMarketingConsentModal}
                            toggleOpen={toggleMarketingConsentModal}
                            showHeader={false}
                            showCloseIcon={false}
                            padding={0}
                            preventCloseOnOverlay={true}
                            >
                    <S.Dialog>
                        <Box flexDirection="column">

                            <Box justifyContent="center">
                                <S.BellIcon dangerouslySetInnerHTML={{__html: BellIcon}}></S.BellIcon>
                            </Box>

                            <Box margin="20px 0 0" alignSelf="center">
                                <S.DialogText data-translation="registerForm_marketingConsent_additionalText">{translation('registerForm_marketingConsent_additionalText')}</S.DialogText>
                            </Box>

                            <Box justifyContent="space-between" margin="20px 0 0">
                                <S.DialogBtnOk onClick={()=>{handleButtonClick(true)}} data-translation="registerForm_marketingConsent_buttonAccept">{translation('registerForm_marketingConsent_buttonAccept')}</S.DialogBtnOk>
                                <S.DialogBtnCancel onClick={()=>{handleButtonClick(false)}} data-translation="registerForm_marketingConsent_buttonCancel">{translation('registerForm_marketingConsent_buttonCancel')}</S.DialogBtnCancel>
                            </Box>
                        </Box>
                    </S.Dialog>
                </ModalOpacity>

                {/* REGISTER CONFIRMATION MODAL */}
                <ModalOpacity isOpen={showConfirmationModal}
                            toggleOpen={onConfirmationModalClose}
                            showHeader={false}
                            padding={0}
                            showCloseIcon={false}
                            >
                    <S.ConfirmationModal>
                        <S.ConfirmationMsg data-translation="account_unloggedPanel_confirmationModal">{translation('account_unloggedPanel_confirmationModal')}</S.ConfirmationMsg>
                        <S.ConfirmationCloseBtn data-translation="common_ok"
                            onClick={onConfirmationModalClose}>{translation('common_ok')}</S.ConfirmationCloseBtn>
                    </S.ConfirmationModal>


                </ModalOpacity>

                {step == 1 && (
                    <RegisterWizardFormStep1 onSubmit={handleSubmitOnClick}/>
                )}
                {step == 2 && (
                    <RegisterWizardFormStep2 initialValues={secondStepInitialValues}
                                            onSubmit={registerSecondStep}
                                            isPromoCodeVisible={isPromoCodeVisible}
                    />
                )}
                <S.HideIframe ref={incomeAccessIframeRef}></S.HideIframe>
            </S.RegisterWizardForm>
    );
};

const selector = formValueSelector('RegisterWizardForm');
const mapStateToProps = (state) => {
    const {TopMenu:{ isPromoCodeVisible }} = state;

    return {
        personalIdentifier:  selector(state, 'personalIdentifier'),
        smsVerificationCode:  selector(state, 'verificationCode'),
        isPromoCodeVisible
        // selectAll:  selector(state, 'selectAllFields')
    };
};

const mapDispatchToProps = (dispatch) => {
  const bindedActions = {
      loadPublicRegConsents,
      logInUser,
      registerUser,
      preregisterUser,
      verificationCode,
      fetchPromoCodeSetting,
      getFavouriteLeagues,
  }

  return {
      ...bindActionCreators(bindedActions, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RegisterWizardForm);

