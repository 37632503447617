import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import S from 'StyledAccountQueueBonus.js';
import {translation, formatMoney} from 'helpers/utilsHelper.js';
import {formatDate} from 'helpers/datesHelper.js';
import _map from 'lodash/map';
import _size from 'lodash/size';
import _get from 'lodash/get';
import Loader from 'Loader.js';
import Pagination from 'Pagination.js';
import ModalOpacity from 'ModalOpacity.js';
import {
    fetchBonusListByFilter,
    setCurrentPage,
    manageBonus,
    fetchBonusListPending,
    fetchBonusListFulfilled,
    fetchBonusListFailure
} from 'accountBonusActions.js';


class AccountQueueBonus extends Component {

    state = {
        selectedOfferId: null,
        showConfirmationModal: false,
        showInfoModal: false,
        modalTitle: '',
        modalDescription: '',
        infoModalTitle: '',
        infoModalDescription: '',
        status: '',
        isPendingBonusAction: false
    };

    componentDidMount = async() => {
        const {fetchBonusListPending, fetchBonusListByFilter, fetchBonusListFailure, fetchBonusListFulfilled, startDate, endDate, limit} = this.props;

        fetchBonusListPending();

        try {
            const data = await fetchBonusListByFilter({status: 'QUEUE', endDate, startDate});
            fetchBonusListFulfilled(data);
        } catch (error) {
            console.log('AccountQueueBonus:', error);
            fetchBonusListFailure(error)
        }
    };

    setSelectedOfferId = (selectedOfferId) => {
        this.setState((prevState) => {
            return {...prevState, selectedOfferId}
        });
    };

    setStatus = (status) => {
        this.setState((prevState) => {
            return {...prevState, status}
        });
    };

    setInfoModalTextElements = (infoModalTitle, infoModalDescription) => {
        this.setState((prevState) => {
            return {...prevState, infoModalTitle, infoModalDescription};
        });
    };

    setConfirmModalTextElements = (action) => {
        let modalTitle = '';
        let modalDescription = '';
        switch (action) {
            case 'activate':
                modalTitle = translation('account_bonus_activationTitle');
                modalDescription = translation('account_bonus_confirmActivate');
                break;
            case 'cancel':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmCancel');
                break;
            case 'reject':
                modalTitle = translation('account_bonus_cancellationTitle');
                modalDescription = translation('account_bonus_confirmReject');
                break;
        }

        this.setState((prevState) => {
            return {...prevState, modalTitle, modalDescription};
        });
    };

    toggleModal = (type) => {
        this.setState((prevState) => {
            return {...prevState, [type]: !prevState[type]}
        });
    };

    renderBonusActionConfirmation = (offerId, status) => {
        this.setStatus(status);
        this.setConfirmModalTextElements(status);
        this.setSelectedOfferId(offerId);
        this.toggleModal('showConfirmationModal');
    };

    componentWillUnmount = () => {
        const {setCurrentPage} = this.props;
        setCurrentPage(0, 'QUEUE');
    }

    goPage = async(status, currentPage) => {
        const {fetchBonusListByFilter, startDate, endDate, limit, setCurrentPage} = this.props;
        let calcPage = currentPage * limit;
        const params = {
            endDate,
            startDate,
            limit,
            currentPage: calcPage,
            status
        };

        try {
            await fetchBonusListByFilter(params);
            setCurrentPage(currentPage, status);
        } catch (error) {
            console.log('goPage:' + error);
        }
    };

    toggleBonusPendingStatus = () => {
        const {isPendingBonusAction} = this.state;
        this.setState((prevState) => {
            return {...prevState, isPendingBonusAction: !prevState['isPendingBonusAction']}
        });
    }

    bonusActionAccept = async(offerId, action) => {
        const {manageBonus} = this.props;

        this.toggleBonusPendingStatus();

        try {
            const result = await manageBonus(offerId, action);
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');
            this.setInfoModalTextElements(translation('account_bonus_action_success'), '');
        } catch (error) {
            this.toggleModal('showConfirmationModal');
            this.toggleModal('showInfoModal');
            this.setInfoModalTextElements(translation('account_bonus_action_error'), translation(error));
        }
    };

    render() {
        const {isPending, error, bonus, limit, currencyCode} = this.props;
        const {showInfoModal, showConfirmationModal, modalTitle, modalDescription, infoModalTitle, infoModalDescription, status, selectedOfferId, isPendingBonusAction} = this.state;

        const queueBonus = _get(bonus, 'queue');
        const queueBonusData = _get(queueBonus, 'data');

        const activeBonus = _get(bonus, 'active');
        const activeBonusData = _get(activeBonus, 'data');

        return (
            <S.AccountQueueBonus className="account-queue-bonus">

                {isPending ?
                    <Loader/>
                    : (
                        <>

                        <ModalOpacity isOpen={showConfirmationModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showConfirmationModal')}>

                                    <S.ActivateModal>
                                    <S.Title>{modalTitle}</S.Title>
                                        <S.Description>{modalDescription}</S.Description>
                                        {isPendingBonusAction ? 
                                            <Loader/>
                                            : (
                                                <S.BtnsWrapper>
                                                    <S.ButtonCancel
                                                        onClick={this.toggleModal.bind(this, 'showConfirmationModal')}
                                                    data-test='account_bonus_queue_cancel'>{translation('common_cancel')}</S.ButtonCancel>
                                                    <S.ButtonAccept
                                                        onClick={this.bonusActionAccept.bind(this, selectedOfferId, status)}
                                                        data-test='account_bonus_queue_accept'>{translation('common_accept')}</S.ButtonAccept>
                                                </S.BtnsWrapper>
                                            )}
                                    </S.ActivateModal>
                                

                            
                        </ModalOpacity>

                        <ModalOpacity isOpen={showInfoModal} showHeaderIcon={false} showHeaderTitle={false}
                                      toggleOpen={this.toggleModal.bind(this, 'showInfoModal')}>

                            <S.ModalInfo>
                                <S.InfoTitle>{infoModalTitle}</S.InfoTitle>
                                {infoModalDescription && <S.InfoDescription>{infoModalDescription}</S.InfoDescription>}
                            </S.ModalInfo>

                        </ModalOpacity>

                        <S.BonusList>
                            <S.Table>
                                <S.Thead>
                                    <S.Tr className="queueHeader">
                                        <S.Th>{translation('account_bonus_name')}</S.Th>
                                        <S.Th>{translation('account_bonus_amount')}</S.Th>
                                        <S.Th>{translation('account_bonus_totalToWager')}</S.Th>
                                        <S.Th>{translation('account_bonus_expiresOn')}</S.Th>
                                        <S.Th>{translation('account_bonus_action')}</S.Th>
                                    </S.Tr>
                                </S.Thead>
                                <S.Tbody>

                                    {_size(queueBonusData) ?
                                        (
                                            <>

                                            {_map(queueBonusData, ({offerName, validTo, bonusValue, totalToWager, offerQueueId, descriptionKey}, index) => {
                                                return (
                                                    <React.Fragment key={offerQueueId}>
                                                        <S.Tr key={offerQueueId}>
                                                            <S.Td className="td">
                                                                <span>{offerName}</span>
                                                            </S.Td>
                                                            <S.Td>
                                                                <span
                                                                    className="ammount">{formatMoney(bonusValue)}</span>&nbsp;
                                                                <span className="currency">{translation('common_pln')}</span>
                                                            </S.Td>
                                                            <S.Td className="amount">
                                                                <span
                                                                    className="ammount">{formatMoney(totalToWager)}</span>&nbsp;
                                                                <span className="currency">{translation('common_pln')}</span>
                                                            </S.Td>
                                                            <S.Td>
                                                                <span>{formatDate(validTo, 'dd/MM/yyyy')}</span>
                                                            </S.Td>
                                                            <S.Td className="bonus-actions">
                                                                <S.Button className="reject"
                                                                        onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'reject')}
                                                                        data-test='account_bonus_queue_delete'>
                                                                        {translation('account_bonus_delete')}
                                                                </S.Button>
                                                                <S.Button className="accept"
                                                                        onClick={this.renderBonusActionConfirmation.bind(this, offerQueueId, 'activate')}
                                                                        disabled={activeBonusData && activeBonusData.length !== 0}
                                                                    data-test='account_bonus_queue_use'>
                                                                        {translation('account_bonus_use')}
                                                                </S.Button>
                                                            </S.Td>
                                                        </S.Tr>
                                                        <S.Tr className="spacer"></S.Tr>
                                                    </React.Fragment>
                                                )
                                            })}

                                            {_size(queueBonusData) && (
                                                <S.Tr>
                                                    <S.Td colSpan="6" className="pagination">
                                                        <Pagination
                                                            currentPage={_get(queueBonus, 'currentPage')} limit={limit}
                                                            data={queueBonusData} cb={this.goPage.bind(this, 'queue')}/>
                                                    </S.Td>
                                                </S.Tr>)}

                                            </>

                                        )
                                        :
                                        <S.NoResult>
                                            <S.Td className="noResults" colSpan="8">{translation('account_bonus_notFound')}</S.Td>
                                        </S.NoResult>
                                    }
                                </S.Tbody>
                            </S.Table>
                        </S.BonusList>

                        </>
                    )
                }

            </S.AccountQueueBonus>
        );
    }
}

const mapStateToProps = ({AccountBonus:{isPending, error, startDate, endDate, activeTab, limit, bonus}, Auth:{user:{currencyCode}}}, props) => {
    return {
        error,
        isPending,
        startDate,
        endDate,
        activeTab,
        limit,
        bonus,
        currencyCode
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBonusListByFilter: bindActionCreators(fetchBonusListByFilter, dispatch),
        setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
        fetchBonusListPending: bindActionCreators(fetchBonusListPending, dispatch),
        fetchBonusListFulfilled: bindActionCreators(fetchBonusListFulfilled, dispatch),
        fetchBonusListFailure: bindActionCreators(fetchBonusListFailure, dispatch),
        manageBonus: bindActionCreators(manageBonus, dispatch),
        dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountQueueBonus);
