const customerVerificationStatus = {
    
    ACCEPTED: 'ACCEPTED',

    AWAITING_ADMIN_APPROVAL: 'AWAITING_ADMIN_APPROVAL',
    
    REJECTED: 'REJECTED',
   
    REJECTED_BY_CUSTOMER: 'REJECTED_BY_CUSTOMER',
   
    TO_CHECK: 'TO_CHECK'

}

export default customerVerificationStatus;