
const fetchCFLocationData = () => {
    return async(dispatch, getState, {OtherApi}) => {
      let data = [];
        try {
          const response = await OtherApi.getCFLocationData();
          const lines = response.data?.split('\n');
          lines.forEach(function(line){
            const keyValue = line.split('=');
            data[keyValue[0]] = decodeURIComponent(keyValue[1] || '');    
          });
          return data;
        } catch (error) {
            throw error;
        }
    }
};


export {fetchCFLocationData};
