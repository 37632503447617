import {translation} from 'utilsHelper.js';

const getAllEventsForLeaguesIds = (ids) => {
    return async (dispatch, getState, {EventsApi}) => {
        try {
            const {code, data} = await EventsApi.getAllEventsForLeaguesIds(ids);
            if (code != 200) {
                throw {message: translation(`api_error_${code}`)};
            }
            return data;
        } catch (error) {
            throw error;
        }
    }
};
const getByRemoteId = (remoteId) => {
    return async (dispatch, getState, {EventsApi}) => {
        try {
            const {code, data} = await EventsApi.getByRemoteId(remoteId);
            if (code != 200) {
                throw {message: translation(`api_error_${code}`)};
            }
            return data;
        } catch (error) {
            throw error;
        }
    }
};

export {getAllEventsForLeaguesIds, getByRemoteId}