import styled from 'styled-components';
import {List, Button} from 'global/styles/styles.js';
import StyledAccountSessionTimer from 'StyledAccountSessionTimer.js';
import {rem} from 'polished';

const FwRegular = ({theme:{$fwRegular}}) => `${$fwRegular}`;

const S = {};
const baseFont = `12px`;
 
S.AccountWrapper = styled.div`
    background-color: ${({theme:{$accountTopHeaderBackground}}) => $accountTopHeaderBackground};
    color: ${({theme:{$accountTopHeaderColor}}) => $accountTopHeaderColor};
    height: 100%;
    display: block;
    flex-direction: column;
    flex:1;
    width: 100%;
`;

S.AccountTopPanel = styled.div` 
    background-color: ${({theme:{$accountTopHeaderBackground}}) => $accountTopHeaderBackground};
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    height: ${rem('150px', baseFont)};
    margin-top: ${rem('20px', baseFont)};
    display: flex;
    margin-left: auto;
    margin-right: auto;
`;
S.VipContainer = styled.div`
display:flex;
max-width:100rem;
margin:0 auto;
align-items: center;
`
S.UserAvatarContainer = styled.div`
    flex-basis: 12.5rem;
`;

S.UserAvatar = styled.div`
    width: 93%;
    height: 93%;
`;

S.UserBalanceContainer = styled.div`
    display: flex;
    flex: 1;
    padding-top: 4.5rem;
`;

S.UserMainBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 25%;
    height: 3.333rem;
    text-align: center;
`;

S.UserFreebets = styled(S.UserMainBalance)``;

S.UserMainBalanceLabel = styled.div`
    width: 100%;
    text-align: center;
    font-size: 1rem;
`;

S.UserMainBalanceContainer = styled.div`
    display: inline-block;
    margin: auto;
`;

S.UserMainBalanceValue = styled.div`
    font-size: ${rem('26px', baseFont)};
    line-height: 1;
    display: inline-block;
    margin: auto;
    font-weight: bold;
`;

S.FreebetsBalanceValue = styled(S.UserMainBalanceValue)``;
S.VipCtaBanner= styled.div`
position:relative;
display:flex;
flex-direction:column;
&.direction-row {
    flex-direction:row;
}
align-items: center;
justify-content: space-around;
flex-wrap:wrap;
width:${props=>props.isModal ? "100%":"85%"};
max-width:100rem;
margin: ${props=>props.isModal ? "":"20px auto"};
background: url('/images/background-pattern.png') center #101010;
border-radius:${props=>props.isModal?"":"15px"};
padding: ${props=>props.isModal? "20px 0":""};
${({isModal})=>!isModal &&`
&:after{
    position: absolute;
    top:calc(100% - 15px);
    right:calc(0% - 12px);
    content:"";
    background: #101010;
    clip-path: polygon(53% 1%, 0 47%, 81% 82%);
    width: 30px;
    height:30px;
}
`}
`

S.VipCtaButtonsContainer = styled.div`
display: flex;
justify-content:center;
align-items:center;
width: 100%;
`

S.VipCtaButton= styled.div`
display:flex;
background-color: #C5A96A;
justify-content:center;
align-items:center;
color:#fff;
text-transform:uppercase;
padding:0.62rem 1.56rem;
font-size: 1.16rem;
font-weight:600;
&:hover{
    cursor:pointer;
}
&.extra-margin {
    &:first-child {
        margin-right: 1rem;
    }
    &:last-child {
        margin-left: 1rem;
    }
}
`
S.UserMainBalanceCurrency = styled.div`
    font-size: ${rem('26px', baseFont)};
    margin-left: 2px;
    line-height: 1;
    display: inline-block;
    font-weight: bold;
`;

S.UserBonusBalanceWrapper = styled.div`
    width: 25%;
    text-align: center;
`;

S.UserCashbackWrapper = styled.div`
    width: 25%;
    text-align: center;
`;

S.UserBonusBalance = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.0833333333rem;
    height: 3.333rem;
    padding-right: 0;
`;

S.UserBonusBalanceLabel = styled(S.UserMainBalanceLabel)``;

S.UserBonusBalanceAmount = styled(S.UserMainBalanceValue)``;

S.UserCashbackBalanceLabel = styled(S.UserMainBalanceLabel)``;

S.UserCashbackBalanceAmount = styled(S.UserMainBalanceValue)``;

S.FreebetLabel = styled(S.UserMainBalanceLabel)``;

S.UserNameDataContainer = styled.div`
    flex-basis: 30rem;
    text-align: right;
`;

S.UserName = styled.div`
    font-size: 32px;
`;

S.UserDataButtonContainer = styled.div`
    width: 100%;
    display: inline-block;
`;

S.UserDataButton = styled.div`
    float: right;
    cursor: pointer;
    text-align: center;
    width: ${rem('110px', baseFont)};
    height: ${rem('37px', baseFont)};
    line-height: ${rem('37px', baseFont)};
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: ${({theme: {$accountCustomerDataButtonBackground}}) => $accountCustomerDataButtonBackground};
    color: ${({theme: {$accountCustomerDataButtonColor}}) => $accountCustomerDataButtonColor};
`;

S.UserDataSessionTimerContainer = styled.div` 
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: ${rem('10px', baseFont)};
    margin-bottom: ${rem('10px', baseFont)};
    color: #000000DE;
    font-size:  ${rem('13px', baseFont)};
    line-height: 1;
    
    & ${StyledAccountSessionTimer.AccountSessionTimer}{
      margin-left: ${rem('3px', baseFont)};
    }
    & ${StyledAccountSessionTimer.TimeParts}{
      color: #000000DE;
      font-size:  ${rem('13px', baseFont)};
      font-weight: ${FwRegular};
      margin-right: ${rem('3px', baseFont)};
      & > span{
       margin-top: 0;
        font-size:  ${rem('13px', baseFont)};
        color: #000000DE;
      }
    }
`;

S.UserActivityLink = styled.div`
    cursor: pointer;
    font-size: ${rem('14px', baseFont)};
    font-weight: 600;
    color: ${({theme: {$accountUserSimpleLinkColor}}) => $accountUserSimpleLinkColor};
`;

S.AccountMainMenuContainer = styled.div` 
    width: 100%;
    max-width: ${rem('1200px', baseFont)};
    margin: 0 auto;
`;

S.AccountBody = styled.div`
    background-color: ${({theme:{$accountTopHeaderBackground}}) => $accountTopHeaderBackground};
    flex: 1;
    display: flex;
    justify-content: center;
    
`;
S.AccountBottom = styled.div`
    flex: 1;
    padding: 0;
    display: flex;
    min-height: calc(100% - 5.83rem);
    height: auto;
`;

S.UserName = styled.div`
    font-size: 22px;
    font-weight: 600;
`;

S.BalanceSelect = styled.div`
    width: ${rem('200px', baseFont)};
    margin: auto;
    cursor: pointer;
    align-items: stretch;
    top: -3px;
    left: -10px;
    
    & .react-select__menu{
        margin-top: 0;
        
        & .react-select__menu-list{
             padding: 0;
        
            & > div{
                cursor: pointer;
            }
        }
    }
    
    & .react-select__control{
        box-shadow: none;
        min-height: 0;
        border: none;
        background: inherit;
        
        & .react-select__dropdown-indicator{
            padding: 3px;
        }
    
        & .react-select__value-container{
        
            & .react-select__single-value{
                 font-size: 1.2rem;
                 font-family: Roboto,sans-serif;
            }
        }
    
    }
`;

S.UserCashbackRefreshButton = styled(Button)`
    background-color: #D32F2F;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem('76px', baseFont)};
    height: ${rem('37px', baseFont)};
    color: #fff;
    margin: 0 auto;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: ${rem('14px', baseFont)};
`;

export default S;