import styled from 'styled-components';
import {Form, Input, Button} from 'global/styles/styles.js';
import {rem} from 'polished';

const SubmitBtnBgColor = ({theme:{$loginFormSubmitBtnBgColor}}) => `${$loginFormSubmitBtnBgColor}`;
const SubmitBtnColor = ({theme:{$loginFormSubmitBtnColor}}) => `${$loginFormSubmitBtnColor}`;
const FontFamily = ({theme: {$fontFamily}}) => `${$fontFamily}`;
const FwSemibold = ({theme:{$fwSemibold}}) => `${$fwSemibold}`;

const baseFont = `12px`;
const S = {};

S.ResetPasswordForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${rem('500px', baseFont)};
    padding:  0 ${rem('30px', baseFont)} ${rem('30px', baseFont)};
    font-family: ${FontFamily};
`;

S.Row = styled.div`
  width: 100%;
`;

S.Label = styled.label`
    pointer-events: none;
    transition: .2s;
    position: absolute;
    top: ${rem('11px', baseFont)};
    left: ${rem('16px', baseFont)};
    color: rgba(0, 0, 0, 0.5);
    font-size:  ${rem('14px', baseFont)};
    font-weight: 400;
`;

S.InputWrapper = styled.div`
    position: relative;
    margin-bottom: ${rem('10px', baseFont)};
`;

S.Input = styled(Input)`
    box-shadow: none;
    color: rgba(0, 0, 0, 0.7);
    font-size:  ${rem('14px', baseFont)};
    padding: 0 ${rem('16px', baseFont)};
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    display: block;
    width: 100%;
    height: ${rem('42px', baseFont)};
    &:valid,
    &[disabled]{
       & + ${S.Label}{
         margin-top: ${rem('-17px', baseFont)};
         margin-left: ${rem('-3px', baseFont)};
         font-size:  ${rem('10px', baseFont)};
         padding: 0 ${rem('5px', baseFont)};
         background-color: #fff;
       }
    }
    &:-webkit-autofill {
                background-color: #fff;
                -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 0 0 83.33rem #fff inset;
                }
     }
     
    &.has-error{
       border: 1px solid #D32F2F;
       & + ${S.Label}{
            color: #D32F2F;
       }
    }
    
    &.is-valid{
        border: 1px solid #63dd7d;
        & + ${S.Label}{
            color: #63dd7d;
       }
    }
`;


S.SubmitBtn = styled(Button)`
    background-color: ${SubmitBtnBgColor};
    height: ${rem('42px', baseFont)};
    font-size: ${rem('14px', baseFont)};
    font-weight: ${FwSemibold};
    padding: 0;
    width: 100%;
    margin-top: ${rem('20px', baseFont)};
    color: ${SubmitBtnColor};
    cursor: pointer;
    border-radius: 3px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    display: block;
         ${({disabled})=> {
    return (disabled == true) && `
                background: #eee;
                opacity: 1;
                color: #676767
        `;
}}
`;

S.Error = styled.div`
   color: red;
    float: left;
    width: 100%;
    margin-top: 0;
    border-radius: 0;
    padding: 0 0 5px 0;
    margin-left: 0;
`;

S.SubmissionError = styled.div`
    margin: 0;
    border-radius: .25rem;
    width: 100%;
    color: red;
    font-size: 1rem;
    text-align: left;
    padding: .83rem;
    background-color: #fba59f;
    margin-bottom: ${rem('20px', baseFont)};
`;

S.SubmissionSuccess = styled(S.SubmissionError)`
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
`;



export default S;