import styled, {css, keyframes}  from 'styled-components';
import {rem} from 'polished';
import {List, Button, Input} from 'global/styles/styles.js';

const BetSlipOutcomeOddsColor = ({theme:{$betSlipOutcomeOddsColor}}) => `${$betSlipOutcomeOddsColor}`;
const BetSlipOutcomeEventNameColor = ({theme:{$betSlipOutcomeEventNameColor}}) => `${$betSlipOutcomeEventNameColor}`;
const BetSlipOutcomeRemoveIconColor = ({theme:{$betSlipOutcomeRemoveIconColor}}) => `${$betSlipOutcomeRemoveIconColor}`;
const BetSlipOutcomeGameNameColor = ({theme:{$betSlipOutcomeGameNameColor}}) => `${$betSlipOutcomeGameNameColor}`;
const BetSlipOutcomeNameColor = ({theme:{$betSlipOutcomeNameColor}}) => `${$betSlipOutcomeNameColor}`;
const BetSlipOutcomeBorderColor = ({theme:{$betSlipOutcomeBorderColor}}) => `${$betSlipOutcomeBorderColor}`;
const BetSlipOutcomeBackgroundColor = ({theme:{$betSlipOutcomeBackgroundColor}}) => `${$betSlipOutcomeBackgroundColor}`;
const BetSlipOutcomeEvenBackgroundColor = ({theme:{$betSlipOutcomeEvenBackgroundColor}}) => `${$betSlipOutcomeEvenBackgroundColor}`;

const baseFont = `12px`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.9;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 0.9;
  }
`;

const S = {};

S.BetSlipAccumulator = styled.div`
    max-height: 500px;
`;

S.Outcome = styled.div`
   padding: 0;
   border-bottom: 1px solid ${BetSlipOutcomeBorderColor};
   display: flex;
   background: ${BetSlipOutcomeBackgroundColor};
   
   &.no-active{
    background-color: grey;
    opacity: 0.5;
  }
   
   &.unavailable{
     background-color: red;
   }
   &.oddsChanged{
    &.up{
       border: 2px dashed green;
    }
    &.down{
        border: 2px dashed red;
    }
   }

   &:nth-child(even) {
       background: ${BetSlipOutcomeEvenBackgroundColor};
   }
`;

S.OutcomeBody = styled.div`
    display: flex;
    flex: 1;
    padding: ${rem('8px', baseFont)};
`;

S.SportIcon = styled.svg`
    width: 18px;
    height: 18px;
    margin-right: 1rem;
`;

S.OutcomeLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

S.OutcomeRightSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${rem('40px', baseFont)};
    align-items: flex-end;
`;

S.EventName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeEventNameColor};
    font-size: ${rem('12px', baseFont)};
    
    & > span {
        ${({theme: { lineClamp }}) => lineClamp(2)};
        font-weight: 600;
        cursor: pointer;
    }
`;

S.LiveIndicator = styled.div`
    align-self: center;
    background-color: #CE0006;
    color: #fff;
    height: ${rem('19px', baseFont)};
    text-transform: uppercase;
    flex-shrink: 0;
    margin-left:  ${rem('5px', baseFont)};
    padding: 0 ${rem('5px', baseFont)};
    border-radius: 3px;
    display: flex;
    align-items: center;
    font-weight: bold;
`;

S.GameName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeGameNameColor};
    font-size: ${rem('10px', baseFont)};
`;

S.OutcomeName =  styled.div`
    display: flex;
    color: ${BetSlipOutcomeNameColor};
    font-size: ${rem('11px', baseFont)};
    margin-top: ${rem('5px', baseFont)};
`;

S.OutcomeOdds = styled.div`
    color: ${BetSlipOutcomeOddsColor};
    font-size: ${rem('12px', baseFont)};
    font-weight: bold;
`;

S.PrevOdds = styled.span`
    font-size: ${rem('12px', baseFont)};
    position: relative;
    &.up,
    &.down{
         padding-left: 25px;
         &:before{
              left: 5px;
              top: 4px;
              content: '';
              width: 0;
              height: 0;
              position: absolute;
              animation-direction: normal;
              animation-duration: 1.5s;
              animation-iteration-count: infinite;
              animation-name: ${blinkAnimation};
              animation-timing-function: ease;
          }
    }
    &.up{
        color: green;
        &:before{
            border-color: green transparent;
	        border-style: solid;
	        border-width: 0px 7px 7px 7px;
        }
    }
    &.down{
        color: red;
        &:before{
            border-color: red transparent;
	        border-style: solid;
	        border-width: 7px 7px 0px 7px;
        }
     }
`;

S.RemoveOutcome = styled(Button)`
    padding: 0;
    background-color: transparent;
    flex-shrink: 0;
    width:  ${rem('12px', baseFont)};
`;


export default S;