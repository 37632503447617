const accountStatus = 
    {

        INACTIVE: -1,
        
        ACTIVE: 1,
        
        CLOSED_TEMPORARY: 10,
        
        CLOSED_BY_CUSTOMER: 11,
        
    };

export default accountStatus;