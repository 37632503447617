import styled,{css} from 'styled-components';
import {List, Link} from 'global/styles/styles.js';
import { rem } from 'polished';

const baseFont = `12px`;
const S = {};

const TotalBonusWrapperBackground = ({ theme: { $betSlipBonusWrapperBackground } }) => `${$betSlipBonusWrapperBackground}`;
const TotalBonusWrapperText = ({ theme: { $betSlipBonusWrapperText } }) => `${$betSlipBonusWrapperText}`;

S.TotalBonusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.67rem;
    border-radius: 0.25rem;
    margin-top: 0.67rem;
    background: ${TotalBonusWrapperBackground};
`;

S.TotalBonusTitle = styled.div`
    color: ${TotalBonusWrapperText};
    font-size: ${rem('14px', baseFont)};
    font-weight: bold;
    text-align: center;
`;

S.TotalBonusAdditionalInfo = styled.div`
    margin-top: ${rem('6px', baseFont)};
    color: ${TotalBonusWrapperText};
    font-size: ${rem('14px', baseFont)};
    text-align: center;
`;

export default S;