import React, { Component } from 'react';
import Select from 'react-select';
import _find from 'lodash/find';
import _each from 'lodash/each';
import { translation, redirect } from 'helpers/utilsHelper.js';
import { isCurrentViewEsport } from 'helpers/spikeHelper.js';
import S from 'StyledTimeFilter.js';


class TimeFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hourRange: [1, 2, 4, 8, 16, 24, 36, 48, 72, 96],
            selectedHour: {
                value: null,
            },
        };
    };

    getTimeList = () => {
        const options = [];

        _each(this.state.hourRange, ( hour ) => {
            const timeCategory = {
                value: hour,
                label: `${hour}h`,
            };
            options.push(timeCategory);
        })
        return options;
    };

    selectedOption = () => {
        const selectedTime = this.state.selectedHour;
        const selectedValue = _find(this.getTimeList(), { value: Number(selectedTime) });
        return selectedValue;
    };

    onTimeChange = ({ value }) => {
        const selectedTime = value;
        this.setState({
            selectedHour: _find(this.getTimeList(), { value: Number(selectedTime) })
        })

        this.redirectToEventList(selectedTime);
    };

    redirectToEventList = (selectedTime) => {
        const params = {
            hour: selectedTime,
        };
        const queryParams = {
            isEsport: isCurrentViewEsport(),
        };
        redirect('/search/hour/:hour', params, queryParams);
    };



    render() {
        return (
            <S.TimeSelect
                as={Select}
                options={this.getTimeList()}
                value={this.selectedOption()}
                onChange={this.onTimeChange}
                placeholder={translation('filters_timeFilterPlaceholder')}
                classNamePrefix={'react-select'}
            />
        )
    }
}

export default TimeFilter;