import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import S from 'StyledLoggedPanel.js';
import {translation, formatMoney, redirect} from 'utilsHelper.js';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _get from 'lodash/get';
import useToggle from 'UseToggle.js';
import ModalOpacity from 'ModalOpacity.js';
import ResponsibleGamingForm from 'ResponsibleGamingForm.js';
import RegisterConfirmation from 'RegisterConfirmation.js';
import { calculateWinning } from 'betSlipActions.js'
import BalanceIcon from 'balance_icon.svg';
import AccountIcon from 'account_icon.svg';
import CashbackIcon from 'cashback_icon.svg';
import CmsPage from 'CmsPage.js';
import { getCustomerAvatar } from 'customerActions.js';
import {startIntervalLoadCustomerOffers, stopIntervalLoadCustomerOffers} from 'offersActions.js';

const CASHBACK_AUDIO_FILE = '/static/sounds/cha-ching.mp3';

const LoggedPanel = ({user, customerRegulations, logOut, balanceId, toggleBalance, toggleUserAccountModal, isAccountOpen, freebets, offersBalances, calculateWinning, startIntervalLoadCustomerOffers, stopIntervalLoadCustomerOffers, getCustomerAvatar}) => {

    const {login, firstName, userId, currencyCode, balance, bonusBalanceList, status: accountStatus, daysFromRegistration } = user;
    //const [showAccountModal, toggleAccountModal] = useToggle(isAccountOpen);
    const [showResponsibleGamingModal, toggleResponsibleGamingModal] = useToggle(false);
    const [showConfirmationModal, toggleConfirmationModal] = useToggle(false);
    const [showTemporaryAccountInformation, toggleTemporaryAccountInformation] = useToggle(false);
    const [showCashbackInfoModal, toggleCashbackInfoModal] = useToggle(false);
    const [temporaryAccountParam, setTemporaryAccountParam] = useState('account_temporaryAccount_information23days');
    const [cashbackSoundFile, setCashbackSoundFile] = useState(new Audio(CASHBACK_AUDIO_FILE));
    const cashbackRef = useRef(({cashBackBalance: 0, initial: true}));
    const avatarPlacementRef = useRef(null);
    const avatarDefaultRef = useRef(null);

    const onBalanceChange = ({value}) => {
        const balanceId = value;
        const setBonusBalance = Boolean(balanceId);

        toggleBalance(setBonusBalance, balanceId);
        localStorage.setItem('_bonus_balance_id', balanceId);
    };

    const selectedOption = () => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;
        const selectedValue = _find(getBalanceList(), {value: Number(currentBalanceId)});
        return selectedValue;
    };

    const getBalanceList = () => {
        const options = [];
        const mainBalance = {
            value: 0,
            label: `${translation('account_loggedPanel_balance')}: ${formatMoney(balance)} ${currencyCode}`
        };

        options.push(mainBalance);

        _each(bonusBalanceList, ({nameKey, currency, wagerBonusId, amount}) => {
            const bonusBalance = {
                value: wagerBonusId,
                label: `${translation(nameKey)}: ${formatMoney(amount)} ${currency}`
            };
            options.push(bonusBalance);
        });

        return options;
    };

    const getCashbackBalanceAmount = () => { 
        const offer = offersBalances.find((o)=>o.identifier == process.env.CASHBACK_PLUS_OFFER_IDENTIFIER);
        let balance = '0.00';
        if ( offer ){
            balance = formatMoney(offer.customerBalance);
            compareCashbackAmount(offer)
        }
        return `${balance} ${translation('common_pln')}`;
    };

    const checkIt = () => { 
        const offer = offersBalances.find((o)=>o.identifier == process.env.CASHBACK_PLUS_OFFER_IDENTIFIER);
        let balance = 0;
        if ( offer ){
            balance = offer.customerBalance;
        }
        return balance
    };

    const compareCashbackAmount = (offer) => {
        const incomingBalance = offer.customerBalance;
        const cashbackNewInfo = {cashBackBalance: incomingBalance, initial: false}
        //useRef trzyma stan między renderami, ale nie ma możliwości wywołania funkcji w initial value. Przy initial loadingu danych updatujemy stan refa, aby nei wywoływac modala, za 1szym wejściem
        if(cashbackRef.current.initial) {cashbackRef.current = cashbackNewInfo}
        //cashbackRef.current.initial = false

        let currentBalanceAmount = cashbackRef.current.cashBackBalance;
        let currentBalanceStatus = cashbackRef.current.initial;

        if(incomingBalance > currentBalanceAmount && !currentBalanceStatus) {
            cashbackSoundFile.play()
            toggleCashbackInfoModal()
            cashbackRef.current = cashbackNewInfo
        } else if(offer.customerBalance < currentBalanceAmount){
            cashbackRef.current = cashbackNewInfo
        }
    }

    useEffect(() => {
        const currentBalanceId = localStorage.getItem('_bonus_balance_id') || balanceId;
        const setBonusBalance = Boolean(parseInt(currentBalanceId));

        toggleBalance(setBonusBalance, currentBalanceId);

        // Logout user - need calculateWinning call
        return () => {
            toggleBalance(false, 0);
            calculateWinning();
         }
    }, []);

    useEffect(() => {
        app.service.BetSlip.betSlip.placeFromBonusBalance = Boolean(balanceId);
        calculateWinning();
    }, [balanceId]);

    useEffect(() => {
        const bettingAllowed = _get(customerRegulations, ['bettingAllowed']);
        const bettingDisabledReasons = _get(customerRegulations, ['bettingDisabledReasons']);
        if(!bettingAllowed && bettingDisabledReasons && (bettingDisabledReasons.indexOf('REGULATIONS_NOT_ACCEPTED')!=-1)){
            toggleResponsibleGamingModal();
        }
    }, []);

    useEffect(() => {
        if(accountStatus == 'TEMPORARY_ACCOUNT') {
            if(
                daysFromRegistration > process.env.DAYS_FROM_REGISTRATION_POPUP_START
                && daysFromRegistration <= process.env.DAYS_FROM_REGISTRATION_POPUP_STOP
            ) {
                setTemporaryAccountParam(`account_temporaryAccount_information${process.env.DAYS_FROM_REGISTRATION_POPUP_START}days`);
                toggleTemporaryAccountInformation();
            } else if (daysFromRegistration > process.env.DAYS_FROM_REGISTRATION_POPUP_STOP) {
                setTemporaryAccountParam(`account_temporaryAccount_information${process.env.DAYS_FROM_REGISTRATION_POPUP_STOP}days`);
                toggleTemporaryAccountInformation();
            }
        }
    }, []);

    useEffect(() => {
        startIntervalLoadCustomerOffers();
        return () => stopIntervalLoadCustomerOffers();
    }, []);

    useEffect(() => {

        const fetchCustomerAvatar = async () => {

            let path = await getCustomerAvatar();
            if (path){
                path = JSON.stringify(path).replace(/\\\\/g,'/');
                path = JSON.parse(path).toString();
                let completePath = `${process.env.DOMAIN_URL}/${path}`;
                avatarPlacementRef.current.style.backgroundImage = `url('${completePath}')`;
                avatarPlacementRef.current.style.backgroundSize = `cover`;
                avatarPlacementRef.current.style.backgroundPosition = `center`;
                avatarDefaultRef.current.className = 'hidden-default-avatar';
            } else {
                avatarDefaultRef.current.style.display = 'block';
            }

        }

        fetchCustomerAvatar();

    }, []);

    return (
        <S.LoggedPanel className="logged-panel-box">

            <ModalOpacity isOpen={showConfirmationModal} toggleOpen={toggleConfirmationModal} showHeader={false} padding={0}>
                <RegisterConfirmation toggleOpen={toggleConfirmationModal}/>
            </ModalOpacity>

            <ModalOpacity isOpen={showResponsibleGamingModal}  title="Odpowiedzialana gra" showHeader={false} padding={0}>
                <ResponsibleGamingForm toggleOpen={toggleResponsibleGamingModal} toggleConfirmationModal={toggleConfirmationModal}></ResponsibleGamingForm>
            </ModalOpacity>
            
            {/* Commented out TemporaryAccountModal*/}
            {/* <ModalOpacity 
                isOpen={showTemporaryAccountModal && !showConfirmationModal && !showResponsibleGamingModal} 
                toggleOpen={toggleTemporaryAccountModal} 
                showHeader={false} 
                showHeaderIcon={false} 
                showHeaderTitle={false} 
                headerPadding={0} 
                padding={0}
                minHeightBody={'150px'}
                minWidthBody={'500px'}
                justifyContentBody={'center'}
            >
                <CmsPage name={`${temporaryAccountParam}`} isPlaces={true}/>
            </ModalOpacity> */}

            <ModalOpacity 
                // Commented out TemporaryAccountModal
                // isOpen={showCashbackInfoModal && !showTemporaryAccountModal && !showConfirmationModal && !showResponsibleGamingModal}
                isOpen={showCashbackInfoModal && !showConfirmationModal && !showResponsibleGamingModal} 
                toggleOpen={toggleCashbackInfoModal} 
                showHeader={true} 
                showHeaderIcon={false} 
                showHeaderTitle={false} 
                headerPadding={0} 
                padding={0}
                minHeightBody={'150px'}
                minWidthBody={'500px'}
                justifyContentBody={'center'}
                backgroundWrapper={'transparent'}
                backgroundCurtain={'rgba(0,0,0,0.4)'}
                positionByMargin={'0 0 0 auto'}
                closeIconColor={'#fff'}
            >
                <CmsPage name="cashbackInfo" alternativeContent="cashback-info" isPlaces={true}/>
            </ModalOpacity>
            <S.DepositButton onClick={redirect.bind(null, '/konto/wplata')}>{translation('account_userPanel_deposit')}</S.DepositButton>

            <S.Balance onClick={redirect.bind(null, '/konto/wplata')}>
                <S.BalanceIcon dangerouslySetInnerHTML={{__html: BalanceIcon }}></S.BalanceIcon>
                <S.BalanceAmount>{formatMoney(balance)} {translation('common_pln')}</S.BalanceAmount>
            </S.Balance>

            <S.Cashback onClick={redirect.bind(null, '/konto/cashback')}>
                <S.CashbackIcon dangerouslySetInnerHTML={{__html: CashbackIcon}}></S.CashbackIcon>
                <S.CashbackAmount>{getCashbackBalanceAmount()}</S.CashbackAmount>
            </S.Cashback>

            <S.UserAccount onClick={redirect.bind(null, '/konto/historia-kuponow')} ref={avatarPlacementRef}>
                <S.UserAccountIcon dangerouslySetInnerHTML={{__html: AccountIcon}} ref={avatarDefaultRef}></S.UserAccountIcon>
            </S.UserAccount>

            {showTemporaryAccountInformation && (
                <S.TemporaryAccountInformation data-translation={temporaryAccountParam}>{translation(temporaryAccountParam)}</S.TemporaryAccountInformation>
            )}

        </S.LoggedPanel>
    );
};

const mapDispatchToProps = {
    calculateWinning,
    startIntervalLoadCustomerOffers,
    stopIntervalLoadCustomerOffers,
    getCustomerAvatar
}

export default withRouter(connect(null, mapDispatchToProps)(React.memo(LoggedPanel)));
