import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getSimpleIframeGame } from 'integrationActions.js';
import tvBetGames from 'tvBetGames.enum.js';
import * as styled from 'StyledTvBet';

const PROVIDER = 'tvbet';

const Loader = () => {
  return (
    <styled.LoaderWrapper>
      <styled.Loader />
    </styled.LoaderWrapper>
  )
}

const Iframe = ({
  iframeUrl,
  onLoad
}) => {
  if (!iframeUrl) return null

  return (
    <styled.Iframe
      src={iframeUrl}
      onLoad={onLoad}
      scrolling='no'
      title='TvBet'
      id='TvBetIframe'
      allowfullscreen
      allow="autoplay"
    />
  )
}

const onMessage = (event) => {
  const iframe = document.querySelector('#TvBetIframe')

  try {
    const { tvbet_height: height } = JSON.parse(event.data)

    if (height) iframe.height = `${height}px`;
  } catch (err) {
    // silently ignore
  }
}

const TvBet = ({ isLogged }) => {
  const [iframeUrl, setIframeUrl] = useState(undefined)
  const [isLoaderShown, setIsLoaderShown] = useState(true)
  const [key, setKey] = useState(+new Date())

  const onIframeLoad = () => {
    setIsLoaderShown(false)

    // Color body onLoad
    window.document.body.style.backgroundColor = '#141517'
  }

  const dispatch = useDispatch()

  useEffect(() => {

    const getIframeUrl = async () => {
      let gameId = null;
      if (process.env.TVBET_START_URL_GAME) {
        const href = window.location.pathname;
        for (let gameName in tvBetGames) {
          if (href.includes(gameName)) {
            gameId = tvBetGames[gameName];
            break;
          }
        }
      }
      const iframeUrl = await dispatch(getSimpleIframeGame(PROVIDER, gameId))

      setIframeUrl(iframeUrl)
    }

    getIframeUrl()
    setKey(+new Date())
  }, [dispatch, isLogged])

  useEffect(() => {
    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <>
      {isLoaderShown && <Loader />}
      <Iframe key={key} iframeUrl={iframeUrl} onLoad={onIframeLoad} />
    </>
  )
}

const mapStateToProps = ({ Auth }) => {
  return Auth
}

export default connect(mapStateToProps)(TvBet)
