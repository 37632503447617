import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect, batch } from 'react-redux';
import { pl } from 'date-fns/locale';
import classNames from 'classnames';
import Box from 'react-styled-box';
import { translation, formatMoney } from 'utilsHelper.js';
import { formatDate, substractDays, compareAsc } from 'datesHelper.js';
import { changeDateByType, changeActiveTab, fetchTransactionsByFilter, setCurrentPage, resetToInitialState, cancellPayout } from 'accountWalletActions.js';
import { proccessFrontError } from 'errorActions.js';
import Loader from 'Loader.js';
import ModalOpacity from 'ModalOpacity.js';
import Datepicker from 'Datepicker.js';
import Pagination from 'Pagination.js';
import ArrowIcon from 'arrow_icon.svg';
import BellIcon from 'bell-icon.svg';
import S from 'StyledAccountWallet.js';

class AccountWallet extends Component {
  state = {
    transactions: [],
    selectedTransactionId: null,
    showConfirmationModal: false,
  };

  componentDidMount = () => {
    const { fetchTransactionsByFilter, resetToInitialState, processFrontError } = this.props;
    try {
      batch(() => {
        resetToInitialState();
        fetchTransactionsByFilter();
      })
    } catch (error) {
      processFrontError(error, 'AccountWallet');
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isPending !== this.props.isPending && !this.props.isPending && this.props.data?.length) {
      this.prepeareStateFromTransations(this.props.data);
    }
  }

  prepeareStateFromTransations = (data) => {
    const transactions = data.map((transaction) => ({ ...transaction, isOpen: false }));
    this.setState(() => ({ transactions }));
  };

  handleChangeStart = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const startDate = new Date(date);

    batch(() => {
      changeDateByType('startDate', startDate);
      fetchTransactionsByFilter({ startDate });
    })
  };

  handleChangeEnd = (date) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const endDate = new Date(date);

    batch(() => {
      changeDateByType('endDate', endDate);
      fetchTransactionsByFilter({ endDate });
    })
  };

  onChangeFilter = (activeTab) => {
    const { changeActiveTab, fetchTransactionsByFilter } = this.props;

    batch(() => {
      changeActiveTab(activeTab);
      fetchTransactionsByFilter({ activeTab });
    })
  };

  toggleDropdown = (id) => {
    const indexOfItem = this.findIndexOfItem(id);
    if (indexOfItem !== -1) {
      const [item] = this.state.transactions.slice(indexOfItem, indexOfItem + 1);
      this.updateTransactionItem(indexOfItem, item);
    }
  };

  findIndexOfItem = (searchId) => {
    return this.state.transactions.findIndex(({ transactionId }) => transactionId === searchId);
  };

  findTransactionById = (searchId) => {
    return this.state.transactions.find(({ transactionId }) => transactionId === searchId);
  };

  updateTransactionItem = (indexOfItem, item) => {
    this.setState((prevState) => {
      const itemsBeforeIndex = prevState.transactions.slice(0, indexOfItem);
      const itemsAfterIndex = prevState.transactions.slice(indexOfItem + 1);
      const nextState = {
        ...prevState,
        transactions: [...itemsBeforeIndex, { ...item, isOpen: !item.isOpen }, ...itemsAfterIndex],
      };
      return nextState;
    });
  };

  /*
    PENDING = "INI";
    FAILED = "FLD";
    SUCCESS = "SCS";
    DECLINED = "DEC";
    ERROR = "ERR";
    IN_PROGRESS = "IPR"
  */
  getTransactionStatusClass = (statusCode) => {
    const status = statusCode.toUpperCase();
    switch (status) {
      case 'DEC':
      case 'FLD':
      case 'ERR':
        return 'red';
      case 'SCS':
        return 'green';
      case 'IPR':
      case 'INI':
        return 'gray';
      default:
        return '';
    }
  };

  parseStatus = (status) => {
    return 'transfer_statusList.' + status;
  };

  parseType = (type) => {
    return 'transferTypeList.' + type;
  };

  goPage = async (nextPage) => {
    const { fetchTransactionsByFilter, setCurrentPage, processFrontError } = this.props;
    try {
      batch(async () => {
        await fetchTransactionsByFilter({ nextPage });
        setCurrentPage(nextPage);
      })
    } catch (error) {
      processFrontError(error, 'AccountWallet.goPage');
    }
  };

  setStartDate = (days) => {
    const { changeDateByType, fetchTransactionsByFilter } = this.props;
    const today = new Date();
    const startDate = substractDays(today, days);
    const endDate = days === 1 ? startDate : today;

    batch(() => {
      changeDateByType('startDate', startDate);
      changeDateByType('endDate', endDate);
      fetchTransactionsByFilter({ startDate, endDate });
    })
  };

  compareDates = (dateLeft, dateRight) => {
    dateLeft = new Date(formatDate(dateLeft, 'yyyy-MM-dd'));
    dateRight = new Date(formatDate(dateRight, 'yyyy-MM-dd'));
    const result = compareAsc(dateLeft, dateRight);

    if (!result) {
      return true;
    }
    return false;
  };

  openConfirmPopupCancellPayout = (transactionId) => {
    this.setSelectedTransactionId(transactionId);
    this.toggleModal('showConfirmationModal');
  };

  setSelectedTransactionId = (selectedTransactionId) => {
    this.setState((prevState) => {
      return { ...prevState, selectedTransactionId };
    });
  };

  cancelPayoutAction = async (transactionId) => {
    const { fetchTransactionsByFilter, cancellPayout, processFrontError } = this.props;
    try {
      this.toggleModal('showConfirmationModal');
      batch(async () => {
        await cancellPayout({ transactionId });
        fetchTransactionsByFilter();
      })
    } catch (error) {
      processFrontError(error, 'AccountWallet.cancelPayoutAction');
    }
  };

  toggleModal = (type) => {
    this.setState((prevState) => {
      return { ...prevState, [type]: !prevState[type] };
    });
  };

  render() {
    const { isPending, startDate, endDate, activeTab, data, prevData, nextData, currentPage, limit } = this.props;
    const { showConfirmationModal, selectedTransactionId } = this.state;
    const today = new Date();

    return (
      <S.AccountWallet>
        <ModalOpacity
          preventCloseOnOverlay
          padding="0px"
          width="350px"
          isOpen={showConfirmationModal}
          toggleOpen={() => this.toggleModal('showConfirmationModal')}
        >
          <S.ConfirmModal>
            <S.BellContainer>
              <S.BellIcon dangerouslySetInnerHTML={{ __html: BellIcon }}></S.BellIcon>
            </S.BellContainer>
            <S.Description>{translation('account_wallet_confirmCancelPayoutQuestion')}</S.Description>
            <S.BtnsWrapper>
              <S.ButtonAccept onClick={() => this.cancelPayoutAction(selectedTransactionId)}>{translation('common_yes')}</S.ButtonAccept>
              <S.ButtonCancel onClick={() => this.toggleModal('showConfirmationModal')}>{translation('common_cancel')}</S.ButtonCancel>
            </S.BtnsWrapper>
          </S.ConfirmModal>
        </ModalOpacity>

        <S.WalletFilters>
          <S.WalletFiltersTop>
            <S.WalletFiltersDates>
              <S.DateWrapper>
                <S.DateLabel>{translation('common_dateFrom')}</S.DateLabel>
                <Datepicker selected={startDate} locale={pl} dateFormat="yyyy-MM-dd" cb={this.handleChangeStart} />
                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
              </S.DateWrapper>

              <S.DateWrapper>
                <S.DateLabel>{translation('common_dateTo')}</S.DateLabel>
                <Datepicker selected={endDate} locale={pl} dateFormat="yyyy-MM-dd" cb={this.handleChangeEnd} />
                <S.DateIcon className="ion ion-ios-calendar-outline"></S.DateIcon>
              </S.DateWrapper>
            </S.WalletFiltersDates>

            <S.WalletFiltersBtns>
              <S.FilterBtn isActive={this.compareDates(startDate, today) && this.compareDates(endDate, today)} onClick={this.setStartDate.bind(this, 0)}>
                {translation('account_wallet_startDateToday')}
              </S.FilterBtn>

              <S.FilterBtn
                isActive={this.compareDates(startDate, substractDays(today, 1)) && this.compareDates(endDate, substractDays(today, 1))}
                onClick={this.setStartDate.bind(this, 1)}
              >
                {translation('account_wallet_startDateYesterday')}
              </S.FilterBtn>

              <S.FilterBtn
                isActive={this.compareDates(startDate, substractDays(today, 7)) && this.compareDates(endDate, today)}
                onClick={this.setStartDate.bind(this, 7)}
              >
                {translation('account_wallet_startDate7days')}
              </S.FilterBtn>

              <S.FilterBtn
                isActive={this.compareDates(startDate, substractDays(today, 30)) && this.compareDates(endDate, today)}
                onClick={this.setStartDate.bind(this, 30)}
              >
                {translation('account_wallet_startDate30days')}
              </S.FilterBtn>
            </S.WalletFiltersBtns>
          </S.WalletFiltersTop>

          <S.WalletFiltersBottom>
            <S.RadioButtons>
              <S.RadioButton onClick={() => this.onChangeFilter('all')}>
                <S.RadioButtonInput className={classNames({ active: activeTab == 'all' })} type="radio" name="transaction-type" />
                <S.RadioButtonControlIndicator />
                <S.RadioButtonText>{translation('account_wallet_types_all')}</S.RadioButtonText>
              </S.RadioButton>

              <S.RadioButton onClick={() => this.onChangeFilter('deposit')}>
                <S.RadioButtonInput className={classNames({ active: activeTab == 'deposit' })} type="radio" name="transaction-type" />
                <S.RadioButtonControlIndicator />
                <S.RadioButtonText>{translation('account_wallet_types_deposits')}</S.RadioButtonText>
              </S.RadioButton>

              <S.RadioButton onClick={() => this.onChangeFilter('payout')}>
                <S.RadioButtonInput className={classNames({ active: activeTab == 'payout' })} type="radio" name="transaction-type" />
                <S.RadioButtonControlIndicator />
                <S.RadioButtonText>{translation('account_wallet_types_payouts')}</S.RadioButtonText>
              </S.RadioButton>

              <S.RadioButton onClick={() => this.onChangeFilter('promotion')}>
                <S.RadioButtonInput className={classNames({ active: activeTab == 'promotion' })} type="radio" name="transaction-type" />
                <S.RadioButtonControlIndicator />
                <S.RadioButtonText>{translation('account_wallet_types_cashback')}</S.RadioButtonText>
              </S.RadioButton>
            </S.RadioButtons>
          </S.WalletFiltersBottom>
        </S.WalletFilters>

        <S.WalletTransactions className="wallet-transaction-list">
          {isPending ? (
            <Loader />
          ) : (
            <>
              <S.WalletTransactionsList className="transaction-list">
                <S.FakeTableHead>
                  <div className="wallet-toggle"></div>
                  <div className="wallet-date">{translation('account_wallet_transfers_date')}</div>
                  <div className="wallet-type">{translation('account_wallet_transfers_type')}</div>
                  <div className="wallet-amount">{translation('account_wallet_transfers_amount')}</div>
                  <div className="wallet-status">{translation('account_wallet_transfers_status')}</div>
                </S.FakeTableHead>
                {this.state.transactions?.length > 0 ? (
                  <S.FakeTableBody>
                    {this.state.transactions.map(({ amount, regDate, type, statusCode, transactionId, referenceId }) => {
                      // For initializated withdrawns, tab could be cancelled - open tab on start
                      const isWithdrawalInitialized = type === 156 && statusCode === 'INI';
                      const { isOpen } = this.findTransactionById(transactionId) ?? {};
                      if (isWithdrawalInitialized && isOpen) {
                        isOpen = !isOpen;
                      }

                      return (
                        <S.WalletTransactionsListItemWrapper key={transactionId}>
                          <S.WalletTransactionsListItem className="transaction-list-item">
                            <div className="wallet-toggle" onClick={this.toggleDropdown.bind(this, transactionId)}>
                              <S.ArrowIcon isOpen={isOpen} dangerouslySetInnerHTML={{ __html: ArrowIcon }} />
                            </div>

                            <div className="wallet-date">
                              <span>{formatDate(regDate, 'dd-MM-yyyy')}</span>
                            </div>

                            <div className="wallet-type" title={translation(this.parseType(type))}>
                              <div>{translation(this.parseType(type))}</div>
                            </div>

                            <div className={`wallet-amount ${amount > 0 ? 'green' : 'red'}`}>
                              {formatMoney(amount)} <span className="currency">{translation('common_pln')}</span>
                            </div>

                            <div className={`wallet-status ${this.getTransactionStatusClass(statusCode)}`}>
                              <div className="status-icon"></div>
                            </div>
                          </S.WalletTransactionsListItem>

                          <S.WalletTransactionsListItemDetails isOpen={isOpen}>
                            <Box flexDirection="column">
                              <Box justifyContent="center" margin="30px 0 26px">
                                <S.ItemDetailsTitle>
                                  {`${translation(this.parseType(type))}:`} {formatMoney(amount)} {translation('common_pln')}
                                </S.ItemDetailsTitle>
                              </Box>

                              {isWithdrawalInitialized ? (
                                <Box justifyContent="center" margin="0 0 26px 0">
                                  <S.CancellPayoutBtn onClick={() => this.openConfirmPopupCancellPayout(referenceId)}>
                                    {translation('account_wallet_cancellPayout')}
                                  </S.CancellPayoutBtn>
                                </Box>
                              ) : null}

                              <Box justifyContent="space-between" margin="0 0 16px">
                                <Box flexDirection="column" alignItems="center" width="33%">
                                  <S.ItemDetailsHeader>{translation('common_id')}</S.ItemDetailsHeader>
                                  <S.ItemDetailsBody>{transactionId}</S.ItemDetailsBody>
                                </Box>
                                <Box flexDirection="column" alignItems="center" width="33%">
                                  <S.ItemDetailsHeader>{translation('account_wallet_transfers_type')}</S.ItemDetailsHeader>
                                  <S.ItemDetailsBody>{translation(this.parseType(type))}</S.ItemDetailsBody>
                                </Box>
                                <Box flexDirection="column" alignItems="center" width="33%">
                                  <S.ItemDetailsHeader>{translation('account_wallet_transfers_date')}</S.ItemDetailsHeader>
                                  <S.ItemDetailsBody>
                                    <span>{formatDate(regDate, 'dd-MM-yyyy HH:mm')}</span>
                                  </S.ItemDetailsBody>
                                </Box>
                              </Box>

                              <Box flexDirection="column" alignItems="center" margin="0 0 30px">
                                <S.ItemDetailsHeader>{translation('account_wallet_transfers_status')}</S.ItemDetailsHeader>
                                <S.ItemDetailsBody className={this.getTransactionStatusClass(statusCode)}>
                                  {translation(this.parseStatus(statusCode))}
                                </S.ItemDetailsBody>
                              </Box>
                            </Box>
                          </S.WalletTransactionsListItemDetails>
                        </S.WalletTransactionsListItemWrapper>
                      );
                    })}
                  </S.FakeTableBody>
                ) : (
                  <S.NoResult className="no-result">{translation('account_wallet_transfers_notFound')}</S.NoResult>
                )}
              </S.WalletTransactionsList>

              {data?.length > 0 ? <Pagination useNewPagination cb={this.goPage} {...{ currentPage, limit, data, prevData, nextData }} /> : null}
            </>
          )}
        </S.WalletTransactions>
      </S.AccountWallet>
    );
  }
}

const mapStateToProps = ({ AccountWallet: { isPending, error, startDate, endDate, activeTab, data, prevData, nextData, currentPage, limit } }) => {
  return {
    error,
    isPending,
    startDate,
    endDate,
    activeTab,
    currentPage,
    limit,
    data,
    prevData,
    nextData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveTab: bindActionCreators(changeActiveTab, dispatch),
    changeDateByType: bindActionCreators(changeDateByType, dispatch),
    fetchTransactionsByFilter: bindActionCreators(fetchTransactionsByFilter, dispatch),
    setCurrentPage: bindActionCreators(setCurrentPage, dispatch),
    resetToInitialState: bindActionCreators(resetToInitialState, dispatch),
    cancellPayout: bindActionCreators(cancellPayout, dispatch),
    processFrontError: bindActionCreators(proccessFrontError, dispatch),
    dispatch,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountWallet);
